import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, Divider, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { Add, Backspace, Cancel } from '@mui/icons-material';
import { handleModal } from '../../../store/slices/modalSlice';
import BaseInputValidation from '../../Input/Base/BaseInputValidation';
import crmApi from '../../../api/CRM/CRMCustomers';

const valueTypes = ['Datetime', 'Formula', 'Option', 'Numeric', 'Text'];

const generalConfig = [
  { key: 'is_required', name: 'Is Required', type: 'boolean' },
  // Strange behavior
  // { key: 'on_project_list ', name: 'Display on Project List', type: 'boolean' },
];
const optionConfig = [{ key: 'multiple_value', name: 'Multiple Value', type: 'boolean' }];

// const formatConfig = [
//   {
//     key: 'note',
//     type: 'string',
//     used: 'global',
//   },
//   {
//     key: 'unit',
//     type: ['string', 'array'],
//     used: 'global',
//   },
//   {
//     key: 'formula',
//     type: 'string',
//     used: 'formula',
//   },
// ];

const symbols = ['+', '-', '*', '/', '(', ')'];
const allowedKey = ['Backspace'];
const staticSchema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  type: yup.string().required('Type is a required field'),
});

function CheckBoxField({ opt, control }) {
  return (
    <FormControl>
      <Controller
        control={control}
        name={opt.key}
        render={({ field: { onChange, value, ref } }) => (
          <FormControlLabel className="h-8" control={<Checkbox onChange={onChange} checked={value || false} inputRef={ref} />} label={opt.name} />
        )}
      />
    </FormControl>
  );
}

const labelStyle = {
  top: '-8px',
  '&.Mui-focused': { top: -8, background: '#fff' },
  '&.MuiFormLabel-filled': { top: -28, background: '#fff' },
  '&.MuiInputLabel-shrink': { top: 0, background: '#fff' },
};

const { createCRMProjectAttribute, actionCRMProjectAttribute } = crmApi();

// /([0-9\+\-\*/\\/\.\(\)])\w+/g

export default function Attribute({ dataAttribute, attributeSelected, refetch }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const activeClient = useSelector((state) => state.client.activeClient);
  const isModal = useSelector((state) => state.modal);
  const [type, setType] = useState(null);
  const [formula, setFormula] = useState('');
  const [objFormula, setObjFormula] = useState({});

  const isInitialize = useRef(false);

  const {
    setValue,
    watch,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(staticSchema),
    defaultValues: {},
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'option', // unique name for your Field Array
  });

  const addFormula = (val, keyName = null) => {
    let currFormula = formula;
    currFormula += val;
    setFormula(currFormula);
    if (!keyName) {
      setObjFormula((state) => {
        let newFormula = state.formula || '';
        newFormula += val;

        const currEAttributes = state.eval_attributes;
        let newEAttributes = currEAttributes;
        if (currEAttributes?.length) {
          currEAttributes.forEach((a) => {
            const slugA = a.toLowerCase().split(' ').join('_');
            if (!currFormula.includes(slugA)) {
              newEAttributes = newEAttributes.filter((v) => v !== a);
            }
          });
        }

        return { ...state, formula: newFormula, eval_formula: currFormula, eval_attributes: newEAttributes };
      });
    } else {
      setObjFormula((state) => {
        const currEAttr = state.eval_attributes || [];
        let newFormula = state.formula || '';
        newFormula += val;
        if (currEAttr?.length) {
          if (!currEAttr.includes(val)) {
            currEAttr.push(val);
          }
        } else {
          currEAttr.push(val);
        }
        return { ...state, formula: newFormula, eval_formula: currFormula, eval_attributes: currEAttr };
      });
    }
    setValue('formula', currFormula);
  };

  const removeFormula = () => {
    const currFormula = formula.slice(0, -1);
    setFormula(currFormula);
    setObjFormula((state) => {
      let newFormula = state.formula || '';
      if (newFormula) {
        newFormula = newFormula.slice(0, -1);
      }

      const currEAttributes = state.eval_attributes;
      let newEAttributes = currEAttributes;
      if (currEAttributes?.length) {
        currEAttributes.forEach((a) => {
          const slugA = a.toLowerCase().split(' ').join('_');
          if (!currFormula.includes(slugA)) {
            newEAttributes = newEAttributes.filter((v) => v !== a);
          }
        });
      }

      return { ...state, formula: newFormula, eval_formula: currFormula, eval_attributes: newEAttributes };
    });
    setValue('formula', currFormula);
  };

  const onCloseModal = () => {
    dispatch(handleModal({ modalId: '', componentName: '' }));
  };

  const wKeyName = watch('key_name');
  const wName = watch('name');

  const onSubmit = (dataForm) => {
    const data = {
      ...dataForm,
      client: activeClient,
      value_type: dataForm.type,
    };
    delete data.option;
    if (dataForm.unit || dataForm.note || dataForm.formula || dataForm.decimal_place) {
      data.format = {};
    }
    if (dataForm.option) data.options = dataForm.option;
    if (dataForm.unit) data.format.unit = dataForm.unit;
    if (dataForm.note) data.format.note = dataForm.note;
    if (dataForm.formula) {
      data.format.formula = objFormula.formula;
      data.format.eval_formula = dataForm.formula;
      data.format.eval_attributes = objFormula.eval_attributes;
    }
    if (dataForm.decimal_place) data.format.decimal_place = +dataForm.decimal_place;

    if (!attributeSelected) {
      createCRMProjectAttribute(data)
        .then(() => {
          onCloseModal();
          refetch();
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.response?.data?.details ? err.response?.data?.details[0] : 'Something went wrong', { variant: 'Error' });
        });
    } else {
      actionCRMProjectAttribute(attributeSelected.id, 'patch', data)
        .then(() => {
          onCloseModal();
          refetch();
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.response?.data?.details ? err.response?.data?.details[0] : 'Something went wrong', { variant: 'Error' });
        });
    }
  };

  useEffect(() => {
    if (wName) {
      setValue('key_name', wName.toLowerCase().split(' ').join('_'));
    } else {
      setValue('key_name', '');
    }
  }, [wKeyName, wName, setValue]);

  useEffect(() => {
    if (attributeSelected && !isInitialize.current) {
      isInitialize.current = true;
      setValue('name', attributeSelected.name);
      setValue('type', attributeSelected.value_type);
      setValue('multiple_value', attributeSelected.multiple_value);
      setValue('is_required', attributeSelected.is_required);
      setValue('on_project_list', attributeSelected.on_project_list);
      if (attributeSelected.options?.length) {
        attributeSelected.options.forEach((opt) => {
          append({ name: opt.name, index: opt.index, configuration: { color: opt.configuration?.color || '#fff' } });
        });
      }

      setType(attributeSelected.value_type);
      if (attributeSelected.format?.formula) {
        setValue('formula', attributeSelected.format.eval_formula);
        setFormula(attributeSelected.format.eval_formula);
        const objAttrFormula = {
          formula: attributeSelected.format.formula,
          eval_formula: attributeSelected.format.eval_formula,
          eval_attributes: attributeSelected.format.eval_attributes,
        };
        if (attributeSelected.format?.decimal_place) {
          setValue('decimal_place', attributeSelected.format.decimal_place);
          objAttrFormula.decimal_place = attributeSelected.format.decimal_place;
        }
        setObjFormula(objAttrFormula);
      } else if (attributeSelected.format?.decimal_place) {
        const objAttr = {};
        setValue('decimal_place', attributeSelected.format.decimal_place);
        objAttr.decimal_place = attributeSelected.format.decimal_place;
        setObjFormula(objAttr);
      }

      if (attributeSelected.format?.unit) {
        setValue('unit', attributeSelected.format.unit);
      }
      if (attributeSelected.format?.note) {
        setValue('note', attributeSelected.format.note);
      }
    }
  }, [attributeSelected, setValue, append]);

  useEffect(() => {
    if (!isModal.isOpen) {
      reset({
        name: '',
        type: '',
        multiple_value: false,
        is_required: false,
        on_project_list: false,
      });
      isInitialize.current = false;
    }
  }, [isModal.isOpen, reset]);

  const renderConfig = (config) => {
    return config.map((opt, i) => {
      let html = null;
      if (opt.type === 'boolean') {
        html = <CheckBoxField key={`${opt.name}-${i + 1}`} opt={opt} control={control} />;
      }
      return html;
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <BaseInputValidation control={control} name="name" label="Name" errors={errors} required />
      <BaseInputValidation control={control} name="key_name" label="Key" errors={errors} disabled />
      <FormControl fullWidth>
        <InputLabel htmlFor="attribute-type" sx={labelStyle} shrink={!!type}>
          Type
        </InputLabel>
        <Select
          id="attribute-type"
          value={type}
          className="h-10"
          sx={{ width: '100%', px: 0.2, py: 0.2 }}
          onChange={(e) => {
            const val = e.target.value;
            setValue('type', val);
            setType(val);
          }}
          displayEmpty
        >
          {valueTypes?.map((v) => (
            <MenuItem key={v} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {type === 'Option' && (
        <div className="flex flex-col gap-2">
          {fields.length > 0 && (
            <div className="flex flex-col gap-2">
              {fields.map((field, i) => {
                return (
                  <div key={field.name} className="flex flex-row justify-between gap-2">
                    <BaseInputValidation control={control} name={`option.${i}.name`} label="Name" errors={errors} />
                    <BaseInputValidation control={control} name={`option.${i}.index`} label="Index" errors={errors} type="number" />
                    <BaseInputValidation control={control} name={`option.${i}.configuration.color`} label="Color" errors={errors} type="color" />
                    <IconButton color="info" aria-label="remove option" title="remove option" onClick={() => remove(i)}>
                      <Cancel />
                    </IconButton>
                  </div>
                );
              })}
            </div>
          )}
          <Button
            className="ml-auto mt-2"
            size="small"
            variant="contained"
            startIcon={<Add />}
            onClick={() => append({ name: '', index: '', configuration: { color: '#ffffff' } })}
          >
            Add
          </Button>
        </div>
      )}
      {['Formula', 'Numeric'].includes(type) && <BaseInputValidation control={control} name="unit" label="Unit" errors={errors} />}
      {['Formula', 'Numeric'].includes(type) && <BaseInputValidation control={control} name="decimal_place" label="Decimal Place" errors={errors} type="number" />}
      <BaseInputValidation control={control} name="note" label="Note" errors={errors} />
      {type === 'Formula' && (
        <div className="flex flex-col gap-4">
          <div className="flex flex-row flex-wrap gap-4">
            {symbols.map((s) => (
              <Button variant="contained" size="small" key={s} onClick={(e) => addFormula(e.target.innerText)}>
                {s}
              </Button>
            ))}
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                if (formula) {
                  removeFormula();
                }
              }}
              startIcon={<Backspace sx={{ fontSize: 20 }} />}
              sx={{ '> .MuiButton-startIcon': { marginRight: 0 } }}
            />
          </div>
          <FormControl fullWidth>
            <InputLabel htmlFor="attribute-field" sx={labelStyle}>
              Field Attribute
            </InputLabel>
            <Select
              id="attribute-field"
              className="h-10"
              sx={{ width: '100%', px: 0.2, py: 0.2 }}
              value=""
              onChange={(e) => {
                addFormula(e.target.value.key_name, e.target.value.name);
              }}
              displayEmpty
            >
              {dataAttribute?.map((v) => (
                <MenuItem key={v.key_name} value={v}>
                  {v.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            type="text"
            label="Formula"
            name="formula"
            className=""
            required
            error={errors && errors.name}
            size="small"
            value={formula}
            onKeyDown={(e) => {
              if (!e) return;
              if (e.key === 'Backspace' && formula) {
                removeFormula();
              }
              if (symbols.includes(e.key)) {
                addFormula(e.key);
                return;
              }
              if (allowedKey.includes(e.key)) return;
              if (/^[a-zA-Z0-9_-]+$/.test(e.key)) {
                addFormula(e.key);
              }
            }}
            multiline
            maxRows={4}
            InputLabelProps={{ shrink: !!formula }}
          />
        </div>
      )}
      {renderConfig(generalConfig)}
      {/* TODO Strange behavior if mapping through general config */}
      <FormControl>
        <Controller
          control={control}
          name="on_project_list"
          render={({ field: { onChange, value } }) => (
            <FormControlLabel className="h-8" control={<Checkbox onChange={onChange} checked={value || false} />} label="Display on Project List" />
          )}
        />
      </FormControl>
      {type === 'Option' && renderConfig(optionConfig)}
      <Divider />
      <Stack direction="row" spacing={1} className="ml-auto">
        <Button variant="outlined" onClick={() => onCloseModal()}>
          Cancel
        </Button>
        <Button type="submit" variant="contained">
          Save
        </Button>
      </Stack>
    </form>
  );
}
