import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nextHttpBackend from 'i18next-http-backend';

const mode = import.meta.env.VITE_MODE;

i18n
  .use(i18nextHttpBackend)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('bzLang') ? localStorage.getItem('bzLang') : 'en',
    backend: {
      // loadPath: 'https://bzpublishadminazure.z7.web.core.windows.net/media/{{lng}}.json',
      // loadPath: 'http://localhost:3000/locales/{{lng}}/translation.json',
      loadPath: mode === 'development' ? 'http://localhost:3000/locales/{{lng}}/translation.json' : `${import.meta.env.VITE_LOCALES_URI}locales/{{lng}}/translation.json`,
      crossDomain: true,
    },
    // fallbackLng: false,

    interpolation: { escapeValue: false },
  });

export default i18n;
