import React from 'react';
import { Circle, Badge } from '@mui/icons-material';

import Job from '../pages/Job/Job';
import JobAdd from '../pages/Job/JobAdd';
import ApplicantDetail from '../pages/Job/ApplicantDetail';
import JobDetail from '../pages/Job/JobDetail';
import Applicant from '../pages/Job/Applicant';
import Stages from '../pages/Job/Stages';
import ApplicantAdd from '../pages/Job/ApplicantAdd';
import ApplicantEdit from '../pages/Job/ApplicantEdit';
import Application from '../pages/Job/Application/Application';
import ApplicationDetail from '../pages/Job/Application/ApplicationDetail';

const jobRoute = [
  {
    name: 'Job Poster',
    element: <Job />,
    icon: <Circle />,
    privilege: [3, 5],
    subscriptions: [2],
    path: '/hr/recruitment/job-poster',
    isSidebar: true,
  },
  {
    name: 'Recruitment Stages',
    element: <Stages />,
    icon: <Circle />,
    privilege: [3, 5, 15, 19],
    subscriptions: [2, 10],
    path: '/hr/recruitment/stages',
    isSidebar: true,
  },
  {
    name: 'Job Add',
    element: <JobAdd />,
    privilege: [3, 5, 15, 19],
    subscriptions: [2, 10],
    path: '/hr/recruitment/job-posters/add',
  },
  {
    name: 'Job Detail',
    element: <JobDetail />,
    privilege: [3, 5, 15, 19],
    subscriptions: [2, 10],
    path: '/hr/recruitment/job-poster/detail/:jobPosterId',
  },
  {
    name: 'Applicants',
    element: <Applicant />,
    icon: <Badge />,
    privilege: [3, 5, 15, 19],
    subscriptions: [2, 10],
    path: '/hr/recruitment/applicants',
    isSidebar: true,
  },
  {
    name: 'Applicant Add',
    element: <ApplicantAdd />,
    icon: <Circle />,
    privilege: [3, 5, 15, 19],
    subscriptions: [2, 10],
    path: '/hr/recruitment/applicant/add',
    isSidebar: false,
  },
  {
    name: 'Job Applicant',
    element: <ApplicantDetail />,
    privilege: [3, 5, 15, 19],
    subscriptions: [2, 10],
    path: '/hr/recruitment/applicant/show/:applicantId',
  },
  {
    name: 'Job Applicant',
    element: <ApplicantEdit />,
    privilege: [3, 5, 15, 19],
    subscriptions: [2, 10],
    path: '/hr/recruitment/applicant/detail/:applicantId',
  },
  {
    name: 'Job Application',
    element: <ApplicantEdit />,
    privilege: [3, 5, 15, 19],
    subscriptions: [2, 10],
    path: '/hr/recruitment/applicant/detail/:applicantId',
  },
  {
    name: 'Applications',
    element: <Application />,
    icon: <Circle />,
    privilege: [3, 5, 15, 19],
    subscriptions: [2, 10],
    path: '/hr/recruitment/applications',
    isSidebar: true,
  },
  {
    name: 'Application Detail',
    element: <ApplicationDetail />,
    icon: <Circle />,
    privilege: [3, 5, 15, 19],
    subscriptions: [2, 10],
    path: '/hr/recruitment/application/show/:id',
  },
];

export default jobRoute;
