/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import swal from 'sweetalert';
import InputContainerJP from '../../Input/InputContainerJP';
import CustomRadioGroup from '../../Input/CustomRadioGroup';
import applicationApi from '../../../api/job/application';
import { handleModal } from '../../../store/slices/modalSlice';
import application from '../../../api/job/application';
import Select from '../../Input/SelectItem';

function OffersForm({reload}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const dispatch = useDispatch();
  const currentData = useSelector((state) => state.modal.modalData)
  
  const [fileInput, setFileInput] = useState()

  const handleFileInput = (e) => {
    const files = e.target.files
		setFileInput(files);
  }

  const onSubmit = (data) => {
    const { postOffersData } = applicationApi();

    try {
      const newOffersData = {
        ...data,
        application: currentData,
        letter: fileInput,
      }

      let bodyFormData = new FormData()

      for (let [key, val] of Object.entries(newOffersData)) {
        if (key === 'letter') {
          bodyFormData.append(`letter`, val[0])
        } else {
          bodyFormData.append(key, val);
        }
      }

      postOffersData(bodyFormData)
      .then((res) => {
        if (res.status === 201 || res.data) {
          dispatch(handleModal({ modalId: '', componentName: '' }));
          swal('Offering Letter Has been Sent', { icon: 'success' });
          return reload(true);
        }
      })
      .catch((err) => {
        console.error('error', err);
      });
    } catch (error) {
      console.error('error', error);
    }
  }

  return (
    <form className='space-y-4' onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-2">
        <div className="font-bold">Offering :</div>
        <input type="text" className="w-full py-[8.5px] px-[14px] border rounded-md" name='salary_offer' {...register(`salary_offer`)} />
      </div>
      <div className="space-y-2">
        <div className="font-bold">Deadline :</div>
        <input type="text" className="w-full py-[8.5px] px-[14px] border rounded-md" name='deadline' {...register(`deadline`)} />
      </div>
      <div className="space-y-2">
        <div className="font-bold">Letter :</div>
        <input type="file" name="file" onChange={handleFileInput}  />
      </div>
      <Button className="flex mt-7 ml-auto bg-blue-600" type="submit" name="submit contact" variant="contained">
        Send
      </Button>
    </form>
  );
}

export default OffersForm;
