import axiosInstances from '../helper/axiosInstances';

const baseURL = import.meta.env.VITE_API_URL;

function site() {
  const { axiosInstance } = axiosInstances();

  async function getAllSitesByClientID(clientId) {
    const method = 'get';
    const url = `${baseURL}clients/${clientId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function getTemplateStructure(templateId) {
    const method = 'get';
    const url = `${baseURL}templates/${templateId}/get_template_structure/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function getSiteDetail(id) {
    const method = 'get';
    const url = `${baseURL}sites/${id}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function createSite(data = {}) {
    const method = 'post';
    const url = `${baseURL}sites/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  return { getAllSitesByClientID, getTemplateStructure, getSiteDetail, createSite };
}

export default site;
