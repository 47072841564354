import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { Avatar, InputAdornment, InputBase, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, CircularProgress, Select, OutlinedInput, MenuItem, Checkbox } from '@mui/material';
import { GridView, Search, ViewList } from '@mui/icons-material';
import _ from 'lodash';
import { handleModal } from '@/store/slices/modalSlice';
import BzThumbnail from '@/assets/simple.png'
import POSApi from '@/api/pos/posApi';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';

import SelectAutocomplete from '@/components/Input/ProductManagement/SelectAutoComplete';
import HrApi from '@/api/HR/api';
import PosApi from '@/api/pos/posApi';

function PairOperators({submitEmployees}) {
  const { control, reset, resetField } = useForm();
  const activeClient = useSelector((state) => state.modal.modalId);
  const operatorsData = useSelector((state) => state.modal.modalData);
  const dispatch = useDispatch();

  const statusOptions = [
    { name: "active", value: true },
    { name: "inactive", value: false },
  ];

  const [isFetching, setIsFetching] = useState(true);
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);
  const [selectedCount, setSelectedCount] = useState(null);
  const [selectedData, setSelectedData] = useState([]);

  const [allEmployees, setAllEmployees] = useState([]);
  const [allBu, setAllBu] = useState([]);
  const [allDepartment, setAllDepartment] = useState([]);

  const [selectedBU, setSelectedBU] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(true);

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [buOptions, setBuOptions] = useState([]);

  const fetchData = async (apiFunction, params) => {
    try {
      const res = params ? await apiFunction(params) : await apiFunction();
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const departments = useCallback((params = { max_size: true, client: activeClient }) => fetchData(HrApi().getDepartment, params), [activeClient]);
  const businessUnits = useCallback((params = { max_size: true, client: activeClient }) => fetchData(HrApi().getBusinessunitByClient, params), [activeClient]);

  const getAllOptions = useCallback(async () => {
    const [departmentList, businessUnitList] = await Promise.all([departments(), businessUnits()]);
    if (departmentList && businessUnitList) {
      setDepartmentOptions(departmentList.results);
      setBuOptions(businessUnitList.results);

    }
    setIsLoadingOptions(false);
  }, [departments, businessUnits]);

  useEffect(() => {
    getAllOptions();
  }, [activeClient, getAllOptions]);

  const employeeList = useCallback((params) => fetchData(HrApi().getAllEmployeesWithDynamicQueries, params), [activeClient]);
  const getAllEmployees = useCallback(async (newParams) => {
    const [employees] = await Promise.all([employeeList(newParams)]);
    if (employees) {
      const excludedIds = operatorsData?.outletEmployeesIds;
  
      const filteredCollections = employees?.results.filter(item => !excludedIds?.includes(item.id));

      setAllEmployees(filteredCollections);
    }
    setIsFetching(false)
  }, [employeeList]);

  useEffect(() => {
    setIsFetching(true);
    
    const newParams = {
      client: activeClient,
      is_active: selectedStatus,
      max_size: true
    };
  
    if (selectedBU !== null) {
      newParams.current_business_unit = selectedBU;
    }
  
    if (selectedDepartment !== null) {
      newParams.current_department = selectedDepartment;
    }

    getAllEmployees(newParams);
    setAllEmployees([])
  }, [activeClient, selectedBU, selectedDepartment, selectedStatus, getAllEmployees]);

  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [isAllSelectedEmployees, setIsAllSelectedEmployees] = useState(false);
  
  const handleSelectAllClickEmployees = (event) => {    
    if (selectedEmployees.length === outletEmployees.length) {
      setIsAllSelectedEmployees(false);
      setSelectedEmployees([]);
      return;
    } else {
      const newSelecteds = outletEmployees?.map((n) => n.id);
      setIsAllSelectedEmployees(true);
      setSelectedEmployees(newSelecteds);
      return;
    }
  };

  const handleClickEmployees = (event, id) => {
    const selectedIndex = selectedEmployees.indexOf(id);
    let newSelected = [...selectedEmployees];

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelectedEmployees(newSelected);
  };

  const isSelectedEmployees = (name) => selectedEmployees.indexOf(name) !== -1;
  
  return (
    <div className="w-full h-full space-y-4">
      <div className='w-full flex justify-end'>
        <button
          className="btn bg-[#2C6D47] p-2 text-white rounded-lg border ml-auto cursor-pointer"
          name="submitEmployees"
          type="button"
          onClick={() => submitEmployees(selectedEmployees)}
        >
          Submit Employees
        </button>
      </div>
      <div className="w-full flex gap-x-4">
        <div className="w-[40rem] flex items-center">
          <p className="font-semibold">{selectedEmployees.length} selected</p>
        </div>
        <div className="w-full h-fit mb-2">
          <p className="font-semibold mb-[5px]">Business Unit</p>
          <SelectAutocomplete
            variant="outlined"
            options={buOptions?.map((dt) => ({ name: dt.name, value: dt.id }))}
            defaultValue={selectedBU || null}
            onChange={(e, val) => {
              setSelectedBU(val)
            }}
            isLoading={isLoadingOptions}
            // disabled={mode === "edit"}
          />
        </div>
        <div className="w-full h-fit mb-2">
          <p className="font-semibold mb-[5px]">Department</p>
          <SelectAutocomplete
            variant="outlined"
            options={departmentOptions?.map((dt) => ({ name: dt.name, value: dt.id }))}
            defaultValue={selectedDepartment || null}
            onChange={(e, val) => {
              setSelectedDepartment(val)
            }}
            isLoading={isLoadingOptions}
            // disabled={mode === "edit"}
          />
        </div>
        <div className="w-full h-fit mb-2">
          <p className="font-semibold mb-[5px]">Status</p>
          <SelectAutocomplete
            variant="outlined"
            options={statusOptions.map((dt) => ({ name: dt.name, value: dt.value }))}
            defaultValue={statusOptions.find(option => option.value === selectedStatus) || null}
            onChange={(e, val) => {
              setSelectedStatus(val?.value || true); // pastikan nilai yang di-set sesuai dengan format yang diharapkan
            }}
            isLoading={isLoadingOptions}
          />
        </div>
      </div>
      <div className="w-full border">
        <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selectedEmployees.length > 0 && selectedEmployees.length < allEmployees.length}
                    checked={allEmployees.length > 0 && selectedEmployees.length === allEmployees.length}
                    onClick={handleSelectAllClickEmployees}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                  />
                </TableCell>
                <TableCell align="center" className="!font-bold">
                  id
                </TableCell>
                <TableCell align="center" className="!font-bold">
                  Name
                </TableCell>
                <TableCell align="center" className="!font-bold">
                  Department
                </TableCell>
                <TableCell align="center" className="!font-bold">
                  Business Unit
                </TableCell>
                <TableCell align="center" className="!font-bold">
                  Job Title
                </TableCell>
                <TableCell align="center" className="!font-bold">
                  Employment Type
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allEmployees.length > 0 && allEmployees.map((coll, idx) => {
                const employeeName = coll?.first_name + " " + coll?.last_name;
                const { employment_type, position, department } = coll?.current_employment || {}; // Gunakan default object jika `coll.current_employment` null atau undefined
                const isItemSelected = isSelectedEmployees(coll.id);
                const labelId = `enhanced-table-checkbox-${idx}`;

                return (
                  <TableRow role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={coll.id} selected={isItemSelected}>
                    <TableCell padding="checkbox">
                      <Checkbox onClick={(event) => handleClickEmployees(event, coll.id)} color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                    </TableCell>
                    <TableCell align="left">{coll.id || '-'}</TableCell>
                    <TableCell align="left">{employeeName || '-'}</TableCell>
                    <TableCell align="left">{department?.name || '-'}</TableCell>
                    <TableCell align="left">{department?.business_unit?.name || '-'}</TableCell>
                    <TableCell align="left">{position || '-'}</TableCell>
                    <TableCell align="left">{employment_type || '-'}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
      </div>
    </div>
  )
}

export default PairOperators