import { ArrowBack } from '@mui/icons-material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function BaseBackButton({ className, navTo }) {
  const navigate = useNavigate();
  return (
    <button onClick={() => (navTo ? navigate(navTo) : navigate(-1))} type="button" className={className || 'shadow p-1 rounded w-fit bg-white flex'}>
      <ArrowBack />
      <div>Back</div>
    </button>
  );
}
