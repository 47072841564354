import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ButtonBase, Box, Tooltip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';

import ModalPNLCategory from '../../../../components/Modal/Accounting/Settings/PNLCategory';
import BasePromptDialog from '../../../../components/ui/Dialog/BasePromptDialog';

import AccountingSettingsContext from '../AccountingSettingsContext';
import accountingApi from '../../../../api/accounting/accounting';

const { getPNLCategories } = accountingApi();

export default function TabPNLBSCategory() {
  const asContext = useContext(AccountingSettingsContext);
  const dispatch = useDispatch();
  const accSettingsFilter = useSelector((state) => state.accountSettings.tableState);
  const cl = useSelector((state) => state.auth.client.id);
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeUser = useSelector((state) => state.auth.user);
  const client = activeUser.is_superuser ? activeClient : cl;

  const [isModalPnlCategories, setModalPnlCategories] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [modalData, setModalData] = useState('');
  const [isModalDelete, setModalDelete] = useState(false);

  const tableState = useMemo(() => accSettingsFilter.pnlBsGroup ? JSON.parse(accSettingsFilter.pnlBsGroup) : {}, [accSettingsFilter])

  const {
    isFetching,
    isFetched,
    data: aP,
    refetch,
  } = useQuery({
    queryKey: ['pnl-category', client],
    enabled: !!client,
    queryFn: () => getPNLCategories({pnl_bs_group__client: client, ordering: 'code', max_size: true}),
    onError: (error) => {
      console.log(error);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 0,
  });

  const pnlCategories = aP?.data?.results || [];

  const handleActionSubmit = (mode, data) => {
    if (data) {
      const { addPNLCategory, updatePNLCategory } = accountingApi();
      if (mode === 'create') {
        addPNLCategory(data)
          .then((res) => {
            if (res.status === 201 || res.data) {
              refetch();
              asContext.refetchPnlC();
            }
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
      if (mode === 'update') {
        updatePNLCategory(modalData.id, data)
          .then((res) => {
            if (res.status === 201 || res.data) {
              refetch();
              asContext.refetchPnlC();
            }
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
    }
    setModalData(false);
  };

  const onEditClick = (data) => {
    setModalData(data);
    setModalType('update');
    setModalPnlCategories(true);
  };

  const onDeleteClick = (data) => {
    setModalData(data);
    setModalDelete(true);
  };

  const onConfirmDelete = () => {
    const { deletePNLCategory } = accountingApi();

    if (modalData && modalData.id) {
      deletePNLCategory(modalData.id)
        .then((res) => {
          if (res.status === 204) {
            refetch();
            asContext.refetchPnlC();
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  const renderAction = (dt) => {
    return (
      <Box className="w-full flex space-x-2 justify-center">
        <ButtonBase className="bz-btn-tb" onClick={() => onEditClick(dt)}>
          <Edit fontSize="24" />
        </ButtonBase>
        <ButtonBase className="bz-btn-tb" onClick={() => onDeleteClick(dt)}>
          <Delete fontSize="24" />
        </ButtonBase>
      </Box>
    );
  };

  const columns = [
    {
      field: 'code',
      headerName: 'Code',
      minWidth: 196,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.description}>
          <span className="truncate">{params.row.description}</span>
        </Tooltip>
      ),
    },
    {
      field: 'pnl_bs_group',
      headerName: 'Group',
      minWidth: 196,
      valueGetter: params => params?.row?.pnl_bs_group?.code || '-'
    },
    {
      field: 'id',
      headerName: 'Action',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => renderAction(params.row),
    },
  ];

  return (
    <div>
      <div className="w-full flex px-4 pt-4">
        <button
          type="button"
          className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 ml-auto"
          style={{ backgroundColor: '#2C6D47' }}
          onClick={() => {
            setModalType('create');
            setModalPnlCategories(true);
          }}
        >
          <p className="text-white">Add New PNL/BS Category</p>
        </button>
        {/* <Button
          variant="contained"
          className="ml-auto"
          onClick={() => {
            setModalType('create');
            setModalPnlCategories(true);
          }}
        >
          Add New PNL/BS Category
        </Button> */}
      </div>
      <div className="w-full flex flex-col p-4 justify-center overflow-hidden">
        {isFetched && (
          <Box className="w-full h-full pt-4 mb-4">
            <DataGrid
              autoHeight
              loading={isFetching}
              rows={pnlCategories || []}
              columns={columns}
              rowsPerPageOptions={[10, 20, 50]}
              className="m-auto max-w-[1200px] w-full"
              initialState={Object.keys(tableState).length ? { ...tableState, pagination: {...tableState.pagination}  } : { pagination: { page: 0, pageSize: 20 } }}
              onStateChange={(state) => {
                dispatch({ type: 'accountSettings/setTabState', payload: { type: 'pnlBsGroup', value: JSON.stringify(state) } });
              }}
            />
          </Box>
        )}
      </div>
      <ModalPNLCategory
        open={isModalPnlCategories}
        close={() => {
          setModalPnlCategories(false);
          setModalType(false);
          setModalData(false);
        }}
        action={handleActionSubmit}
        type={modalType}
        data={modalData}
      />
      <BasePromptDialog
        open={isModalDelete}
        title="Delete"
        content={modalData?.name}
        action={onConfirmDelete}
        close={() => {
          setModalDelete(false);
          setTimeout(() => {
            setModalData(false);
          }, 300);
        }}
      />
    </div>
  );
}
