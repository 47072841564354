import React from 'react';
import { Circle } from '@mui/icons-material';

import SuperUser from '../pages/Superuser/Superuser';
import SuperUserAdd from '../pages/Superuser/SuperuserAdd';
import SuperUserList from '../pages/Superuser/SuperuserList';

const superUserRoute = [
  {
    name: 'Superuser',
    element: <SuperUser />,
    icon: <Circle />,
    privilege: [99],
    subscriptions: [1, 2, 5],
    path: '/superadmin',
    isSidebar: true,
  },
  {
    name: 'Superuser Add',
    element: <SuperUserAdd />,
    privilege: [99],
    subscriptions: [1, 2, 5],
    path: '/superadmin/users/add',
  },
  {
    name: 'Superuser List',
    element: <SuperUserList />,
    privilege: [99],
    subscriptions: [1, 2, 5],
    path: '/superadmin/user/:userid',
  },
];

export default superUserRoute;
