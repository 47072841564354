/* eslint-disable */


import request from '../helper/axiosUtil';
import store from '../store';

export const fetchProjects = async ({ queryKey }) => {
  const [, { clientId, nextUrl }] = queryKey;
  return request({
    url: nextUrl ? nextUrl : `/project-management/projects/?client=${clientId}`,
    params: queryKey[2] ? queryKey[2] : {} // assign second object on queryKey as parameters
  });
};

export const fetchProjectDetail = async ({ queryKey }) => {
  const [, { projectId }] = queryKey;
  const response = await request({ url: `/project-management/projects/${projectId}/` });
  if (response?.response?.status === 401 || response?.response?.status === 0) {
    store.dispatch({ type: 'auth/setLogout' });
    store.dispatch({ type: 'client/setActiveClient', payload: false });
    store.dispatch({ type: 'lang/setLanguageList', payload: false });
  }
  return response;
};

export const createProjects = (data) => request({
  url: '/project-management/projects/',
  method: 'post',
  data,
  headers: { 'Content-Type': 'application/json' },
});

export const updateProjects = async (id, data) => request({
  url: `/project-management/projects/${id}/`,
  method: 'patch',
  data,
  headers: { 'Content-Type': 'application/json' },
});

export const deleteProjects = async (id) => request({
  url: `/project-management/projects/${id}/`,
  method: 'delete',
});
