import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, ButtonBase, Divider, FormControl, Popover, Stack, TextField } from '@mui/material';
import { Edit, Person as PersonIcon } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import DetailContext from '../../Projects/DetailContext';
import formatterInitialName from '../../../../helper/formatterInitialName';
import BaseAutocompleteValidation from '../../../../components/Input/Base/BaseAutocompleteValidation';
import teamApi from '../../../../api/task/team';
import projectApi from '../../../../api/task/project';

const schema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  notes: yup.string().nullable(),
  team: yup.object().nullable().required('Team is a required field'),
  member: yup.object().nullable(),
});

const generateValues = (val) => {
  const isWhiteSpace = val.indexOf(' ') >= 0;
  const valTime = isWhiteSpace ? val.substring(val.lastIndexOf(' ') + 1) : val;
  const valDay = isWhiteSpace ? Number(val.split(' ')[0]) : 0;
  let valMonth;
  let valDays;
  let periods = 'time';
  valMonth = 0;
  valDays = valDay;
  if (valDay && valDay > 30) {
    const valDiv = valDay / 30;
    valMonth = Math.floor(valDiv);
    valDays = +valDay - valMonth * 30;
    periods = valMonth ? 'month' : 'day';
  } else if (valDay && valDay < 30) {
    periods = 'day';
  }
  return {
    period: periods,
    currValues: {
      m: valMonth.toString(),
      d: valDays.toString(),
      t: valTime.substring(0, valTime.indexOf(':') + 3),
    },
  };
};

export default function Overview() {
  const myContextDetail = useContext(DetailContext);
  const { projects } = myContextDetail;
  const { formatter } = formatterInitialName();
  const acvUser = useSelector((state) => state.auth?.user);
  const [team, setTeam] = useState(false);
  const [anchorUser, setAnchorUser] = useState(false);
  const [activeUser, setActiveUser] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isOwner, setIsOwner] = useState();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      project_id: '',
      notes: '',
      team: '',
      member: '',
      time: '00:00',
    },
  });

  const getProjectTeamDetail = useCallback(() => {
    const { getTeamDetail } = teamApi();
    getTeamDetail(projects.team?.id)
      .then((res) => {
        if (res.status === 200 || res.data) {
          setTeam(res.data);
          // setActiveUserRole(res.data?.team_users?.find((data) => data.user.id === acvUser.id))
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [projects.team?.id]);

  useEffect(() => {
    if (projects.team) {
      getProjectTeamDetail();
    }
  }, [projects.team, getProjectTeamDetail]);

  useEffect(() => {
    if (projects && projects?.owner) {
      setIsOwner(projects?.owner?.id === acvUser.id)
    }
  }, [projects]);

  const onSubmit = (data) => {
    const { name, project_id, member, notes, team: newTeam, time: newTime } = data;
    const newData = {
      member: member?.id,
      name,
      project_id,
      notes,
      team: newTeam?.id,
      assignee_log_limit: `${newTime}:00`,
    };
    const { updateTaskProject } = projectApi();
    updateTaskProject(projects.id, newData)
      .then((res) => {
        if (res.status === 200 || res.data) {
          myContextDetail.dispatchProject('GET');
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
    setIsEditMode(false);
  };

  const openUser = Boolean(anchorUser);
  const idUser = openUser ? 'simple-popover' : undefined;

  useEffect(() => {
    if (!isInitialized && projects) {
      setValue('name', projects.name);
      setValue('project_id', projects.project_id);
      setValue('notes', projects.notes);
      setValue('team', projects.team);
      setValue('member', projects.member);
      setValue('time', generateValues(projects.assignee_log_limit).currValues.t || '00:00');
      setIsInitialized(true);
    }
  }, [isInitialized, projects, setValue]);

  return (
    <div className="w-full h-full px-6 py-6 space-y-6">
      <div className='space-y-3'>
        <p className="text-lg font-bold mb-4">Project Information</p>
        <div className="flex flex-row">
          <p className="w-20 mr-2">Name</p>
          <p>{projects?.name}</p>
        </div>
        <div className="flex flex-row">
          <p className="w-20 mr-2">Note</p>
          <p>{projects?.notes}</p>
        </div>
        <div className="flex flex-row">
          <p className="w-20 mr-2">Owner</p>
          <p>{projects?.owner?.name}</p>
        </div>
      </div>

      <div>
        <div className="flex space-x-4 items-center mb-4">
          <p className="text-lg font-bold">Project Settings</p>
          {isOwner === true && <Edit className="w-6 h-6 border rounded-full p-1 mt-1" fontSize="18" onClick={() => setIsEditMode(true)} />}
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-4 w-96 flex flex-col space-y-4">
          <FormControl>
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  label="Name"
                  size="small"
                  error={!!errors.name?.message}
                  helperText={errors.name?.message}
                  placeholder=""
                  disabled={!isEditMode}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              control={control}
              name="project_id"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  label="Code"
                  size="small"
                  error={!!errors.project_id?.message}
                  helperText={errors.project_id?.message}
                  placeholder=""
                  disabled={!isEditMode}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              control={control}
              name="notes"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  label="Notes"
                  size="small"
                  error={!!errors.notes?.message}
                  helperText={errors.notes?.message}
                  placeholder=""
                  disabled={!isEditMode}
                />
              )}
            />
          </FormControl>
          <BaseAutocompleteValidation
            control={control}
            name="team"
            label="Team"
            errors={errors}
            options={myContextDetail.teamList}
            optionLabel="name"
            config={{ classes: '', multiple: false, disabled: !isEditMode }}
          />
          <BaseAutocompleteValidation
            control={control}
            name="member"
            label="Customer"
            errors={errors}
            options={myContextDetail.customerList}
            optionLabel="name"
            config={{ classes: '', multiple: false, disabled: !isEditMode }}
          />
          <FormControl>
            <Controller
              control={control}
              name="time"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  label="Time limit"
                  size="small"
                  type="time"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ step: 300 }}
                  error={!!errors.time?.message}
                  helperText={errors.time?.message}
                  placeholder=""
                  disabled={!isEditMode}
                />
              )}
            />
          </FormControl>

          <Divider className="mt-4" />
          {isEditMode && (
            <Stack direction="row" spacing={2} className="w-full mt-5 justify-end">
              <Button variant="outlined" onClick={() => setIsEditMode(false)}>
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                Update
              </Button>
            </Stack>
          )}
        </form>
      </div>
    </div>
  );
}
