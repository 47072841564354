/* eslint-disable */

import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { DevTool } from '@hookform/devtools';

import InputContainer from '../../Input/InputContainer';
import { handleModal } from '../../../store/slices/modalSlice';
import ReactSelectMultiple from '../../Input/ReactSelectMultiple';
import ReactSelectSingle from '../../Input/ReactSelectSingle';
import { getDefaultSelectObject, getOptionSelectWithKey } from '../../../lib/OptionsInputHelper';

function EmailReportForm({ defaultValues, onFormSubmit, unitData }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: defaultValues?.name,
      business_unit: defaultValues?.business_unit?.id,
      whistleblower_report_recipient: defaultValues?.whistleblower_report_recipient,
    },
  });
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.client.activeClient);

  const devLang = [];

  defaultValues?.category_languages?.map((l) => {
    devLang.push(l);
    return devLang;
  });

  const optionsUnit = unitData?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));

  const defUnit = {
    label: defaultValues?.business_unit?.name,
    value: defaultValues?.business_unit?.id,
  };

  const onSubmit = (data) => {
    onFormSubmit({ name: data.name, client: clientId, whistleblower_report_recipient: data.whistleblower_report_recipient, business_unit: data.business_unit });
  };

  const handleCancel = () => {
    dispatch(handleModal({ id: '' }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full space-y-4">
        <InputContainer name="name" control={control} label="Department" readOnly />
        <div className="mt-5 !w-full">
          <ReactSelectSingle
            isDisabled
            name="business_unit"
            control={control}
            options={getOptionSelectWithKey(unitData)}
            defaultValue={getDefaultSelectObject(defaultValues?.business_unit?.id, getOptionSelectWithKey(unitData))}
            placeholder="Select Business Unit"
          />
        </div>
        <InputContainer name="whistleblower_report_recipient" control={control} label="Email Recipient" />
        <div className="flex justify-end ml-auto gap-x-2">
          <Button onClick={handleCancel} className="flex" variant="outlined">
            Cancel
          </Button>
          <Button className=" flex" type="submit" variant="outlined">
            {defaultValues ? 'Update' : 'Add'}
          </Button>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </form>
  );
}
export default EmailReportForm;
