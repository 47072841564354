/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { ArrowBack, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DownloadIcon from '@mui/icons-material/Download';
import * as yup from 'yup';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import { handleModal } from '../../store/slices/modalSlice';
import CRMApi from '../../api/CRM/CRMCustomers';
import AccountingApi from '../../api/accounting/accounting'
import ContactSelect from '../../components/Input/ContactSelect/ContactSelect';
import Input from './redesigning/InputContainerRedesign';
import Select from '../../components/Input/MuiSelectSingleItem';
// import Select from '../../components/Input/SelectItem';
import DatePickerField from './redesigning/DatePickerFieldRedesign';
import ModalWrapper from '../../components/ui/ModalWrapper';
import CollectionForm from '../../components/Form/Article/CollectionForm';
import AddNote from '../../components/Form/CRM/AddNote';
import ViewHistory from '../../components/Modal/CRM/ViewHistory';
import EditHistory from '../../components/Form/CRM/EditHistory';
import CustomSelect from './redesigning/CustomSelectRedesign';
import ContactSelectButton from '../../components/Input/ContactSelect/ContactSelectButton';
import LocationPair from '../../components/Form/CRM/LocationPair';
import AddNewLocation from '../../components/Form/CRM/AddNewLocation';
import BaseMainTitle from '../../components/BaseMainTitle';
import BZHelmet from '../../utils/BZHelmet';
import AddContact from '@/components/Form/CRM/AddContact';
import PosApi from '@/api/pos/posApi'
import store from '@/store';

const schema = yup.object().shape({
  countries: yup.array().required('must input at least 1 country'),
  name: yup.string().required(),
});

const containerStyle = {
  width: '100%',
  height: '220px',
};

const lib = ['drawing', 'places'];

export default function CustomerDetail() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCKqMs4hmNgoQtmIUsd7diBKHj6UV610Jg',
    libraries: lib,
  });

  const clientId = useSelector((state) => state.client.activeClient);
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname.split('/');
  const paramID = useParams();
  const dispatch = useDispatch();

  const activeUser = useSelector((state) => state.auth.user);
  const isSuperUser = activeUser.is_superuser;
  const userGroup = activeUser.groups?.map((a) => +a.id) || [];
  // console.log('activeUser ', activeUser);
  // const isOnlyUser = !(isSuperUser && userGroup.includes(8));
  const isOnlyUser = isSuperUser === true ? false : userGroup.includes(17) ? true : false;

  const [mode, setMode] = useState('');
  const [companiesData, setCompaniesData] = useState();
  const [companiesLocation, setCompaniesLocation] = useState([]);
  const [attributeCategories, setAttributeCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [attributeValues, setAttributeValues] = useState([]);
  const [companyDocuments, setCompanyDocuments] = useState([]);
  const [assignedContacts, setAssignedContacts] = useState([]);
  const [assignedContactsOnDetail, setAssignedContactsOnDetail] = useState([]);
  const [outletList, setOutletList] = useState([]);
  const [companiesContacts, setCompaniesContacts] = useState([]);
  const [histories, setHistories] = useState([]);
  const [historyData, setHistoryData] = useState();
  const [projectList, setProjectList] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [checkedDocuments, setCheckedDocuments] = useState([]);
  const [attributesOptions, setAttributesOptions] = useState([]);
  const [disabledButtonDelete, setDisabledButtonDelete] = useState(true);
  const [map, setMap] = useState(null);
  const [NoteModalStatus, setNoteModalStatus] = useState('');
  const [noteId, setNoteId] = useState(0);
  const [selectedMainLocation, SetSelectedMainLocation] = useState('');
  const [selectedLocations, SetSelectedLocations] = useState([]);
  
  const [companyLogo, setCompanyLogo] = useState();
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [businessUnitList, setBusinessUnitList] = useState(null)
  const [selectedbusinessUnit, setSelectedBusinessUnit] = useState([])

  const [notes, setNotes] = useState([]);
  const [reloadNotes, setReloadNotes] = useState(true);
  const [reloadHistories, setReloadHistories] = useState(true);
  const [reloadLocations, setReloadLocations] = useState(false);
  

  useEffect(() => {
    if (!Object.entries(checkedDocuments).length) {
      setDisabledButtonDelete(true);
    } else {
      setDisabledButtonDelete(false);
    }
  }, [checkedDocuments]);

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const getAllCountries = useCallback(async () => {
    const { getCountries } = CRMApi();

    try {
      const getData = await getCountries({ max_size: true, ordering: 'name' });
      const data = await getData.data.results;
      setCountries(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getBusinessUnitList = useCallback(async () => {
    const { getListBusinessUnit } = AccountingApi();

    try {
      const getData = await getListBusinessUnit({ client: clientId, max_size: true });
      const data = await getData.data;

      setBusinessUnitList(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getoutlets = useCallback(async () => {
    const { getOutletList } = PosApi();

    try {
      const getData = await getOutletList({ department__business_unit__client: clientId, ordering: "name", max_size: true });
      const data = await getData.data;
      
      setOutletList(data.results);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const memberDetail = useCallback(async () => {
    const { getMemberDetail } = CRMApi();
    let attributeValues = [];
    let companydocs = [];
    let id = paramID.customerId;

    try {
      const getData = await getMemberDetail(id);
      const data = await getData.data;

      const attributeCat = data?.attribute_categories;
      const companyId = data?.id

      attributeCat?.forEach((category, idxCategory) => {
        let attrs = [];
        category.attributes.forEach((attribute, idxAttribute) => {
          if (attribute.value_type === 'File') {
            companydocs.push(attribute?.member_attribute?.attribute_values);
          }
          attrs.push(attribute.member_attribute?.attribute_values);
        });
        attributeValues.push(attrs);
      });

      setCompaniesData(data);
      setCompanyLogo({ logoPreview: data.logo });
      setCompaniesLocation(data?.locations);
      // setAttributeCategories(attributeCat);
      setAttributeValues(data.documents);
      setCompanyDocuments(data.documents);

      if (companyId) {
        getContacts(companyId)
        getAssignedContacts(companyId)
      }
    } catch (error) {
      console.error(error);
    }
  }, [paramID]);

  const getAllAttributeOptions = useCallback(async () => {
    const { getAttributeOptions, getAttributeCategories } = CRMApi();
    let attributesCat = [];
    let AllAttributeOptions = [];

    try {
      const getAttributeCat = await getAttributeCategories({ max_size: true, client: clientId });
      const attributeCatdata = await getAttributeCat.data.results;
      attributesCat.push(...attributeCatdata);

      attributeCatdata.forEach((category) => {
        category?.attributes.forEach(async (attr, idx) => {
          if (attr.value_type === 'Option') {
            let customParams = {};
            if (attr.name === 'Membership type') {
              customParams.ordering = undefined;
            }
            const getData = await getAttributeOptions(attr.id, customParams);
            const data = await getData.data.results;
            setAttributesOptions((attributesOptions) => ({ ...attributesOptions, [attr.key_name]: data }));
          }
        });
      });
    } catch (error) {
      console.error(error);
    }

    setAttributeCategories(attributesCat);
  }, []);

  const getAssignedContacts = useCallback(async (id) => {
    const { getAllAssignedContacts } = CRMApi();

    try {
      const getData = await getAllAssignedContacts({ member: id, client: clientId });
      const data = await getData.data.results;
        const forNewContactOnDetail = data.map(item => {
          return {
            ...item,
            contact: null
          };
        });
        setAssignedContactsOnDetail(forNewContactOnDetail)
        setAssignedContacts(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getContacts = useCallback(async (id) => {
    const { getAllContacts } = CRMApi();

    try {
      const getData = await getAllContacts({ member: id, max_size: true, ordering: 'name' });
      const data = await getData.data.results;
      setCompaniesContacts(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getAllHistories = useCallback(async (param) => {
    const { getAllHistoriesByMemberId, getAllAttributes } = CRMApi();
    const nameAttributes = ['Date joined', 'Membership type', 'Membership status'];
    const historiesData = [];
    const attributes = [];
    const objectAttributes = {};

    try {
      const resAttributes = await getAllAttributes({ max_size: true });
      resAttributes.data?.results.forEach((attribute) => {
        objectAttributes[attribute.id] = attribute;
        const nameAttributesIdx = nameAttributes.indexOf(attribute.name);
        if (nameAttributesIdx > -1) {
          attributes[nameAttributesIdx] = attribute;
        }
      });
      await attributes.reduce(async (promise, attribute) => {
        await promise;
        const res = await getAllHistoriesByMemberId(param, { max_size: true, ordering: '-id', attribute: attribute.id });
        historiesData.push(res.data.results);
      }, Promise.resolve());
    } catch (error) {
      console.error(error);
    }

    setHistories(historiesData);
    setAttributes(objectAttributes);
  }, [paramID?.customerId]);

  const getProjects = useCallback(async (param) => {
    const { getProjectById } = CRMApi();

    try {
      const getData = await getProjectById({ member: param });
      const data = await getData.data.results;
      setProjectList(data);
    } catch (error) {
      console.error(error);
    }
  }, [paramID?.customerId]);

  const getAllNotes = useCallback(async (param) => {
    const { getAllNotesByMemberId } = CRMApi();

    try {
      const getData = await getAllNotesByMemberId(param);
      const data = await getData.data.results;

      setNotes(data);
    } catch (error) {
      console.error(error);
    }
  }, [paramID?.customerId]);

  const getLocations = useCallback(async () => {
    const { getAllLocations } = CRMApi();

    try {
      const params = paramID && paramID.customerId ? { member: paramID.customerId, client: clientId, max_size: true } : { client: clientId, max_size: true };

      const getData = await getAllLocations(params);
      const data = await getData.data.results;
      
      const locations = await data
        .filter((location) => selectedLocations.includes(parseInt(location.id)))
        .map((location) => {
          return {
            ...location,
            is_main: location.id === selectedMainLocation,
          };
        });

      setCompaniesLocation(mode === 'add' ? locations : data);
    } catch (error) {
      console.error(error);
    }
  }, [selectedLocations]);

  useEffect(() => {
    if (url.includes('detail')) {
      setMode('edit');
    } else {
      setMode('add');
      setCompaniesData([]);
    }
  }, [paramID?.customerId]);

  useEffect(() => {
    if (mode === "add") {
      getAssignedContacts()
    }
  }, [mode]);

  useEffect(() => {
    if (paramID?.customerId) {
      memberDetail();
      getProjects(paramID?.customerId);
    }
  }, [paramID?.customerId]);

  useEffect(() => {
    getAllAttributeOptions();
    getAllCountries();
    getBusinessUnitList();
    getoutlets();
  }, [paramID?.customerId]);


  useEffect(() => {
    if (reloadLocations) {
      getLocations();
    }
    setReloadLocations(false);
  }, [reloadLocations]);

  useEffect(() => {
    if (reloadNotes && paramID?.customerId) {
      getAllNotes(paramID?.customerId);
    }
    setReloadNotes(false);
  }, [reloadNotes, paramID?.customerId]);

  useEffect(() => {
    if (reloadHistories && paramID?.customerId) {
      getAllHistories(paramID?.customerId);
    }
    setReloadHistories(false);
  }, [reloadHistories, paramID?.customerId]);

  const { register, handleSubmit, control, errors, setValue } = useForm();

  const handleCheckedDocuments = (event, id, idxAttr) => {
    const isChecked = event.target.checked;

    let newCheckedDocuments = _.cloneDeep(checkedDocuments);

    // let setChecked = new Set(newCheckedDocuments);

    if (isChecked) {
      newCheckedDocuments.push(id);
    } else {
      let index = newCheckedDocuments.indexOf(id);
      if (index !== -1) {
        newCheckedDocuments.splice(index, 1);
      }
    }

    // newCheckedDocuments = [...setChecked];

    setCheckedDocuments(newCheckedDocuments);
  };

  const deleteDocuments = () => {
    let newAttributeValues = _.cloneDeep(attributeValues);
    const checkedFile = checkedDocuments;

    const remainFiles = companyDocuments.filter((item) => !checkedFile.includes(item.id));

    // newAttributeValues[idxCategory][idxAttr] = remainFiles;

    // setAttributeValues(newAttributeValues);
    setCheckedDocuments([])
    setCompanyDocuments(remainFiles);
  };

  const handleFileUpload = (event) => {
    let newAttributeValues = _.cloneDeep(companyDocuments);

    for (let i = 0; i < event.target.files.length; i++) {
      newAttributeValues.push({ id: '', file: event.target.files[i] });
    }
    setCompanyDocuments(newAttributeValues);
    // setAttributeValues(newAttributeValues);
  };

  const handleSelectedContact = (contactId, idx) => {
    let newAssignedContacts = _.cloneDeep(assignedContacts);
    let newAssignedContactsOnDetail = _.cloneDeep(assignedContactsOnDetail);
    let selectedContact = companiesContacts.filter((contact) => contact.id === parseInt(contactId));

    if (selectedContact.length) {
      selectedContact = selectedContact[0];
      newAssignedContacts[idx].contact = selectedContact;
    } else {
      newAssignedContacts[idx].contact = null;
    }

    newAssignedContactsOnDetail[idx].contact = null;

    setAssignedContacts(newAssignedContacts);
    setAssignedContactsOnDetail(newAssignedContactsOnDetail);
  };

  const handleAssignedContact = (data, id) => {
    let newAssignedContacts = _.cloneDeep(assignedContacts);

    data.contact_title = assignedContacts[id]?.id;
    newAssignedContacts[id].contact = data;

    setAssignedContacts(newAssignedContacts);
  };

  const handleAssignedContactOnDetail = (data, id) => {
    let newAssignedContactsOnDetail = _.cloneDeep(assignedContactsOnDetail);
    let newAssignedContacts = _.cloneDeep(assignedContacts);

    data.contact_title = newAssignedContactsOnDetail[id]?.id;
    newAssignedContactsOnDetail[id].contact = data;
    newAssignedContacts[id].contact = null

    setAssignedContactsOnDetail(newAssignedContactsOnDetail);
    setAssignedContacts(newAssignedContacts);
  };

  const handleRemoveLogo = () => {
    setCompanyLogo({ logo: '' });
  };

  const handleChangeLogo = async (e) => {
    const file = e.target.files?.[0];
    if (file) {
      const imageSrc = URL.createObjectURL(file);

      setCompanyLogo({ logo: file, logoPreview: imageSrc });
    }
  };

  const validationSubmit = (data) => {
    console.log({data});
    
    let isError = false;

    if (!data.name || !data.countries?.length) {
      isError = true;
    }

    try {
      attributeCategories.forEach((attributeCategory) => {
        attributeCategory.attributes.forEach((attr) => {
          if (attr.is_required) {
            const { key_name } = attr;
            const attrData = data[key_name];
            if (attr.value_type === 'String' && attrData === '') {
              isError = true;
            } else if (attr.value_type === 'Option') {
              if ((Array.isArray(attrData) && !attrData.length) || !attrData) {
                isError = true;
              }
            }
          }
        });
      });
    } catch (error) {
      isError = true;
    }

    if (isError) {
      swal('Error!', 'The required fields (*) cannot be blank.', 'error');
    } else {
      SubmitData(data);
    }
  };


  const SubmitLocation = (memberId) => {
    const { bulkUpdateLocation } = CRMApi();

    const data = companiesLocation?.map((selectedLocation) => {
      return {
        member: memberId,
        id: selectedLocation?.id,
        is_main: selectedMainLocation === selectedLocation?.id,
      };
    });

    bulkUpdateLocation([...data])
      .then(() => {
        swal('Success !', 'You successfully add new member !', 'success').then(() => {
          navigate('/customers/detail/' + memberId);
        });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const SubmitData = async (data) => {
    const { addMemberDetail, updateMemberDetail, bulkUpdateAssignedContacts, addContact } = CRMApi();
    const bodyFormData = new FormData();
    data.client = parseInt(clientId);

    const isCustomerIdEmpty = !paramID.customerId;
    const allAttributes = attributeCategories.flatMap((category) => category.attributes);
    const allAttributeKeys = allAttributes.map((attr) => attr.key_name);

    const filteredData = Object.entries(data)
      .filter(([key]) => allAttributeKeys.includes(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

    const valuesNotInAttributes = Object.entries(data)
      .filter(([key]) => !allAttributeKeys.includes(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

    const mappingresult = Object.keys(filteredData).reduce((result, key) => {
      result[key] = filteredData[key];
      return result;
    }, {});

    const countriesValue = valuesNotInAttributes.countries;

    const fixedFieldsData = {
      attributes: JSON.stringify(mappingresult),
      ...valuesNotInAttributes,
      countries: countriesValue[0]?.value ? countriesValue.map((v) => v.value) : countriesValue,
    };
    if (companiesData?.member_id) {
      fixedFieldsData.member_id = companiesData.member_id;
    }

    if (companyLogo !== undefined) {
      if ('logo' in companyLogo) {
        fixedFieldsData.logo = companyLogo?.logo;
      } else {
        fixedFieldsData.logoPreview = companyLogo?.logoPreview;
      }
    }

    Object.entries(fixedFieldsData).forEach(([key, value]) => {
      Array.isArray(value) ? value.forEach((val) => bodyFormData.append(key, val)) : bodyFormData.append(key, value);
    });

    if (companyDocuments?.length) {
      Array.from(companyDocuments).forEach((file, idx) => {
        const fileId = file.id || '';
        const fileValue = file.file;

        if (file.id) {
          bodyFormData.append(`documents[${idx}]id`, fileId);
        } else {
          if (fileValue) {
            bodyFormData.append(`documents[${idx}]file`, fileValue);
          }
        }
      });
    }

    if (isCustomerIdEmpty) {
      bodyFormData.append(`client`, clientId);

      try {
        const result = await addMemberDetail(bodyFormData);
        const memberId = result.data?.id;
        const dataWithContact = assignedContacts.filter(item => item.contact);

        await Promise.all(dataWithContact.map(async (assignedContact) => {
          const contactData = { ...assignedContact.contact, member: memberId };
          try {
            await addContact(contactData);
            console.log('Contact added successfully');
          } catch (addContactError) {
            console.error('Error adding contact:', addContactError);
          }
        }));

        // SetSelectedMainLocation('');
        // SetSelectedLocations([]);

        SubmitLocation(memberId);
      } catch (err) {
        swal('Error!', 'Failed!', 'error');
        console.error(err);
      }
    } else {
      try {

        const dataWithContact = assignedContactsOnDetail.filter(item => item.contact);

        await Promise.all(dataWithContact.map(async (assignedContact) => {
          const contactData = { ...assignedContact.contact, member: paramID.customerId };
          try {
            await addContact(contactData);
            console.log('Contact added successfully');
          } catch (addContactError) {
            console.error('Error adding contact:', addContactError);
          }
        }));

        await updateMemberDetail(paramID.customerId, bodyFormData);
        swal('Success!', 'You successfully updated member!', 'success').then(() => {
          navigate('/customers');
        });
      } catch (err) {
        swal('Error!', 'Error when updating member...', 'error');
        console.error(err);
      }

      const assignedContactToSubmit = assignedContacts
        .map((assignedContact) => {
          const id = assignedContact.contact ? assignedContact.contact.id : null;
          const contact_title = assignedContact.id;
          return { id, contact_title };
        })
        .filter((e) => e.id);

      const arrAssignedContactIds = assignedContactToSubmit.map((e) => e.id);
      const nullContacts = companiesContacts
        .filter((contact) => !arrAssignedContactIds.includes(contact.id) && contact.contact_title)
        .map((contact) => ({ id: contact.id, contact_title: null }));

      const updatedAssignedContacts = [...assignedContactToSubmit, ...nullContacts];

      try {
        await bulkUpdateAssignedContacts(updatedAssignedContacts);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const getFilename = (url, options) => {
    url = _.isString(url) ? url : url.name;

    const filename = url.split('/');
    if (options?.onlyExtension) {
      const ext = filename[filename.length - 1].split('.');
      return '.' + ext[ext.length - 1];
    }
    return filename[filename.length - 1];
  };

  const openModalCreateLocation = () => {
    dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
    return dispatch(handleModal({ modalId: clientId, componentName: 'addNewLocation', modalData: paramID.customerId }));
  };

  const backtoLocationPair = () => {
    dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
    return dispatch(handleModal({ modalId: clientId, componentName: 'locationPair', modalData: paramID.customerId }));
  };

  const collectionSelected = companiesData?.countries;
  const resultsCountries = countries?.filter(({ value: name }) => collectionSelected?.some(({ id: id2 }) => id2 === name));

  const getBlankImageSrc = () => {
    return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII=';
  };

  const ChangeCompaniesData = (value, stateName) => {
    let newFixedFieldsData = _.cloneDeep(companiesData);

    if (stateName === 'countries') {
      value = value.map((val) => ({ id: val.value, name: val.label }));
    }

    newFixedFieldsData[stateName] = value;
  };

  const ChangeCountries = (value) => {
    let newFixedFieldsData = _.cloneDeep(companiesData);

    value = value.map((val) => ({ id: val.value, name: val.label }));

    newFixedFieldsData['countries'] = value;
    return setCompaniesData(newFixedFieldsData);
  };

  const requiredStar = <span className="text-red-500">*</span>;

  const InputLabel = ({ children, customClass }) => <p className={`font-semibold ${customClass}`}>{children}</p>;

  const PhoneInput = () => (
    <div className="w-full">
      <InputLabel>Phone</InputLabel>
      <Input
        control={control}
        defaultValue={companiesData?.phone === 'null' ? '' : companiesData?.phone}
        label="Phone"
        name="phone"
        onChangeValue={ChangeCompaniesData}
        setState={setCompaniesData}
      />
    </div>
  )

  const CompanyNameInput = () => (
    <div className="w-full">
      <InputLabel>Customer Name{requiredStar}</InputLabel>
      <Input
        control={control}
        defaultValue={companiesData?.name}
        // label={['Company Name', requiredStar]}
        name="name"
        onChangeValue={ChangeCompaniesData}
        setState={setCompaniesData}
      />
    </div>
  )

  const CountriesInput = () => (
    <div className="w-full mb-2">
    <InputLabel>Countries{requiredStar}</InputLabel>
    {companiesData?.countries ? (
      <Controller
        name="countries"
        control={control}
        defaultValue={companiesData?.countries.map((item) => ({ label: item.name, value: item.id }))}
        render={({ field: { onChange, value } }) => (
          <CustomSelect
            className="mt-[4px] bg-white"
            options={countries?.map((item) => ({ label: item.name, value: item.id }))}
            // placeholder={['Countries', requiredStar]}
            defaultValue={value}
            isMulti
            onChange={(v) => {
              onChange(v);
              ChangeCountries(v);
            }}
          />
        )}
      />
    ) : (
      <Controller
        name="countries"
        control={control}
        render={({ field: { onChange } }) => (
          <CustomSelect
            options={countries?.map((item) => ({ label: item.name, value: item.id }))}
            className="mt-[4px] bg-white"
            // placeholder={['Countries', requiredStar]}
            defaultValue={selectedCountries}
            isMulti
            onChange={(v) => {
              onChange(v);
              setSelectedCountries(v);
            }}
          />
        )}
      />
    )}
  </div>
  )

  const BusinessUnitInput = () => {
    const options = (businessUnitList || []).map((attr) => ({
      label: attr.name,
      value: attr.id,
    }));
  
    const defaultValue = (() => {
      if (mode !== 'add') {
        if (options.length === 1) {
          return [options[0]?.value];
        } else if (companiesData?.business_units) {
          return companiesData.business_units.map((data) => data.id);
        }
      }
      return [];
    })();
  
    return (
      <div className="w-full">
        <InputLabel customClass="mb-[4px]">Business Unit</InputLabel>
        <Select
          name="business_units"
          control={control}
          label="Business Unit"
          defaultValue={defaultValue}
          options={options}
          multiple
          OptionLabel="Business Unit"
          menuProps={{
            style: {
              maxHeight: 400,
            },
          }}
          isInputLabel
          disabled={options.length === 1}
        />
      </div>
    );
  };

  
  const OutletsInput = ({ defaultValue }) => {
    const options = (outletList || []).map((attr) => ({
      label: attr.name,
      value: attr.id,
    }));
  
    return (
      <div className="w-full">
        <InputLabel customClass="mb-[4px]">Outlets</InputLabel>
        <Select
          name="outlets"
          control={control}
          label="Outlets"
          defaultValue={defaultValue}
          options={options}
          multiple
          OptionLabel="outlets"
          menuProps={{
            style: {
              maxHeight: 400,
            },
          }}
          isInputLabel
        />
      </div>
    );
  };
  
  const company = attributeCategories && (
    <form className={mode !== 'add' ? 'w-full space-y-4 col-span-9' : 'w-full grid space-y-4 col-span-9'}>
      <Paper elevation={0} className="rounded-lg border-[1.5px]">
        <div className="border rounded-t-md p-4 font-bold uppercase">
          <h2>Customer</h2>
        </div>
        <div className="p-4 border rounded-b-md ">
          <div className="w-full FOR-COMPANY-PROFILE mb-1">
            <div className="flex gap-x-4 gap-y-1 items-center">
              {/* boundary */}
              <div className="w-full grid grid-cols-2 gap-x-4">
                <div className="flex w-full gap-x-4">
                  <div className="w-[170px] h-[130px] relative mt-2">
                    {companyLogo?.logoPreview && (
                      <CancelIcon className="absolute right-[2px] top-[2px] cursor-pointer" sx={{ color: 'red', height: '20px', width: '20px' }} onClick={handleRemoveLogo} />
                    )}
                    <label className="bg-white">
                      <img
                        src={companyLogo?.logoPreview || getBlankImageSrc()}
                        alt=""
                        style={{ width: '100%', height: '135px', objectFit: 'contain', cursor: 'pointer', border: '1px solid black' }}
                        className="bg-white"
                      />
                      <input type="file" style={{ display: 'none' }} name="uploaded_logo" accept="image/*" onChange={(e) => handleChangeLogo(e)} />
                    </label>
                  </div>
                  <div className="w-full">
                    {mode !== 'add' && companiesData?.member_id && (
                      <>
                        <div className="w-full">
                          <InputLabel>ID</InputLabel>
                          <Input control={control} defaultValue={companiesData?.member_id && companiesData?.member_id} label="ID" type="number" name="ID" disabled={true} />
                        </div>
                        <CompanyNameInput />
                      </>
                    )}
                    
                    {mode === 'add' && (
                      <>                      
                        <CompanyNameInput />
                        {businessUnitList !== null && businessUnitList.length > 0 ? <BusinessUnitInput /> : <PhoneInput />}
                      </>
                    )}
                  </div>
                </div>
                <div className="w-full">
                  <CountriesInput />
                  {
                    mode === "add" ? (
                      <></>
                    ): businessUnitList !== null && businessUnitList.length > 0 ? <BusinessUnitInput /> : <PhoneInput />
                  }
                  {/* {businessUnitList !== null && businessUnitList.length > 0 ? <BusinessUnitInput /> : mode === 'add' ? "" : <PhoneInput />} */}
                </div>
              </div>
              {/* boundary */}
            </div>
            <div className={`w-full grid ${businessUnitList !== null && businessUnitList.length > 0 ? 'grid-cols-3' : 'grid-cols-2'} gap-x-4`}>
              <div className={`w-full`}>
                <InputLabel>Customer Address</InputLabel>
                <Input
                  control={control}
                  defaultValue={companiesData?.address}
                  label="Customer Address"
                  name="address"
                  onChangeValue={ChangeCompaniesData}
                  setState={setCompaniesData}
                />
              </div>
              <div className={`w-full`}>
                <InputLabel>Email</InputLabel>
                <Input control={control} defaultValue={companiesData?.email} label="Email" name="email" onChangeValue={ChangeCompaniesData} setState={setCompaniesData} />
              </div>
              {businessUnitList !== null && businessUnitList.length > 0 ? <PhoneInput /> : ''}
            </div>
            <div className="w-full grid grid-cols-3 gap-x-4">
              <div className={`w-full`}>
                <InputLabel>Website</InputLabel>
                <Input
                  control={control}
                  defaultValue={companiesData?.website}
                  label="websiter"
                  name="website"
                  onChangeValue={ChangeCompaniesData}
                  setState={setCompaniesData}
                />
              </div>
              <div className={`w-full`}>
                <InputLabel>Tax Number</InputLabel>
                <Input
                  control={control}
                  defaultValue={companiesData?.tax_number}
                  label="Tax Number"
                  name="tax_number"
                  onChangeValue={ChangeCompaniesData}
                  setState={setCompaniesData}
                />
              </div>
              <div className={`w-full`}>
                <InputLabel>{'Organization Number (NIB)'}</InputLabel>
                <Input
                  control={control}
                  defaultValue={companiesData?.organization_number}
                  label="Organization Number (NIB)"
                  name="organization_number"
                  onChangeValue={ChangeCompaniesData}
                  setState={setCompaniesData}
                />
              </div>
              {
                outletList.length > 0 && <OutletsInput defaultValue={companiesData?.outlets && companiesData?.outlets.map((item) => item.id) || []} />
              }
            </div>
          </div>
          <div className="w-full FOR-ATTRIBUTE-CATEGORY">
            <div className="w-full grid grid-cols-2 gap-x-4 gap-y-1">
              {attributeCategories?.map((val, idxCategory) => {
                return val.attributes?.map((data, idx) => {
                  const value = data?.member_attribute?.attribute_values;
                  const matchedAttribute = companiesData?.attributes?.find((attrData) => attrData.name === data.name);                  

                  if (data.value_type == 'Date') {
                    return (
                      <div className="w-full">
                        <InputLabel>{data.is_required ? [data.name, requiredStar] : data.name}</InputLabel>
                        <DatePickerField
                          sx={{ marginBottom: '8px', marginTop: '4px', background: 'white' }}
                          size="small"
                          fullWidth
                          name={data.key_name}
                          control={control}
                          className="mt-[4px]"
                          // label={data.is_required ? [data.name, requiredStar] : data.name}
                          defaultValue={matchedAttribute?.value ? matchedAttribute?.value : null}
                        />
                      </div>
                    );
                  }
                  if (data.value_type === 'String' ?? 'Number') {
                    return (
                      <div className="w-full">
                        <InputLabel>{data.is_required ? [data.name, requiredStar] : data.name}</InputLabel>
                        <Input
                          name={data.key_name}
                          control={control}
                          // label={data.is_required ? [data.name, requiredStar] : data.name}
                          type={data.value_type === 'Number' ? 'Number' : ''}
                          defaultValue={matchedAttribute?.value ? matchedAttribute?.value : ''}
                        />
                      </div>
                    );
                  }
                  if (data.value_type === 'Option') {
                    const options = (attributesOptions[matchedAttribute?.key ? matchedAttribute?.key : data.key_name] || []).map((attr) => ({
                      label: attr.name,
                      value: attr.name,
                    }));

                    return (
                      <div className="w-full">
                        <InputLabel customClass="mb-[4px]">{data.is_required ? [data.name, requiredStar] : data.name}</InputLabel>
                        <Select
                          name={data.key_name}
                          control={control}
                          label={data.name}
                          defaultValue={matchedAttribute?.value ? matchedAttribute?.value : []}
                          options={options}
                          multiple={matchedAttribute?.multiple_value ? matchedAttribute?.multiple_value : data?.multiple_value}
                          OptionLabel={data.name}
                          menuProps={{
                            style: {
                              maxHeight: 400,
                            },
                          }}
                          isInputLabel
                        />
                      </div>
                    );
                  }
                });
              })}
            </div>
          </div>
          <div className="w-full mt-4 pt-6 mr-4 border-t-2">
            {isLoaded ? (
              <div className="w-full">
                <div className="w-full pr-4 flex flex-col gap-y-2 pb-4">
                  <InputLabel>Location :</InputLabel>
                  <div className="w-full grid grid-cols-2 gap-6">
                    {companiesLocation.length !== 0 &&
                      companiesLocation?.map((loc) => {
                        return (
                          <div className="w-full">
                            <GoogleMap
                              mapContainerStyle={containerStyle}
                              center={{ lat: parseFloat(loc?.latitude), lng: parseFloat(loc?.longitude) }}
                              zoom={14}
                              // onLoad={onLoad}
                              onUnmount={onUnmount}
                            >
                              <Marker position={{ lat: parseFloat(loc?.latitude), lng: parseFloat(loc?.longitude) }} />
                            </GoogleMap>
                            <div className="min-h-[85px] flex flex-col gap-y-1 border-x border-b p-2 rounded-md shadow-sm">
                              <p className="italic text-[#f16b26] font-semibold">{loc.is_main ? 'Main Location' : ''}</p>
                              <p className="text-sm text-slate-500">{loc?.address}</p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                {
                  isOnlyUser === false &&
                    <div className="w-[50%] pr-4">
                      <button
                        className="w-full bg-[#2C6D47] p-2 text-white rounded-lg"
                        type="button"
                        onClick={() => dispatch(handleModal({ modalId: clientId, componentName: 'locationPair', modalData: paramID.customerId }))}
                      >
                        Set Locations
                      </button>
                    </div>
                }
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="w-full mt-4 pt-6 mr-4 border-t-2">
            <div className="flex w-full">
              <div className="w-full pr-4 flex flex-col gap-y-2">
                <div className="flex">
                  <InputLabel>Documents uploaded :</InputLabel>
                  {
                    isOnlyUser === false && (
                      <button
                        className={
                          !disabledButtonDelete
                            ? 'btn bg-red-600 p-2 text-white rounded-lg border ml-auto cursor-pointer'
                            : 'btn bg-red-200 p-2 text-white rounded-lg border ml-auto'
                        }
                        onClick={() => deleteDocuments()}
                        // disabled={isDisabledDeleteDocuments(idxAttr)}
                        disabled={disabledButtonDelete}
                        name="update document list"
                        type="button"
                      >
                        Delete
                      </button>
                    )
                  }
                </div>
                <div className="grid grid-cols-1 2xl:grid-cols-2 gap-2">
                  {(companyDocuments || []).map((item) => {
                    const filename = item.file ? getFilename(item.file) : '';
                    const extension = item.file ? getFilename(item.file, { onlyExtension: true }) : '';

                    return (
                      <div className="w-full px-2">
                        <div className="w-full flex gap-y-1 gap-x-3 border-x border p-2 rounded-md shadow-sm">
                          {
                            isOnlyUser === false &&
                            <input
                              className="mr-3"
                              type="checkbox"
                              value={item.id}
                              checked={checkedDocuments.includes(item.id)}
                              onChange={(e) => handleCheckedDocuments(e, item.id)}
                            />
                          }
                          <div className="w-full 2xl:w-[55%] font-bold flex items-center" style={{ color: '#707070' }}>
                            {filename}
                          </div>
                          <div className="w-full flex items-center justify-between">
                            <div style={{ color: '#707070' }}>{extension}</div>
                            <a
                              href={item.file}
                              download
                              className="btn btn-link flex items-center justify-center"
                              style={{ backgroundColor: '#E2E2E2', height: '32px', width: '32px', borderRadius: '50%' }}
                            >
                              <DownloadIcon color="primary" />
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {
                  isOnlyUser === false && (
                    <div className="mt-4 w-6/12">
                      <label className="w-full bg-[#2C6D47] p-2 text-white rounded-lg cursor-pointer flex justify-center">
                        <input type="file" style={{ display: 'none' }} name="uploaded_documents" multiple onChange={(e) => handleFileUpload(e)} />
                        Add new documents
                      </label>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </Paper>
      <Paper elevation={0} className="w-full h-fit rounded-lg border-[1.5px]">
        <div className="border rounded-t-md p-4 font-bold uppercase">
          <h2>Contact</h2>
        </div>
        <div className="w-full p-4 border rounded-b-md grid grid-cols-1 2xl:grid-cols-2 gap-x-24 gap-y-8">
          {assignedContacts?.map((assignedContact, idxContact) => {
            const { contact } = assignedContact;

            const newContactsOnDetail = assignedContactsOnDetail.length > 0 && assignedContactsOnDetail[idxContact].contact !== null

            if (mode === 'add') {
              return (
                <div key={assignedContact.id} className="">
                  <InputLabel className="pl-2 mb-2">{assignedContact.name}</InputLabel>
                  <ContactSelectButton
                    idxContact={idxContact}
                    name={contact?.name}
                    title={contact?.position}
                    phone={contact?.phone}
                    email={contact?.email}
                    inputData={contact}
                    handleAssignedContact={handleAssignedContact}
                  />
                </div>
              );
            } else {
              return (
                <div key={assignedContact.id} className="">
                  <InputLabel className="pl-2 mb-2">{assignedContact.name}</InputLabel>
                  <ContactSelect
                    idx={idxContact}
                    name={newContactsOnDetail !== false ? assignedContactsOnDetail[idxContact]?.contact?.name : contact?.name}
                    title={newContactsOnDetail !== false ? assignedContactsOnDetail[idxContact]?.contact?.position : contact?.position}
                    phone={newContactsOnDetail !== false ? assignedContactsOnDetail[idxContact]?.contact?.phone : contact?.phone}
                    email={newContactsOnDetail !== false ? assignedContactsOnDetail[idxContact]?.contact?.email : contact?.email}
                    contactId={newContactsOnDetail !== false ? assignedContactsOnDetail[idxContact].id : contact?.id}
                    contacts={companiesContacts}
                    selectedContacts={assignedContacts.map((c) => c.contact?.id)}
                    handleSelectedContact={handleSelectedContact}
                    authorize={isOnlyUser}
                    isDetailPage={assignedContactsOnDetail[idxContact]?.contact !== null}
                  />
                  <div className="mt-2">
                    <button
                      type="button"
                      className="w-full bg-[#2C6D47] p-2 text-white rounded-lg"
                      onClick={() => dispatch(handleModal({ modalId: idxContact, componentName: 'AddNewContactonDetail', modalData: assignedContactsOnDetail[idxContact]?.contact }))}
                    >
                      Assign New Contact
                    </button>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </Paper>
      {
        isOnlyUser === false && (
          <div className="flex justify-end">
            <button
              className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
              type="submit"
              name="submit data company"
              onClick={handleSubmit(validationSubmit)}
            >
              {mode !== 'add' ? 'Update Customer Data' : 'Add New Customer'}
            </button>
          </div>
        )
      }
    </form>
  );

  const customFeatures = (
    <div className="col-span-3">
      {projectList.length ? (
        <div className="w-full mb-4">
          <Accordion>
            <AccordionSummary 
              expandIcon={<ExpandMore className="" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="border-b-2 bg-white min-h-[57px]"
              sx={{
                '&.Mui-expanded': {
                  minHeight: '57px!important',
                },
              }}
            >
              <h2 className="font-bold">Project List</h2>
            </AccordionSummary>
            <AccordionDetails className="py-5">
              {projectList?.map((val, idx) => {
                return (
                  <div key={val.id} className="w-full px-4">
                    <ul>
                      <li>
                        <Link to={`/task/project/${val.id}`} className="hover:text-blue-500">{`${idx + 1}. ${val.name}`}</Link>
                      </li>
                    </ul>
                  </div>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        <></>
      )}
      <div className="w-full mb-4">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore className="" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="border-b-2 bg-white min-h-[57px]"
            sx={{
              '&.Mui-expanded': {
                minHeight: '57px!important',
              },
            }}
          >
            <h2 className="font-bold">History</h2>
          </AccordionSummary>
          <AccordionDetails className="border-t-[1.5px] border-[#E5E7EB] py-5">
            <div className="w-full max-h-[400px] overflow-auto">
              {histories &&
                histories
                  ?.filter((unEmptyData) => unEmptyData.length)
                  .map((history) => {
                    return (
                      <div className="w-full border rounded-md">
                        <div className="border-b p-2 font-medium">{attributes[history[0]?.attribute]?.name}</div>
                        {history?.map((val) => {
                          return (
                            <div className="mb-2 flex flex-col bg-slate-300 mt-2 mx-2 rounded-sm p-4 gap-y-4">
                              <div className={['mb-1'].join(' ')} style={{ fontWeight: '500' }}>
                                <span className="badge badge-pill border border-dark p-1 rounded-sm">{val.action}</span>
                              </div>
                              <div className="font-medium">{val.description}</div>
                              <div className="flex justify-between" style={{ fontSize: '12px', fontWeight: '500' }}>
                                <div>{new Date(val.timestamp).toLocaleString('en-US', { dateStyle: 'long', timeStyle: 'short', hour12: true })}</div>
                                {
                                  isOnlyUser == false && (
                                    <EditIcon
                                      className="cursor-pointer"
                                      onClick={() => {
                                        dispatch(handleModal({ modalId: '', componentName: 'EditHistory' }));
                                        setHistoryData(val);
                                      }}
                                    />
                                  )
                                }
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
            </div>
            <div className="w-full mt-2 px-4">
              <button type="button" className="w-full bg-[#2C6D47] p-2 text-white rounded-lg" onClick={() => dispatch(handleModal({ modalId: '', componentName: 'ViewHistory' }))}>
                View More
              </button>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="w-full mb-4">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore className="" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="border-b-2 bg-white min-h-[57px]"
            sx={{
              '&.Mui-expanded': {
                minHeight: '57px!important',
              },
            }}
          >
            <h2 className="font-bold">Notes List</h2>
          </AccordionSummary>
          <AccordionDetails className="border-t-[1.5px] border-[#E5E7EB] py-5">
            <div className="w-full max-h-[400px] overflow-auto">
              {notes &&
                notes.map((val, idx) => {
                  return (
                    <div className={idx % 2 === 0 ? 'w-full mb-2 py-2 px-5 rounded-sm bg-[#bedcee]' : 'w-full mb-2 py-2 px-5 rounded-sm bg-[#ead2b5]'}>
                      <div className="flex flex-col gap-y-2">
                        <div className={idx % 2 === 0 ? 'flex justify-between py-2 border-b-2 border-[#a1d0ed]' : 'flex justify-between py-2 border-b-2 border-[#e7c5a0]'}>
                          <h2 className="font-medium">{val.context}</h2>
                          {
                            isOnlyUser === false && (
                              <EditIcon
                                className="cursor-pointer"
                                onClick={() => {
                                  dispatch(handleModal({ modalId: '', componentName: 'AddNote', modalData: 'edit' }));
                                  setNoteModalStatus('edit');
                                  setNoteId(val.id);
                                }}
                              />
                            )
                          }
                        </div>
                        <div>
                          <div>
                            <h2 className="font-medium">Contacted Person :</h2>
                            <p className="px-2">{val.contacted_person || '-'}</p>
                          </div>
                          <div>
                            <h2 className="font-medium">Reason :</h2>
                            <p className="px-2">{val.reason || '-'}</p>
                          </div>
                          <div>
                            <h2 className="font-medium">Result :</h2>
                            <p className="px-2">{val.result || '-'}</p>
                          </div>
                        </div>
                        <div className={idx % 2 === 0 ? 'text-sm font-semibold py-2 border-t-2 border-[#a1d0ed]' : 'text-sm font-semibold py-2 border-t-2 border-[#e7c5a0]'}>
                          <p>{val.date}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            {
              isOnlyUser === false && (
                <div className="w-full mt-4 px-4">
                  <button
                    type="button"
                    className="w-full bg-[#2C6D47] p-2 text-white rounded-lg"
                    onClick={() => {
                      dispatch(handleModal({ modalId: '', componentName: 'AddNote', modalData: 'add' }));
                      setNoteModalStatus('add');
                    }}
                  >
                    Add Note
                  </button>
                </div>
              )
            }
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );

  return (
    memberDetail && (
      <div className="w-full h-full">
        <BZHelmet title={mode !== 'add' ? 'Company Detail' : 'Add Company'} description="" content="" />
        <BaseMainTitle title={mode !== 'add' ? 'Company Detail' : 'Add Company'} />
        <div className="p-5">
          <div className="mb-4">
            <button
              onClick={() => navigate('/customers')}
              className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
              type="button"
            >
              <ArrowBack />
              Back
            </button>
          </div>
          <div className={mode !== 'add' ? 'grid grid-cols-12 gap-x-2 my-4' : 'w-full'}>
            {mode !== 'add' ? companiesData && company : company}
            {mode !== 'add' && customFeatures}
          </div>
        </div>
        <ModalWrapper componentName="AddNote" header="Add Note" maxWidth="sm">
          <AddNote status={NoteModalStatus} memberId={paramID.customerId} reload={setReloadNotes} noteId={noteId} />
        </ModalWrapper>
        <ModalWrapper componentName="ViewHistory" header="View History" maxWidth="lg">
          <ViewHistory memberId={paramID.customerId} />
        </ModalWrapper>
        <ModalWrapper componentName="EditHistory" header="Edit History" maxWidth="sm">
          <EditHistory data={historyData} reload={setReloadHistories} />
        </ModalWrapper>
        <ModalWrapper componentName="locationPair" header="Pair Location" maxWidth="xl" onCloseButtonClick={() => store.dispatch({ type: 'crmFilter/resetLocationPair' })}>
          <LocationPair
            mode={mode === 'add'}
            submit={openModalCreateLocation}
            reload={setReloadLocations}
            StateLocations={selectedLocations}
            StateMainLocation={selectedMainLocation}
            SetStateLocations={SetSelectedLocations}
            SetStateMainLocation={SetSelectedMainLocation}
          />
        </ModalWrapper>
        <ModalWrapper componentName="addNewLocation" header="New Location" maxWidth="sm" onCloseButtonClick={() => store.dispatch({ type: 'crmFilter/resetLocationPair' })}>
          <AddNewLocation backtoLocationPair={backtoLocationPair} />
        </ModalWrapper>
        <ModalWrapper componentName="AddNewContactonDetail" header="Add New Contact" maxWidth="sm">
          <AddContact noSubmitApi handleAssignedContact={handleAssignedContactOnDetail} />
        </ModalWrapper>
      </div>
    )
  );
}
