import React from 'react';
import { Link } from 'react-router-dom';

export default function SidebarBusinessUnitsSettings({ dataBusinessUnits }) {
  const html =
    dataBusinessUnits.length > 0 &&
    dataBusinessUnits?.map((bu, i) => (
      <div key={`${bu.name + i}`} className="flex items-center justify-start py-2">
        <Link to={`/settings/business-unit/${bu.id}`} className="flex space-y-3 items-center">
          <div className="ml-14 flex items-center">
            <span className="hover:text-orange-500">{bu.name}</span>
          </div>
        </Link>
      </div>
    ));
  return html;
}
