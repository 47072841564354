import axios from 'axios';
import Cookies from 'js-cookie';
import useTokenBearer from './useTokenBearer';

const client = axios.create({ baseURL: `${import.meta.env.VITE_API_URL}` });
const token = Cookies.get('bzKey') || localStorage.getItem('bzKey');

const useRequest = () => {
  const request = (options) => {
    client.defaults.headers.common.Authorization = `${useTokenBearer()} ${token || options.token}`;

    const onSuccess = (response) => response;
    const onError = (error) => error;
    return client(options).then(onSuccess).catch(onError);
  };

  return { request };
};

export default useRequest;
