import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Paper, Stack, TableRow, Table, TableBody, TableCell, TableContainer, TableHead, Pagination, ButtonBase, InputBase } from '@mui/material';
import { Delete, Edit, Save } from '@mui/icons-material';
import { useSelector } from 'react-redux';

import accountingApi from '../../../../api/accounting/accounting';

function Row(props) {
  const cl = useSelector((state) => state.auth.client.id);
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeUser = useSelector((state) => state.auth.user);
  const client = activeUser.is_superuser ? activeClient : cl;

  const [counterPart, setCounterPart] = useState('');
  const { row, index, onEditClick, onDeleteClick, isEditMode, onSubmit } = props;
  const isEdit = isEditMode?.id === row.id;

  const onChange = (e) => {
    const { value } = e.target;
    if (value) {
      setCounterPart(value);
    }
  };

  const counterpartData = {
    client: client,
    customer: row.id,
    code: counterPart,
    name: counterPart,
  };

  let btnSaveHtml;
  if (row.counterpart) {
    btnSaveHtml = (
      <ButtonBase className="bz-btn-tb" onClick={() => onSubmit('update', counterpartData, row.counterpartId)}>
        <Save fontSize="24" />
      </ButtonBase>
    );
  } else {
    btnSaveHtml = (
      <ButtonBase className="bz-btn-tb" onClick={() => onSubmit('create', counterpartData)}>
        <Save fontSize="24" />
      </ButtonBase>
    );
  }
  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell align="center">{index + 1}</TableCell>
      <TableCell component="th" scope="row">
        {row.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {isEdit ? <InputBase defaultValue={row.counterpart} onChange={onChange} className="border rounded-md border-gray-300 px-2 text-sm" /> : row.counterpart}
      </TableCell>
      <TableCell align="center">
        <div className="flex space-x-2">
          {isEdit ? (
            btnSaveHtml
          ) : (
            <ButtonBase className="bz-btn-tb" onClick={onEditClick}>
              <Edit fontSize="24" />
            </ButtonBase>
          )}
          <ButtonBase className={`bz-btn-tb ${!row.counterpartId ? 'bg-gray-100' : ''}`} disabled={!row.counterpartId} onClick={onDeleteClick}>
            <Delete fontSize="24" />
          </ButtonBase>
        </div>
      </TableCell>
    </TableRow>
  );
}

export default function TabCounterPart() {
  const cl = useSelector((state) => state.auth.client.id);
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeUser = useSelector((state) => state.auth.user);
  const client = activeUser.is_superuser ? activeClient : cl;

  const [counterParts, setCounterpart] = useState(false);
  const [members, setMembers] = useState(false);
  const [dataMembers, setDataMembers] = useState(false);
  const [joinedData, setJoinedData] = useState(false);
  const [modalData, setModalData] = useState('');

  // pagination
  const [rowsPerPage] = useState(10);
  const [countPage, setCountPage] = useState(1);
  const [page, setPage] = useState(1);
  const prevPageRef = useRef(page);

  const getJoinedData = useCallback(
    (cps = null) => {
      const joinedDatas = dataMembers.map((m) => {
        const dataCounter = cps || counterParts;
        const dataCounterPart = dataCounter.filter((cp) => m.id === cp.customer.id);
        if (dataCounterPart.length > 0) {
          return {
            id: m.id,
            name: m.name,
            counterpart: dataCounterPart[0].name,
            counterpartId: dataCounterPart[0].id,
          };
        }
        return {
          id: m.id,
          name: m.name,
          counterpart: '',
          counterpartId: '',
        };
      });
      setJoinedData(joinedDatas);
    },
    [dataMembers, counterParts]
  );

  const getCounterPartList = useCallback(
    (params = {}, action) => {
      const { getCounterparts } = accountingApi();
      getCounterparts({client, ...params})
        .then((res) => {
          if (res.status === 200 && res.data.results) {
            setCounterpart(res.data.results);
            if (action) {
              getJoinedData(res.data.results);
            }
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    [getJoinedData]
  );

  const getMemberList = useCallback(
    (params) => {
      const { getMembers } = accountingApi();
      getMembers(params)
        .then((res) => {
          if (res.status === 200 && res.data) {
            setMembers(res.data);
            setDataMembers(res.data.slice(0, rowsPerPage));
            setCountPage(Math.ceil((res.data.length + 1) / rowsPerPage));
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    [rowsPerPage]
  );

  const handleChangePage = (event, value) => {
    if (value > page) {
      setDataMembers(members.slice(page * rowsPerPage, (page + 1) * rowsPerPage));
    } else {
      setDataMembers(members.slice((value - 1) * rowsPerPage, value * rowsPerPage));
    }
    setPage(value);
  };

  const handleActionSubmit = (mode, data, counterpartId = null) => {
    if (data) {
      const { addCounterpart, updateCounterpart } = accountingApi();
      if (mode === 'create') {
        addCounterpart(data)
          .then((res) => {
            if ([200, 201].includes(res.status) || res.data) {
              getCounterPartList({}, true);
            }
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
      if (mode === 'update' && counterpartId) {
        updateCounterpart(counterpartId, data)
          .then((res) => {
            if ([200, 201].includes(res.status) || res.data) {
              getCounterPartList({}, true);
            }
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
      setPage(1);
      setModalData(false);
    }
  };

  const onEditClick = (data) => {
    setModalData(data);
  };

  const onDeleteClick = (counterpartId) => {
    const { deleteCounterpart } = accountingApi();

    if (counterpartId) {
      deleteCounterpart(counterpartId)
        .then(() => {
          getCounterPartList({}, true);
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  useEffect(() => {
    if (!members) {
      getMemberList({ client });
      getCounterPartList({ max_size: true });
    }
  }, [members, getMemberList, getCounterPartList, client]);

  useEffect(() => {
    if (dataMembers && counterParts && !joinedData) {
      getJoinedData();
    }
  }, [dataMembers, counterParts, joinedData, getJoinedData]);

  useEffect(() => {
    if (prevPageRef.current !== page) {
      getJoinedData();
      prevPageRef.current = page;
    }
  }, [getJoinedData, page]);

  return (
    <div>
      <div className="w-full flex p-4 justify-center overflow-hidden min-h-[]">
        <TableContainer component={Paper} style={{ width: 'auto' }}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {joinedData?.length > 0 && <TableCell />}
                <TableCell align="left" className="font-bold">
                  Name
                </TableCell>
                <TableCell align="left" className="font-bold">
                  Counterpart
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {joinedData &&
                joinedData.length > 0 &&
                joinedData.map((row, i) => (
                  <Row
                    key={`${row + i}`}
                    row={row}
                    index={i}
                    onEditClick={() => onEditClick(row, i)}
                    onDeleteClick={() => onDeleteClick(row.counterpartId)}
                    isEditMode={modalData}
                    onSubmit={handleActionSubmit}
                  />
                ))}
              {joinedData && joinedData.length === 0 && (
                <TableRow className="p-4">
                  <TableCell component="th" scope="row">
                    <span>no data</span>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {joinedData && joinedData.length > 0 && (
        <div className="w-full flex justify-center p-4">
          <Stack spacing={2}>
            <Pagination count={countPage} page={page} onChange={handleChangePage} />
          </Stack>
        </div>
      )}
    </div>
  );
}
