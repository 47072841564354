import React from 'react';
// import { Assignment } from '@mui/icons-material';
import { HomeOutlined, PeopleAltOutlined, AccountTree, AccessTime } from '@mui/icons-material';
// import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
// import StarBorderPurple500OutlinedIcon from '@mui/icons-material/StarBorderPurple500Outlined';
// import Boards from '../pages/Task/Boards/Boards';
import Team from '../pages/Task/Team';
// import Report from '../pages/Task/Report';
// import Goals from '../pages/Task/Goals';
import TaskHome from '../pages/Task/Home';
import Project from '../pages/Task/Project';
import TaskReport from '../pages/Task/TaskReport/TaskReport';
import CreateBlank from '../pages/Task/Projects/CreateBlank';
import EditProject from '../pages/Task/Projects/Edit';
import Detail from '../pages/Task/Projects/Detail';
import TaskDetail from '../pages/Task/TaskDetail/TaskDetail';

const taskRoute = [
  {
    name: 'Home',
    element: <TaskHome />,
    icon: <HomeOutlined />,
    privilege: [99, 5, 7, 8, 12, 17],
    subscriptions: [8],
    path: '/task/home',
    isSidebar: true,
    layout: 'task',
  },
  {
    name: 'Team',
    element: <Team />,
    icon: <PeopleAltOutlined />,
    privilege: [99, 5, 7, 8, 12, 17],
    subscriptions: [8],
    path: '/task/teams',
    isSidebar: true,
    layout: 'task',
  },
  {
    name: 'Project',
    element: <Project />,
    icon: <AccountTree />,
    privilege: [99, 5, 7, 8, 12, 17],
    subscriptions: [8],
    path: '/task/projects/',
    isSidebar: true,
    layout: 'task',
    // child: <div className="">tes</div>,
  },
  {
    name: 'Project Team New',
    element: <CreateBlank />,
    icon: <AccountTree />,
    privilege: [99, 5, 7, 8, 12, 17],
    subscriptions: [8],
    path: '/task/team/:teamId/project/new/blank',
    isSidebar: false,
    layout: 'task',
  },
  {
    name: 'Project New',
    element: <CreateBlank />,
    icon: <AccountTree />,
    privilege: [99, 5, 7, 8, 12, 17],
    subscriptions: [8],
    path: '/task/project/new/blank',
    isSidebar: false,
    layout: 'task',
  },
  {
    name: 'Team Project Edit',
    element: <EditProject />,
    icon: <AccountTree />,
    privilege: [99, 5, 7, 8, 12, 17],
    subscriptions: [8],
    path: '/task/team/:teamId/project/:projectId/edit',
    isSidebar: false,
    layout: 'task',
  },
  {
    name: 'Project Task Detail',
    element: <Detail />,
    privilege: [99, 5, 7, 8, 12, 17],
    subscriptions: [8],
    path: '/task/project/:projectId',
    isSidebar: false,
    layout: 'task',
  },
  {
    name: 'Project Task Layout Tab',
    element: <Detail />,
    privilege: [99, 5, 7, 8, 12, 17],
    subscriptions: [8],
    path: '/task/project/:projectId/:tabs',
    isSidebar: false,
    layout: 'task',
  },
  {
    name: 'Task Detail',
    element: <TaskDetail />,
    privilege: [99, 5, 7, 8, 12, 17],
    subscriptions: [8],
    path: '/task/detail/:taskId',
    isSidebar: false,
    layout: 'task',
  },
  {
    name: 'Task Report',
    element: <TaskReport />,
    icon: <AccessTime />,
    privilege: [99, 5, 7, 8, 12, 17],
    subscriptions: [8],
    path: '/task/report/',
    isSidebar: true,
    layout: 'task',
  },
];
export default taskRoute;
