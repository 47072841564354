/* eslint-disable */

import React from 'react';
import { useParams } from 'react-router';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { fetchUserDetail } from '../../hooks/useUsersData';
import UserForm from '../../components/Form/User/UserForm';
import request from '../../helper/axiosUtil';
import store from '../../store';
import BaseMainTitle from '../../components/BaseMainTitle';
import BZHelmet from '../../utils/BZHelmet';
import renderError from '../../utils/Error/renderError';
import useGet from '../../hooks/useGet';
import UserFormSSO from '@/components/Form/User/UserFormSSO';
import { useDispatch } from 'react-redux';
import { handleModal } from '@/store/slices/modalSlice';

export default function UserDetail() {
  const { userId } = useParams();
  const { data: userData } = useGet({
    url: `users/${userId}`,
    validate: `user-detail-${userId}`,
    // onSuccess: (e) => {
    //   window.location.reload();
    // },
  });
  const dispatch = useDispatch();

  const isSSO = JSON.parse(localStorage.getItem('isSSO'));

  const { enqueueSnackbar } = useSnackbar();
  const qClient = useQueryClient();

  const userLogin = useSelector((state) => state.auth.user.id);
  const userGroups = useSelector((state) => state.auth.user);

  const updateUsers = async (data) => request({ url: `/users/${userId}/`, method: 'put', data });

  const { mutateAsync: updateUser } = useMutation(updateUsers, {
    onSuccess: (res) => {
      qClient.invalidateQueries('users');
      qClient.invalidateQueries(['user-detail', { userId }]);
      if (res.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
        dispatch(handleModal({ modalId: '' }));
      } else {
        enqueueSnackbar('error', { variant: 'error' });
        dispatch(handleModal({ modalId: '' }));

        renderError({ data: JSON.parse(res.request.response) });
      }

      if (userLogin.toString() === userId.toString()) {
        const newGroupsUser = { ...userGroups };
        newGroupsUser.groups = e.data?.groups;
        store.dispatch({ type: 'auth/setIsGroup', payload: { isGroups: e?.data?.groups.map((gr) => gr.id), newUser: newGroupsUser } });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    },
  });

  const onUpdateUser = async (data) => {
    await updateUser(data);
  };

  return (
    <div>
      <BZHelmet title="User Detail" description="bz publish user detail" />
      <BaseMainTitle title="User Detail :" />
      <div className="p-5 ">
        <div className="bg-white border p-5 shadow rounded">
          {!isSSO ? (
            <UserForm defaultValues={userData?.data} key={userData?.data?.id} onFormSubmit={onUpdateUser} />
          ) : (
            <UserFormSSO defaultValues={userData?.data} key={userData?.data?.id} onFormSubmit={onUpdateUser} />
          )}
        </div>
      </div>
    </div>
  );
}
