import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, Box, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { handleModal } from '@/store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ModalWrapper from '@/components/ui/ModalWrapper';
import Swal from 'sweetalert2';
import OutletServingTimeForm from '@/components/Form/Sales/OutletServingTimeForm';
import POSApi from '@/api/pos/posApi';

function ServingTimesTab({ dataServingTimes, getAllServingTimes, outletId }) {
  const clientId = useSelector((state) => state.client.activeClient);
  const dispatch = useDispatch();

  function formatOffsetUTC(offsetUTC) {
    const tandaOffset = offsetUTC >= 0 ? '+' : '-';
    const jamOffset = Math.abs(offsetUTC) / 60;
  
    return ` (UTC${tandaOffset}${jamOffset})`;
  }  

  const deleteItem = async(data) => {
    const { deleteServingTimeById } = POSApi();

    try {
      await deleteServingTimeById(data.id);
      getAllServingTimes(clientId);
      Swal.fire({
        title: 'Success!',
        text: 'Successfully Deleted Serving Time!',
        icon: 'success',
      });
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Error When Try to Delete Serving Time.',
        icon: 'error',
      });
      console.error(error)
    }
  }

  const handleDeleteClick = (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to delete this Serving Time?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2C6D47',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem(data);
      }
    });
  };

  return (
    <>
      <div className="w-full flex justify-end mb-4 items-center gap-x-2">
        <button 
          className="btn bg-[#2C6D47] p-2 text-white rounded-lg border cursor-pointer" 
          name="AddNewServingTime" 
          type="button"
          onClick={() => 
            dispatch(handleModal({
              modalId: clientId, 
              componentName: 'manageServingTime', 
              modalData: {
                mode: "add",
              }
            }))
          }  
        >
          Add New Serving Time
        </button>
      </div>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" className="!font-bold">
                  Name
                </TableCell>
                <TableCell align="center" className="!font-bold">
                  Start Time
                </TableCell>
                <TableCell align="center" className="!font-bold">
                  End Time
                </TableCell>
                <TableCell align="center" className="!font-bold" width={300}>
                  Order Cut-off Duration
                </TableCell>
                <TableCell align="center" className="!font-bold" width={300}>
                  Cancel Cut-off Duration
                </TableCell>
                <TableCell align="center" className="!font-bold" width={100} />
              </TableRow>
            </TableHead>
            <TableBody>
              {dataServingTimes.length > 0 &&
                dataServingTimes.map((item, idx) => (
                  <TableRow key={idx}>
                    <TableCell align="left">
                      <div 
                        className="w-fit cursor-pointer text-[#2C6D47] font-semibold"
                        onClick={() => 
                          dispatch(handleModal({
                            modalId: clientId, 
                            componentName: 'manageServingTime', 
                            modalData: { 
                              mode: "edit",
                              data: item
                            }
                          }))
                        }
                      >
                        {item?.name || '-'}
                      </div>
                    </TableCell>
                    <TableCell align="center">{item?.start_time || '-'}</TableCell>
                    <TableCell align="center">{item?.end_time || '-'}</TableCell>
                    <TableCell align="center">{item?.order_cut_off_duration || '-'}</TableCell>
                    <TableCell align="center">{item?.cancel_cut_off_duration || '-'}</TableCell>
                    <TableCell align="center">
                      <DeleteIcon className='cursor-pointer' onClick={() => handleDeleteClick(item)} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>
      <ModalWrapper componentName="manageServingTime" header="Manage Serving Time" maxWidth="sm">
        <OutletServingTimeForm getAllServingTimes={getAllServingTimes} outletId={outletId} />
      </ModalWrapper>
    </>
  );
}

export default ServingTimesTab;