import React from 'react';
import { AttachMoney, AutoGraph, Balance, Business, Settings as SettingsIcon } from '@mui/icons-material';

import Transaction from '../pages/Accounting/Transaction/Transaction';
import NewTransaction from '../pages/Accounting/Transaction/NewTransaction';
import TransactionDetail from '../pages/Accounting/Transaction/TransactionDetail';
import Settings from '../pages/Accounting/Settings/Settings';
import BusinessUnits from '../pages/Accounting/BusinessUnits/BusinessUnits';
import BusinessUnitDetail from '../pages/Accounting/BusinessUnits/BusinessUnitDetail';
import ReportAccounting from '../pages/Accounting/Report/ReportAccounting';
import ReportPnl from '../pages/Accounting/Report/ReportPnl';
import ReportBalanceSheet from '../pages/Accounting/Report/ReportBalanceSheet';
import AccountingPeriods from '../pages/Accounting/Settings/AccountingPeriods';
import ImportTransaction from '@/pages/Accounting/Transaction/ImportTransaction';
import Ledgers from '@/pages/Accounting/Settings/Ledgers';

const jobRoute = [
  {
    name: 'GL Drilldown',
    element: <Transaction />,
    icon: <AttachMoney />,
    privilege: [99, 13, 14],
    subscriptions: [9],
    path: '/transaction',
    isSidebar: true,
  },
  {
    name: 'TransactionNew',
    element: <NewTransaction />,
    icon: <AttachMoney />,
    privilege: [99, 13, 14],
    subscriptions: [9],
    path: '/transaction/create',
    isSidebar: false,
  },
  {
    name: 'NewTransaction',
    element: <TransactionDetail />,
    icon: <AttachMoney />,
    privilege: [99, 13, 14],
    subscriptions: [9],
    path: '/transaction/detail/:transactionId',
    isSidebar: false,
  },
  {
    name: 'ImportTransaction',
    element: <ImportTransaction />,
    icon: <AttachMoney />,
    privilege: [99, 13, 14],
    subscriptions: [9],
    path: '/transaction/import',
    isSidebar: false,
  },
  {
    name: 'Settings',
    element: <Settings />,
    icon: <SettingsIcon />,
    privilege: [99, 13, 14],
    subscriptions: [9],
    path: '/accounting/settings',
    isSidebar: true,
    child: [
      {
        name: 'Account Codes',
        element: <Settings />,
        icon: <SettingsIcon />,
        privilege: [99],
        subscriptions: [9],
        path: '/accounting/settings/account',
        isSidebar: true,
      },
      {
        name: 'Account Periods',
        element: <AccountingPeriods />,
        icon: <SettingsIcon />,
        privilege: [99, 13, 14],
        subscriptions: [9],
        path: '/accounting/settings/account-periods',
        isSidebar: true,
      },
      {
        name: 'Business Units',
        element: <BusinessUnits />,
        icon: <Business />,
        privilege: [99, 13, 14],
        subscriptions: [9],
        path: '/accounting/settings/business-units',
        isSidebar: true,
      },
      {
        name: 'BusinessUnitsDetail',
        element: <BusinessUnitDetail />,
        icon: <Business />,
        privilege: [99, 13, 14],
        subscriptions: [9],
        path: '/accounting/settings/business-unit/:businessUnitId',
        isSidebar: false,
      },
      {
        name: 'BusinessUnitsCreate',
        element: <BusinessUnitDetail />,
        icon: <Business />,
        privilege: [99, 13, 14],
        subscriptions: [9],
        path: '/accounting/settings/business-unit/create',
        isSidebar: false,
      },
      {
        name: 'Ledgers',
        element: <Ledgers />,
        icon: <SettingsIcon />,
        privilege: [99, 13, 14],
        subscriptions: [9],
        path: '/accounting/settings/ledgers',
        isSidebar: true,
      },
    ],
  },
  {
    name: 'Reports',
    element: <ReportAccounting />,
    icon: <AutoGraph />,
    privilege: [99, 13, 14],
    subscriptions: [9],
    path: '/accounting/reports',
    child: [
      {
        name: 'Profit and Loss',
        element: <ReportPnl />,
        icon: <AttachMoney />,
        privilege: [99, 13, 14],
        subscriptions: [9],
        path: '/accounting/reports/profit-and-loss',
        isSidebar: true,
      },
      {
        name: 'Balance Sheet',
        element: <ReportBalanceSheet />,
        icon: <Balance />,
        privilege: [99, 13, 14],
        subscriptions: [9],
        path: '/accounting/reports/balance-sheet',
        isSidebar: true,
      },
    ],
    isSidebar: true,
  },
];

export default jobRoute;
