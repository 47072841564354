import { AccountTree } from '@mui/icons-material';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import OutletList from '@/pages/PointOfSale/OutletList';
import OutletDetail from '@/pages/PointOfSale/OutletDetail';


const SalesRoute = [
  {
    name: 'POS - Outlets',
    element: <OutletList />,
    icon: <PointOfSaleIcon />,
    privilege: [99, 5, 7, 8, 9, 10, 17],
    subscriptions: [5],
    path: '/sales/outlets',
    isSidebar: true,
  },
  {
    name: 'POS - Outlet Detail',
    element: <OutletDetail />,
    privilege: [99, 7, 8, 9, 10, 17],
    subscriptions: [5],
    path: '/sales/outlets/detail/:outletId',
  },
  {
    name: 'Add New Outlet',
    element: <OutletDetail />,
    privilege: [99, 7, 8, 9, 10, 17],
    subscriptions: [5],
    path: '/sales/outlets/add',
    isSidebar: false,
  },
];

export default SalesRoute;