import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ButtonBase, IconButton, InputBase, Popover } from '@mui/material';
import { Close, FilterList, Search } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import DetailContext from '../../../pages/Task/Projects/DetailContext';
import BaseHeaderTask from './BaseHeaderTask';
import TeamHeaderTask from './TeamHeaderTask';
import ProjectHeaderTask from './ProjectHeaderTask';

import styles from './HeaderTask.module.css';

export default function HeaderTask({ tab }) {
  const context = useContext(DetailContext);
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const activeHeader = useSelector((state) => state.sidebar.activeHeader);
  const user = useSelector((state) => state.auth.user);
  const [headerContent, setHeaderContent] = useState(false);
  const [activeHeaders, setActiveHeaders] = useState(null);
  const [defaultSections, setDefaultSections] = useState(null);
  const [filterEl, setFilterEl] = useState(null);
  const [filters, setFilters] = useState({
    myTask: false,
    tTypes: [],
    tPriorities: [],
    tLabels: [],
  });

  const { register, watch, setValue } = useForm();

  const handleChangeHeader = useCallback(
    (param) => {
      let header;
      switch (param) {
        case 'base':
          header = <BaseHeaderTask />;
          break;
        case 'team':
          header = <TeamHeaderTask />;
          break;
        case 'project':
          header = <ProjectHeaderTask tab={tab} />;
          break;
        default:
          header = '';
          break;
      }
      setActiveHeaders(param);
      setHeaderContent(header);
    },
    [tab]
  );

  const applySearch = (e) => {
    if (context) {
      const val = e.target.value;
      if (val) {
        context.dispatchProject('GET', { topic_name: e.target.value });
      } else {
        context.dispatchProject('GET', {});
        setValue('search', '');
      }
    }
  };

  useEffect(() => {
    handleChangeHeader(activeHeader);
  }, [handleChangeHeader, activeHeader]);

  useEffect(() => {
    if (activeHeaders !== activeHeader) {
      handleChangeHeader(activeHeader);
    }
  }, [handleChangeHeader, activeHeaders, activeHeader]);

  useEffect(() => {
    if (!defaultSections && context?.sections) {
      setDefaultSections(context.sections);
    }
  }, [defaultSections, context]);

  const searchVal = watch('search');

  const onFilters = (field, value) => {
    setFilters((currState) => {
      const state = { ...currState };
      const currFilter = state[field];

      if (field === 'myTask') {
        state[field] = !currFilter;
      } else if (field !== 'myTask') {
        if (currFilter.includes(value)) {
          state[field].splice(currFilter.indexOf(value), 1);
        } else {
          state[field].push(value);
        }
      }
      return state;
    });
  };

  const onApplyFilters = () => {
    const { myTask, tLabels, tPriorities, tTypes } = filters;

    const currSection = [...defaultSections];
    const newSection = currSection.map((s) => {
      const { topics } = s;
      const newTopics = topics.filter((t) => {
        let isMine;
        let isLabel;
        let isPriority;
        let isTypes;
        if (myTask) {
          isMine = t.assignee?.id === user.id;
        }
        if (tLabels.length) {
          isLabel = t.labels?.length && t.labels.map((l) => l.id).some((lb) => tLabels.includes(lb));
        }
        if (tPriorities.length) {
          isPriority = t.priority && tPriorities.some((tP) => tP === t.priority.id);
        }
        if (tTypes.length) {
          isTypes = t.topic_type && tTypes.some((tT) => tT === t.topic_type.id);
        }
        return isMine || isLabel || isPriority || isTypes;
      });
      return { ...s, topics: newTopics };
    });
    setFilterEl(null);
    context.setSections(newSection);
  };

  const openFilter = Boolean(filterEl);
  const id = openFilter ? 'simple-popover' : undefined;

  const btnFilterStyle = [styles.filterBtn, 'px-2 py-1 rounded-xl'].join(' ');

  return (
    <div className={[styles.baseTopBar, !isSidebarOpen && styles.baseOpenTopBar].join(' ')}>
      <div className={styles.baseContainer}>
        {headerContent}
        <div className="flex items-center space-x-1 pl-4">
          {!!(context?.activeTab && [1, 2].includes(context.activeTab)) && (
            <div className="w-auto h-full">
              <ButtonBase
                className="h-auto min-w-[40px] px-2 py-1"
                onClick={(e) => {
                  setFilterEl(e.currentTarget);
                }}
              >
                <FilterList fontSize="medium" color="action" />
              </ButtonBase>
              <Popover
                id={id}
                open={openFilter}
                anchorEl={filterEl}
                onClose={() => {
                  setFilterEl(null);
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <div className="py-4 px-6">
                  <p className="mb-2">Quick filters</p>
                  <div className="flex flex-wrap gap-2">
                    <ButtonBase className={[btnFilterStyle, filters.myTask && styles.active].join(' ')} onClick={() => onFilters('myTask')}>
                      My Task
                    </ButtonBase>
                  </div>

                  {context?.types?.length > 0 && (
                    <>
                      <p className="mb-2 mt-4">Type:</p>
                      <div className="flex flex-wrap gap-2">
                        {context.types.map((type) => {
                          const active = filters.tTypes.includes(type.id) && styles.active;
                          return (
                            <ButtonBase className={[btnFilterStyle, active].join(' ')} key={type.id} onClick={() => onFilters('tTypes', type.id)}>
                              {type.name}
                            </ButtonBase>
                          );
                        })}
                      </div>
                    </>
                  )}

                  {context?.priorities?.length > 0 && (
                    <>
                      <p className="mb-2 mt-4">Priorities:</p>
                      <div className="flex flex-wrap gap-2">
                        {context.priorities.map((p) => {
                          const active = filters.tPriorities.includes(p.id) && styles.active;
                          return (
                            <ButtonBase className={[btnFilterStyle, active].join(' ')} key={p.id} onClick={() => onFilters('tPriorities', p.id)}>
                              {p.name}
                            </ButtonBase>
                          );
                        })}
                      </div>
                    </>
                  )}

                  {context?.labels?.length > 0 && (
                    <>
                      <p className="mb-2 mt-4">Labels</p>
                      <div className="flex flex-wrap gap-2">
                        {context.labels.map((lbl) => {
                          const active = filters.tLabels.includes(lbl.id) && styles.active;
                          return (
                            <ButtonBase className={[btnFilterStyle, active].join(' ')} key={lbl.id} onClick={() => onFilters('tLabels', lbl.id)}>
                              {lbl.name}
                            </ButtonBase>
                          );
                        })}
                      </div>
                    </>
                  )}

                  {context?.setSections && (
                    <div className="flex justify-end gap-4 mt-4 pt-4 border-t border-t-slate-700">
                      <ButtonBase
                        className={btnFilterStyle}
                        onClick={() => {
                          setFilterEl(null);
                          setFilters({
                            myTask: false,
                            tTypes: [],
                            tPriorities: [],
                            tLabels: [],
                          });
                          context.setSections(defaultSections);
                        }}
                      >
                        Clear All
                      </ButtonBase>
                      <ButtonBase className={btnFilterStyle} onClick={onApplyFilters}>
                        Apply
                      </ButtonBase>
                    </div>
                  )}
                </div>
              </Popover>
            </div>
          )}

          <div className="flex items-center border border-zinc-400 rounded-full">
            <IconButton type="submit" sx={{ p: '4px' }} aria-label="search">
              <Search />
            </IconButton>
            <InputBase
              {...register('search')}
              placeholder="Search"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  applySearch(e);
                }
              }}
              className="w-48"
            />
            {searchVal && (
              <IconButton sx={{ p: '4px' }} aria-label="search" onClick={applySearch}>
                <Close />
              </IconButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
