/* eslint-disable */

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Box } from '@mui/material';
import { DevTool } from '@hookform/devtools';
import InputContainer from '../../Input/InputContainer';
import { handleModal } from '../../../store/slices/modalSlice';
import MuiCusotmTabs from '../../ui/Tab/MuiCustomTabs';
import MuiCustomTabPanel from '../../ui/Tab/MuiCustomTabPanel';

function SectionCategoryForm({ defaultValues, onFormSubmit }) {
  const { control, handleSubmit } = useForm({ defaultValues });
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.client.activeClient);
  const bzLanguage = useSelector((state) => state.lang.languagesList);

  const [valueTab, setValueTab] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const devLang = [];

  defaultValues?.category_languages?.map((l) => {
    devLang.push(l);
    return devLang;
  });

  const onSubmit = (data) => {
    console.log(data);
    let name;
    const category_languages = bzLanguage.reduce((acc, curr) => {
      const inputLang = devLang.find((dl) => dl.language === curr.id);
      const output = {};
      output.language = curr.language.id;
      output.languageName = curr.name;
      output.name = data[`name-${curr.id}`] || inputLang?.name || null;
      name = output.name;
      if (defaultValues) {
        output.id = inputLang?.id;
      }
      return [...acc, output];
    }, []);
    onFormSubmit({ name, client: clientId, category_languages });
  };

  const handleCancel = () => {
    dispatch(handleModal({ id: '' }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full space-y-4">
        <InputContainer name="name" control={control} label="Name" />
        <MuiCusotmTabs
          optionsTab={bzLanguage.map((lang) => {
            return {
              name: lang?.name,
              id: lang?.id,
            };
          })}
          value={valueTab}
          onChange={handleChangeTab}
        />
        {bzLanguage?.map((lang, index) => (
          <MuiCustomTabPanel key={lang.id} value={valueTab} index={index}>
            <InputContainer name={`name-${lang.id}`} control={control} label="Name" />
          </MuiCustomTabPanel>
        ))}
        <div className="flex justify-end ml-auto gap-x-2">
          <Button onClick={handleCancel} className="bz-btn secondary" variant="outlined">
            Cancel
          </Button>
          <Button className="bz-btn" type="submit" variant="outlined">
            {defaultValues ? 'Update Category' : 'Create Category'}
          </Button>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </form>
  );
}
export default SectionCategoryForm;
