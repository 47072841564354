/* eslint-disable */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material/';
import { Link } from 'react-router-dom';
import CRMApi from '../../../api/CRM/CRMCustomers';
import CardChart from '../../../components/Chart/CardChart';
import BaseMainTitle from '../../../components/BaseMainTitle';
import BZHelmet from '../../../utils/BZHelmet';
import { getStaticPeriod, standardizeInputDate } from '../../../lib/StaticPeriod';

export default function Dashboard() {
  const now = new Date()
  const clientId = useSelector((state) => state.client.activeClient);

  const [staticPeriod, setStaticPeriod] = useState("1")
  const [memberCountries, setMemberCountries] = useState([])
  const [membersJoined, setMembersJoined] = useState([])
  const [membersUpgrade, setMembersUpgrade] = useState([])
  const [membersDowngrade, setMembersDowngrade] = useState([])
  const [membersDiscontinue, setMembersDiscontinue] = useState([])
  const [period, setPeriod] = useState({ start_date: `${now.getFullYear()}-01-01`, end_date: `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`, isNow: true })

  useEffect(() => {
    const timezoneOffset = new Date().getTimezoneOffset()
    const { getDynamicReport } = CRMApi();
    const params = { client: clientId }
    let data = {
        start_date: standardizeInputDate(period.start_date, timezoneOffset),
        end_date: standardizeInputDate(period.end_date, timezoneOffset, period.isNow)
    }

    const getCountryReport = async () => {
      const data = { report: 1 } // hardcoded
      const response = await getDynamicReport(params, data)
      setMemberCountries(response.data.results)
    }

    const getMembersJoined = async () => {
        data.report = 2 // hardcoded
        const response = await getDynamicReport(params, data)
        setMembersJoined(response?.data?.results)
    }

    const getMembersUpgrade = async () => {
        data.report = 3 // hardcoded
        const response = await getDynamicReport(params, data)
        setMembersUpgrade(response?.data?.results)
    }

    const getMembersDowngrade = async () => {
        data.report = 4 // hardcoded
        const response = await getDynamicReport(params, data)
        setMembersDowngrade(response?.data?.results)
    }

    const getMembersDiscontinue = async () => {
        data.report = 5 // hardcoded
        const response = await getDynamicReport(params, data)
        setMembersDiscontinue(response?.data?.results)
    }

    getCountryReport()
    getMembersJoined()
    getMembersUpgrade()
    getMembersDowngrade()
    getMembersDiscontinue()
  }, [clientId, period])

  useEffect(() => {
    setPeriod(getStaticPeriod(staticPeriod))
  }, [staticPeriod])

  const totalMembersJoined = useMemo(() => membersJoined.reduce((prev, value) => {
    return prev + value.count
  }, 0), [membersJoined])

  const totalMembersUpgrade = useMemo(() => membersUpgrade.reduce((prev, value) => {
      return prev + value.count
  }, 0), [membersUpgrade])

  const totalMembersDowngrade = useMemo(() => membersDowngrade.reduce((prev, value) => {
      return prev + value.count
  }, 0), [membersDowngrade])

  const totalMembersDiscontinue = useMemo(() => membersDiscontinue.reduce((prev, value) => {
      return prev + value.count
  }, 0), [membersDiscontinue])

  return (
    <>    
      <BZHelmet title="Dashboard" description="" content="" />  
      <BaseMainTitle title="Dashboard" />
      <div className="p-5">
        <div className="w-full px-3 mb-4">
          <div className='flex'>
            <div className='flex flex-col mr-4'>
                <label className='font-semibold'>Period</label>
                <select value={staticPeriod} className='h-[30px] border border-black rounded-sm' onChange={(e) => setStaticPeriod(e.target.value)}>
                    <option value="1">This year</option>
                    <option value="2">Past year</option>
                    <option value="3">Past 6 months</option>
                    <option value="4">Past 3 months</option>
                    <option value="5">Past month</option>
                    <option value="6">Past 2 years</option>
                    <option value="7">All time</option>
                    <option value="custom">Custom</option>
                </select>
            </div>

            <div className='flex flex-col mr-4'>
                <label className='font-semibold'>From</label>
                <input 
                  disabled={staticPeriod !== "custom"} 
                  type='date' value={period.start_date || ""} 
                  onChange={(e) => setPeriod({ ...period, start_date: e.target.value })} 
                  className={['h-[30px] px-1', staticPeriod === "custom" ? 'border border-black rounded-sm' : ''].join(' ')} 
                />
            </div>
            <div className='flex flex-col mr-4'>
                <label className='font-semibold'>To</label>
                <input 
                  disabled={staticPeriod !== "custom"} 
                  type='date' value={period.end_date || ""} 
                  onChange={(e) => setPeriod({ ...period, end_date: e.target.value })} 
                  className={['h-[30px] px-1', staticPeriod === "custom" ? 'border border-black rounded-sm' : ''].join(' ')} 
                />
            </div>
        </div>
        </div>
        <div className='space-y-4'>
          <div>
            <div className="grid grid-cols-3 gap-x-4 mt-2">
              <TableContainer className='shadow-lg' component={Paper} sx={{ height: "auto"}}>
                <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="font-semibold text-base" align="center" colSpan={2}>Members Joined ({totalMembersJoined})</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="font-semibold text-base">Company</TableCell>
                    <TableCell className="font-semibold text-base" align="center">Date Joined</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    membersJoined.map((variable) => (variable.members.map(member => {
                      return (
                        <TableRow className='hover:bg-slate-200' component={Link} to={"/customers/detail/" + member.member__id} key={member.member__id}>
                          <TableCell>
                            {member.member__name}
                          </TableCell>
                          <TableCell align="center">{new Date(member.timestamp).toLocaleDateString("en-US", { dateStyle: "medium" })}</TableCell>
                        </TableRow>
                      )
                    })))
                  }
                </TableBody>
                </Table>
              </TableContainer>
              <div className='border rounded-md shadow-lg'>
                <CardChart
                  // className="h-[52rem]"
                  title={"Membership Type"}
                  labels={["Upgrade", "Downgrade", "Discontinue"]}
                  data={[totalMembersUpgrade, totalMembersDowngrade, totalMembersDiscontinue]}
                  typeChart={["pie"]}
                  chartStyle={{ pie: { maxHeight: "350px" } }}
                />
              </div>
            </div>
            <div className="h-full grid grid-cols-3 gap-x-4 mt-2">
              <TableContainer className='shadow-lg' component={Paper} sx={{ maxHeight: 440 }}>
                <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell className="font-semibold text-base" align="center" colSpan={2}>Upgraded Members ({totalMembersUpgrade})</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="font-semibold text-base">Company</TableCell>
                    <TableCell className="font-semibold text-base" align="center">Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="overflow-scroll">
                  {
                    membersUpgrade.map((variable) => (variable.members.map(member => {
                      return (
                        <TableRow className='hover:bg-slate-200' component={Link} to={"/customers/detail/" + member.member__id} key={member.member__id}>
                          <TableCell>
                            {member.member__name}
                          </TableCell>
                          <TableCell align="center">{variable.variable}</TableCell>
                        </TableRow>
                      )
                    })))
                  }
                </TableBody>
                </Table>
              </TableContainer>
              <TableContainer className='shadow-lg' component={Paper} sx={{ height: "auto" }}>
                <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell className="font-semibold text-base" align="center" colSpan={2}>Downgraded Members ({totalMembersDowngrade})</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="font-semibold text-base">Company</TableCell>
                    <TableCell className="font-semibold text-base" align="center">Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    membersDowngrade.map((variable) => (variable.members.map(member => {
                      return (
                        <TableRow className='hover:bg-slate-200' component={Link} to={"/customers/detail/" + member.member__id} key={member.member__id}>
                          <TableCell>{member.member__name}</TableCell>
                          <TableCell align="center">{variable.variable}</TableCell>
                        </TableRow>
                      )
                    })))
                  }
                </TableBody>
                </Table>
              </TableContainer>
              <TableContainer className='shadow-lg' component={Paper} sx={{ height: "auto" }}>
                <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell className="font-semibold text-base" align="center" colSpan={2}>Discontinued Members ({totalMembersDiscontinue})</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="font-semibold text-base">Company</TableCell>
                    <TableCell className="font-semibold text-base" align="center">Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    membersDiscontinue.map((variable) => (variable.members.map(member => {
                      return (
                        <TableRow className='hover:bg-slate-200' component={Link} to={"/customers/detail/" + member.member__id} key={member.member__id}>
                          <TableCell>{member.member__name}</TableCell>
                          <TableCell align="center">{variable.variable}</TableCell>
                        </TableRow>
                      )
                    })))
                  }
                </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className='border rounded-md shadow-lg'>
            <CardChart
              title="Countries"
              labels={memberCountries.map(item => item.variable)}
              data={memberCountries.map(item => item.count)}
              typeChart={["bar"]}
            />
          </div>
        </div>
      </div>
    </>
  );
}
