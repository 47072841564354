import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSearchParams, Link, useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Switch,
  IconButton,
  Collapse,
  TextareaAutosize,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  OutlinedInput,
  TextField,
  InputAdornment,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Edit as EditIcon,
  Cancel as CancelIcon,
  Download as DownloadIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  ArrowBack,
} from '@mui/icons-material';
import BZHelmet from '@/utils/BZHelmet';
import BaseMainTitle from '@/components/BaseMainTitle';
import Input from '../CRM/redesigning/InputContainerRedesign';
import ModalWrapper from '@/components/ui/ModalWrapper';
import { handleModal } from '@/store/slices/modalSlice';
import ComponentPairOnProductDetail from '@/components/Form/ProductManagement/ComponentPairOnProductDetail';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Swal from 'sweetalert2';
import _ from 'lodash';
import SelectAutocomplete from '@/components/Input/ProductManagement/SelectAutoComplete';
import AddOnsPair from '@/components/Form/ProductManagement/AddOnsPair';
import HrApi from '@/api/HR/api';
import POSApi from '@/api/pos/posApi';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import OutletPairCollections from '@/components/Form/Sales/OutletPairCollections';
import OutletHomeScreenIndexing from '@/components/Form/Sales/OutletHomeScreenIndexing';
import CollectionsTab from './Components/CollectionsTab';
import OperatorsTab from './Components/OperatorsTab';
import CustomersTab from './Components/CustomersTab';
import HomePosTab from './Components/HomePosTab';
import DiscountsTab from './Components/DiscountsTab';
import PaymentsTab from './Components/PaymentsTab';
import ServingTimesTab from './Components/ServingTimesTab';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const containerStyle = {
  width: '100%',
  height: '220px',
};

const lib = ['drawing', 'places'];

function OutletDetail() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCKqMs4hmNgoQtmIUsd7diBKHj6UV610Jg',
    libraries: lib,
  });

  const { register, handleSubmit, control, errors, setValue } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const navigate = useNavigate();
  const { outletId } = useParams();
  const dispatch = useDispatch();

  // Detail Behaviors
  const [isFetching, setIstFetching] = useState(outletId === undefined ? false : true);
  const [map, setMap] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);
  const [isLoadingDepartmentManagersOptions, setIsLoadingDepartmentManagersOptions] = useState(false);

  // ALL PRODUCT DATA
  const [outletData, setOutletData] = useState(null);
  const [outletCollections, setOutletCollections] = useState([]);  
  const [outletEmployees, setOutletEmployees] = useState([]);
  const [collectionsProductsShowInHome, setCollectionsProductsShowInHome] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
  const [outletLocation, setOutletLocation] = useState(null);
  const [outletDiscounts, setOutletDiscounts] = useState([]);
  const [allPaymentTypes, setAllPaymentTypes] = useState([]);
  const [allServingTimes, setAllServingTimes] = useState([]);
  const [utcOffset, setUtcOffset] = useState(null)

  // ALL OPTIONS
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [departmentManagerOptions, setDepartmentManagerOptions] = useState([]);

  // All Pagination
  const [page, setPage] = useState({ customer: 1 });
  const [rowsPerPage, setRowsPerPage] = useState({ customer: 15 });
  const [count, setCount] = useState({ customer: 0 });

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  // ALL BEHAVIORS
  const [mode, setMode] = useState('');
  useEffect(() => {
    if (outletId === undefined) {
      setMode('add');
    } else {
      setMode('edit');
    }
  }, [outletId]);

  useEffect(() => {
    if (selectedDepartmentId === null) {
      setValue('manager', null);
    }
  }, [selectedDepartmentId, setValue]);

  // REUSABLE FETCH FUNCTION
  const fetchData = async (apiFunction, params) => {
    try {
      const res = params ? await apiFunction(params) : await apiFunction();
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  /**
   * ALL GET REQUESTS
   */
  const OutletDetail = useCallback(async () => {
    const { getOutletDetail } = POSApi();

    try {
      const getData = await getOutletDetail(outletId);
      const data = await getData.data;

      setOutletData(data);

      setOutletCollections(data.collections || []);
      setOutletEmployees(data.employees || []);
      setSelectedDepartmentId(data?.department?.id);
      setOutletLocation(data?.department?.locations[0] || null);
      setUtcOffset(data?.department?.locations[0]?.utc_offset);

      // Update form values with setValue
      setValue('name', data?.name || '');
      setValue('code', data?.code || '');
      setValue('department', data?.department?.id || null);
      setValue('manager', data?.manager?.id || null);

      setIstFetching(false);
    } catch (error) {
      console.error(error);
    }
  }, [outletId]);

  const departments = useCallback((params = { max_size: true, client: clientId }) => fetchData(HrApi().getDepartment, params), [clientId]);

  const getAllOptions = useCallback(async () => {
    const [departmentList] = await Promise.all([departments()]);
    if (departmentList) {
      setDepartmentOptions(departmentList.results);

      setIsLoadingOptions(false);
    }
  }, [departments]);

  const outletShowCollections = useCallback(
    (params = { outlet: outletId, ordering: 'index' }) => fetchData(POSApi().getAllHomeCollections, params),
    [outletId]
  );
  const outletShowProducts = useCallback((params = { outlet: outletId, ordering: 'index' }) => fetchData(POSApi().getAllOutletProducts, params), [outletId]);

  const getShowCollectionsProducts = useCallback(async () => {
    const [collections, products] = await Promise.all([outletShowCollections(), outletShowProducts()]);

    if (collections && products) {
      const combinedResults = [...collections.results, ...products.results];
      
      combinedResults.sort((a, b) => a.index - b.index);

      setCollectionsProductsShowInHome(combinedResults);
    }
  }, [outletShowCollections, outletShowProducts]);

  useEffect(() => {
    if (outletId !== undefined) {
      OutletDetail();
    }
  }, [outletId, OutletDetail]);

  useEffect(() => {
    getShowCollectionsProducts();
  }, [outletId]);

  useEffect(() => {
    getAllOptions();
  }, [outletId, clientId, getAllOptions]);

  const departmentManagers = useCallback((params) => fetchData(HrApi().getAvailableManagersByDepartment, params), [selectedDepartmentId]);

  const getAllDepartmentManagers = useCallback(
    async (selectedDepartmentId) => {
      const [managerList] = await Promise.all([departmentManagers(selectedDepartmentId)]);
      if (managerList) {
        setDepartmentManagerOptions(managerList);
      } else {
        setDepartmentManagerOptions([]);
      }
      setIsLoadingDepartmentManagersOptions(false);
    },
    [departmentManagers, selectedDepartmentId]
  );

  useEffect(() => {
    if (selectedDepartmentId !== null && selectedDepartmentId !== '') {
      getAllDepartmentManagers(selectedDepartmentId);
    } else {
      setDepartmentManagerOptions([]);
    }
  }, [selectedDepartmentId]);

  const [isFetchingCustomers, setIsFetchingCustomers] = useState(true);
  const [allOutletCustomers, setAllOutletCustomers] = useState([]);
  const customers = useCallback((params) => fetchData(POSApi().getAllOUtletCustomers, params), [outletId]);
  const getAllCustomers = useCallback(
    async (paramCustomers) => {
      if (!outletId) return;

      const [customerList] = await Promise.all([customers(paramCustomers)]);
      if (customerList) {
        setAllOutletCustomers(customerList.results || []);
        setCount(count => ({...count, customer: customerList.count}));
      }
      setIsFetchingCustomers(false);
    },
    [customers, outletId]
  );
  
  useEffect(() => {
    if (outletId !== undefined) {
      setAllOutletCustomers([]);
      const newParams = {
        outlet: outletId,
        ordering: 'member__name',
        page: page.customer,
        page_size: rowsPerPage.customer,
      };
      getAllCustomers(newParams);
    } else {
      setIsFetchingCustomers(false)
    }
  }, [getAllCustomers, outletId]);

  const handleChangePage = (event, newPage, tab) => {

    const watchNextPage = event.target.outerHTML.includes('KeyboardArrowRightIcon');
    let switchMathPage = watchNextPage ? newPage + 1 : newPage === 0 ? 1 : newPage + 1;
  
    setPage(page => ({...page, [tab]: switchMathPage}));
    setIsFetchingCustomers(true);
  };

  const handleChangeRowsPerPage = (event, tab) => {
    
    setRowsPerPage(rows => ({...rows, [tab]: parseInt(event.target.value, 10)}));
    setIsFetchingCustomers(true);
  };

  const [classesList, SetClassesList] = useState([]);
  const ListOfClasses = useCallback((params) => fetchData(POSApi().listOfClassForFilter, params), [outletId]);

  const getAllClasses = useCallback(async (params) => {
    const [classes] = await Promise.all([ListOfClasses(params)]);

    if (classes) {
      SetClassesList(classes)
    }
  }, [ListOfClasses]);

  useEffect(() => {
    if (outletId !== undefined){
      getAllClasses({ outlet: outletId })
    }
  }, [outletId])

  const allDiscounts = useCallback((params) => fetchData(POSApi().getAllDiscounts, params), [outletId]);

  const getOutletDiscounts = useCallback(
    async (outletId) => {
      const [discounts] = await Promise.all([allDiscounts({ outlet: outletId })]);
      if (discounts) {
        setOutletDiscounts(discounts.results);
      }
    },
    [allDiscounts]
  );

  useEffect(() => {
    if (outletId !== undefined) {
      getOutletDiscounts(outletId);
    }
  }, [getOutletDiscounts, outletId]);

  const fetchAllPaymentType = useCallback((params) => fetchData(POSApi().getAllPaymentTypes, params), [clientId]);

  const getAllPaymentTypes = useCallback(
    async (clientId) => {
      const [paymentTypes] = await Promise.all([fetchAllPaymentType({ client: clientId })]);
      if (paymentTypes) {
        const { results } = paymentTypes;
        const excludeNull = results.filter((dt) => dt.client !== null);
        setAllPaymentTypes(excludeNull);
      }
    },
    [fetchAllPaymentType]
  );

  useEffect(() => {
    getAllPaymentTypes(clientId);
  }, []);

  const fetchAllServingTimes = useCallback((params) => fetchData(POSApi().getAllServingTimes, params), [outletId]);

  const getAllServingTimes = useCallback(
    async (outletId) => {
      const [serving] = await Promise.all([fetchAllServingTimes({ outlet: outletId, max_size: true })]);
      if (serving) {
        const { results } = serving;
        setAllServingTimes(results);
      }
    },
    [fetchAllServingTimes]
  );

  useEffect(() => {
    getAllServingTimes(outletId);
  }, []);

  /**
   *
   * ALL FUNCTIONS
   */
  const handleChangeTab = (_, newValue) => {
    setTabValue(newValue);
  };

  const handleDepartmentSelect = (val) => {
    const selectedOption = val !== null ? departmentOptions.find((option) => option.id === val) : null;
    setOutletLocation(selectedOption && selectedOption.locations.length > 0 ? selectedOption.locations[0] : null);
    setSelectedDepartmentId(val);
    setIsLoadingDepartmentManagersOptions(true);
  };

  const onChangePageAndRowsPerPage = (value, filter) => {
    console.log({value, filter});
  };


  const SubmitOutletData = async (data) => {
    const { addNewOutlet, updateOutletData } = POSApi();

    // if (outletLocation) {
    //   data.location = outletLocation.id;
    // }

    if (!data.department || !data.manager) {
      Swal.fire({
        title: 'Warning!',
        text: 'Please Insert Department And Manager!',
        icon: 'warning',
      });
      return;
    }

    try {
      const action = mode === 'add' ? addNewOutlet(data) : updateOutletData(outletId, data);
      const result = await action;

      Swal.fire({
        title: 'Success!',
        text: mode === 'add' ? 'Successfully added new Outlet!' : 'Successfully updated Outlet Data!',
        icon: 'success',
      }).then(() => {
        if (mode === 'add') {
          navigate(`/sales/outlets/detail/${result?.data?.id}`);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const InputForm = ({ defaultValue, label, name, type = 'text', disabled, onChangeValue, setState, control, className }) => (
    <div className={`w-full h-fit ${className}`}>
      <p className={`font-semibold `}>{label}</p>
      <Input control={control} defaultValue={defaultValue} label={label} name={name} disabled={disabled} type={type} onChangeValue={onChangeValue} />
    </div>
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const SelectForm = ({ defaultValue, label, name, multiple = false, control, options, disabled = false, onChangeValue }) => {
    return (
      <div className="w-full h-fit">
        <p className={`font-semibold mb-[4px]`}>{label}</p>
        <Select
          className="w-full h-full"
          labelId={`label-${name}`}
          id={`select-${name}`}
          onChange={onChangeValue}
          value={defaultValue !== undefined ? defaultValue : []}
          displayEmpty
          multiple={multiple}
          disabled={disabled}
          MenuProps={MenuProps}
          input={<OutlinedInput label={label} />}
          sx={{
            minWidth: '100%',
            width: '100%',
            px: 0.2,
            py: 0.6,
            background: 'white',
          }}
        >
          {options.map((opt) => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.value}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  };
  
  return (
    <div className="w-full h-full">
      <BZHelmet title={mode === 'add' ? 'Add Outlet' : 'Outlet Detail'} description="" content="" />
      <BaseMainTitle title={mode === 'add' ? 'Add Outlet' : 'Outlet Detail'} />
      <div className="p-5 space-y-6">
        <button
          className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
          type="text"
          name="Back to list"
          onClick={() => navigate('/sales/outlets')}
        >
          <ArrowBack />
          Back To List
        </button>
        <form onSubmit={handleSubmit(SubmitOutletData)}>
          <div className="flex gap-x-4">
            <div className="w-[35%]">
              <InputForm className="col-span-2" defaultValue={outletData?.name && outletData?.name} label="Outlet Name" name="name" control={control} />
              <div className="w-full h-fit mb-2">
                <p className="font-semibold mb-[5px]">Department</p>
                <Controller
                  name="department"
                  control={control}
                  defaultValue={(outletData?.department?.id && outletData?.department?.id) || null}
                  render={({ field }) => {
                    return (
                      <SelectAutocomplete
                        variant="outlined"
                        options={departmentOptions?.map((dt) => ({ name: dt.name, value: dt.id }))}
                        defaultValue={field.value || null}
                        onChange={(e, val) => {
                          field.onChange(val);
                          handleDepartmentSelect(val);
                        }}
                        isLoading={isLoadingOptions}
                        // disabled={mode === "edit"}
                      />
                    );
                  }}
                />
              </div>
              <div className="w-full h-fit mb-2">
                <p className="font-semibold mb-[5px]">Outlet Manager</p>
                <Controller
                  name="manager"
                  control={control}
                  defaultValue={(outletData?.manager?.id && outletData?.manager?.id) || null}
                  render={({ field }) => {
                    return (
                      <SelectAutocomplete
                        variant="outlined"
                        options={departmentManagerOptions?.map((dt) => ({ name: `${dt.first_name} ${dt.last_name}`, value: dt.id }))}
                        defaultValue={field.value || null}
                        onChange={(e, val) => field.onChange(val)}
                        isLoading={isLoadingDepartmentManagersOptions}
                        disabled={selectedDepartmentId === null || departmentManagerOptions.length === 0}
                      />
                    );
                  }}
                />
              </div>
              <InputForm className="col-span-2" defaultValue={outletData?.code && outletData?.code} label="Outlet Code" name="code" control={control} />
            </div>
            <div className="w-[65%] ">
              <div className="w-full">
                <p className="font-semibold mb-[5px]">Outlet Location :</p>
                {isLoaded && outletLocation !== null ? (
                  <div className="w-full border shadow-sm">
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={{ lat: parseFloat(outletLocation?.latitude), lng: parseFloat(outletLocation?.longitude) }}
                      zoom={14}
                      // onLoad={onLoad}
                      onUnmount={onUnmount}
                    >
                      <Marker position={{ lat: parseFloat(outletLocation?.latitude), lng: parseFloat(outletLocation?.longitude) }} />
                    </GoogleMap>
                    <div className="min-h-[85px] flex flex-col gap-y-1 border-x border-b p-2 ">
                      <p className="text-sm text-slate-500">
                        <span className="font-semibold">Location Name:</span> {outletLocation?.name || ''}
                      </p>
                      <p className="text-sm text-slate-500">
                        <span className="font-semibold">Address:</span> {outletLocation?.address || ''}
                      </p>
                    </div>
                  </div>
                ) : (
                  <>-</>
                )}
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end mt-4">
            <button className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white" type="submit" name="Add Outlet">
              {mode === 'edit' ? 'Update Outlet' : 'Add Outlet'}
            </button>
          </div>
        </form>
          {mode === 'edit' && outletId !== undefined && (
            <div className="border-t mt-6 pt-2">
              <Box sx={{ width: '100%', position: 'relative' }}>
                <Box sx={{ marginBottom: 4 }}>
                  <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    aria-label="basic tabs example"
                    TabIndicatorProps={{
                      sx: {
                        backgroundColor: '#2C6D47',
                      },
                    }}
                    sx={{
                      '.MuiButtonBase-root': {
                        fontWeight: '600',
                      },
                      '.Mui-selected': {
                        color: '#2C6D47!important',
                      },
                    }}
                  >
                    <Tab value={0} label="Customers" {...a11yProps(0)} />
                    <Tab value={1} label="Operators" {...a11yProps(1)} />
                    <Tab value={2} label="Discounts" {...a11yProps(2)} />
                    <Tab value={3} label="Payments" {...a11yProps(3)} />
                    <Tab value={4} label="Home POS" {...a11yProps(4)} />
                    <Tab value={5} label="Collections" {...a11yProps(5)} />
                    <Tab value={6} label="Serving Time" {...a11yProps(6)} />
                  </Tabs>
                </Box>
                {tabValue === 0 && (
                  <CustomersTab 
                    dataCustomers={allOutletCustomers} 
                    POSApi={POSApi} 
                    outletId={outletId} 
                    isFetching={isFetchingCustomers} 
                    setIsFetching={setIsFetchingCustomers} 
                    getAllCustomers={getAllCustomers} 
                    classesList={classesList} 
                    count={count.customer}
                    rowsPerPage={rowsPerPage.customer}
                    page={page.customer}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleChangePage={handleChangePage}
                  />
                )}
                {tabValue === 1 && <OperatorsTab outletEmployees={outletEmployees} SelectForm={SelectForm} POSApi={POSApi} OutletDetail={OutletDetail} outletId={outletId} />}
                {tabValue === 2 && <DiscountsTab dataDiscounts={outletDiscounts} outletId={outletId} getOutletDiscounts={getOutletDiscounts} />}
                {tabValue === 3 && <PaymentsTab dataPaymentTypes={allPaymentTypes} outletId={outletId} getAllPaymentTypes={getAllPaymentTypes} />}
                {tabValue === 4 && <HomePosTab collData={collectionsProductsShowInHome} getHomePosData={getShowCollectionsProducts} outletId={outletId} collectionIds={outletCollections.map((v) => v.collection.id)} />}
                {tabValue === 5 && <CollectionsTab outletCollections={outletCollections} SelectForm={SelectForm} POSApi={POSApi} OutletDetail={OutletDetail} outletId={outletId} />}
                {tabValue === 6 && <ServingTimesTab dataServingTimes={allServingTimes} outletId={outletId} getAllServingTimes={getAllServingTimes} utcOffset={utcOffset} />}
              </Box>
            </div>
          )}
      </div>
    </div>
  );
}

export default OutletDetail;
          {/* <div className="w-[10rem]">
            <div>
              <p className="font-bold">Filter By Class</p>
            </div>
            <MuiSelectSingleItem
              name="class"
              label="class"
              OptionLabel="class"
              control={control}
              options={classesList.map((dt) => ({name: dt, value: dt}))}
              defaultValue={selectedClass}
              onChangeValue={(e) => {
                setSelectedClass(e);
                // store.dispatch({ type: 'crmFilter/setSorting', payload: e });
              }}
              isInputLabel
            />
          </div> */}