/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

const crmFilter = createSlice({
  name: "crmFilter",
  initialState: {
    search: '',
    sorting: '',
    countries: [],
    dynamicFilter: [],
    membersTablePage: 0,
    membersTableRowsPerPage: 15,
    locationPair: {
      selectedLocation: [],
      mainLocation: null
    }
  },
  reducers: {
    setSearch: (state, action) => {
      const currState = state;
      currState.search = action.payload;
    },
    setSorting: (state, action) => {
      const currState = state;
      currState.sorting = action.payload;
    },
    setCountries: (state, action) => {
      const currState = state;
      currState.countries = action.payload;
    },
    setDynamicFilter: (state, action) => {
      const currState = state;
      currState.dynamicFilter = action.payload
    },
    setMembersTablePage: (state, action) => {
      const currState = state;
      currState.membersTablePage = action.payload
    },
    setMembersTableRowsPerPage : (state, action) => {
      const currState = state;
      currState.membersTableRowsPerPage = action.payload
    },
    setLocationPair: (state, action) => {
      state.locationPair = {
        selectedLocation: action.payload.selectedLocation,
        mainLocation: action.payload.mainLocation
      };
    },
    removeDynamicFilterByIndex: (state, action) => {
      const currState = state;
      const index = action.payload;
      currState.dynamicFilter = state.dynamicFilter.filter((_, i) => i !== index);
    },
    resetState: (state) => {
      state.search = '';
      state.sorting = '';
      state.countries = [];
      state.dynamicFilter = [];
      state.membersTablePage = 0;
      state.membersTableRowsPerPage = 15;
    },
    resetLocationPair: (state) => {
      state.locationPair = {
        selectedLocation: [],
        mainLocation: null
      };
    }
  }
})

export const crmFilterActions = crmFilter.actions;
export default crmFilter;