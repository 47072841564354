import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSearchParams, Link, useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Switch, IconButton, Collapse, TextareaAutosize, Button, CircularProgress } from '@mui/material';
import { TextField, InputAdornment } from '@mui/material';
import { handleModal } from '@/store/slices/modalSlice';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import _ from 'lodash';

import CancelIcon from '@mui/icons-material/Cancel';
import SelectAutocomplete from '@/components/Input/ProductManagement/SelectAutoComplete';
import CustomSelectRedesign from '../../CRM/redesigning/CustomSelectRedesign';
import {DatePickerFieldWithoutController} from '@/components/Input/DatePickerField';
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';
import Swal from 'sweetalert2';
import { useProductContext } from '../ProductContext';
import ModalWrapper from '@/components/ui/ModalWrapper';
import Select from '@/components/Input/MuiSelectSingleItem';
import Input from '../../CRM/redesigning/InputContainerRedesign';
import ComponentPairOnProductDetail from '@/components/Form/ProductManagement/ComponentPairOnProductDetail';
import AddOnsPair from '@/components/Form/ProductManagement/AddOnsPair';
import moment from 'moment';
import { toast } from 'react-toastify';
import { toastSuccess } from '@/constants/ToastConfig';

function formatNominal(number, fixedDecimal = true) {
  if (isNaN(number)) {
      return "-";
  }

  const formattedNumber = fixedDecimal ? Number(number).toFixed(2) : number.toString();
  return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function TabInput() {
  const { register, handleSubmit, control, errors, setValue } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const navigate = useNavigate();
  const { productId, vendorId } = useParams();
  const isFromProcurement = vendorId !== undefined;
  const params = useParams();
  const dispatch = useDispatch();

  const {
    currencyOptions,
    productData,
    marketTabValue,
    marketManagers,
    pricingManagers,
    pricingManagersSplit,
    setPricingManagersSplit,
    addNew,
    setAddNew,
    selectedInput,
    setSelectedInput,
    setIsAllSelectedEmployees,
    newDimensions,
    setNewDimensions,
    // startPeriod,
    // setStartPeriod,
    getAllPricingManagerData,
  } = useProductContext();

  const priceTypeOptions = [
    {name: "Contingency Cost", value: "contingency_cost"},
    {name: "Overhead Cost", value: "overhead_cost"},
    {name: "Item Cost", value: "item_cost"},
    {name: "Profit Value", value: "profit_value"},
    {name: "Selling Price", value: "selling_price"},
  ]

  const inputTypeOptions1 = [
    {name: "Fixed Price", value: "fixed_price"},
    {name: "Percentage", value: "percentage_price"},
  ]

  const inputTypeOptionsCost = [
    {name: "Fixed Price", value: "fixed_price"},
    {name: "System Average", value: "system_average"},
  ]

  const inputTypeOptionsSellingPrice = [
    {name: "Fixed Price", value: "fixed_price"},
    {name: "System Automation", value: "system_automation"},
  ]

  const [pricingCurrency, setPricingCurrency] = useState(currencyOptions.find((dt) => dt.code === productData?.currency)?.id );
  
  const [startPeriod, setStartPeriod] = useState(null);

  useEffect(() => {
    getAllPricingManagerData({ currency: pricingCurrency })
  }, [pricingCurrency])

  useEffect(() => {
    const startPeriodFormatting = startPeriod !== null ? moment(startPeriod).format('YYYY-MM-DD') : null
    getAllPricingManagerData({ start_date: startPeriodFormatting, onlyPricingManagers: true })
  }, [startPeriod])
  
  const handleClickEmployees = (event, id) => {
    const selectedIndex = selectedInput.indexOf(id);
    let newSelected = [...selectedInput];

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelectedInput(newSelected);
  };

  const handleSelectAllClickPricing = (event) => {    
    if (selectedInput.length === pricingManagers.length) {
      setIsAllSelectedEmployees(false);
      setSelectedInput([]);
      return;
    } else {
      const newSelecteds = pricingManagers?.map((n) => n.id);
      setIsAllSelectedEmployees(true);
      setSelectedInput(newSelecteds);
      return;
    }
  }
  
  const handlePricingDimensionChange = (index, field, event) => {    
    const newPricingDimensions = [...newDimensions];
    newPricingDimensions[index][field] = event;
    setNewDimensions(newPricingDimensions);
  };

  // const handleDateChange = (index, key, value) => {
  
  //   if (value && !isNaN(new Date(value).getTime())) {
  //     const formattedDate = format(new Date(value), 'yyyy/MM/dd');
  
  //     setNewDimensions(prevState => {
  //       const updatedDimensions = [...prevState];
  //       updatedDimensions[index][key] = formattedDate;
  //       return updatedDimensions;
  //     });
  //   } else {
  //     console.log('Invalid date');
  //   }
  // };

  const addPricingDimension = () => {
    setNewDimensions([...newDimensions, { id: newDimensions.length + 1, start_date: null, end_date: null, price_type: '', price_option: '', price: '' }]);
  };

  const removeLastPricingDimension = () => {
    if (newDimensions.length > 1) {
      setNewDimensions(newDimensions.slice(0, -1));
    }
  };
  
  const handleAddNewButton = (val) => {
    if (val === true) {
      setAddNew(true);
      setNewDimensions([...newDimensions, { id: newDimensions.length + 1, start_date: null, end_date: null, price_type: '', price_option: '', price: '' }]);
    } else {
      setAddNew(false);
      setNewDimensions([]);
    }
  }

  const changingPrice = (event, tabValue, key, id) => {
    const dataByTabValue = _.cloneDeep(pricingManagersSplit[tabValue]);
    const dataByTabValueIndex = dataByTabValue.findIndex(dt => dt.id === id);
  
    if (dataByTabValueIndex !== -1) {
      dataByTabValue[dataByTabValueIndex][key] = event;
  
      setPricingManagersSplit(prevState => ({
        ...prevState,
        [tabValue]: dataByTabValue
      }))
    }
  };

  const deletePricingData = async () => {
    const { bulkDeletePricingManagers } = ProductManagementApi();
    const newData = {
      ids: selectedInput
    }

    try {
      await bulkDeletePricingManagers(newData);
      setNewDimensions([]);
      setAddNew(false);
      setIsAllSelectedEmployees(false);
      setSelectedInput([]);
      getAllPricingManagerData({ currency: pricingCurrency })
      toast('Success', {
        ...toastSuccess,
      });
    } catch (error) {
      console.log(error)
    }
  }

  const onFilterStartDate = (event) => {    
    const parsedDate = new Date(event);
    const currentYear = new Date().getFullYear();

    if (event === null) {
      return setStartPeriod(event);
    }
  
    if (parsedDate.getFullYear() < currentYear) {
      console.log('Event year is less than current year, skipping update');
      return;
    } else {
      return setStartPeriod(event);
    }
  }

  const onCheckingDate = (event, tabValue, key, id) => {  
    const dataByTabValue = _.cloneDeep(pricingManagersSplit[tabValue]);
    const dataByTabValueIndex = dataByTabValue.findIndex(dt => dt.id === id);
  
    const parsedDate = new Date(event);
    if (isNaN(parsedDate.getTime())) {
      console.log('Invalid Date, skipping setPricingManagersSplit');
      return;
    }
  
    const currentYear = new Date().getFullYear();
  
    if (parsedDate.getFullYear() < currentYear) {
      console.log('Event year is less than current year, skipping update');
      return;
    }
  
    if (dataByTabValueIndex !== -1) {
      dataByTabValue[dataByTabValueIndex][key] = moment(event).format('YYYY-MM-DD');
  
      setPricingManagersSplit(prevState => ({
        ...prevState,
        [tabValue]: dataByTabValue || null
      }));
      submitUpdateOne(dataByTabValue[dataByTabValueIndex]);
    }
  };
  

  const onChecking = (event, tabValue, key, id) => {
    const dataByTabValue = _.cloneDeep(pricingManagersSplit[tabValue]);
    const dataByTabValueIndex = dataByTabValue.findIndex(dt => dt.id === id);
  
    if (dataByTabValueIndex !== -1) {
      if (key === "start_date" || key === "end_date") {
        dataByTabValue[dataByTabValueIndex][key] = moment(event).format('YYYY-MM-DD');
      } else {
        dataByTabValue[dataByTabValueIndex][key] = event;
      }
  
      if (key === "price_option") {
        dataByTabValue[dataByTabValueIndex].price = "";
      }
  
      setPricingManagersSplit(prevState => ({
        ...prevState,
        [tabValue]: dataByTabValue
      }));
      submitUpdateOne(dataByTabValue[dataByTabValueIndex]);
    }
  };

  const submitUpdateOne = async (data) => {
    const { updateOnePricingManagers } = ProductManagementApi();

    const { id, ...otherData } = data;

    const newData = {
      ...otherData,
      currency: otherData.currency.id,
      market_manager: otherData.market_manager.id,
    };
    

    try {
      await updateOnePricingManagers(id, newData);
      setNewDimensions([]);
      setAddNew(false);
      getAllPricingManagerData({ currency: pricingCurrency })
      toast('Success', {
        ...toastSuccess,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleBulkAddNew = async () => {
    const { bulkProcessPricingManagers } = ProductManagementApi();

    const marketManager = marketManagers.find(manager => manager.market_name === marketTabValue);
  
    if (!marketManager) {
      console.error("Market manager not found for", marketTabValue);
      return;
    }  
    
    try {
      const checking = currencyOptions.find((dt) => dt.code === productData?.currency);

      const newData = newDimensions.map(item => {
        const { id, ...rest } = item;
        return {
          ...rest,
          start_date: item.start_date ? moment(item.start_date).format('YYYY-MM-DD') : null,
          end_date: item.end_date ? moment(item.end_date).format('YYYY-MM-DD') : null,
          market_manager: marketManager.id,
          currency: checking?.id,
          product: +productId
        };
      });
      
      await bulkProcessPricingManagers(newData);
      setNewDimensions([]);
      setAddNew(false);
      getAllPricingManagerData({ currency: pricingCurrency })
      toast('Success', {
        ...toastSuccess,
      });
      console.log({ newData });
    } catch (error) {
      console.error(error);
    }
  };

  const isSelectedInput = (name) => selectedInput.indexOf(name) !== -1;

  const ReusableDatePicker = ({className, mask, onChange = () => {}, value, disabled, onBlur = () => {}, minDate}) => (
    <DatePickerFieldWithoutController
      value={value}
      onChange={(date) => onChange(date)}
      renderInput={(params) => {
        return <TextField 
        sx={{ width: "auto" }} 
        size="small"
        onBlur={onBlur}
        inputFormat="DD/MM/YYYY"
        {...params} 
        />;
      }}
      componentsProps={{ actionBar: { actions: ['clear'] } }}
      minDate={minDate}
      disabled={disabled}
    />
  )

  return (
    <div className='space-y-4'>
      <div className="w-full flex justify-between gap-4">
        <div className="w-[15rem]">
          <div>
            <p className="font-bold">Start Period</p>
          </div>
          <DatePickerFieldWithoutController
            value={startPeriod}
            onChange={(newValue) => onFilterStartDate(newValue)}
            renderInput={(params) => {
              return <TextField 
              sx={{ width: "auto" }} 
              size="small"
              {...params} 
              />;
            }}
            componentsProps={{ actionBar: { actions: ['clear'] } }}
            minDate={new Date(2024, 0, 1)}
          />
        </div>
        <div className="w-fit flex justify-end items-center gap-x-2">
          {
            !addNew ? (
              <>
                <button
                  className={`
                      flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 text-white
                      ${selectedInput.length ? 'bg-[#FC581D] cursor-pointer ' : 'bg-[#FC581D26] cursor-default'}
                    `}
                  name="unpairCollection"
                  type="button"
                  disabled={selectedInput.length === 0 ? true : false}
                  onClick={deletePricingData}
                >
                  Delete
                </button>
                <button
                  className="btn bg-[#2C6D47] p-2 text-white rounded-lg border cursor-pointer"
                  name="manageOperators"
                  type="button"
                  onClick={() => handleAddNewButton(true)}
                >
                  Add New
                </button>
              </>
            ) : (
              <>
                <button
                  className={`
                      flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 text-white bg-[#FC581D] cursor-pointer
                    `}
                  name="unpairCollection"
                  type="button"
                  onClick={() => handleAddNewButton(false)}
                >
                  cancel
                </button>
                <button
                  className={`
                      flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 text-white bg-[#FC581D] cursor-pointer
                    `}
                  name="unpairCollection"
                  type="button"
                  disabled={newDimensions.length <= 1}
                  onClick={removeLastPricingDimension}
                >
                  Remove New Last Row
                </button>
                <button
                  className="btn bg-[#2C6D47] p-2 text-white rounded-lg border cursor-pointer"
                  name="manageOperators"
                  type="button"
                  onClick={addPricingDimension}
                >
                  Add New Row
                </button>
                <button
                  className="btn bg-[#2C6D47] p-2 text-white rounded-lg border cursor-pointer"
                  name="manageOperators"
                  type="button"
                  onClick={handleBulkAddNew}
                >
                  Submit
                </button>
              </>
            )
          }
        </div>
      </div>
      <div className='border'>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={selectedInput.length > 0 && selectedInput.length < pricingManagers.length}
                  checked={pricingManagers.length > 0 && selectedInput.length === pricingManagers.length}
                  onClick={handleSelectAllClickPricing}
                  inputProps={{ 'aria-label': 'select all desserts' }}
                />
              </TableCell>
              <TableCell align='center' className='!font-bold'>Start Period</TableCell>
              <TableCell align='center' className='!font-bold'>End Period</TableCell>
              <TableCell align='center' className='!font-bold' width={350}>Line Type</TableCell>
              <TableCell align='center' className='!font-bold' width={350}>Input Type</TableCell>
              <TableCell align='center' className='!font-bold'>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {addNew === true && newDimensions.map((nd, idx) => {
              return (
                <TableRow>
                  <TableCell>{""}</TableCell>
                  <TableCell align='center'>
                    <ReusableDatePicker value={nd?.start_date ? new Date(nd.start_date) : null} onChange={(date) => handlePricingDimensionChange(idx, "start_date", date)} />
                  </TableCell>
                  <TableCell align='center'>
                    <ReusableDatePicker value={nd?.end_date ? new Date(nd.end_date) : null} onChange={(date) => handlePricingDimensionChange(idx, "end_date", date)} />
                  </TableCell>
                  <TableCell align='center'>
                    <SelectAutocomplete
                      variant="outlined"
                      options={priceTypeOptions}
                      defaultValue={nd.price_type || ""}
                      onChange={(_, value) => handlePricingDimensionChange(idx, "price_type", value)}
                      isLoading={false}
                    />
                  </TableCell>
                  <TableCell align='center'>
                    <SelectAutocomplete
                      variant="outlined"
                      options={nd?.price_type !== "item_cost" ? nd?.price_type !== "selling_price" ? inputTypeOptions1 : inputTypeOptionsSellingPrice : inputTypeOptionsCost}
                      defaultValue={nd?.price_option}
                      onChange={(_, value) => handlePricingDimensionChange(idx, "price_option", value)}
                      isLoading={false}
                    />
                  </TableCell>
                  <TableCell align='center'>
                    <TextField defaultValue={nd?.price || ''} onChange={(e) => handlePricingDimensionChange(idx, "price", e.target.value)} size="small" fullWidth  />
                  </TableCell>
                </TableRow>
              )
            })}
            {
              marketTabValue !== '' && pricingManagersSplit !== null && (
                <>
                  {pricingManagersSplit[marketTabValue]?.map((dt, idx) => {
                    const isItemSelected = isSelectedInput(dt.id);
                    const labelId = `enhanced-table-checkbox-${idx}`;
                    const today = new Date().toISOString().slice(0, 10);
                    const isPastDate = dt?.end_date !== null ? new Date(dt?.end_date) < new Date(today) : false;
                    
                    return (
                      <TableRow role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={dt.id} selected={isItemSelected}>
                        <TableCell padding="checkbox">
                          <Checkbox onClick={(event) => handleClickEmployees(event, dt.id)} color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                        </TableCell>
                        <TableCell align="center">
                          <ReusableDatePicker value={dt?.start_date} onChange={(e) => onCheckingDate(e, marketTabValue, "start_date", dt.id)} />
                        </TableCell>
                        <TableCell align="center">
                          <ReusableDatePicker value={dt?.end_date} onChange={(e) => onCheckingDate(e, marketTabValue, "end_date", dt.id)} />
                        </TableCell>
                        <TableCell align="center">
                          <SelectAutocomplete
                            variant="outlined"
                            options={priceTypeOptions}
                            defaultValue={dt?.price_type}
                            onChange={(_, val) => onChecking(val, marketTabValue, "price_type", dt.id)}
                            isLoading={false}
                            disabled={isPastDate}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <SelectAutocomplete
                            variant="outlined"
                            options={dt?.price_type !== "item_cost" ? dt?.price_type !== "selling_price" ? inputTypeOptions1 : inputTypeOptionsSellingPrice : inputTypeOptionsCost}
                            defaultValue={dt?.price_option}
                            onChange={(_, val) => onChecking(val, marketTabValue, "price_option", dt.id)}
                            isLoading={false}
                            disabled={isPastDate}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            value={dt?.price || ''}
                            size="small"
                            fullWidth
                            InputProps={{
                              endAdornment: dt?.price_option === 'percentage_price' ? (
                                <InputAdornment position="end">%</InputAdornment>
                              ) : null,
                            }}
                            onChange={(e) => changingPrice(e.target.value, marketTabValue, "price", dt.id)}
                            onBlur={(e) => onChecking(e.target.value, marketTabValue, "price", dt.id)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                onChecking(e.target.value, marketTabValue, "price", dt.id);
                              }
                            }}
                            disabled={isPastDate}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </>
              )
            }
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default TabInput