/* eslint-disable */

import { fetchGroups } from '@/hooks/useUsersData';
import ForceRerender from '@/utils/ForceRerender';
import { DevTool } from '@hookform/devtools';
import { Add, AttachFile, Delete, DeleteForever } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Avatar, Box, FormControlLabel, IconButton, Switch, Tab } from '@mui/material';
import Button from '@mui/material/Button';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import HandleWatchingState from '../../../helper/HandleWatchingState';
import renderDate from '../../../helper/renderDate';
import useGet from '../../../hooks/useGet';
import { generateSelectListDefault, getDefaultSelectObject, getOptionSelectWithKey } from '../../../lib/OptionsInputHelper';
import DatePickerField from '../../Input/DatePickerField';
import FileContatiner from '../../Input/FileContatiner';
import FileContatinerMultiAdd from '../../Input/FileContatinerMultiAdd';
import InputContainer from '../../Input/InputContainer';
import ReactSelectCreateable from '../../Input/ReactSelectCreateable';
import ReactSelectMultiple from '../../Input/ReactSelectMultiple';
import ReactSelectSingle from '../../Input/ReactSelectSingle';
import TextAreaContainer from '../../Input/TextAreaContainer';
import EmpEduFieldArr from './EmpEduFieldArr';
import EmpHistoryFieldArr from './EmpHistoryFieldArr';
import UserForm from './UserForm';
import schemaEmployee from './validationEmployee';
import { useDebounce } from 'use-debounce';

function getFileNameAndType(url) {
  return url?.substring(url?.lastIndexOf('/') + 1);
  // var fileType = fileName.substring(fileName.lastIndexOf('.') + 1);
  // return { name: fileName, type: fileType };
}

const optionGender = [
  {
    name: '-- No Selected --',
    id: '',
  },
  {
    id: 'Male',
    name: 'Male',
  },
  {
    id: 'Female',
    name: 'Female',
  },
];
const optionMarital = [
  {
    name: '-- No Selected --',
    id: '',
  },
  {
    id: 'Single',
    name: 'Single',
  },
  {
    id: 'Married',
    name: 'Married',
  },
  {
    id: 'Widowed',
    name: 'Widowed',
  },
  {
    id: 'Divorce',
    name: 'Divorce',
  },
];

function EmployeeNewForm({ defaultValues, onFormSubmit, cancel, isModal }) {
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.client.activeClient);
  const activeSite = useSelector((state) => state.content.activeSite);
  const template = useSelector((state) => state.content.activeTemplate);
  const subsActive = useSelector((state) => state.client.clientSubscriptions);
  const userSubs = useSelector((state) => state.auth.isGroups);
  const isSuperUser = useSelector((state) => state.auth.isSuperUser);
  const isAdminHr = userSubs.includes(15);
  const navigate = useNavigate();

  const [reloadFormUser, setReloadFormUser] = useState(false);

  // const [valueTab, setValueTab] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  const {
    control,
    handleSubmit,
    setValue,
    register,
    watch,
    getValues,
    unregister,
    formState: { errors },
  } = useForm({
    defaultValues: {
      avatar: defaultValues?.avatar,
      user: defaultValues?.user?.id,
      first_name: defaultValues?.first_name,
      // user_data: defaultValues?.first_name,
      last_name: defaultValues?.last_name,
      email: defaultValues?.email,
      gender: defaultValues?.gender,
      marital_status: defaultValues?.marital_status,
      phone: defaultValues?.phone || '',
      // marital_status: defaultValues?.marital_status,
      address: defaultValues?.address,
      is_active: defaultValues?.is_active,
      employment_id: defaultValues?.employment_id,
      hired_date: defaultValues?.hired_date || null,
      spoken_languages: defaultValues?.spoken_languages?.map((spoke) => spoke?.id),
      skills: defaultValues?.skills?.map((sk) => sk),
      educations: defaultValues?.educations?.map((ed) => ed),
      attachments: defaultValues?.attachments?.map((attc) => attc),
      employment_histories: defaultValues?.employment_histories?.map((his) => ({
        ...his,
        department: his.department.id,
        business_unit: his.department.business_unit.id,
        employment_type: his.employment_type,
        position: his.position,
        start_date: his.start_date,
        end_date: his.end_date,
      })),
    },
    resolver: schemaEmployee,
  });
  const userDataWatch = useWatch({ control, name: 'user_data' });
  const userId = watch('user');
  const { data: languagesData } = useGet({
    url: `languages`,
    queryString: {
      // client: clientId,
      max_size: true,
      // language: activeLang,
    },
    validate: 'languages',
  });

  useEffect(() => {
    register('employment_histories');
  }, [register]);

  const onSubmit = (data) => {
    console.log('🚀 ~ onSubmit ~ data:', data);
    const formData = new FormData();
    formData.append('client', clientId);
    if (data.user) {
      formData.append('user', data.user || '');
    }

    const nameVal = data?.first_name ? `${data?.first_name} ${data?.last_name || ''}` : '';

    const userDataObj = data?.user_data && { name: nameVal, email: data?.email, groups: userDataWatch?.groups };
    if (defaultValues && data.user_data?.password) {
      userDataObj.password = data.user_data?.password;
    }
    console.log('🚀 ~ onSubmit ~ userId:', userId);
    if (userId) {
      userDataObj.id = userId;
    }
    if (!defaultValues) {
      userDataObj.password = data.user_data?.password;
    }

    formData.append('user_data', JSON.stringify(userDataObj));
    formData.append('last_name', data.last_name || '');
    formData.append('first_name', data.first_name || '');
    formData.append('gender', data.gender);
    formData.append('marital_status', data.marital_status);
    formData.append('phone', data.phone || '');
    formData.append('address', data.address || '');
    data?.spoken_languages?.forEach((data, i) => {
      formData.append(`spoken_languages`, data);
    });

    if (data.educations && data.educations.length > 0) {
      data.educations.forEach((exp, idx) => {
        formData.append(`educations[${idx}]id`, exp?.id);
        formData.append(`educations[${idx}]title`, exp?.title);
        formData.append(`educations[${idx}]school`, exp?.school);
        formData.append(`educations[${idx}]degree`, exp?.degree);
        formData.append(`educations[${idx}]description`, exp?.description);
        formData.append(`educations[${idx}]duration`, exp?.duration);
        if (exp?.start_date) {
          formData.append(`educations[${idx}]start_date`, renderDate(exp?.start_date));
        } else formData.append(`educations[${idx}]start_date`, '');
        if (exp?.end_date) {
          formData.append(`educations[${idx}]end_date`, renderDate(exp?.end_date));
        } else formData.append(`educations[${idx}]end_date`, '');
      });
    }
    if (data.skills && data.skills.length > 0) {
      formData.append(`skills`, JSON.stringify(data.skills.filter((skill) => skill !== null)));
    } else {
      formData.append('skills', null);
    }
    formData.append('employment_id', data.employment_id);
    formData.append('hired_date', renderDate(data.hired_date));
    if (data.employment_histories && data.employment_histories.length > 0) {
      data?.employment_histories?.forEach((data, i) => {
        if (data.id) {
          formData.append(`employment_histories[${i}]id`, data.id);
        }
        if (data.employee) {
          formData.append(`employment_histories[${i}]employee`, data.employee);
        }
        formData.append(`employment_histories[${i}]position`, data.position);
        formData.append(`employment_histories[${i}]employment_type`, data.employment_type);
        formData.append(`employment_histories[${i}]department`, data.department);
        if (data?.start_date) {
          formData.append(`employment_histories[${i}]start_date`, renderDate(data.start_date));
        } else formData.append(`employment_histories[${i}]start_date`, '');
        if (data?.end_date) {
          formData.append(`employment_histories[${i}]end_date`, renderDate(data.end_date));
        } else formData.append(`employment_histories[${i}]end_date`, '');
      });
    } else {
      formData.append(`employment_histories`, null);
    }

    if (data.attachments && data.attachments.length === 0) {
      formData.append(`attachments`, null);
    } else {
      data?.attachments?.forEach((data, i) => {
        if (data instanceof File) {
          formData.append(`attachments[${i}]name`, data.name);
          formData.append(`attachments[${i}]attachment`, data);
        } else {
          formData.append(`attachments[${i}]id`, data.id);
          formData.append(`attachments[${i}]name`, data.name);
        }
      });
    }

    if (data.avatar?.length > 0 && data.avatar[0] instanceof File) {
      formData.append(`avatar`, data.avatar[0]);
    }
    formData.append('is_active', data.is_active);
    formData.append(`email`, data.email);

    onFormSubmit(formData);
  };

  const handleCancel = () => {
    if (isModal) {
      cancel();
    } else navigate(cancel);
  };

  const ava = watch('avatar');

  function renderAva() {
    const createAva = ava && typeof ava === 'string' ? defaultValues?.avatar : URL.createObjectURL(ava[0]);
    return createAva;
  }

  const handleUnregAtt = (idx) => {
    const newAttFile = watch('attachments').filter((_, index) => index !== idx);

    return setValue('attachments', newAttFile);
  };
  const getExp = watch('employment_histories') || [];

  const watchHistory = HandleWatchingState(getExp.length + 1);

  const { data: SkillsData } = useGet({
    url: `applicants/list_skills`,
    queryString: {
      user__client: clientId,
    },
    validate: 'skillsApplicant',
  });

  const { data: UsersData } = useGet({
    url: `users`,
    queryString: {
      client: clientId,
      max_size: true,
    },
    validate: 'users',
  });

  const subs = subsActive?.map((s) => {
    return `subscription=${s}&`;
  });

  const subId = subs.join('');
  const { data: subsData } = useQuery(['subs', { subId }], fetchGroups);

  const watchDefUserId = HandleWatchingState(defaultValues?.user?.id);

  const userObject = watch('user_object');
  const watchUserId = HandleWatchingState(JSON.stringify(userId));
  const { data: userData, isFetching } = useGet({
    url: `users/${userId}`,
    validate: `user-detail-${userId}`,
    isEnabled: watchUserId && Boolean(userId),
    onSuccess: (res) => {
      const stateUser = res.data;
      // window.location.reload();
      setValue('user', stateUser?.id);
      setValue('user_object', { value: stateUser?.id, label: `${stateUser?.name} - ${stateUser?.email}` });
      setValue('user_data.name', stateUser?.name);
      setValue('user_data.email', stateUser?.email);
      setValue(
        'user_data.groups',
        stateUser?.groups?.map((gr) => gr?.id)
      );
    },
  });
  const watchUserData = useWatch({ control, name: 'user_data' });

  const newDefData = subsData?.data?.results.filter((apiData) => watchUserData?.groups?.some((pairData) => apiData.id.toString() === pairData?.toString()));

  const [valueTab, setValueTab] = React.useState('1');

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const memoizedUser = watchUserData && userData?.data?.id ? { ...watchUserData, groups: newDefData } : null;

  const { email } = useWatch({ control });

  const [inputEmail] = useDebounce(email, 1000);

  const getUserByEmail = email && UsersData?.data?.results.filter((usr) => usr.email === inputEmail);

  useEffect(() => {
    setReloadFormUser(true);
    setTimeout(() => {
      setReloadFormUser(false);
    }, 1000);
  }, [watchUserId, inputEmail]);

  useEffect(() => {
    if (email && getUserByEmail && getUserByEmail.length > 0 && reloadFormUser) {
      console.log('🚀 ~ useEffect ~ email:', email);
      setValue('user', getUserByEmail[0].id);
    }
  }, [getUserByEmail, inputEmail, reloadFormUser]);

  const watchInputEmail = HandleWatchingState(inputEmail);
  useEffect(() => {
    if (getUserByEmail && getUserByEmail.length === 0 && !userId && !watchInputEmail) {
      console.log('🚀 ~ useEffect ~ userId:', userId);
      setValue('user', null);
      setValue('user_data', null);
    }
  }, [getUserByEmail, userId, watchInputEmail]);
  useEffect(() => {
    if (!watchInputEmail && getUserByEmail && getUserByEmail.length === 0) {
      console.log('🚀 ~ useEffect ~ watchInputEmail:', watchInputEmail);
      setValue('user', null);
      setValue('user_data', null);
    }
  }, [getUserByEmail, watchInputEmail]);

  useEffect(() => {
    setValue('user', defaultValues?.user?.id);
  }, [watchDefUserId]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full space-y-2 ">
          <TabContext value={valueTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                <Tab label="Main" value="1" />
                {(isSuperUser || isAdminHr) && <Tab label="User Access" value="2" />}
              </TabList>
            </Box>
            <TabPanel className="!p-0" value="1">
              <div className="w-full gap-x-3 ">
                <div className="spacke-y-4 flex">
                  <div className="w-full flex space-x-3 relative">
                    <FileContatiner
                      name="avatar"
                      // label="Avatar"
                      errors=""
                      control={control}
                      watchValue={ava}
                      title={getFileNameAndType(ava ? renderAva() : '')}
                      custom
                      customPaceholder={
                        <div className="w-full ">
                          <Avatar
                            src={ava ? renderAva() : ''}
                            alt="photo preview"
                            className="w-full h-full object-cover  max-w-[300px] max-h-[300px] border p-2"
                            // sx={{ bgcolor: deepOrange[500] }}
                            variant="square"
                          />
                        </div>
                      }
                    />
                    {ava && (
                      <div className="absolute left-[310px] top-[30px]">
                        <IconButton onClick={() => setValue('avatar', null)} aria-label="delete" size="small" type="button" variant="contained" color="error">
                          <DeleteForever className="" />
                        </IconButton>
                      </div>
                    )}
                  </div>
                  <div className="w-full">
                    <div className="flex flex-col justify-end">
                      <div className="my-3 font-bold ">Employment Status</div>
                      <div className="flex items-center">
                        <div className="mr-3">Inactive</div>
                        <Controller
                          name="is_active"
                          control={control}
                          defaultValue={Boolean(defaultValues?.is_active)}
                          render={({ field: { value, onChange } }) => <FormControlLabel control={<Switch checked={value} onChange={onChange} />} label=" Active" />}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full gap-x-3">
                <div className="w-6/12">
                  <InputContainer name="first_name" control={control} label="First Name" errors={errors.first_name} className="" />
                </div>
                <div className="w-6/12">
                  <InputContainer name="last_name" control={control} label="Last Name" errors={errors.name} className="" />
                </div>
              </div>
              <div className="flex w-full gap-x-3">
                <div className="w-6/12">
                  <InputContainer name="email" control={control} label="Email" errors={errors.email} />
                </div>
                <div className="w-6/12">
                  <InputContainer name="phone" control={control} label="Phone" errors={errors.phone} />
                </div>
              </div>
              <div className="flex w-full gap-x-3">
                <div className="w-6/12">
                  <InputContainer name="employment_id" control={control} label="Employment ID" errors={errors.employment_id} />
                </div>
                <div className="w-6/12">
                  <TextAreaContainer name="address" control={control} label="Address" errors={errors.email} rows={2} />
                </div>
              </div>
              <div className="w-full gap-x-3 grid grid-cols-2 py-3">
                <div className="w-full">
                  <DatePickerField sx={{ marginBottom: '0px' }} control={control} name="hired_date" label="Hired Date" size="small" fullWidth errors={errors.hired_date} />
                </div>
                <div className="w-full">
                  <ReactSelectSingle
                    name="gender"
                    control={control}
                    options={getOptionSelectWithKey(optionGender)}
                    defaultValue={getDefaultSelectObject(defaultValues?.gender, getOptionSelectWithKey(optionGender))}
                    placeholder="Select Gender"
                    errors={errors.gender}
                  />
                </div>
              </div>
              <div className="w-full gap-x-3 grid grid-cols-2 ">
                <div className=" bg-white">
                  <Controller
                    name="spoken_languages"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <ReactSelectMultiple
                        options={languagesData?.data?.results?.map((item) => ({
                          label: item?.name,
                          value: item?.id,
                        }))}
                        defaultValue={generateSelectListDefault(defaultValues?.spoken_languages)}
                        placeholder="Select Languages"
                        isMulti
                        onChange={(v) => onChange(v?.map((val) => val.value))}
                      />
                    )}
                  />
                </div>
                <div className="w-full">
                  {' '}
                  <div className="w-full">
                    <ReactSelectSingle
                      name="marital_status"
                      control={control}
                      options={getOptionSelectWithKey(optionMarital)}
                      defaultValue={getDefaultSelectObject(defaultValues?.marital_status, getOptionSelectWithKey(optionMarital))}
                      placeholder="Select Marital Status"
                      errors={errors.marital_status}
                    />
                  </div>
                </div>
              </div>
              <div className="">
                <div className="font-bold pb-3">Skills</div>
                <div className="flex flex-wrap">
                  <ReactSelectCreateable
                    name="skills"
                    control={control}
                    options={getOptionSelectWithKey(
                      SkillsData?.data?.map((sk) => ({
                        name: sk,
                        id: sk,
                      }))
                    )}
                    defaultValue={generateSelectListDefault(
                      defaultValues?.skills?.map((sk) => ({
                        name: sk,
                        id: sk,
                      }))
                    )}
                    placeholder="Select Skills"
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="font-bold pb-3">Education</div>
                <EmpEduFieldArr
                  name="educations"
                  control={control}
                  defaultValues={defaultValues}
                  setValue={setValue}
                  register={register}
                  unregister={unregister}
                  watch={watch}
                  // label="Educations"
                  classNameInput="w-full"
                  errors={errors}
                />
              </div>
              {!watchHistory && (
                <div className="">
                  <div className="font-bold pb-3">Employment history</div>
                  <EmpHistoryFieldArr
                    name="employment_histories"
                    control={control}
                    setValue={setValue}
                    register={register}
                    unregister={unregister}
                    watch={watch}
                    // label="Educations"
                    classNameInput="w-full"
                    errors={errors}
                  />
                </div>
              )}
              {watchHistory && (
                <div className="">
                  <div className="font-bold pb-3">Employment history</div>
                  <EmpHistoryFieldArr
                    name="employment_histories"
                    control={control}
                    setValue={setValue}
                    register={register}
                    unregister={unregister}
                    watch={watch}
                    // label="Educations"
                    classNameInput="w-full"
                    errors={errors}
                  />
                </div>
              )}
              <div className="w-full flex space-x-3 relative">
                <FileContatinerMultiAdd
                  name="attachments"
                  label="Attachments"
                  errors=""
                  control={control}
                  multiple
                  prevVal={() => getValues('attachments')}
                  custom
                  customPaceholder={
                    <div className="w-full ">
                      <Add
                        // src={ava ? renderAva() : ''}
                        alt="photo preview"
                        className="w-full h-full object-cover  max-w-[30px] max-h-[30px] "
                        // sx={{ bgcolor: deepOrange[500] }}
                        variant="square"
                      />
                    </div>
                  }
                />
              </div>
              {watch('attachments') &&
                watch('attachments').length > 0 &&
                watch('attachments').map((val, id) => (
                  <div key={val.id} className="flex gap-x-4">
                    <a className="flex gap-x-1" href={val?.file} target="_blank" rel="noreferrer">
                      <AttachFile color="primary" />
                      <p className="font-medium text-[#2196f3]">{val?.file ? getFileNameAndType(val?.file) : val?.name}</p>
                    </a>
                    <Delete className="cursor-pointer" onClick={() => handleUnregAtt(id)} />
                  </div>
                ))}
            </TabPanel>
            <TabPanel className="!p-0" value="2">
              <div className="flex flex-col justify-end">
                <ForceRerender condition={!reloadFormUser} component={<UserForm control={control} setValue={setValue} defaultValues={memoizedUser} errors={errors?.user_data} />} />
              </div>
            </TabPanel>
            {/* filling user form data */}
            <div className="flex flex-col justify-end hidden">
              <ForceRerender condition={!reloadFormUser} component={<UserForm control={control} setValue={setValue} defaultValues={memoizedUser} errors={errors?.user_data} />} />
            </div>{' '}
            {/* filling user form data */}
          </TabContext>
          <div className="flex justify-end ml-auto gap-x-2">
            {cancel && (
              <Button onClick={handleCancel} className="flex" variant="outlined">
                Cancel
              </Button>
            )}
            <Button className=" flex" type="submit" variant="outlined">
              {defaultValues ? 'Update Employee' : 'Create Employee'}
            </Button>
          </div>
        </div>
        <DevTool control={control} />
      </form>
    </>
  );
}
export default EmployeeNewForm;
