import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { Box, Button, ButtonBase, Tooltip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';

import BasePromptDialog from '../../../components/ui/Dialog/BasePromptDialog';
import accountingApi from '../../../api/accounting/accounting';
import BZHelmet from '../../../utils/BZHelmet';

export default function BusinessUnits() {
  const navigate = useNavigate();
  const { getBusinessUnits, deleteBusinessUnit } = accountingApi();
  const client = useSelector((state) => state.auth.client);
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeUser = useSelector((state) => state.auth.user);
  const usedClient = activeUser.is_superuser ? activeClient : client.id;

  const [dataBusinessUnits, setDataBusinessUnits] = useState(false);
  const [modalData, setModalData] = useState('');
  const [isModalDelete, setModalDelete] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getBusinessUnitsList = useCallback(() => {
    getBusinessUnits({ client: usedClient, max_size: true })
      .then((res) => {
        if (res.status === 200 && res.data.results) {
          setDataBusinessUnits(res.data.results);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [getBusinessUnits, usedClient]);

  useLayoutEffect(() => {
    if (!dataBusinessUnits) {
      getBusinessUnitsList();
    }
  }, [dataBusinessUnits, getBusinessUnitsList]);

  const onEditClick = (idBusiness) => {
    navigate(`/accounting/settings/business-unit/${idBusiness}`);
  };

  const onDeleteClick = (data) => {
    setModalData(data);
    setModalDelete(true);
  };

  const onConfirmDelete = () => {
    if (modalData && modalData.id) {
      deleteBusinessUnit(modalData.id)
        .then(() => {
          getBusinessUnitsList();
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  const renderAction = (dt) => {
    return (
      <Box className="flex gap-2">
        <ButtonBase className="bz-btn-tb" onClick={() => onEditClick(dt.id)}>
          <Edit fontSize="24" />
        </ButtonBase>
        <ButtonBase className="bz-btn-tb" onClick={() => onDeleteClick(dt)}>
          <Delete fontSize="24" />
        </ButtonBase>
      </Box>
    );
  };

  const columns = [
    {
      field: 'code',
      headerName: 'Code',
      headerAlign: 'center',
      minWidth: 96,
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 256,
      flex: 1,
      renderCell: (params) => <span className="truncate">{params.row.name}</span>,
    },
    {
      field: 'base_currency',
      headerName: 'Base Currency',
      headerAlign: 'center',
      align: 'center',
      minWidth: 144,
      renderCell: (params) => <span className="truncate">{params.row.base_currency?.code}</span>,
    },
    {
      field: 'ledger_currencies',
      headerName: 'Ledger Currency',
      headerAlign: 'center',
      minWidth: 144,
      maxWidth: 576,
      flex: 1,
      renderCell: (params) => {
        const lc = [];
        params.row.ledger_currencies.forEach((curr) => {
          lc.push(curr.code);
        });
        return (
          <Tooltip title={lc.join(', ')}>
            <span className="truncate">{lc.join(', ')}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 256,
      flex: 1,
    },
    {
      field: 'address',
      headerName: 'Address',
      minWidth: 480,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.address}>
          <p className="truncate">{params.row.address}</p>
        </Tooltip>
      ),
    },
    {
      field: 'id',
      headerName: 'Action',
      sortable: false,
      width: 80,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => renderAction(params.row),
    },
  ];

  return (
    <>
      <BZHelmet title="Business Unit" description="bz publish business unit" />
      <Box className="w-full h-full">
        <div className="w-full flex p-4 shadow-md">
          <h1 className="text-lg font-bold">Business Units</h1>
        </div>
        <div>
          <div className="w-full flex px-4 pt-4">
          <button
            type="button"
            className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 ml-auto"
            style={{ backgroundColor: '#2C6D47' }}
            onClick={() => navigate('/accounting/settings/business-unit/create')}
          >
            <p className="text-white">Add New Business unit</p>
          </button>
          </div>
          {dataBusinessUnits && (
            <Box style={{ height: `calc((${dataBusinessUnits.length} * 4rem) + 8rem)` }} className="w-full h-full px-4 pt-4 mb-4">
              <DataGrid
                autoHeight
                rows={dataBusinessUnits || []}
                columns={columns}
                pageSize={rowsPerPage}
                onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
                rowsPerPageOptions={[10, 20, 50]}
              />
            </Box>
          )}
        </div>
        <BasePromptDialog
          open={isModalDelete}
          title="Delete"
          content={modalData?.name}
          action={onConfirmDelete}
          close={() => {
            setModalDelete(false);
            setTimeout(() => {
              setModalData(false);
            }, 300);
          }}
        />
      </Box>
    </>
  );
}
