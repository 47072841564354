import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { handleModal, handleModalNested } from '../../store/slices/modalSlice';
import { taskAdd } from '../../store/slices/taskCrudSlice';
import { resetAsset } from '../../store/slices/assetCrudSlice';

export default function ModalWrapper({
  isOpen,
  header,
  children,
  maxWidth,
  isHeader,
  componentName,
  isNested,
  extraClose,
  PaperProps = {},
  onCloseButtonClick
}) {
  const isModal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [onClose, setOnClose] = useState(false);

  useEffect(() => {
    if (onClose) {
      if (isNested) {
        dispatch(handleModalNested({ modalIdNested: '', componentNameNested: '' }));
      } else {
        dispatch(handleModal({ modalId: '', componentName: '' }));
      }
      if (extraClose) {
        extraClose();
      }
      dispatch(taskAdd({ name: '', project: '', section: '' }));
      dispatch(resetAsset());
      setOnClose(!onClose);
    }
  }, [onClose]);

  const handleCloseButtonClick = () => {
    if (onCloseButtonClick) {
      onCloseButtonClick();
    }
    setOnClose(true);
  };

  return (
    <Dialog
      open={(isModal.isOpen && isModal.componentName === componentName) || (isModal.isOpenNested && isModal.componentNameNested === componentName)}
      maxWidth={maxWidth}
      fullWidth
      className="top-[40px] "
      PaperProps={PaperProps}
    >
      <DialogTitle className={`${isHeader === false ? 'hidden' : 'block'}`}>
        <div style={{ display: 'flex', position: 'relative' }}>
          {header}
          <button
            onClick={handleCloseButtonClick}
            type="button"
            className="absolute right-8"
          >
            <CloseIcon />
          </button>
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}
