import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, InputBase, Tab, Tabs, CircularProgress } from '@mui/material';
import BzThumbnail from '@/assets/simple.png';
import { Search } from '@mui/icons-material';
import { useDebounce } from 'use-debounce';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { handleModal } from '@/store/slices/modalSlice';
import POSApi from '@/api/pos/posApi';
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function OutletHomeScreenIndexing({ setIndexing, submitIndexing, collectionIds }) {
  const clientId = useSelector((state) => state.modal.modalId);
  const collectionsData = useSelector((state) => state.modal.modalData);
  const dispatch = useDispatch();
  
  const [homePosData, setHomePosData] = useState(collectionsData?.outletHomePos || []);
  const [allProductList, setAllProductList] = useState([]);
  
  const [allCollectionList, setAllCollectionList] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [debounceSearchValue] = useDebounce(searchValue, 300);
  const [isFetchingCollections, setIsFetchingCollections] = useState(false);
  const [isFetchingProducts, setIsFetchingProducts] = useState(false);
  const [productsFetched, setProductsFetched] = useState(false);
  const [collectionsFetched, setCollectionsFetched] = useState(false);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchData = async (apiFunction, params) => {
    try {
      const res = params ? await apiFunction(params) : await apiFunction();
      return res.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };

  const allProducts = useCallback(
    (params = { outlet: collectionsData?.outletId, max_size: 'true' }) => {
      const formData = new URLSearchParams();
      for (const key in params) {
        formData.append(key, params[key]);
      }

      if (tabValue === 1 && debounceSearchValue !== '') {
        formData.append('search', debounceSearchValue);
      }

      if (collectionIds?.length > 0) {
        collectionIds.forEach((id, index) => {
          formData.append(`collections`, id);
        });
      }
      return fetchData(POSApi().getAllProductsByCollections, formData);
    },
    [collectionsData?.outletId, tabValue, debounceSearchValue]
  );

  const getAllProducts = useCallback(async () => {
    if (productsFetched) return;
    setIsFetchingProducts(true);
    try {
      const products = await allProducts();
      if (products) {
        const { results } = products;
        const addingFieldProduct = results.map((dt) => ({...dt, product: {name: dt.name, id: dt.id, thumbnail: dt.thumbnail}}))
        setAllProductList(addingFieldProduct);
        setProductsFetched(true);
      }
    } catch (error) {
      console.error('Error fetching products: ', error);
    } finally {
      setIsFetchingProducts(false);
    }
  }, [allProducts, productsFetched]);

  const allOutletCollections = useCallback(
    (params = { client: clientId, max_size: 'true' }) => {      
      if (tabValue === 0 && debounceSearchValue !== '') {
        params.search = debounceSearchValue;
      }
      return fetchData(ProductManagementApi().getCollections, params);
    },
    [clientId, tabValue, debounceSearchValue]
  );

  const getAllCollections = useCallback(async () => {
    if (collectionsFetched) return;
    setIsFetchingCollections(true);
    try {
      const collections = await allOutletCollections();
      if (collections) {
        setAllCollectionList(collections.results);
        setCollectionsFetched(true);
      }
    } catch (error) {
      console.error('Error fetching Collections: ', error);
    } finally {
      setIsFetchingCollections(false);
    }
  }, [allOutletCollections, collectionsFetched]);

  useEffect(() => {
    setAllCollectionList([])
    setAllProductList([])
    setProductsFetched(false);
    setCollectionsFetched(false);
  }, [debounceSearchValue]);
  
  useEffect(() => {
    if (collectionsData?.outletId) {
      if (tabValue === 0) {
        getAllCollections();
      } else if (tabValue === 1) {
        getAllProducts();
      }
    }
  }, [tabValue, getAllProducts, getAllCollections, collectionsData?.outletId, debounceSearchValue]);

  const isChecked = (item) => {
    return homePosData.some((pos) => 
      (item.product ? pos.product?.id === item.product.id : pos.collection?.id === item.collection?.id)
    );
  }

  const isCheckedCollections = (item) => {
    return homePosData.some((pos) => pos.collection?.id === item.id);
  }

  const handleSelectedHomePos = (checked, id, item) => {
    if (checked) {
      setHomePosData((prev) => [...prev, { ...item, index: prev.length + 1 }]);
    } else {
      setHomePosData((prev) => {
        const updatedData = prev.filter((pos) => (item.product ? pos.product?.id !== item.product.id : pos.collection?.id !== item.id));
        return updatedData.map((item, index) => ({
          ...item,
          index: index + 1
        }));
      });
    }
  };

  const handleSelectedHomePosProduct = (checked, item) => {
    if (checked) {
      setHomePosData((prev) => [
        ...prev,
        { ...item, index: prev.length + 1, product: { id: item.product.id, name: item.product.name } },
      ]);
    } else {
      setHomePosData((prev) =>
        prev.filter((pos) => pos.product?.id !== item.product.id)
      );
    }
  };
  
  const handleSelectedHomePosCollection = (checked, item) => {
    if (checked) {
      const collection = allCollectionList.find((col) => col.id === item.id);
      if (collection) {
        setHomePosData((prev) => [
          ...prev,
          { ...collection, index: prev.length + 1, collection: { id: collection.id, name: collection.name } },
        ]);
      }
    } else {
      setHomePosData((prev) =>
        prev.filter((pos) => pos.collection?.id !== item.id)
      );
    }
  };

  const InputLabel = ({ children }) => <p className="font-bold">{children}</p>;

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(homePosData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedItems = items.map((item, index) => ({
      ...item,
      index: index + 1
    }));
    setHomePosData(updatedItems);
  };

  const sumbitIndexingHomePos = () => {
    console.log({homePosData});
    
    const newIndexData = homePosData.map((dt) => dt?.collection ? ({"collection_id": dt.collection.id, "product_id": null}) : ({"collection_id": null, "product_id": dt.product.id}));
    
    submitIndexing(newIndexData)
  };
  
  return (
    <div className="w-full flex space-x-5" style={{ maxHeight: '980px', overflowY: 'hidden' }}>
      <div className="w-[70%] pr-2" style={{ maxHeight: '980px', overflowY: 'hidden' }}>
        <div className="">
          <Box sx={{ width: '100%' }}>
            <Box sx={{ marginBottom: 4, zIndex: 1200 }}>
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
                TabIndicatorProps={{
                  sx: {
                    backgroundColor: '#2C6D47',
                  },
                }}
                sx={{
                  '.MuiButtonBase-root': {
                    fontWeight: '600',
                  },
                  '.Mui-selected': {
                    color: '#2C6D47!important',
                  },
                  zIndex: 1200,
                }}
              >
                <Tab value={0} label="Collections" {...a11yProps(0)} />
                <Tab value={1} label="Products" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <div className="grid grid-cols-3 gap-6 pb-2 pr-2 overflow-y-auto max-h-[800px]">
              {tabValue === 0 && isFetchingCollections && (
                <CircularProgress />
              )}
              {tabValue === 1 && isFetchingProducts && (
                <CircularProgress />
              )}
              {tabValue === 0 && !isFetchingCollections && allCollectionList?.length === 0 && (
                <p className="select-none text-gray-600">No collections available.</p>
              )}
              {tabValue === 1 && !isFetchingProducts && allProductList?.length === 0 && (
                <p className="select-none text-gray-600">No products available.</p>
              )}
            {tabValue === 0 &&
              allCollectionList?.length > 0 &&
              allCollectionList.map((dt) => (
                <div key={dt.id} className="w-full border rounded-md shadow-sm">
                  <div className="flex justify-between bg-[#2C6D47] p-4 rounded-t-md">
                    <div className="text-white flex items-center">
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        id={`location-pair-${dt?.id}`}
                        checked={isCheckedCollections(dt)}
                        onChange={(e) => handleSelectedHomePosCollection(e.target.checked, dt)}
                      />
                      <label className="text-lg ml-2" htmlFor={`location-pair-${dt.id}`}>
                        <p>{dt?.name}</p>
                      </label>
                    </div>
                  </div>
                  <div className="flex gap-x-2 min-h-[150px] p-2 justify-center items-center">
                    <img className={`scale-90 max-h-[150px] origin-top-left ${!dt?.thumbnail && 'grayscale'}`} src={dt?.thumbnail || BzThumbnail} />
                  </div>
                </div>
              ))}

            {tabValue === 1 &&
              allProductList?.length > 0 &&
              allProductList.map((dt) => (
                <div key={dt.id} className="w-full border rounded-md shadow-sm">
                  <div className="flex justify-between bg-[#2C6D47] p-4 rounded-t-md">
                    <div className="text-white flex items-center">
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        id={`location-pair-${dt?.id}`}
                        checked={isChecked(dt)}
                        onChange={(e) => handleSelectedHomePosProduct(e.target.checked, dt)}
                      />
                      <label className="text-lg ml-2" htmlFor={`location-pair-${dt.id}`}>
                        <p>{dt.product.name}</p>
                      </label>
                    </div>
                  </div>
                  <div className="flex gap-x-2 min-h-[150px] p-2 justify-center items-center">
                    <img className={`scale-90 max-h-[150px] origin-top-left ${!dt?.product?.thumbnail && 'grayscale'}`} src={dt?.product?.thumbnail || BzThumbnail} />
                  </div>
                </div>
              ))}
            </div>
          </Box>
        </div>
      </div>
      <div className="w-[30%] space-y-5">
        <div className="w-full">
          <div>
            <InputLabel>{tabValue === 0 ? 'Search Collections' : 'Search Products'}</InputLabel>
          </div>
          <InputBase
            size="small"
            placeholder="search"
            endAdornment={<Search color="disabled" />}
            onChange={(v) => setSearchValue(v.target.value)}
            value={searchValue}
            className="w-full h-[2.5rem] border border-[#BCB9B9] rounded px-4 bg-white hover:border-[#adacac]"
          />
        </div>
        <div className="w-full flex justify-end gap-x-3 pb-2 border-b">
          <button
            className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
            variant="contained"
            onClick={sumbitIndexingHomePos}
          >
            Submit Indexing
          </button>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="homePosData">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-3 px-2 max-h-[800px] overflow-y-auto">
                {homePosData.map((dt, idx) => (
                  <Draggable key={dt.index} draggableId={dt.index.toString()} index={idx}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="w-full flex gap-x-2 items-center">
                        <p className="font-semibold text-lg">{`${idx + 1}.`}</p>
                        <div className={`w-full flex justify-between p-4 items-center text-white rounded-md shadow-sm ${dt?.product ? 'bg-[#2C6D47]' : 'bg-red-400'}`}>
                          <div className="flex items-center">
                            <input
                              type="checkbox"
                              className="h-4 w-4"
                              checked={isChecked(dt)}
                              onChange={(e) => handleSelectedHomePos(e.target.checked, dt.id, dt)}
                              id={`location-pair-${dt.id}`}
                            />
                            <label className="text-lg ml-2" htmlFor={`location-pair-${dt.id}`}>
                              {dt?.collection?.name || dt?.product?.name}
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}

export default OutletHomeScreenIndexing;
