import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { CircularProgress } from '@mui/material';

import { getActiveClientDetail } from '../store/slices/clientSlice';
import { getActiveSiteDetail } from '../store/slices/content/contentSlice';
import languageApi from '../api/language';
import clientApi from '../api/client';
import { getActiveBusinessUnitDetail } from '../store/slices/accounting/accountingSlice';

const { getAllLanguage } = languageApi();
const { getAllClient } = clientApi();

export default function AuthProvider({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const key = useSelector((state) => state.auth.key);
  const isSuperUser = useSelector((state) => state.auth.isSuperUser);
  const client = useSelector((state) => state.auth.client);
  const user = useSelector((state) => state.auth.user);
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeDetailClient = useSelector((state) => state.client.activeDetailClient);
  const activeSite = useSelector((state) => state.content.activeSite);
  const activeSiteDetail = useSelector((state) => state.content.activeSiteDetail);
  const activeBusinessUnit = useSelector((state) => state.acc.activeBusinessUnit);
  const activeBusinessUnitDetail = useSelector((state) => state.acc.activeBusinessUnitDetail);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const initialize = useRef(false);

  useEffect(() => {
    if (!isAuthenticated && client && key && user) {
      dispatch({ type: 'auth/setAuthenticated' });
    }
  });

  const {
    isFetching,
    isFetched,
    data: dataLanguages,
  } = useQuery({
    queryKey: ['introspect'],
    enabled: !!key && isAuthenticated,
    queryFn: () => getAllLanguage({ key }),
    onSuccess: (res) => {
      if (res.data?.results) {
        dispatch({ type: 'lang/setLanguageList', payload: res.data.results });
      }
    },
    onError: () => {
      dispatch({ type: 'auth/setLogout' });
      navigate('/login');
    },
  });

  useQuery({
    queryKey: ['mainClients'],
    enabled: !!key && isFetched && !!dataLanguages && isSuperUser,
    queryFn: () => getAllClient({ key }),
    onSuccess: (res) => {
      if (res.data?.results) {
        dispatch({ type: 'client/setClientList', payload: res.data.results });
      }
    },
  });

  if (isFetched && dataLanguages && !initialize.current) {
    if (activeClient && !activeDetailClient) {
      dispatch(getActiveClientDetail(activeClient));
    } else if (client?.id) {
      dispatch(getActiveClientDetail(client?.id));
      dispatch({ type: 'client/setActiveClient', payload: client?.id });
    }
    if (activeSite && !activeSiteDetail) {
      dispatch(getActiveSiteDetail(activeSite));
    }
    if (activeBusinessUnit && !activeBusinessUnitDetail) {
      dispatch(getActiveBusinessUnitDetail(activeBusinessUnit));
    }
    initialize.current = true;
  }

  if (isFetching || (isFetched && dataLanguages && !initialize.current) || (isFetched && dataLanguages && initialize.current && !activeDetailClient)) {
    return (
      <div className="flex items-center justify-center absolute  h-[100vh] w-[100vw]">
        <CircularProgress className="!text-[#2C6D47] " />
      </div>
    );
  }

  return children;
}
