/* eslint-disable */
import { ArrowLeft, ArrowRight, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Skeleton, Typography, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import store from '../../store/index';
import { getActiveTemplateStructure } from '../../store/slices/content/contentSlice';

import sidebarItemTemp from './Item/sidebarItemTemp';
import sidebarTheme from './Theme/sidebarTheme';

import styles from './Sidebar.module.css';

import 'react-tooltip/dist/react-tooltip.css';
import useGet from '../../hooks/useGet';
import { handleCloseDrawer, handleSideBar } from '../../store/slices/sidebarTaskSlice';
import SidebarBusinessUnits from './Item/SidebarBusinessUnits';
import SidebarBusinessUnitsSettings from './Item/SidebarBusinessUnitsSettings';
import SidebarCRMProject from './Item/SidebarCRMProject';
import SidebarProject from './Item/SidebarProject';
import RenderItems from './RenderItems';
import SideLink from './SideLink';
// import { resetFilter } from '../../store/slices/filterComponentSlice';
import clsxm from '@/utils/clsxm';
import { BsChevronDoubleLeft } from 'react-icons/bs';
import fullLogo from '../../assets/full-logo.svg';
import simple from '../../assets/simple.png';
import { PathHandler } from '@/utils/pathHandler';
import slugify from '@sindresorhus/slugify';

function filterArr(arr) {
  if (arr.length > 0) {
    return arr.reduce((a, b) => a.filter((c) => b.includes(c)));
  }
  return false;
}

const defaultStyleDynamicChild = (arr) => {
  const h = arr.length > 4 ? '10rem' : `calc(${arr.length * 3}rem)`;
  return { height: h };
};

const finalTheme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: '#FAFAFA',
          boxShadow: 'none',
          margin: '0px',
          position: 'unset',
          borderBottom: '1px solid #F4F1EC',
          '&.Mui-expanded': {
            margin: '0px',
            background: '#FFF',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          background: '#FAFAFA',
          content: { margin: '20px' },
          '&.Mui-expanded': {
            background: '#FFF',
            margin: '0px',
            color: '#f16b26',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          background: '#FAFAFA',
          padding: '0px ',
        },
      },
    },
  },
});

export default function Sidebar(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const splitPath = location.pathname.split('/');
  const { isLoaded } = props;

  const { lastPath } = PathHandler();
  const sideBarItem = sidebarItemTemp();

  const [currLocation, setCurrLocation] = useState('/');
  const [sidebarItems, setSidebarItems] = useState(false);
  const [activeClient, setActiveClient] = useState(useSelector((state) => state.client.activeClient) || '');
  const [activeSubs, setActiveSubs] = useState(useSelector((state) => state.client.clientSubscriptions || []));

  const [activeTemplate, setActiveTemplate] = useState(useSelector((state) => (state.content.activeTemplate && Number(state.content.activeTemplate)) || ''));

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isSuperUser = useSelector((state) => state.auth.isSuperUser);
  const isGroups = useSelector((state) => state.auth.isGroups);
  const clientSubs = useSelector((state) => state.client.clientSubscriptions);
  const activeDetailClient = useSelector((state) => state.client.activeDetailClient);
  const activeTemplateStructure = useSelector((state) => state.content.activeTemplateStructure);
  const templateList = useSelector((state) => state.content.templateList);

  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const isSidebar = useSelector((state) => state.sidebar);

  const client = useSelector((state) => state.auth.client);
  const profileClient = useSelector((state) => state.client);
  const clientId = client.id;

  // const [stateDraw, setStateDraw] = React.useState(false);
  const stateDraw = isSidebar.isOpenDrawer;
  const [childItm, setChildItm] = React.useState({ id: '', item: null, idx: null });

  const handleActiveTemplate = useCallback(
    (templateId) => {
      setActiveTemplate(templateId);
      store.dispatch({
        type: 'content/setActiveTemplate',
        payload: templateId,
      });
      dispatch(getActiveTemplateStructure(templateId));
    },
    [dispatch]
  );

  const resetAllCustomerState = () => {
    store.dispatch({ type: 'crmFilter/resetState' });
    store.dispatch({ type: 'crmProjectFilter/resetCrmProjectState' });
  };

  const crmProjectProfile = (id) => {
    store.dispatch({
      type: 'crmProjectFilter/setFilterOnTable',
      payload: {
        on_project_list: true,
        max_size: true,
        ordering: '-id',
        search: '',
        profile: id || '',
        allData: id !== '' ? false : true,
      },
    });
  };

  const renderItemsAccordion = (itm, child, style, action) => {
    const linkStyle = slugify(itm?.name) === lastPath ? '!text-[#f16b26]' : '';
    return (
      <ThemeProvider theme={finalTheme}>
        <Accordion key={itm.name} className="!m-0 ">
          <AccordionSummary
            className={[style, 'px-4 !min-h-fit !my-3'].join(' ')}
            expandIcon={<ExpandMore color="#c2c7d0" />}
            aria-controls={itm.name}
            id={itm.name}
            onClick={action}
          >
            {/* {itm.icon} */}
            <Typography className={clsxm('ml-2 ', linkStyle)}>{itm.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex flex-col space-y-4">{child}</div>
          </AccordionDetails>
        </Accordion>
      </ThemeProvider>
    );
  };

  const renderItemChilds = useCallback(
    (child, activeRoute, style) => {
      let html = <SideLink child={child} activeRoute={activeRoute} style={style} config={{ onClick: resetAllCustomerState }} />;

      return html;
    },

    [renderItemsAccordion, navigate, isSuperUser, isGroups, childItm]
  );

  const renderItemSidebar = useCallback(
    (item, style) => {
      const itemChilds = [];
      if (item.child.some((val) => 'client' in val)) {
        item.child?.map((child) => {
          const checkChild = 'client' in child;
          let html;

          const activeRoute = child.path === location.pathname ? styles.activeRoute : '';
          if (child.isSidebar && ((checkChild && child?.client.includes(+activeClient)) || !checkChild)) {
            if (child.child?.length > 0) {
              const itmChilds = renderItemSidebar(child, 'pl-12');
              html = renderItemsAccordion(child, itmChilds, 'min-h-0 max-h-7 pl-12 pr-4');
            } else {
              html = renderItemChilds(child, activeRoute, style);
            }
          }
          return itemChilds.push(html);
        });
      } else {
        item.child?.map((child) => {
          const subs = [child.subscriptions, activeSubs];
          const groups = [child.privilege, isGroups];
          let html;

          const activeRoute = child.path === location.pathname ? styles.activeRoute : '';
          if (child.isSidebar && ((isSuperUser && filterArr(subs).length > 0) || (!isSuperUser && filterArr(groups).length > 0))) {
            if (child.child?.length > 0) {
              const itmChilds = renderItemSidebar(child, 'pl-12');

              html = renderItemsAccordion(child, itmChilds, 'min-h-0 max-h-7 pl-12 pr-4');
            } else {
              html = renderItemChilds(child, activeRoute, style);
            }
          }
          return itemChilds.push(html);
        });
      }

      return itemChilds;
    },
    [activeSubs, isGroups, location.pathname, isSuperUser, renderItemsAccordion, renderItemChilds, childItm]
  );

  const memoizedDraw = useMemo(() => stateDraw, [stateDraw]);

  const renderSidebar = useCallback(
    (items) => {
      const allItems = items?.map((itm, idx) => {
        const subs = [itm.subscriptions, activeSubs];
        const groups = [itm.privilege, isGroups];

        let html;
        const itemChilds = renderItemSidebar(itm, 'pl-12');
        if ((isSuperUser && filterArr(subs).length > 0) || (!isSuperUser && filterArr(groups).length > 0)) {
          isSidebar.activeSide === itm.name && setChildItm({ id: itm.name, item: itemChilds });
          html = (
            <RenderItems
              idx={idx}
              sidebarItems={sidebarItems}
              activeSide={childItm.id}
              childItm={childItm}
              setChildItm={setChildItm}
              // setStateDraw={setStateDraw}
              stateDraw={memoizedDraw}
              itm={itm}
              child={itemChilds}
            />
          );
        }
        return html;
      });
      return allItems;
    },
    [activeSubs, isGroups, isSuperUser, renderItemSidebar, childItm, memoizedDraw]
  );

  useEffect(() => {
    let itm = [];
    if (sidebarItems) {
      itm = sidebarItems;
    }

    setTimeout(() => {
      if (isAuthenticated && activeDetailClient && (activeSubs.length > 0 || isGroups.length > 0) && (!sidebarItems || itm.length === 0)) {
        if (isSuperUser) {
          setCurrLocation(location.pathname);
          const items = renderSidebar(sideBarItem);
          setSidebarItems(items);
        } else if (!isSuperUser) {
          setCurrLocation(location.pathname);
          const items = renderSidebar(sideBarItem);
          setSidebarItems(items);
        }
      }
    }, 100);
  }, [isSuperUser, isGroups, activeSubs, clientSubs, sidebarItems, renderSidebar, isAuthenticated, activeDetailClient]);

  useEffect(() => {
    if (clientSubs !== activeSubs) {
      setActiveSubs(clientSubs);
      setSidebarItems(false);
    }
  }, [clientSubs, activeSubs]);

  const handleChangeClient = useCallback(
    (currentValue = store.getState().client.clientSubscriptions) => {
      const previousValue = clientSubs;
      if (previousValue && currentValue && previousValue !== currentValue) {
        const items = renderSidebar(sideBarItem);
        setSidebarItems(items);
      }
    },
    [clientSubs, renderSidebar]
  );
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      handleChangeClient();
    });
    return unsubscribe;
  }, [handleChangeClient]);

  const handleChangeTemplate = useCallback(
    (currentValue = store.getState().content.activeTemplate) => {
      const previousValue = activeTemplate;
      if (previousValue && currentValue && previousValue !== currentValue) {
        handleActiveTemplate(currentValue);
      }
    },
    [activeTemplate, handleActiveTemplate]
  );
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      handleChangeTemplate();
    });
    return unsubscribe;
  }, [handleChangeTemplate]);

  useEffect(() => {
    if (templateList && !activeTemplate) {
      handleActiveTemplate(templateList[0]?.id);
    }
  }, [activeTemplate, templateList, handleActiveTemplate]);

  useEffect(() => {
    if (templateList && activeTemplate && !activeTemplateStructure) {
      handleActiveTemplate(activeTemplate);
    }
  }, [activeTemplate, activeTemplateStructure, templateList, handleActiveTemplate, navigate]);

  let act = '';
  if (templateList && templateList.length) {
    if (!act) {
      act = templateList[0].id;
    }
    if (templateList.some((item) => item.id === Number(activeTemplate))) {
      act = activeTemplate;
    }
  }

  useGet({
    url: `project-management/projects?client=${activeDetailClient?.id}&max_size=true`,
    validate: 'projectList',
    isEnabled: childItm?.id === 'Task Management',
    onSuccess: (res) => {
      const updatedSideBarItem = [...childItm.item];
      const dataUnarchived = res?.data?.results.filter((data) => data.is_archived === false);

      let html;
      const htmlProject = (
        <div id="sidebarProjectList" style={defaultStyleDynamicChild(dataUnarchived)} className={['overflow-y-auto'].join(' ')}>
          <SidebarProject dataProjects={res?.data?.results} />
        </div>
      );
      if (res?.data?.results.length) {
        html = renderItemsAccordion({ ...childItm, name: 'Projects' }, htmlProject, 'pl-12 min-h-0 max-h-7', () => navigate('/task/projects', { replace: true }));
      }

      if (childItm?.id === 'Task Management') {
        updatedSideBarItem[2] = html;
        setChildItm({ ...childItm, item: updatedSideBarItem });
      }
    },
  });

  useGet({
    url: `accounting/business_units/?client=${activeDetailClient?.id}&max_size=true`,
    validate: `business_units-${childItm?.id}`,
    isEnabled:
      (childItm?.id === 'Settings' && [2, 5, 9, 10].some((item) => activeSubs.includes(item))) ||
      (childItm?.id === 'Accounting' && [2, 5, 9, 10].some((item) => activeSubs.includes(item))),
    onSuccess: (res) => {
      const updatedSideBarItem = [...childItm.item];
      let html;
      const htmlProject = (
        <div id="sidebarBusinessUnitList" style={defaultStyleDynamicChild(res?.data?.results)} className={['overflow-y-auto'].join(' ')}>
          {childItm?.id === 'Settings' ? <SidebarBusinessUnitsSettings dataBusinessUnits={res?.data?.results} /> : <SidebarBusinessUnits dataBusinessUnits={res?.data?.results} />}
        </div>
      );

      if (childItm?.id === 'Settings') {
        if (res?.data?.results.length) {
          html = renderItemsAccordion({ ...childItm, name: 'Business Units' }, htmlProject, 'pl-12 min-h-0 max-h-7', () =>
            navigate(childItm?.id === 'Settings' ? '/settings/business-units' : '/accounting/settings/business-units', { replace: true })
          );
        } else html = renderItemChilds({ ...childItm, name: 'Business Units', path: '/settings/business-units' }, '', '');
        updatedSideBarItem[childItm?.id === 'Settings' ? 9 : 4] = html;
        setChildItm({ ...childItm, item: updatedSideBarItem });
      }
    },
  });

  useGet({
    url: `project-management/project_profiles/?client=${profileClient?.activeClient}&max_size=true`,
    validate: `CRM-${childItm?.id}`,
    isEnabled: childItm?.id === 'CRM',
    onSuccess: (res) => {
      const updatedSideBarItem = [...childItm.item];
      let html;
      const htmlProject = (
        <div id="sidebarProjectList" style={defaultStyleDynamicChild(res?.data?.results)} className={['overflow-y-auto'].join(' ')}>
          {/* <SidebarProject dataProjects={res?.data?.results} /> */}
          <SidebarCRMProject dataProjects={res?.data?.results} onClick={crmProjectProfile} />
        </div>
      );
      if (res?.data?.results.length) {
        html = renderItemsAccordion({ ...childItm, name: 'Projects' }, htmlProject, 'pl-12 min-h-0 max-h-7', () => {
          navigate('/crm/projects/project', { replace: true });
          crmProjectProfile('');
        });
      }

      if (childItm?.id === 'CRM') {
        updatedSideBarItem[3] = html;
        setChildItm({ ...childItm, item: updatedSideBarItem });
      }
    },
  });

  return (
    <>
      <div
        className={clsxm(
          'relative p-4 !overflow-auto !pt3 h-[calc(100vh-56px)] bg-[#F4F1EC] overflow-y-scroll mainSidebar',
          !isSidebarOpen ? 'w-[80px] transition-all  duration-300' : 'w-[280px] transition-all  duration-300'
        )}
      >
        {isLoaded ? (
          <div>
            <Skeleton variant="rectangular" className="mb-2" height={48} />
            <Skeleton variant="rectangular" className="mb-2" height={48} />
            <Skeleton variant="rectangular" className="mb-2" height={48} />
          </div>
        ) : (
          <ThemeProvider theme={sidebarTheme}>
            {sidebarItems}

            <div className="items-center justify-center space-x-2 flex mt-4 ">
              <button type="button" onClick={() => dispatch(handleSideBar())}>
                {isSidebarOpen ? (
                  <ArrowLeft className="rounded-full bg-gray-400 p-1 w-[30px] h-[30px]" />
                ) : (
                  <ArrowRight className="rounded-full bg-gray-400 p-1 w-[30px] h-[30px]" />
                )}
              </button>
            </div>
          </ThemeProvider>
        )}
        <div className={clsxm('absolute bottom-[32px] left-1/2 transform -translate-x-1/2', 'flex')}>
          {isLoaded ? <Skeleton className="w-1/2 h-12 m-auto" /> : <img src={isSidebarOpen ? fullLogo : simple} className="h-8 m-auto" alt="bz-publish logo" />}
        </div>
      </div>
      <div className="">
        <div
          className={clsxm(
            'bg-[#FAFAFA] h-full py-6 ',
            !stateDraw ? ' transition-all  duration-300 !-z-10 !left-[280px] w-[0px] opacity-0' : 'transition-all  duration-300 z-[100] w-[288px]  relative'
          )}
        >
          <div className="flex flex-col space-y-4">{childItm.item}</div>
          <button
            type="button"
            className={clsxm('fixed top-[90px]  border bg-mysecondary p-1 opacity-30 hover:opacity-100 cursor-pointer')}
            onClick={() => {
              setChildItm({ id: '', item: null, idx: null });
              dispatch(handleCloseDrawer());
            }}
          >
            <BsChevronDoubleLeft className="  h-[20px] w-[20px]  " />
          </button>
        </div>
      </div>
      <ReactTooltip className="!z-[10000] absolute w-fit !bg-orange-500/100 !text-white " id="tooltip-project" place="top" />
      {!isSidebar.isOpen && <ReactTooltip className="!z-[100] absolute w-fit !bg-white shadow-xl ml-2" id="tooltip-sidebar" place="right" />}
    </>
  );
}
