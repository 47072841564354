/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
// import InfiniteScroll from 'react-infinite-scroller';

import { Droppable } from '../../../../utils/Task/DragDrop';

import { ContentTask } from '../ContentTask/ContentTask';

import DetailContext from '../../../../pages/Task/Projects/DetailContext';

import styles from './ContainerTaskVertical.module.css';

// const limit = 10;
export function ContainerTasks({ sectionId, topics }) {
  const detailContext = useContext(DetailContext);
  const [currTopics, setCurrTopics] = useState(null);
  // const [page, setPage] = useState(0);
  const [isCreate, setCreate] = useState(false);
  // const [renderer, setRenderer] = useState([]);

  // const getTopic = useMemo(() => topics, [topics]);

  // const isFirstRender = useRef(true);
  const prevTopics = useRef(topics);
  const prevTimestamp = useRef(new Date());

  const compareTwoArrayOfObjects = (firstArr, secondArr) => {
    return (
      // firstArr.length === secondArr.length && firstArr.every((element1) => secondArr.some((element2) => Object.keys(element1).every((key) => element1[key] === element2[key])))
      firstArr.length === secondArr.length && firstArr.every((element1, i) => element1.id === secondArr[i].id)
    );
  };

  // const slicedTopic = useCallback(
  //   (t, reInitialize = false) => {
  //     const currTopic = t?.length ? [...t] : getTopic;
  //     const total = currTopic.length;
  //     const pg = !reInitialize ? page : 0;

  //     const selectedTopics = currTopic?.slice(pg * limit, pg > 0 ? (pg + 1) * limit : limit);
  //     const arrTopics = [].concat(!reInitialize ? currTopics || [] : [], selectedTopics);
  //     const uniqueTopics = Array.from(new Set(arrTopics.map((a) => a.id))).map((id) => {
  //       return arrTopics.find((a) => a.id === id);
  //     });
  //     const isNext = total - uniqueTopics.length > 0;
  //     // isFirstRender.current = true;
  //     setPage(isNext ? pg + 1 : 0);
  //     setCurrTopics(uniqueTopics);
  //   },
  //   [getTopic, currTopics, page]
  // );

  // const compareObj = compareTwoArrayOfObjects(prevTopics?.current || [], topics);
  // const staleTime = Math.abs((new Date().getTime() - prevTimestamp.current.getTime()) / 1000);

  // if ((currTopics && !compareObj) || (compareObj && detailContext.isRefetch && staleTime > 0.05)) {
  // slicedTopic(topics, true);
  // prevTopics.current = topics;
  // prevTimestamp.current = new Date();
  // }

  // useEffect(() => {
  //   if (isFirstRender.current) {
  //     isFirstRender.current = false;
  //   }

  //   // slicedTopic(getTopic, true);
  //   // prevTopics.current = getTopic;
  //   // prevTimestamp.current = new Date();
  //   // const total = getTopic.length;
  //   // const pg = 0;
  //   // const selectedTopics = getTopic?.slice(pg * limit, pg > 0 ? (pg + 1) * limit : limit);
  //   // const arrTopics = [].concat([], selectedTopics);
  //   // const uniqueTopics = Array.from(new Set(arrTopics.map((a) => a.id))).map((id) => {
  //   //   return arrTopics.find((a) => a.id === id);
  //   // });
  //   // const isNext = total - uniqueTopics.length > 0;
  //   // isFirstRender.current = true;
  //   // setPage(isNext ? pg + 1 : 0);
  //   // setCurrTopics(uniqueTopics);
  // }, [getTopic, slicedTopic, sectionId]);

  let html;
  if (topics?.length > 0) {
    html = (
      <div className="flex flex-col space-y-2 bg-transparent overflow-x-hidden">
        <Droppable droppableId={`${sectionId}`} type="card">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <div className={[styles.rootContainerVertical, 'flex flex-col space-y-2 bg-transparent overflow-x-hidden'].join(' ')}>
                {/* <InfiniteScroll
                  pageStart={1}
                  loadMore={slicedTopic}
                  hasMore={topics.length - (currTopics ? currTopics.length : 0) > 0}
                  loader={
                    <div className="w-full flex justify-center items-center py-1">
                      <div className={styles.loader} />
                    </div>
                  }
                  useWindow={false}
                > */}
                {topics.map((card, i) => (
                  <ContentTask
                    cardData={card}
                    key={[card?.id, JSON.stringify(card)].join(' ')}
                    id={card?.id}
                    text={card?.name}
                    indexTopic={i}
                    image={card?.thumbnail}
                    sectionId={sectionId}
                  />
                ))}
                {provided.placeholder}
                {/* </InfiniteScroll> */}
                {isCreate && <ContentTask sectionId={sectionId} isCreate={isCreate} callbackCreateTask={(param) => setCreate(param)} topicsLength={topics.length} />}
                <Button
                  style={{ backgroundColor: '#fff', marginBottom: '16px' }}
                  className={[styles.addTask, 'shadow-md mx-auto mt-2'].join(' ')}
                  startIcon={<Add />}
                  onClick={() => setCreate(true)}
                >
                  Add Task
                </Button>
              </div>
            </div>
          )}
        </Droppable>
      </div>
    );
  } else {
    html = (
      <div className="flex flex-col space-y-2 bg-transparent" style={{ height: 'calc(100vh - 200px)' }}>
        <Droppable droppableId={`${sectionId}`} type="card">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <div className="flex flex-col">
                <ContentTask sectionId={sectionId} isEmpty isCreate={isCreate} callbackCreateTask={(param) => setCreate(param)} topicsLength={0} />
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  }
  return html;
}

export const ContainerTaskVertical = React.memo(ContainerTasks);
