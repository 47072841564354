import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';
import BaseMainTitle from '@/components/BaseMainTitle';
import BZHelmet from '@/utils/BZHelmet';
import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { ArrowBack, ExpandMore, GridView, ViewList } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Input from '../CRM/redesigning/InputContainerRedesign';
import { Checkbox, InputBase, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { Search } from '@mui/icons-material';
import _ from 'lodash';
import { useDebounce } from 'use-debounce';
import Swal from 'sweetalert2';
import BzThumbnail from '@/assets/simple.png';
import ModalWrapper from '@/components/ui/ModalWrapper';
import { handleModal } from '@/store/slices/modalSlice';
import CollectionUpdateForm from '@/components/Form/ProductManagement/CollectionUpdateForm';
import CancelIcon from '@mui/icons-material/Cancel';
import { Link } from 'react-router-dom';

function CollectionDetail() {
  const { register, handleSubmit, control, errors, setValue } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const navigate = useNavigate();
  const { collectionId } = useParams();
  const dispatch = useDispatch();
  const activeModal = useSelector((state) => state.modal.modalId);

  const paramProducts = new URLSearchParams(`client=${clientId}&max_size=true`);
  const paramCollections = new URLSearchParams(`client=${clientId}&max_size=true`);

  const [collectionData, setCollectionData] = useState(null);
  const [productList, setProductList] = useState([]);
  const [pickProducts, setPickProducts] = useState([]);
  const [productMediaShow, setProductMediaShow] = useState(null);
  const [collectionList, setCollectionList] = useState([]);
  const [pickCollections, setPickCollections] = useState([]);
  const [allCollections, setAllCollections] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [isFetchingProducts, setIsFetchingProducts] = useState(true)
  const [isFetchingCollections, setIsFetchingCollections] = useState(true)

  const [collectionsSearch, setCollectionsSearch] = useState('');
  const [productsSearch, setProductsSearch] = useState('');
  const [collectionsearchValue] = useDebounce(collectionsSearch, 300);
  const [productsearchValue] = useDebounce(productsSearch, 300);

  const [isGridCollections, setIsGridCollections] = useState(false);
  const [isGridProducts, setIsGridProducts] = useState(false);

  const [mode, setMode] = useState('');
  useEffect(() => {
    if (collectionId === undefined) {
      setMode('add');
    } else {
      setMode('edit');
    }
  }, [collectionId]);

  useEffect(() => {
    if (activeModal === '') {
      setCollectionsSearch('');
      setProductsSearch('');
      setAllCollections([]);
      setAllProducts([]);
    }
  }, [activeModal]);

  const collectionDetail = useCallback(async () => {
    const { getCollectionDetail } = ProductManagementApi();

    try {
      const getData = await getCollectionDetail(collectionId);
      const data = await getData.data;

      const { collections, products, thumbnail } = data;

      setCollectionData(data);

      if (products) {      
        const sortingProducts = products.sort((a, b) => a.name.localeCompare(b.name));
      
        setProductList(sortingProducts);
        setPickProducts(sortingProducts.map((dt) => dt.id));
      }

      if (thumbnail) {
        setProductMediaShow({ preview: thumbnail });
      }

      if (collections) {
        const sortingCollections = collections.sort((a, b) => a.name.localeCompare(b.name));
        setCollectionList(sortingCollections);
        setPickCollections(sortingCollections.map((dt) => dt.id));
      }

      setValue('name', data?.name || '');
    } catch (error) {
      console.error(error);
    }
  }, [collectionId]);

  useEffect(() => {
    if (mode === 'edit') {
      collectionDetail();
    }
  }, [collectionId, clientId, mode]);

  const getAllCollections = useCallback(
    async (paramCollections) => {
      const { getCollections } = ProductManagementApi();

      try {
        const getData = await getCollections(paramCollections);
        const data = await getData.data;

        setAllCollections(collectionData === null ? data?.results : data?.results?.filter((dt) => dt.id !== +collectionData.id));
        setIsFetchingCollections(false);
      } catch (error) {
        console.error(error);
      }
    },
    [collectionId, paramCollections]
  );

  const getAllProducts = useCallback(
    async (paramProducts) => {
      const { getProductList } = ProductManagementApi();

      try {
        const getData = await getProductList(paramProducts);
        const data = await getData.data;

        setAllProducts(data?.results);
        setIsFetchingProducts(false);
      } catch (error) {
        console.error(error);
      }
    },
    [collectionId, paramProducts]
  );

  useEffect(() => {
    const params = new URLSearchParams(`client=${clientId}&max_size=true`);

    if (collectionsearchValue) {
      params.set('search', collectionsearchValue.split(' ').join('+'));
    }

    getAllCollections(params);
    setIsFetchingCollections(true);
  }, [collectionsearchValue]);

  useEffect(() => {
    const params = new URLSearchParams(`client=${clientId}&max_size=true`);

    if (productsearchValue) {
      params.set('search', productsearchValue.split(' ').join('+'));
    }

    getAllProducts(params);
    setIsFetchingProducts(false);
  }, [productsearchValue]);

  const handleMediaUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageSrc = URL.createObjectURL(file);

      setProductMediaShow({ file: file, preview: imageSrc });
    }
  };

  const handleRemoveImage = () => {
    setProductMediaShow({ preview: '' });
  };

  const validationSubmit = (list, pick, modalMode) => {
    if (modalMode === 'collections') {
      setCollectionList(list);
      setPickCollections(pick);
    }

    if (modalMode === 'products') {
      setProductList(list);
      setPickProducts(pick);
    }

    // if (mode === "edit") {
    //   submitCollection({
    //     name: collectionData?.name,
    //     client: clientId,
    //     products: modalMode === "products" ? pick : pickProducts,
    //     collections: modalMode === "collections" ? pick : pickCollections
    //   });
    // }
  };

  const showErrorAlert = (error) => {
    let errorMessages = 'An unexpected error occurred:<br>';

    if (error.response && error.response.data) {
      const errorData = error.response.data;
      for (const [field, messages] of Object.entries(errorData)) {
        const formattedMessages = messages.join(', ');
        errorMessages += `<strong>${field}:</strong> ${formattedMessages}<br>`;
      }
    } else {
      errorMessages += `<b>${error.message}</b>`;
    }

    Swal.fire({
      title: 'Error',
      html: errorMessages,
      icon: 'error',
    });
  };

  const submitCollection = async (data) => {
    const { updateCollectionDetail, addNewCollection } = ProductManagementApi();

    const newData = {
      name: data?.name || collectionData?.name,
      client: clientId,
      products: data?.products || [],
      collections: data?.collections || [],
      ...(productMediaShow !== null && productMediaShow?.file && { thumbnail: productMediaShow?.file }),
    };

    try {
      const formData = new FormData();
      for (const key in newData) {
        if (Array.isArray(newData[key])) {
          newData[key].forEach((item, index) => {
            formData.append(`${key}`, item);
          });
        } else {
          formData.append(key, newData[key]);
        }
      }
      if (mode === 'add') {
        if (newData.name === '') {
          Swal.fire({
            title: 'Error!',
            text: 'Name cannot be empty!',
            icon: 'error',
          });
          return;
        }

        await addNewCollection(formData)
          .then((result) => {
            const { id } = result?.data;
            Swal.fire({
              title: 'Success!',
              text: 'Successfully added new Collection!',
              icon: 'success',
            }).then(() => {
              return navigate(`/product-management/collections/detail/${id}`);
            });
          })
          .catch((error) => {
            showErrorAlert(error);
          });
      } else {
        await updateCollectionDetail(collectionId, formData)
          .then((result) => {
            const { id } = result?.data;
            Swal.fire({
              title: 'Success!',
              text: 'Successfully updated Collection!',
              icon: 'success',
            }).then(() => {
              // return navigate(`/product-management/collections`);
            });
          })
          .catch((error) => {
            showErrorAlert(error);
          });
      }
    } catch (error) {
      showErrorAlert(error);
    }
  };

  const InputLabel = ({ children }) => <p className="font-bold">{children}</p>;

  console.log({productList});
  

  return (
    <div className="w-full h-full bg-white">
      <BZHelmet title={mode === 'add' ? 'Add Collections' : 'Collection Detail'} description="" content="" />
      <BaseMainTitle title={mode === 'add' ? 'Add Collections' : 'Collection Detail'} />
      <div className="p-5 space-y-6">
        <div className="w-full flex justify-between">
          <button
            className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
            type="text"
            name="Back to list"
            onClick={() => navigate('/product-management/collections')}
          >
            <ArrowBack />
            Back To List
          </button>
          <div className="flex gap-x-2">
            <button
              className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
              type="text"
              name="Pair"
              onClick={() => {
                getAllProducts(paramProducts);
                dispatch(
                  handleModal({
                    modalId: clientId,
                    componentName: 'updateProducts',
                  })
                );
              }}
            >
              Update Products
            </button>
            <button
              className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
              type="text"
              name="Pair"
              onClick={() => {
                getAllCollections(paramCollections);
                dispatch(
                  handleModal({
                    modalId: clientId,
                    componentName: 'updateCollections',
                  })
                );
              }}
            >
              Update collections
            </button>
          </div>
        </div>
        <form
          onSubmit={handleSubmit((data) =>
            submitCollection({
              ...data,
              products: pickProducts,
              collections: pickCollections,
            })
          )}
        >
          <div className="w-[20%] space-y-2 mb-4">
            <div className="w-full h-[250px] relative border border-black">
              {productMediaShow?.preview && (
                <CancelIcon className="absolute right-[2px] top-[2px] cursor-pointer" sx={{ color: 'red', height: '20px', width: '20px' }} onClick={handleRemoveImage} />
              )}
              <label className="bg-white w-full h-full flex items-center justify-center cursor-pointer">
                {productMediaShow?.preview ? (
                  <img
                    src={productMediaShow?.preview ? productMediaShow?.preview : ''}
                    alt=""
                    style={{ width: '100%', height: '250px', objectFit: 'contain', border: '0.5px solid black' }}
                    className="bg-white"
                  />
                ) : (
                  <span className="text-gray-500">Click to upload Thumbnail</span>
                )}
                <input type="file" style={{ display: 'none' }} name="uploaded_logo" accept="image/*" onChange={(e) => handleMediaUpload(e)} />
              </label>
            </div>
          </div>
          <div className="flex gap-x-8">
            <div className="w-full space-y-4">
              <div className="w-1/2 h-fit">
                <InputLabel>Collection Name</InputLabel>
                <Input
                  defaultValue={collectionData?.name && collectionData?.name}
                  label="name"
                  name="name"
                  control={control}
                  // disabled={mode === "edit"}
                  // onChangeValue={onChangeValue}
                />
              </div>
              <div className="space-y-12">
                <div className="w-full h-fit space-y-4">
                  <InputLabel>Collections Picked: </InputLabel>
                  {
                    collectionList.length > 0 && (
                      <div name="grid-icon" className="mb-1 w-fit">
                        <div className="space-x-2 justify-end md:justify-normal items-end">
                          <Tooltip className="cursor-pointer">
                            {isGridCollections ? <ViewList className="scale-125" onClick={() => setIsGridCollections(false)} /> : <GridView className="scale-125" onClick={() => setIsGridCollections(true)} />}
                          </Tooltip>
                        </div>
                      </div>
                    )
                  }
                  <div className="flex flex-wrap gap-4">
                    {collectionList.length > 0 ? (
                      !isGridCollections ? (                        
                        collectionList.map((dt) => (
                          <div key={dt.id} className="w-1/6 border rounded-md shadow-sm">
                            <Link to={`/product-management/collections/detail/${dt.id}`} className="flex justify-between bg-[#2C6D47] hover:bg-[#2C6D47] p-4 rounded-t-md">                          
                              <p className="text-lg ml-2 text-white" htmlFor={`product-detail-${dt.id}`}>
                                {dt.name}
                              </p>
                            </Link>
                            <div className="flex gap-x-2 min-h-[150px] p-2 justify-center items-center">
                              <img className={`scale-90 max-h-[150px] origin-top-left ${!dt?.thumbnail && 'grayscale'}`} src={dt?.thumbnail || BzThumbnail} />
                            </div>
                          </div>
                        ))
                      ): (
                        <div className='border w-[20rem]'>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align='center' className='!font-bold'>Name</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              collectionList.map((dt) => (
                                  <TableRow key={dt.id}>
                                    <TableCell align='left'>
                                        <Link to={`/product-management/collections/detail/${dt.id}`} className="text-bz-green font-semibold" onClick={() => setIstFetching(true)}>
                                          <p>{dt.name}</p>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </div>
                      )
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                </div>
                <div className="w-full h-fit space-y-4">
                  <InputLabel>Products Picked: </InputLabel>
                  {
                    productList.length > 0 && (
                      <div name="grid-icon" className="mb-1 w-fit">
                        <div className="space-x-2 justify-end md:justify-normal items-end">
                          <Tooltip className="cursor-pointer">
                            {!isGridProducts ? <ViewList className="scale-125" onClick={() => setIsGridProducts(true)} /> : <GridView className="scale-125" onClick={() => setIsGridProducts(false)} />}
                          </Tooltip>
                        </div>
                      </div>
                    )
                  }
                  <div className="flex flex-wrap gap-4">
                    {productList.length > 0 ? (
                      !isGridProducts ? (
                        productList.map((dt) => (
                          <div key={dt.id} className="w-1/6 border rounded-md shadow-sm">
                            <Link to={`/product-management/products/detail/${dt.id}`} className="flex justify-between bg-[#2C6D47] hover:bg-[#1f4e33] p-4 rounded-t-md">                          
                              <p className="text-lg ml-2 text-white" htmlFor={`product-detail-${dt.id}`}>
                                {dt.name}
                              </p>
                            </Link>
                            <div className="flex gap-x-2 min-h-[150px] p-2 justify-center items-center">
                              <img className={`scale-90 max-h-[150px] origin-top-left ${!dt?.media_main && 'grayscale'}`} src={dt?.media_main || BzThumbnail} />
                            </div>
                          </div>
                        ))
                      ): (
                        <div className='border w-[20rem]'>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell align='center' className='!font-bold'>Name</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {
                                productList.map((dt) => (
                                    <TableRow key={dt.id}>
                                      <TableCell align='left'>
                                          <Link to={`/product-management/products/detail/${dt.id}`} className="text-bz-green font-semibold" onClick={() => setIstFetching(true)}>
                                            <p>{dt.name}</p>
                                          </Link>
                                      </TableCell>
                                  </TableRow>
                                ))
                              }
                            </TableBody>
                          </Table>
                        </div>
                      )
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end border-t mt-6 pt-2">
            <button
              className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
              type="submit"
              name="submit data Collection"
            >
              {mode === 'add' ? 'Add New Collection' : 'update collection'}
            </button>
          </div>
        </form>
      </div>
      <ModalWrapper componentName="updateCollections" header="Update Collections" maxWidth="xl">
        <CollectionUpdateForm
          searchLabel="Search Collections"
          listOfData={allCollections}
          onSearch={setCollectionsSearch}
          search={collectionsSearch}
          checked={pickCollections}
          listOfCollectionPaired={collectionList}
          onSubmit={validationSubmit}
          mode={mode}
          modalMode="collections"
          isFetching={isFetchingCollections}
        />
      </ModalWrapper>
      <ModalWrapper componentName="updateProducts" header="Update Products" maxWidth="xl">
        <CollectionUpdateForm
          searchLabel="Search Products"
          listOfData={allProducts}
          onSearch={setProductsSearch}
          search={productsSearch}
          checked={pickProducts}
          listOfCollectionPaired={productList}
          // onSubmit={mode === "add" ? validationSubmit : submitCollection}
          onSubmit={validationSubmit}
          mode={mode}
          modalMode="products"
          isFetching={isFetchingProducts}
        />
      </ModalWrapper>
    </div>
  );
}

export default CollectionDetail;
