/* eslint-disable */
import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSearchParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import CircleIcon from '@mui/icons-material/Circle';
import Paper from '@mui/material/Paper';
import SvgIcon from '@mui/material/SvgIcon';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { GridView, Search, ViewList } from '@mui/icons-material';
import { InputBase, Tooltip } from '@mui/material';
import swal from 'sweetalert';
import moment from 'moment';
import _ from 'lodash';
import { handleModal } from '../../store/slices/modalSlice';
import { crmFilterActions } from '../../store/crmFilter';
import SelectItem from '../../components/Input/SelectItem';
import MuiSelectSingleItem from '../../components/Input/MuiSelectSingleItem';
import CustomSelectItem from '../../components/Input/CustomSelect';
import VendorTable from './VendorTable';
import ProcurementApi from '../../api/Procurement/Procurement.js';
import ModalWrapper from '../../components/ui/ModalWrapper';
import CustomSelect from './redesigning/CustomSelectRedesign';
import AddContact from '../../components/Form/Procurement/AddContact';
import BulkChange from '../../components/Form/Procurement/BulkChange';
import { exportToExcel, exportToExcelMultiple } from '../../helper/exportToExcel';
import BaseMainTitle from '../../components/BaseMainTitle';
import BZHelmet from '../../utils/BZHelmet';
import store from '../../store';
import SelectAutocomplete from './redesigning/SelectAutocompleteRedesign';
import CustomerLocations from './VendorLocations';

export default function Vendor() {
  const { control, reset, resetField } = useForm();

  const boxBackgroundColor = ['#2C6D47', '#FC581D'];

  const clientId = useSelector((state) => state.client.activeClient);
  const crmFilterActives = useSelector((state) => state.CrmFilter);

  const activeUser = useSelector((state) => state.auth.user);
  const isSuperUser = activeUser.is_superuser;
  const userGroup = activeUser.groups?.map((a) => +a.id) || [];
  const isOnlyUser = isSuperUser === true ? false : userGroup.includes(17) ? true : false;

  const dispatch = useDispatch();

  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState({ companies: crmFilterActives?.membersTablePage, person: 0, activities: 0 });
  const [rowsPerPage, setRowsPerPage] = useState({ companies: crmFilterActives?.membersTableRowsPerPage, person: 15, activities: 15 });
  const [counts, setCounts] = useState([]);
  const [dataCount, setDataCount] = useState({ companies: 0, person: 0, activities: 0 });
  const [allMembers, setAllMembers] = useState([]);
  const [allActivities, setAllActivities] = useState([]);
  const [allMembersMaxSize, setAllMembersMaxSize] = useState([]);
  const [allContacts, setAllContacts] = useState([]);
  const [allAssignedContacts, setAllAssignedContacts] = useState([]);
  const [attributeFilters, setAttributeFilters] = useState([]);
  const [attributeSorting, setAttributeSorting] = useState([]);
  const [attributeOptions, setAttributeOptions] = useState({});
  const [countries, setCountries] = useState([]);
  const [isDataLoad, setIsDataLoad] = useState(false);
  const [reloadContact, setReloadContact] = useState(false);
  const [reloadCompany, setReloadCompany] = useState(false);
  const [reloadActivities, setReloadActivities] = useState(false);
  const [selected, setSelected] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isExporting, setIsExporting] = useState({companies: false, activities: false});
  const [isBulkChangeActive, setIsBulkChangeActive] = useState(false);
  const [member_ids, setMember_ids] = useState([]);
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [isGrid, setIsGrid] = useState(true);

  // STATE FILTER COMPANIES
  const [searchCompanies, SetSearchCompanies] = useState('');
  const [sortingMembers, SetSortingMembers] = useState('');
  const [filterByCountries, SetfilterByCountries] = useState([]);
  const [dynamicFiltersCompanies, SetdynamicFiltersCompanies] = useState(Object.keys(crmFilterActives?.dynamicFilter).length > 0 ? crmFilterActives?.dynamicFilter : {});

  console.log("crmFilterActives ", crmFilterActives);
  console.log("attributeFilters ", attributeFilters);

  // STATE FILTER PERSON
  const [searchContact, setSearchContact] = useState('');
  const [filterByCompanies, setFilterByCompanies] = useState([]);
  const [filterByContactTitles, setFilterByContactTiltes] = useState([]);

  // STATE FILTER Activities
  const [generateActivities, SetGenerateActivities] = useState(false);
  const [sortingActivities, SetSortingActivities] = useState('-timestamp');
  const [actionTypeActivities, SetActionTypeActivities] = useState('');
  const [activitiesFilterByCompanies, SetActivitiesFilterByCompanies] = useState(null);
  const [filterActivitesTimestamp, setFilterActivitesTimestamp] = useState({date_to: null, date_from: null});

  // PARAMETER
  const [paramsContact, setParamsContact] = useState({ 
    client: clientId, 
    page_size: rowsPerPage.person, 
    page: page.person + 1 
  });
  const [paramsActivities, setParamsActivities] = useState({ vendor__client: clientId, 
    ordering: sortingActivities, 
    page_size: rowsPerPage.activities, 
    page: page.activities + 1 
  });
  const [paramsCompanies, setParamsCompanies] = useState(null);

  useLayoutEffect(() => {
      const companiesQueryParams = new URLSearchParams();
      companiesQueryParams.append('page', page.companies + 1);
      companiesQueryParams.append('page_size', rowsPerPage.companies);
      companiesQueryParams.append('client', clientId);

      if (crmFilterActives.sorting !== '') {
        companiesQueryParams.append('ordering', crmFilterActives.sorting);
      }

      if (crmFilterActives.search !== '') {
        companiesQueryParams.append('search', crmFilterActives.search);
      }

      if (crmFilterActives?.countries.length > 0) {
        crmFilterActives?.countries.forEach((val) => {
          companiesQueryParams.append('countries', val.value);
        });
      }

      if (attributeFilters.length > 0) {
        if (Object.keys(crmFilterActives?.dynamicFilter).length > 0) {
          attributeFilters.forEach((attributeFilter) => {
            (crmFilterActives?.dynamicFilter[attributeFilter.id] || []).forEach((item) => {
              companiesQueryParams.append(attributeFilter.key_name, item.value);
            });
          });
        }
      }

      setParamsCompanies(companiesQueryParams);
  }, [attributeFilters]);

  useEffect(() => {
    if (paramsCompanies !== null) {
      const params = paramsCompanies;
      params.set('page', page.companies + 1);
      params.set('page_size', rowsPerPage.companies);

      setIsDataLoad(false);
      setParamsCompanies(params);
    }
  }, [page.companies, rowsPerPage.companies]);

  useEffect(() => {
    setIsDataLoad(false);
    setParamsContact((paramsContact) => ({ ...paramsContact, page_size: rowsPerPage.person, page: page.person + 1 }));
    setParamsActivities((paramsActivities) => ({ ...paramsActivities, page_size: rowsPerPage.activities, page: page.activities + 1 }));
  }, [page.person, rowsPerPage.person, page.activities, rowsPerPage.activities]);

  const fetchData = async (apiFunction, params) => {
    try {
      const res = await apiFunction(params);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  // get all counts
  const getCounts = useCallback((params = { client: clientId }) => fetchData(ProcurementApi().getCounts, params), [clientId]);

  // get companies
  const getMembers = useCallback((params) => fetchData(ProcurementApi().getAllMembers, params), [clientId]);

  // get all contact person
  const getContacts = useCallback((params) => fetchData(ProcurementApi().getAllContacts, params), [clientId, paramsContact]);

  //get all activities
  const getAllActivities = useCallback((param) => fetchData(ProcurementApi().getAllHistoriesByClientId, param), [clientId]);

  // get all companies
  const getAllMembersMaxSize = useCallback((params) => fetchData(ProcurementApi().getAllMembers, params), [clientId]);

  // get filter attributes
  const getAttributeFilters = useCallback((params) => fetchData(ProcurementApi().getAttributeFilters, {...params, attribute__category__client: clientId, max_size: true}), [clientId]);

  // get all countries for filter
  const getAllCountries = useCallback((params) => fetchData(ProcurementApi().getCountries, {...params, max_size: true}), []);

  // get contact Title
  const getAllAssignedContacts = useCallback((params = { client: clientId }) => fetchData(ProcurementApi().getAllAssignedContacts, params), [clientId]);

  const getAllActivitiesData = useCallback(async (paramsActivities) => {
    const [actData] = await Promise.all([getAllActivities(paramsActivities)]);
    if (actData) {
      setAllActivities(actData?.results)
      setDataCount((values) => ({ ...values, activities: actData.count }));
      setIsDataLoad(true);
    }
  }, [getAllActivities]);

  const getAllData = useCallback(async () => {
    const [counts, title] = await Promise.all([getCounts(), getAllAssignedContacts()]);
    if (counts && title) {
      setCounts(counts.counts);
      setAllAssignedContacts(title.results);
    }
  }, [getCounts, getAllAssignedContacts]);

  const getAllCustomer = useCallback(
    async (paramsCompanies) => {
      const [members] = await Promise.all([getMembers(paramsCompanies)]);

      if (members) {
        setAllMembers(members.results);
        setDataCount((values) => ({ ...values, companies: members.count }));
        setIsDataLoad(true);
      }
    },
    [getContacts, getMembers]
  );

  const getAllMembersData = useCallback(async () => {
    const [allMembers] = await Promise.all([getAllMembersMaxSize({ client: clientId, max_size: true })]);

    if (allMembers) {
      setAllMembersMaxSize(allMembers.results);
    }
  }, [getAllMembersMaxSize]);

  const getAllContact = useCallback(
    async (paramsContact) => {
      const [contact] = await Promise.all([getContacts(paramsContact)]);

      if (contact) {
        setAllContacts(contact.results);
        setDataCount((values) => ({ ...values, person: contact.count }));
        setIsDataLoad(true);
      }
    },
    [getContacts, getMembers]
  );

  const getAllCustomersMemberIds = useCallback(async () => {
    const params = new URLSearchParams();
    params.append('max_size', true);
    params.append('client', clientId);

    if (searchCompanies) {
      params.append('search', searchCompanies);
    }

    if (sortingMembers) {
      params.append('ordering', sortingMembers);
    }

    if (filterByCountries.length) {
      filterByCountries.forEach((value) => {
        params.append('countries', value);
      });
    }

    if (attributeFilters.length) {
      attributeFilters.forEach((attributeFilter) => {
        (dynamicFiltersCompanies[attributeFilter.id] || []).forEach((item) => {
          params.append(attributeFilter.name, item.value);
        });
      });
    }

    const [members] = await Promise.all([getMembers(params)]);

    if (members) {
      members.results.forEach((val) => {
        setMember_ids((data) => [...data, val.id]);
      });
    }
  }, [getMembers]);

  const getCountries = useCallback(async () => {
    const [countries] = await Promise.all([getAllCountries()]);
    if (countries) {
      setCountries(countries.results);
    }
  }, [getAllCountries]);

  const getFilters = useCallback(async () => {
    const [filters] = await Promise.all([getAttributeFilters()]);

    if (filters) {
      const attributeFilters = filters.results?.filter((result) => result.category === 'Filter').map((result) => result.attribute);
      const attributeSorting = filters.results?.filter((result) => result.category === 'Order').map((result) => result.attribute);

      const AllAttributes = attributeFilters;
      setAttributeFilters(AllAttributes);

      Promise.all(
        AllAttributes.map((filter) => {
          return getAttributeOptions(filter.id);
        })
      );

      const _sortingOptions = ['ID', 'Name', ...attributeSorting.filter((attr) => ['Date joined'].includes(attr.name))];

      _sortingOptions.forEach((option) => {
        const signs = ['', '-'];

        signs.forEach((sign) => {
          if (_.isObject(option)) {
            setAttributeSorting((sortingOptions) => [...sortingOptions, { name: option.name + (sign ? ' descending' : ' ascending'), value: sign + option.key_name }]);
          } else {
            let option_value = option;
            if (option === 'ID') {
              option_value = 'member_id';
            }

            setAttributeSorting((sortingOptions) => [...sortingOptions, { name: option + (sign ? ' descending' : ' ascending'), value: sign + option_value.toLowerCase() }]);
          }
        });
      });
    }
  }, [getAttributeFilters]);

  const getAttributeOptions = useCallback(
    async (attributeId) => {
      const { getAttributeOptions } = ProcurementApi();
      try {
        const attributeOptionsResponse = await getAttributeOptions(attributeId);
        const optionsData = await attributeOptionsResponse.data;
        const options = await optionsData.results;

        setAttributeOptions((prevOptions) => ({ ...prevOptions, [attributeId]: options }));
      } catch (error) {
        console.log(error);
      }
    },
    [attributeFilters]
  );

  const normalizeFilter = () => {
    SetdynamicFiltersCompanies({});
    SetSortingMembers('');
    SetfilterByCountries([]);
    SetSearchCompanies('');
    setParamsCompanies(new URLSearchParams(`page=${page.companies + 1}&page_size=${rowsPerPage.companies}&client=${clientId}`));
    reset({ countries: [] });
    resetField('sorting');
  };

  useLayoutEffect(() => {
    getAllData();
    getCountries();
    // getAllActivitiesData();
    getFilters().then(() => {
      setFiltersLoaded(true);
    });
  }, [clientId]);

  useLayoutEffect(() => {
    getAllContact(paramsContact);
  }, [clientId, paramsContact]);
  
  useLayoutEffect(() => {
    getAllActivitiesData(paramsActivities);
  }, [clientId, paramsActivities]);

  useEffect(() => {
    if (paramsCompanies !== null && filtersLoaded) {
      getAllCustomer(paramsCompanies);
    }
  }, [clientId, paramsCompanies, page.companies, rowsPerPage.companies]);

  useEffect(() => {
    // if (allMembersMaxSize.length === 0) {
      getAllMembersData();
    // }
  }, [clientId]);

  useEffect(() => {
    if (allMembers && allMembers) {
      setIsDataLoad(false);
    }
  }, [allMembers, allContacts]);

  useEffect(() => {
    const fetchData = (reloadFlag, fetchDataFunction) => {
      if (reloadFlag) {
        setIsDataLoad(false);
        fetchDataFunction();
      }
    };
  
    fetchData(reloadContact, () => getAllContact(paramsContact));
    fetchData(reloadCompany, () => getAllCustomer(paramsCompanies));
    fetchData(reloadActivities, () => getAllActivitiesData(paramsActivities));
  
    setReloadCompany(false);
    setReloadContact(false);
    setReloadActivities(false);
  }, [reloadContact, reloadCompany, reloadActivities]);

  useEffect(() => {
    if (isBulkChangeActive === true) {
      getAllCustomersMemberIds();
    } else {
      setMember_ids([]);
    }
  }, [isBulkChangeActive]);

  useEffect(() => {
    setIsBulkChangeActive(searchCompanies || filterByCountries.length || Object.entries(dynamicFiltersCompanies).length > 0 && allMembers.length > 0);
  }, [allMembers, searchCompanies, filterByCountries, dynamicFiltersCompanies]);

  const changesParamsContactFilter = () => {
    if (searchContact || filterByCompanies.length || filterByContactTitles.length) {
      setParamsContact((paramsContact) => ({ ...paramsContact, search: searchContact }));
      setParamsContact((paramsContact) => ({ ...paramsContact, member__in: filterByCompanies.map((compny) => compny.value).join(',') }));
      setParamsContact((paramsContact) => ({ ...paramsContact, contact_title: filterByContactTitles.map((contactTitle) => contactTitle.value).join(',') }));
    } else {
      setParamsContact({ client: clientId, page_size: rowsPerPage.person, page: page.person + 1 });
    }

    setIsDataLoad(false);
  };

  const changeParamsCompaniesFilter = () => {
    const params = new URLSearchParams();
    params.append('page', 1);
    params.append('page_size', rowsPerPage.companies);
    params.append('client', clientId);

    if (searchCompanies) {
      params.append('search', searchCompanies);
    }

    if (sortingMembers) {
      params.append('ordering', sortingMembers);
    }

    if (filterByCountries.length) {
      filterByCountries.forEach((val) => {
        params.append('countries', val.value);
      });
    }

    if (attributeFilters.length) {
      attributeFilters.forEach((attributeFilter) => {
        (dynamicFiltersCompanies[attributeFilter.id] || []).forEach((item) => {
          params.append(attributeFilter.key_name, item.value);
        });
      });
    }

    setIsDataLoad(false);
    setParamsCompanies(params);
  };

  const DeleteDatabyId = () => {
    const { bulkDeleteContacts, bulkDeleteMembers } = ProcurementApi();

    try {
      if (tabValue === 0) {
        bulkDeleteMembers(selected)
          .then((res) => {
            if (res.status === 200) {
              setReloadCompany(true);
              swal('Delete companies Success!', { icon: 'success' });
              setSelected([]);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }

      if (tabValue === 1) {
        bulkDeleteContacts(selected)
          .then((res) => {
            if (res.status === 200) {
              setReloadContact(true);
              swal('Delete Contacts Success!', { icon: 'success' });
              setSelected([]);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDynamicFilter = (values, idAttribute) => {
    const newDynamicFiltersCompanies = _.cloneDeep(dynamicFiltersCompanies);
    newDynamicFiltersCompanies[idAttribute] = values;

    if (values.length) {
      SetdynamicFiltersCompanies(newDynamicFiltersCompanies);
      store.dispatch({ type: 'crmFilter/setDynamicFilter', payload: newDynamicFiltersCompanies });
    } else {
      delete newDynamicFiltersCompanies[idAttribute];
      SetdynamicFiltersCompanies((value) => {
        const newData = { ...value };
        delete newData[idAttribute];
        return newData;
      });

      store.dispatch({ type: 'crmFilter/setDynamicFilter', payload: newDynamicFiltersCompanies });
    }
  };

  function handleFilterByCounts(value) {
    const theAttributeOptions = _.cloneDeep(attributeOptions);
    let result = null;
    let theKey = null;

    const params = new URLSearchParams({
      page: 0,
      page_size: rowsPerPage.companies,
      client: clientId,
    });

    if (value.name !== 'Total') {
      for (const key in theAttributeOptions) {
        const attributeData = theAttributeOptions[key].find((data) => data.name === value.name);

        if (attributeData) {
          result = { [key]: [{ label: attributeData.name, value: attributeData.name }] };
          theKey = parseInt(key);
          break;
        }
      }

      SetdynamicFiltersCompanies(result);
      store.dispatch({ type: 'crmFilter/setDynamicFilter', payload: result });

      if (theKey && attributeFilters.some((filter) => filter.id === theKey)) {
        const attributeFilter = attributeFilters.find((filter) => filter.id === theKey);
        params.append(attributeFilter.key_name, result[theKey][0].value);
      }
    } else {
      SetdynamicFiltersCompanies({});
      store.dispatch({ type: 'crmFilter/setDynamicFilter', payload: [] });
    }

    return setIsDataLoad(false), setPage((values) => ({ ...values, companies: 0 })), setParamsCompanies(params);
  }

  const handleChangePage = (event, newPage) => {
    setIsDataLoad(false);

    if (tabValue === 0) {
      setPage((values) => ({ ...values, companies: newPage }));
      store.dispatch({ type: 'crmFilter/setMembersTablePage', payload: newPage });
    } else if (tabValue === 1) {
      setPage((values) => ({ ...values, person: newPage }));
    } else {
      setPage((values) => ({ ...values, activities: newPage }));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setIsDataLoad(false);

    if (tabValue === 0) {
      setRowsPerPage((values) => ({ ...values, companies: parseInt(event.target.value, 10) }));
      setPage((page) => ({ ...page, companies: 0 }));
      store.dispatch({ type: 'crmFilter/setMembersTablePage', payload: 0 });
      store.dispatch({ type: 'crmFilter/setMembersTableRowsPerPage', payload: parseInt(event.target.value, 10) });
    } else if (tabValue === 1) {
      setRowsPerPage((values) => ({ ...values, person: parseInt(event.target.value, 10) }));
      setPage((page) => ({ ...page, person: 0 }));
    } else {
      setRowsPerPage((values) => ({ ...values, activities: parseInt(event.target.value, 10) }));
      setPage((page) => ({ ...page, activities: 0 }));
    }
  };

  const onChangeSearchCompanies = (e) => {
    setIsDataLoad(false);
    SetSearchCompanies(e.toLowerCase());
    store.dispatch({ type: 'crmFilter/setSearch', payload: e.toLowerCase() });
  };

  const onChangeSearchContact = (e) => {
    setSearchContact(e.toLowerCase());
    setIsDataLoad(false);
  };

  const handleChangeTab = (_, newValue) => {
    // if (newValue === 0) {
    //   setParamsCompanies(new URLSearchParams(`page=${page.companies + 1}&page_size=${rowsPerPage.companies}&client=${clientId}`));
    // } else {
    //   setParamsContact({ member__client: clientId, page_size: rowsPerPage.person, page: page.person + 1 });
    // }
    setTabValue(newValue);
    setSelected([]);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function getParamsMembers() {
    let params = new URLSearchParams();
    params.append('max_size', true);
    params.append('client', clientId);

    if (searchCompanies) {
      params.append('search', searchCompanies);
    }
    if (filterByCountries.length) {
      filterByCountries.forEach((value) => {
        params.append('countries', value[value]);
      });
    }
    if (attributeFilters.length) {
      attributeFilters.forEach((attributeFilter) => {
        (dynamicFiltersCompanies[attributeFilter.id] || []).forEach((item) => {
          params.append(attributeFilter.name, item.value);
        });
      });
    }

    if (sortingMembers) {
      params.append('ordering', sortingMembers);
    }

    return params;
  }

  function getFormattedContactsExcel(data) {
    return data.map((contact) => {
      return {
        Name: contact.name,
        Company: contact.member.name,
        Poisition: contact.position,
        Phone: contact.phone,
        Email: contact.email,
        'Contact Title': contact.contact_title?.name,
      };
    });
  }

  function getFormattedMembersExcel(data) {
    return data.map((item) => {
      let formattedItem = {};
      Object.keys(item).forEach((key) => {
        if (key === 'member_attributes') {
          Object.keys(item[key]).forEach((member_attribute_key) => {
            const value = item[key][member_attribute_key];
            formattedItem[member_attribute_key] = _.isArray(value) ? value.join('; ') : value;
          });
        } else if (key === 'id') {
          // pass
        } else if (key === 'member_id') {
          const value = item[key];
          formattedItem['Id'] = _.isArray(value) ? value.join('; ') : value;
        } else {
          const value = item[key];
          formattedItem[_.startCase(key)] = _.isArray(value) ? value.join('; ') : value;
        }
      });
      return formattedItem;
    });
  }

  function exportContacts(contacts) {
    const data = getFormattedContactsExcel(contacts);

    exportToExcel(data, 'Exported contacts');
  }

  function exportMembers() {
    setIsExporting((state) => ({...state, companies: true}));
    const { exportData } = ProcurementApi();
    const params = getParamsMembers();

    try {
      exportData(params)
        .then((result) => {
          let membersData = result.data;

          if (selected.length) {
            membersData = membersData.filter((member) => selected.includes(member.id));
          }

          const data = getFormattedMembersExcel(membersData);
          exportToExcel(data, 'Exported companies');
          setIsExporting((state) => ({...state, companies: false}));
        })
        .catch((err) => {
          swal('Error when get data', { icon: 'error' });
          setIsExporting((state) => ({...state, companies: false}));
          console.error(err);
        });
    } catch (error) {
      swal('Error...', { icon: 'error' });
      setIsExporting((state) => ({...state, companies: false}));
      console.error(error);
    }
  }

  function exportContactsPerCompany() {
    setIsExporting((state) => ({...state, companies: true}));
    const { getAllMembers, getAllContacts } = ProcurementApi();
    const params = getParamsMembers();

    try {
      getAllMembers(params)
        .then((res) => {
          let memberIds = res.data.results.map((member) => member.id);

          if (selected.length) {
            membersData = membersData.filter((member) => selected.includes(member.id));
          }

          let params = {
            max_size: true,
            client: clientId,
          };
          if (memberIds.length) {
            params['member__in'] = memberIds.join(',');
          }

          getAllContacts(params)
            .then((result) => {
              exportContacts(result.data.results);
              setIsExporting((state) => ({...state, companies: false}));
            })
            .catch((err) => {
              swal('Error when get contacts data', { icon: 'error' });
              setIsExporting((state) => ({...state, companies: false}));
              console.error(err);
            });
        })
        .catch((err) => {
          swal('Error when get data', { icon: 'error' });
          setIsExporting((state) => ({...state, companies: false}));
          console.error(err);
        });
    } catch (error) {
      swal('Error...', { icon: 'error' });
      setIsExporting((state) => ({...state, companies: false}));
      console.error(error);
    }
  }

  async function exportAllPerCompany() {
    setIsExporting((state) => ({...state, companies: true}));
    const { getAllMembers, getAllContacts, exportData } = ProcurementApi();
    const params = getParamsMembers();

    try {
      const resMembersData = await exportData(params);
      let membersData = resMembersData.data;

      if (selected.length) {
        membersData = membersData.filter((member) => selected.includes(member.id));
      }

      let contactsParams = { max_size: true, client: clientId };
      const memberIds = membersData.map((member) => member.id);

      if (memberIds.length) {
        contactsParams['member__in'] = memberIds.join(',');
      }

      const contactsData = await getAllContacts(contactsParams);

      const apiDatas = [getFormattedMembersExcel(membersData), getFormattedContactsExcel(contactsData.data.results)];
      const sheetNames = ['Companies', 'Contacts'];

      exportToExcelMultiple(apiDatas, sheetNames, 'Exported all');
      setIsExporting((state) => ({...state, companies: false}));
    } catch (error) {
      swal('Error...', { icon: 'error' });
      setIsExporting((state) => ({...state, companies: false}));
      console.error(error);
    }
  }

  async function exportCompanyPerContacts() {
    setIsExporting((state) => ({...state, companies: true}));
    const { getAllMembers, getAllContacts, exportData } = ProcurementApi();

    try {
      const memberIds = allContacts.map((contact) => contact.member.id);
      const uniqueMemberIds = new Set(memberIds);

      const membersParams = {
        max_size: true,
        activeClient: clientId,
      };

      const responseMembers = await exportData(membersParams);
      const filteredMembers = responseMembers.data.filter((member) => uniqueMemberIds.has(member.id));
      const data = getFormattedMembersExcel(filteredMembers);

      exportToExcel(data, 'Exported companies');
      setIsExporting((state) => ({...state, companies: false}));
    } catch (error) {
      swal('Error...', { icon: 'error' });
      setIsExporting((state) => ({...state, companies: false}));
      console.error(error);
    }
  }

  async function exportAllPerContacts() {
    setIsExporting((state) => ({...state, companies: true}));
    const { exportData } = ProcurementApi();

    try {
      const memberIds = datas.map((contact) => contact.member.id);
      const uniqueMemberIds = new Set(memberIds);

      const membersParams = {
        max_size: true,
        activeClient: clientId,
      };

      const responseMembers = await exportData(membersParams);
      const filteredMembers = responseMembers.data.filter((member) => uniqueMemberIds.has(member.id));

      const apiDatas = [getFormattedMembersExcel(filteredMembers), getFormattedContactsExcel(datas)];
      const sheetNames = ['Companies', 'Contacts'];

      exportToExcelMultiple(apiDatas, sheetNames, 'Exported all');
      setIsExporting((state) => ({...state, companies: false}));
    } catch (error) {
      swal('Error...', { icon: 'error' });
      setIsExporting((state) => ({...state, companies: false}));
      console.error(error);
    }
  }

  const allMemberDatas = useMemo(() => allMembers, [allMembers]);
  const allContactDatas = useMemo(() => allContacts, [allContacts]);

  const InputLabel = ({ children }) => <p className="font-bold">{children}</p>;

  function CompaniesFilter() {
    return (
      <div name="company-filter" className="w-full fhd:flex relative justify-between  mb-4 mt-1">
        <div className='fhd:flex gap-4'>          
          <div className="grid grid-cols-3 fhd:flex gap-4">
            <div className="w-full 5xl:min-w-[6rem] 5xl:w-[10rem]">
              <div>
                <InputLabel>Search</InputLabel>
              </div>
              <InputBase
                size="small"
                placeholder="search"
                endAdornment={<Search color="disabled" />}
                onChange={(v) => onChangeSearchCompanies(v.target.value)}
                value={crmFilterActives?.search !== '' ? crmFilterActives?.search : searchCompanies}
                className="w-full h-[2.5rem] border border-[#BCB9B9] rounded px-4 bg-white hover:border-[#adacac]"
              />
            </div>
            <div className="w-full 5xl:w-[10rem]">
              <div>
                <InputLabel>Sorting</InputLabel>
              </div>
              <MuiSelectSingleItem
                sx={{ height: '40px', py: 0 }}
                FormControlClasses="h-[2.5rem]"
                name="sorting"
                label="sorting"
                OptionLabel="sorting"
                control={control}
                options={attributeSorting}
                defaultValue={crmFilterActives?.sorting ? crmFilterActives?.sorting : sortingMembers}
                onChangeValue={(e) => {
                  SetSortingMembers(e);
                  store.dispatch({ type: 'crmFilter/setSorting', payload: e });
                }}
                isInputLabel
              />
            </div>
            <div className="w-full 5xl:w-[15rem]">
              <div>
                <InputLabel>Countries</InputLabel>
              </div>
              <Controller
                name="countries"
                control={control}
                render={({ field: { onChange } }) => (
                  <CustomSelect
                    options={countries?.map((item) => ({ label: item.name, value: item.id }))}
                    defaultValue={crmFilterActives?.countries ? crmFilterActives?.countries : filterByCountries}
                    placeholder="Countries"
                    isMulti
                    onChange={(v) => {
                      // onChange(v?.map((val) => val.value));
                      SetfilterByCountries(v);
                      store.dispatch({ type: 'crmFilter/setCountries', payload: v });
                    }}
                    className="w-full"
                  />
                )}
              />
            </div>
            {attributeFilters &&
              attributeFilters.map((filter) => {
                const attributeId = filter.id;
                const options = attributeOptions[attributeId] || [];

                return (
                  <div className="w-full 5xl:w-[15rem]">
                    <div>
                      <InputLabel>{filter.name}</InputLabel>
                    </div>
                    <Controller
                      name={filter.name}
                      control={control}
                      render={({ field: { onChange } }) => (
                        <CustomSelect
                          // options={attributeOptions[id]?.map((item) => ({ label: item.name, value: item.id }))}
                          options={options.map((item) => ({ label: item.name, value: item.name }))}
                          defaultValue={
                            crmFilterActives?.dynamicFilter[attributeId]
                              ? crmFilterActives?.dynamicFilter[attributeId]
                              : dynamicFiltersCompanies[attributeId]
                              ? dynamicFiltersCompanies[attributeId]
                              : ''
                          }
                          placeholder={filter.name}
                          label={filter.name}
                          isMulti
                          onChange={(v) => {
                            handleDynamicFilter(v, filter.id);
                          }}
                        />
                      )}
                    />
                  </div>
                );
              })}
          </div>
          <div className="flex justify-between mt-4 fhd:mt-0 w-full">
            <div className="flex gap-x-4">
              <div className="w-fit flex items-end">
                <button
                  type="button"
                  className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4"
                  style={{ backgroundColor: '#2C6D47' }}
                  onClick={changeParamsCompaniesFilter}
                >
                  <AddIcon className="text-white" />
                  <p className="text-white">Apply</p>
                </button>
              </div>
              {isOnlyUser === false && (
                <>
                  <div className="w-fit flex items-end">
                    <Box sx={{ background: 'white' }}>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-autowidth-label"
                          id="demo-simple-select-autowidth"
                          // onChange={handleChange}
                          value={0}
                          defaultValue=""
                          autoWidth
                          renderValue={(value) => {
                            return (
                              <Box sx={{ display: 'flex', gap: 1 }}>
                                {isExporting.companies ? (
                                  <CircularProgress size={20} sx={{ color: 'rgba(252, 88, 29, 0.60)' }} />
                                ) : (
                                  <CircleIcon sx={{ color: '#2C6D47', transform: 'scale(0.8)' }} />
                                )}
                                Export Excel
                              </Box>
                            );
                          }}
                          sx={{ height: 40, maxWidth: '180px' }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                marginTop: 1.3,
                                padding: 0,
                                '& .MuiMenuItem-root': {
                                  padding: 1,
                                  width: '180px',
                                },
                              },
                            },
                          }}
                        >
                          <MenuItem value={1} onClick={exportMembers}>
                            Export Companies
                          </MenuItem>
                          <MenuItem value={2} onClick={exportContactsPerCompany}>
                            Export Contacts
                          </MenuItem>
                          <MenuItem value={3} onClick={exportAllPerCompany}>
                            Export All
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  <div className="w-fit flex items-end">
                    <button
                      type="button"
                      className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 w-fit"
                      style={{ backgroundColor: !isBulkChangeActive ? '#FC581D26' : '#FC581D' }}
                      onClick={() => dispatch(handleModal({ modalId: '', componentName: 'BulkChange' }))}
                      disabled={!isBulkChangeActive}
                    >
                      <RemoveCircleOutlineIcon className="text-white" />
                      <p className="text-white">Bulk Change</p>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div name="grid-icon" className='ml-2 mb-1 mt-auto absolute right-0 bottom-1 fhd:static'>
          <div className="space-x-2 justify-end md:justify-normal items-end">
            <Tooltip className='cursor-pointer'>
              {!isGrid ? <ViewList className="scale-125" onClick={() => setIsGrid(true)} />  :  <GridView className="scale-125" onClick={() => setIsGrid(false)} />}
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }

  function PersonFilter() {
    return (
      <div className="w-full h-full flex gap-x-4 mb-4 mt-1">
        <div className="w-[20rem]">
          <div>
            <InputLabel>Search</InputLabel>
          </div>
          <InputBase
            size="small"
            placeholder="search"
            endAdornment={<Search color="disabled" />}
            onChange={(v) => onChangeSearchContact(v.target.value)}
            className="w-full h-[2.5rem] border border-[#BCB9B9] rounded px-4 bg-white hover:border-[#adacac]"
          />
        </div>
        <div className="w-[30rem]">
          <div>
            <InputLabel>Companies</InputLabel>
          </div>
          <Controller
            name="Companies"
            control={control}
            render={({ field: { onChange } }) => (
              <CustomSelect
                options={allMembersMaxSize.length ? allMembersMaxSize?.map((member) => ({ label: member.name, value: member.id })) : []}
                defaultValue={filterByCompanies}
                placeholder="Companies"
                isMulti
                onChange={(v) => {
                  onChange(v?.map((val) => val.value));
                  setFilterByCompanies(v);
                }}
              />
            )}
          />
        </div>
        <div className="w-[30rem]">
          <div>
            <InputLabel>Contact Title</InputLabel>
          </div>
          <Controller
            name="Contact Title"
            control={control}
            render={({ field: { onChange } }) => (
              <CustomSelect
                options={allAssignedContacts.length ? allAssignedContacts?.map((contactTitle) => ({ label: contactTitle.name, value: contactTitle.id })) : []}
                defaultValue={filterByContactTitles}
                placeholder="Contact Title"
                isMulti
                onChange={(v) => {
                  onChange(v?.map((val) => val.value));
                  setFilterByContactTiltes(v);
                }}
              />
            )}
          />
        </div>
        <div className="flex items-end">
          <button
            type="button"
            className="w-full flex gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-5 py-2"
            style={{ backgroundColor: '#2C6D47' }}
            onClick={changesParamsContactFilter}
          >
            <AddIcon className="text-white" />
            <p className="text-white">Apply</p>
          </button>
        </div>
        {isOnlyUser === false && (
          <div className="w-full flex items-end">
            <Box sx={{ background: 'white' }}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  // onChange={handleChange}
                  value={0}
                  defaultValue=""
                  autoWidth
                  renderValue={(value) => {
                    return (
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        {isExporting.companies ? <CircularProgress size={20} sx={{ color: 'rgba(252, 88, 29, 0.60)' }} /> : <CircleIcon fontSize="small" sx={{ color: '#2C6D47' }} />}
                        Export Excel
                      </Box>
                    );
                  }}
                  sx={{ height: 40, width: '180px' }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        marginTop: 1.3,
                        padding: 0,
                        '& .MuiMenuItem-root': {
                          padding: 1,
                          width: '180px',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value={1} onClick={exportCompanyPerContacts}>
                    Export Companies
                  </MenuItem>
                  <MenuItem value={2} onClick={() => exportContacts(allContactDatas)}>
                    Export Contacts
                  </MenuItem>
                  <MenuItem value={3} onClick={exportAllPerContacts}>
                    Export All
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        )}
      </div>
    );
  }

  const handleGenerateActivities = () => {
    const dtFilter = { ordering: sortingActivities, vendor__client: clientId, page_size: rowsPerPage.activities, page: page.activities + 1 };
    const { date_from, date_to } = filterActivitesTimestamp;
    if (date_from !== null) {
      dtFilter.timestamp__gte = date_from;
    }
    if (date_to !== null) {
      dtFilter.timestamp__lte = date_to;
    }
    if (actionTypeActivities !== "") {
      dtFilter.action = actionTypeActivities;
    }
    if (activitiesFilterByCompanies !== null) {
      dtFilter.member = activitiesFilterByCompanies.id;
    }

    // const shouldGenerateActivities = date_from === null && date_to === null && actionTypeActivities === "" && activitiesFilterByCompanies === null;

    // console.log('shouldGenerateActivities ', shouldGenerateActivities);
    
    // SetGenerateActivities(!shouldGenerateActivities)
    setIsDataLoad(false);
    setParamsActivities(dtFilter);
  };

  const exportActivitiesData = async () => {
    setIsExporting((state) => ({...state, activities: true}));
    // const data = _.cloneDeep(allActivities)
    const activitesParam = { ordering: sortingActivities, vendor__client: clientId, max_size: true };
    const { date_from, date_to } = filterActivitesTimestamp;
    const { getAllHistoriesByClientId } = ProcurementApi();

    try {
      if (date_from !== null) {
        activitesParam.timestamp__gte = date_from;
      }
      if (date_to !== null) {
        activitesParam.timestamp__lte = date_to;
      }
      if (sortingActivities) {
        activitesParam.ordering = sortingActivities;
      }
      if (actionTypeActivities !== "") {
        activitesParam.action = actionTypeActivities;
      }
      if (activitiesFilterByCompanies !== null) {
        activitesParam.member = activitiesFilterByCompanies.id;
      }

      const getHistoriesMaxSize = await getAllHistoriesByClientId(activitesParam);
      const historiesData = getHistoriesMaxSize?.data?.results;

      if(historiesData.length > 0) {
        const newFormat = historiesData.map((data) => ({
          id: data.id,
          member: data.member.name,
          action: data.action,
          description: data.description,
          reason: data.reason,
          user: data.user,
          timestamp: moment(data.timestamp).format("YYYY-MM-DD hh:MM")
        }))
        
        exportToExcel(newFormat, 'Exported Activities');
      } else {
        swal('data is 0', { icon: 'error' });
      }
      
      setIsExporting((state) => ({...state, activities: false}));
    } catch (error) {
      swal('Error...', { icon: 'error' });
      setIsExporting((state) => ({...state, activities: false}));
      console.error(error);
    }
  }

  function ActivitiesFilter() {
    return (
      <div className="w-full flex relative gap-4 mb-4 mt-1">
        <div className="w-[15rem]">
          <div>
            <InputLabel>Sorting</InputLabel>
          </div>
          <MuiSelectSingleItem
            sx={{ height: '40px', py: 0 }}
            FormControlClasses="h-[2.5rem]"
            name="sorting"
            label="sorting"
            OptionLabel="sorting"
            control={control}
            options={[
              {name: 'Date ascending', value: 'timestamp'},
              {name: 'Date descending', value: '-timestamp'},
            ]}
            defaultValue={sortingActivities}

            onChangeValue={(e) => {
              // console.log('dfsdfsdfsdfsd ', e);
              SetSortingActivities(e);
              // store.dispatch({ type: 'crmFilter/setSorting', payload: e });
            }}
            isInputLabel
            initialDisabled
          />
        </div>
        <div className="w-[15rem]">
          <div>
            <InputLabel>Vendor</InputLabel>
          </div>
          <SelectAutocomplete 
            variant="outlined"
            label="Vendor" 
            // defaultValue={userList.find((dt) => dt.name === checkData?.assignee.name)} 
            onChange={(e, val) => SetActivitiesFilterByCompanies(val)}
            options={allMembersMaxSize.length ? allMembersMaxSize?.map((member) => ({ name: member.name, id: member.id })) : []}
          />
        </div>
        <div className="w-[15rem]">
          <div>
            <InputLabel>Action Type</InputLabel>
          </div>
          <MuiSelectSingleItem
            sx={{ height: '40px', py: 0 }}
            FormControlClasses="h-[2.5rem]"
            name="Action"
            label="Action"
            OptionLabel="Action"
            control={control}
            options={[
              {name: 'Create', value: 'Create'},
              {name: 'Update', value: 'Update'},
              {name: 'Delete', value: 'Delete'},
              {name: 'Note', value: 'Note'},
            ]}
            defaultValue={actionTypeActivities}
            onChangeValue={(e) => {
              // console.log('dfsdfsdfsdfsd ', e);
              SetActionTypeActivities(e);
              // store.dispatch({ type: 'crmFilter/setSorting', payload: e });
            }}
            isInputLabel
          />
        </div>
        <div className="w-[15rem]">
          <div>
            <InputLabel>Date From</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                // label="Date From"
                closeOnSelect
                value={filterActivitesTimestamp.date_from}
                onChange={(newValue) => {
                  setFilterActivitesTimestamp((params) => ({...params, date_from: newValue}));
                }}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="w-[15rem]">
          <div>
            <InputLabel>Date to</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                // label="Date to"
                closeOnSelect
                value={filterActivitesTimestamp.date_to}
                onChange={(newValue) => {
                  setFilterActivitesTimestamp((params) => ({...params, date_to: newValue}));
                }}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className='flex items-end gap-x-4'>
          <button 
            type='button'
            className="flex gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-5 py-2 text-white" 
            style={{ backgroundColor: '#2C6D47' }}
            onClick={handleGenerateActivities}
          >
            Generate
          </button>
          <button 
            type='button'
            className="min-w-[148px] min-h-[40px] flex gap-x-2 items-center text-center justify-center btn btn-primary rounded-xl border-0 px-5 py-2 text-white" 
            style={{ backgroundColor: '#2C6D47' }}
            onClick={exportActivitiesData}
          >
            {isExporting.activities ? <CircularProgress size={20} sx={{ color: 'white' }} /> : "Export to Excel"}
          </button>
        </div>
      </div>
    )
  }
  
  return (
    <div className="w-full h-full bg-white">
      <BZHelmet title="Vendors" description="" content="" />
      <BaseMainTitle title="Vendors" />
      <div className="w-full p-5 relative">
        <div className="w-full h-full my-4 flex flex-col gap-8">
          <Paper
            // className={`w-full h-full relative rounded-lg grid grid-cols-6 3xl:grid-cols-12 gap-3 3xl:gap-4 fhd:gap-6 bg-white px-[22px] py-[30px] border-[1.5px]`}
            className={`w-full h-full relative rounded-lg bg-white px-[22px] py-[30px] border-[1.5px] grid grid-cols-3 lg:grid-cols-4 ${counts.length > 6 ? "": "xl:flex"} 3xl:flex gap-3 fhd:gap-6`}
            elevation={0}
            key={0}
          >
            {counts.length > 0 &&
              counts.map((val, id) => {
                const cardStyle = {
                  card: 'w-full h-auto rounded-lg px-4 py-3 flex flex-col gap-y-4 justify-between ',
                  title: 'text-sm font-bold flex justify-start',
                  count: 'flex justify-center text-xl lg:text-3xl font-bold',
                };
                return (
                  <div
                    key={val.id}
                    className={cardStyle.card + ` cursor-pointer bg-[${boxBackgroundColor[id % 2]}] text-white ${id % 2 === 0 ? "hover:bg-[#215235]" : "hover:bg-[#d64c1a]"}`}
                    // onClick={() => handleFilterByCounts(val)}
                    disabled={tabValue === 0 ? false : true}
                  >
                    <h1 className={cardStyle.title}>{val.name}</h1>
                    <p className={cardStyle.count}>{val.count}</p>
                  </div>
                );
              })}
          </Paper>
          <Paper className={`w-full h-full relative rounded-lg bg-white px-[22px] py-[30px] border-[1.5px]`} elevation={0} key={1}>
            <div className="w-full h-full px-[22px]">
              <Box>
                <Tabs
                  className=""
                  value={tabValue}
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                  TabIndicatorProps={{
                    sx: {
                      backgroundColor: '#2C6D47',
                    },
                  }}
                  sx={{
                    '.MuiButtonBase-root': {
                      fontWeight: '600',
                      // background
                    },
                    '.Mui-selected': {
                      color: '#2C6D47!important',
                    },
                  }}
                >
                  <Tab label="Vendor" {...a11yProps(0)} />
                  <Tab label="Person" {...a11yProps(1)} />
                  <Tab label="Activities" {...a11yProps(2)} />
                  <Tab label="Locations" {...a11yProps(3)} />
                  {isOnlyUser === false && (
                    <div className="flex ml-auto border-b-white">
                      <button
                        type="button"
                        className="flex gap-x-2 items-center text-center btn btn- rounded-xl border-0 mr-2 px-5"
                        style={{ backgroundColor: '#2C6D47' }}
                        onClick={() => dispatch(handleModal({ modalId: '', componentName: 'AddNewContact' }))}
                      >
                        <AddIcon className="text-white" />
                        <p className="text-white">Add Contact</p>
                      </button>
                      <Link
                        to="/vendor/add"
                        className="flex gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 cursor-pointer"
                        style={{ backgroundColor: '#2C6D47' }}
                      >
                        <AddIcon className="text-white" />
                        <p className="text-white">Add Vendor</p>
                      </Link>
                      <button
                        type="button"
                        className={
                          selected.length
                            ? 'flex gap-x-2 items-center text-center btn btn-primary bg-[#FC581D] rounded-xl border-0 mr-2 px-5'
                            : 'flex gap-x-2 items-center text-center btn bg-[#FC581D26] rounded-xl border-0 mr-2 px-4 cursor-default'
                        }
                        onClick={DeleteDatabyId}
                        disabled={selected.length ? false : true}
                      >
                        <RemoveCircleOutlineIcon className="text-white" />
                        <p className="text-white">Delete Selected</p>
                      </button>
                    </div>
                  )}
                </Tabs>
              </Box>
            </div>
            <VendorTable
              filterComponent={[CompaniesFilter(), PersonFilter(), ActivitiesFilter()]}
              dataCompanies={allMemberDatas}
              dataPerson={allContactDatas}
              dataActivities={allActivities}
              isDataLoad={isDataLoad}
              setIsAllSelected={setIsAllSelected}
              dataLength={dataCount}
              onChangePage={handleChangePage}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              tabValue={tabValue}
              setSelected={setSelected}
              selected={selected}
              reload={setReloadContact}
              activeClient={clientId}
              isOnlyUser={isOnlyUser}
              isGrid={isGrid}
            />
            {
              tabValue === 3 && (
                <CustomerLocations />
              )
            }
          </Paper>
        </div>
        <ModalWrapper componentName="AddNewContact" header="Add New Contact" maxWidth="sm">
          <AddContact activeClient={clientId} reload={setReloadContact} />
        </ModalWrapper>
        <ModalWrapper componentName="BulkChange" header="Bulk Change" maxWidth="sm">
          <BulkChange activeClient={clientId} memberIds={member_ids} getFilterParams={getParamsMembers} reload={setReloadCompany} normalizeFilter={normalizeFilter} />
        </ModalWrapper>
      </div>
    </div>
  );
}
