/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { Button } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import { GoogleMap, Marker, Autocomplete as GmapAutocomplete, useJsApiLoader } from '@react-google-maps/api';
import * as yup from 'yup';
import swal from 'sweetalert';
import InputContainerJP from '../../Input/InputContainerJP';
// import SelectAutocomplete from '../../Input/SelectAutocomplete';
import { Autocomplete, TextField, Box, Avatar } from '@mui/material';
import SelectAutocomplete from '../../../pages/CRM/redesigning/SelectAutocompleteRedesign';
import ProcurementApi from '../../../api/Procurement/Procurement';
import { handleModal } from '../../../store/slices/modalSlice';
import MuiSelectSingleItem from '../../Input/MuiSelectSingleItem';

const schema = yup.object().shape({
  company: yup.object().nullable(),
  honorific: yup.string().nullable(),
  name: yup.string().max(200).required(),
  position: yup.string().nullable(),
  phone: yup.string().nullable(),
  email: yup.string().nullable(),
  linkedin: yup.string().nullable(),
});

const mapLib = ['drawing', 'places'];

function SelectAutocompleteCustom({ name, control, options, errors, register, variant, placeHolder, onChangeValue, typeofValue }) {
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          if (typeofValue === 'string') {
            return (
              <Autocomplete
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    onChange(newValue);
                  } else if (newValue && newValue.name) {
                    onChange(newValue.name);
                  } else {
                    onChange(newValue);
                  }
                }}
                options={options}
                isOptionEqualToValue={(option, value) => (value && value.name ? option.name === value.name : option.name === value)}
                getOptionLabel={(item) => (item.name ? item.name : '')}
                value={typeof value === 'string' ? options.find((o) => o.name === value) : value || null}
                blurOnSelect
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={placeHolder}
                    variant={variant || 'standard'}
                    sx={{
                      '& > div': {
                        padding: 0,
                        border: 'none',
                        outline: 'none',
                      },
                    }}
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            );
          }
          return (
            <Autocomplete
              onChange={(event, newValue) => {
                onChange(newValue);
              }}
              options={options}
              isOptionEqualToValue={(option, value) => (value && value.name ? option.name === value.name : option.name === value)}
              getOptionLabel={(item) => (item.name ? item.name : '')}
              value={typeof value === 'string' ? options.find((o) => o.name === value) : value || null}
              blurOnSelect
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeHolder}
                  variant={variant || 'standard'}
                  sx={{
                    '& > div': {
                      padding: 0,
                      border: 'none',
                      outline: 'none',
                    },
                  }}
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          );
        }}
      />
      {errors &&
        (Array.isArray(errors[name?.toLowerCase()]?.message) ? (
          errors[name]?.message.map((m) => <span className="text-red-600">{m.toLowerCase()}</span>)
        ) : (
          <span className="text-red-600">{errors[name]?.message.replace(name, name?.toLowerCase() || name.toLowerCase())}</span>
        ))}
    </>
  );
}

function EditContact({ contactId, reload, activeClient, authorize }) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const clientId = useSelector((state) => state.client.activeClient);
  const dispatch = useDispatch();

  const [members, setMembers] = useState([]);
  const [contact, setContact] = useState([]);
  const [isMember, setIsMember] = useState(null);

  // For Location
  const [allCountries, setAllCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [address, setAddress] = useState('');
  const [name, setName] = useState('');
  const [latitude, setLatitude] = useState(-6.1741);
  const [longitude, setLongitude] = useState(106.8296);
  const [onLoad, setOnLoad] = useState(null);

  const HonorificList = [
    { name: 'Mr.', id: 'Mr.' },
    { name: 'Mrs.', id: 'Mrs.' },
    { name: 'Ms.', id: 'Ms.' },
    { name: 'Dr.', id: 'Dr.' },
    { name: 'Prof.', id: 'Prof.' },
    { name: 'H.E.', id: 'H.E.' },
  ];

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_MAP_KEY,
    libraries: mapLib,
  });

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const center = {
    lat: parseFloat(latitude),
    lng: parseFloat(longitude),
  };

  const getAllCountries = async () => {
    const { getCountries } = ProcurementApi();

    try {
      const fetchData = await getCountries({ max_size: true });
      const response = await fetchData.data.results;
      const countriesData = response;

      setAllCountries(countriesData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeMarker = (event) => {
    setLatitude(event.latLng.lat());
    setLongitude(event.latLng.lng());
  };

  useEffect(() => {
    getAllCountries();
  }, []);

  const handleChangePlace = (place) => {
    place = onLoad.getPlace();

    let selectedCountry = {};
    const addressComponents = place?.address_components;

    if (addressComponents) {
      const countriesName = allCountries.map((country) => country.iso_code);
      const addressCountry = addressComponents.map((address) => address.short_name);
      const foundedCountry = _.intersection(countriesName, addressCountry);
      const countryCode = foundedCountry.length ? foundedCountry[0] : '';
      selectedCountry = allCountries.find(({ iso_code }) => iso_code === countryCode);
    }

    setSelectedCountry(selectedCountry);
    setAddress(place?.formatted_address);
    setLatitude(place?.geometry.location.lat());
    setLongitude(place?.geometry.location.lng());
  };

  const getMembers = useCallback(async () => {
    const { getAllMembersName } = ProcurementApi();

    try {
      const fetchData = await getAllMembersName({ client: activeClient, max_size: true });
      const response = fetchData.data;

      setMembers(response);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getContactDetail = useCallback(async () => {
    const { getContactDetail } = ProcurementApi();

    try {
      const fetchData = await getContactDetail(contactId);
      // console.log({contactId});
      // console.log({fetchData});
      const res = fetchData.data;
      const contactLocation = res.location;

      setValue('name', res.name);
      setValue('honorific', res.honorific);
      setValue('phone', res.phone);
      setValue('position', res.position);
      setValue('vendor', res.vendor);
      setValue('email', res.email);
      setValue('linkedin', res.linkedin);
      setSelectedCountry(contactLocation?.country ? contactLocation?.country : {});
      setLatitude(contactLocation?.latitude);
      setLongitude(contactLocation?.longitude);
      setName(contactLocation?.name);
      setAddress(contactLocation?.address);
      setContact(res);
      setIsMember(res.vendor);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getMembers();
    getContactDetail();
  }, []);

  const onSubmit = (data) => {
    const { updateContact } = ProcurementApi();

    const dataEdit = {
      ...data,
      vendor: data.vendor?.id,
      id: contactId,
    };

    if (selectedCountry && name) {
      dataEdit.location = {
        client: clientId,
        country: selectedCountry.id,
        name: name,
        address: address,
        latitude: latitude,
        longitude: longitude,
      };
    }

    try {
      updateContact(contactId, dataEdit)
        .then((res) => {
          if (res.status === 201 || res.data) {
            dispatch(handleModal({ modalId: '', componentName: '' }));
            swal('Edit Contact Successfully!', { icon: 'success' });
            return reload(true);
          }
        })
        .catch((err) => {
          console.error('error', err);
        });
    } catch (error) {
      console.error(error);
    }

    return;
  };

  const InputLabel = ({ children }) => <p className="font-bold">{children}</p>;

  return (
    contact &&
    isLoaded && (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full">
          <div className="w-full grid gap-y-3 mb-3">
            <div className="w-full">
              <InputLabel>Vendor</InputLabel>
              <SelectAutocompleteCustom name="vendor" variant="outlined" control={control} errors={errors} register={register} options={members} onChangeValue={setIsMember} />
            </div>
            <div className="w-full">
              <InputLabel>Honorific</InputLabel>
              <SelectAutocompleteCustom name="honorific" variant="outlined" control={control} errors={errors} options={HonorificList} typeofValue="string" />
            </div>
          </div>
          <div className="w-full">
            <InputLabel>Contact Name</InputLabel>
            <InputContainerJP name="name" label="name" control={control} errors={errors} register={register} />
          </div>
          <div className="w-full">
            <InputLabel>Position</InputLabel>
            <InputContainerJP name="position" label="position" control={control} errors={errors} register={register} />
          </div>
          <div className="w-full">
            <InputLabel>Phone</InputLabel>
            <InputContainerJP name="phone" label="phone" control={control} errors={errors} register={register} />
          </div>
          <div className="w-full">
            <InputLabel>Email</InputLabel>
            <InputContainerJP name="email" label="email" control={control} errors={errors} register={register} />
          </div>
          <div className="w-full">
            <InputLabel>Linkedin</InputLabel>
            <InputContainerJP name="linkedin" label="linkedin" control={control} errors={errors} register={register} />
          </div>
          {isLoaded && (
            <>
              <div className="mt-3" style={{ height: '200px' }}>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={15}
                  mapTypeId="roadmap"
                  onLoad={(e) => setOnLoad(e)}
                  onRightClick={(e) => handleChangeMarker(e)}
                >
                  <GmapAutocomplete className="z-20" onLoad={(e) => setOnLoad(e)} onPlaceChanged={(e) => handleChangePlace(e)}>
                    <input
                      className="border border-transparent shadow-sm text-sm text-ellipsis z-20 w-[50%] h-[2.5rem] rounded box-border px-[12px] absolute top-[.6rem] left-[40%]"
                      id="loc-search-place"
                      type="text"
                      placeholder="Search place"
                    />
                  </GmapAutocomplete>
                  <Marker position={center} />
                </GoogleMap>
              </div>
              <div className="mt-4 space-y-2">
                <div className="space-y-2">
                  <InputLabel>Location Name</InputLabel>
                  <input id="create-location-name" type="text" className="w-full border rounded-md shadow-sm p-2" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="space-y-2">
                  <InputLabel>Address</InputLabel>
                  <textarea
                    id="create-location-address"
                    type="text"
                    className="w-full h-[60px] border rounded-md shadow-sm p-2"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="space-y-2">
                  <InputLabel>Country</InputLabel>
                  <Select
                    options={allCountries?.map((item) => ({ label: item.name, value: item.id }))}
                    value={!selectedCountry.length ? { label: selectedCountry.name, value: selectedCountry.id } : []}
                    isSearchable={false}
                    placeholder="-- select country --"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onChange={(v) => {
                      const selected = allCountries.find(({ id }) => id === parseInt(v.value));
                      setSelectedCountry(selected);
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minHeight: '2.5rem',
                      }),
                      container: (provided) => ({
                        ...provided,
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        overflow: 'visible',
                        display: 'flex',
                        alignItems: 'center',
                      }),
                      placeholder: (provided, state) => ({
                        ...provided,
                        position: 'absolute',
                        top: state.hasValue || state.selectProps.inputValue ? -13 : '20%',
                        transition: 'top 0.1s, font-size 0.1s',
                        fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
                        background: 'white',
                        margin: '0px 20',
                      }),
                      menuPortal: (provided) => ({
                        ...provided,
                        zIndex: 10000,
                        marginTop: '-10px',
                      }),
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <div>
            {/* <Button className="flex mt-7 ml-auto bg-blue-600" type="submit" variant="contained">
              Edit
            </Button> */}
            <button
              type="submit"
              name="Edit contact"
              className="flex h-[40px] mt-7 ml-auto gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4"
              style={{ backgroundColor: '#2C6D47' }}
            >
              <p className="text-white">Edit</p>
            </button>
          </div>
        </div>
      </form>
    )
  );
}

export default EditContact;
