/* eslint-disable */

import React, { useState } from 'react';
import { useParams } from 'react-router';
import { CircularProgress } from '@mui/material';
import BZHelmet from '../../../utils/BZHelmet';
import useGet from '../../../hooks/useGet';
import usePatchOrPut from '../../../hooks/usePatchOrPut';
import BaseMainTitle from '../../../components/BaseMainTitle';
import EmployeeNewForm from '../../../components/Form/HR/EmployeeNewForm';
import BaseBackButton from '../../../components/Button/BaseBackButton';

export default function EmployeeEdit() {
  const [isEmployeeData, setIsEmployeeData] = useState(false);

  const { hrEmployeeId } = useParams();

  useGet({
    url: `employee-management/employees/${hrEmployeeId}/`,
    validate: 'employee',
    onSuccess: (e) => {
      setIsEmployeeData(e?.data);
    },
  });

  const { mutate: updateEmployee } = usePatchOrPut({ url: `employee-management/employees/${hrEmployeeId}`, validate: ['employee'], method: 'put' });

  const onUpdateEmployee = async (data) => {
    await updateEmployee(data);
  };

  return (
    <>
      <BZHelmet title={isEmployeeData?.name ?? ' Employee'} description="" content="" />
      <div>
        <BaseMainTitle title="Employee Detail :" />
        <div className="p-5 ">
          <div className="bg-white border p-5 space-y-3 shadow rounded">
            <BaseBackButton />
            {isEmployeeData ? (
              <EmployeeNewForm defaultValues={isEmployeeData} key={isEmployeeData?.id} onFormSubmit={onUpdateEmployee} cancel="/hr/employee" />
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
