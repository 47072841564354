import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, InputBase, TableCell, TableRow, CircularProgress, Pagination } from '@mui/material';
import { DeleteForever, Edit, Search, Place, Window, GridView } from '@mui/icons-material';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination as swipePagination, Autoplay, Virtual } from 'swiper/core';
import 'swiper/swiper-bundle.css';

import BaseMainTitle from '../../../components/BaseMainTitle';
import { useAddBusinessUnit, useBusinessUnitData, useBusinessUnitDetail, useDestroyBusinessUnit, useUpdateBusinessUnit } from '../../../hooks/useWhistleblowerData';
import BZHelmet from '../../../utils/BZHelmet';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import BusinessUnitForm from '../../../components/Form/Whistleblower/BusinessUnitForm';
import useGet from '../../../hooks/useGet';
import { fetchFilterBunit2 } from '../../../hooks/useNewHrData';
import { useQuery, useQueries } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import useDebounce from '../../../hooks/useDebounce';
import InputContainer from '../../../components/Input/InputContainer';
import HandleWatchingState from '../../../helper/HandleWatchingState';
import { Link } from 'react-router-dom';
import BZButton from '@/components/Button/BZButton';

SwiperCore.use([Navigation, swipePagination, Autoplay, Virtual]);

const containerStyle = {
  width: '100%',
  height: '230px',
};

const lib = ['drawing', 'places'];

const exampleMapStyles = [
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#D6D6D6',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#dcd2be' }],
  },

  {
    featureType: 'landscape.natural',
    elementType: 'geometry',
    stylers: [{ color: '#D6D6D6' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#ffffff' }],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [{ color: '#ffffff' }],
  },
  {
    featureType: 'all',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];

function ActionCell(props) {
  const { id } = props;
  const dispatch = useDispatch();
  const removeBusinessUnit = useDestroyBusinessUnit();

  return (
    <div className="">
      <Button className="w-fi p-0 min-w-0" type="button" variant="">
        <Link to={`/hr/business-unit/${id}`}>
          <Edit className="text-blue-500" />
        </Link>
      </Button>
      <DeleteForever onClick={() => removeBusinessUnit(id)} className="text-red-600 cursor-pointer" />
    </div>
  );
}

export default function UnitList() {
  const { control, watch } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const [activeWindow, setActiveWindow] = useState(null);
  const [activeZoom, setActiveZoom] = useState(1.2);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({ search: '', submit: false });
  const [unitData, setunitData] = useState([]);
  const [count, setCount] = useState(0);
  const [isDataLoad, setIsDataLoad] = useState(true);
  const [dataLocations, setDataLocations] = useState([]);
  const [activeMarker, setActiveMarker] = useState(null);

  const [page, setPage] = useState(1);
  const [markers, setMarkers] = useState([]);
  const searchInput = watch('search');

  const debouncedSearchInput = useDebounce(searchInput, 500);

  const unitId = modal.modalId;

  const watchingUnit = HandleWatchingState(unitId);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  const queryKey = {
    client: clientId,
    business_unit: unitId,
    search: debouncedSearchInput || '',
    page,
    page_size: 4,
  };

  const { isFetching } = useQuery(['BusinessUnits', { queryKey }], fetchFilterBunit2, {
    onSuccess: (e) => {
      const listData = e?.data?.results || e?.data;
      setCount(e?.data?.count);
      setunitData(listData);
    },
    // refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (searchInput) {
      setPage(1);
    }
  }, [searchInput]);

  const { data: locations } = useGet({
    url: `locations`,
    queryString: {
      client: clientId,
      max_size: true,
    },
    validate: 'locations',
  });

  const { mutate: addUnit } = useAddBusinessUnit();
  // const { data: UnitDetail } = useBusinessUnitDetail(unitId, isAutoFetchingUnit);
  const { data: UnitDetail } = useGet({
    url: `accounting/business_units/${unitId}`,
    validate: 'BusinessUnit',
    isEnabled: watchingUnit,
  });

  const { mutate: updateUnit } = useUpdateBusinessUnit(unitId, UnitDetail?.data);

  const data = useMemo(() => unitData, [unitData]);
  const locationList = useMemo(() => locations?.data?.results, [locations]);

  const onSearchCategory = useCallback((event) => {
    setFilter({ search: event.target.value });
  }, []);

  const onFilter = () => {
    setSearch(filter.search);
  };

  const handleChange = (e, p) => {
    setIsDataLoad(false);
    setTimeout(() => {
      setIsDataLoad(true);
    }, 100);
    setPage(p);
  };

  const handleToggle = (i) => {
    if (activeWindow === i) {
      return setActiveWindow(null);
    }

    setActiveWindow(i);
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCKqMs4hmNgoQtmIUsd7diBKHj6UV610Jg',
    libraries: lib,
  });

  const tabelAction = (
    <div className="">
      <div className="bg-gray-100 w-full py-5 px-3  rounded items-center">
        <div className="flex  justify-between">
          {' '}
          <div className="flex space-x-3 items-center">
            <div className="w-full max-w-[300px] ">
              <InputContainer name="search" control={control} label="Search" className="!my-0 bg-white" />
            </div>
            {/* <button className="bg-teal-600 p-1 text-sm font-bold text-white rounded" type="button" onClick={onFilter}>
              Find
            </button> */}
          </div>
          <div className="flex justify-end">
            <Link to="/hr/business-unit/add">
              <BZButton variant="secondary">Add Business Unit</BZButton>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <BZHelmet title="Business Unit" description="bz publish  Employee" />
      <BaseMainTitle title="Business Unit" />
      <div className="p-5">
        {tabelAction}
        <div className="py-4 grid grid-cols-1 gap-4">
          {!isFetching ? (
            data?.map((val, compId) => {
              const dataLocations = val?.business_unit_locations;
              const dataLocationsMain = dataLocations?.filter((v) => v.is_main);
              const dataLocationsNotMain = dataLocations?.filter((v) => !v.is_main);

              return (
                <div key={val.id} className="w-full border-2 p-4 flex flex-col gap-y-4 bg-slate-100">
                  <div className="w-full flex justify-between">
                    <div className="flex space-x-4">
                      <p className="text-2xl font-semibold">{val.name}</p>
                      <div>
                        <Link to={`/hr/business-unit/${val.id}/edit`}>
                          <BZButton variant="secondary">Edit Data</BZButton>
                        </Link>
                      </div>
                    </div>
                    {dataLocations.length !== 0 && (
                      <div className="mt-1 h-fit" onClick={() => handleToggle(compId)}>
                        <GridView className="cursor-pointer" color={activeWindow === compId ? 'success' : 'black'} />
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col gap-y-[1.3rem]">
                    {dataLocationsMain.map((data, idx) => {
                      return (
                        <div key={data?.id} className="w-3/4 flex bg-white shadow-xl rounded">
                          <div className="w-3/12">
                            <GoogleMap
                              mapContainerStyle={containerStyle}
                              center={{ lat: parseFloat(data?.location?.latitude), lng: parseFloat(data?.location?.longitude) }}
                              zoom={10}
                              options={{
                                scrollwheel: false,
                                // styles: exampleMapStyles,
                              }}
                            >
                              {/* Child components, such as markers, info windows, etc. */}
                              <Marker position={{ lat: parseFloat(data?.location?.latitude), lng: parseFloat(data?.location?.longitude) }} />
                            </GoogleMap>
                          </div>
                          <div className="w-9/12 p-6 space-y-2">
                            <div className="w-full flex justify-between">
                              <p className="text-bz-greenLight text-2xl">{data?.location?.name}</p>
                              {data?.is_main && (
                                <div className="bg-bz-green px-2 py-1 rounded-xl text-white">
                                  <p className="font-semibold">Main</p>
                                </div>
                              )}
                            </div>
                            <p className="">{data?.location?.address}</p>
                            <div className="flex gap-x-3">
                              <div>
                                <p className="">Country</p>
                                <p className="">Phone</p>
                                <p className="">Email</p>
                              </div>
                              <div>
                                <p className="">:</p>
                                <p className="">:</p>
                                <p className="">:</p>
                              </div>
                              <div className='grid grid-rows-3'>
                                <p className="">{data?.location?.country?.name}</p>
                                <p className="">{data?.location?.contact?.phone}</p>
                                <p className="">{data?.location?.contact?.email}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {dataLocations.length !== 0 ? (
                    activeWindow !== compId ? (
                      <div>
                        <Swiper
                          slidesPerView={dataLocationsNotMain.length > 1 ? 2 : 1}
                          spaceBetween={10}
                          id="swiper"
                          virtual
                          onReachEnd={() => {
                            const tmp = dataLocationsNotMain.unshift();
                            dataLocationsNotMain.push(tmp);
                          }}
                          pagination
                        >
                          {dataLocationsNotMain?.map((data) => {
                            return (
                              <SwiperSlide style={{ listStyle: 'none' }}>
                                <div key={data?.id} className={`flex ${dataLocationsNotMain.length > 1 ? 'w-full' : 'w-3/4'} bg-white shadow-xl rounded`}>
                                  <div className="w-3/12">
                                    <GoogleMap
                                      mapContainerStyle={containerStyle}
                                      center={{ lat: parseFloat(data?.location?.latitude), lng: parseFloat(data?.location?.longitude) }}
                                      zoom={10}
                                      options={{
                                        scrollwheel: false,
                                        // styles: exampleMapStyles,
                                      }}
                                    >
                                      {/* Child components, such as markers, info windows, etc. */}
                                      <Marker position={{ lat: parseFloat(data?.location?.latitude), lng: parseFloat(data?.location?.longitude) }} />
                                    </GoogleMap>
                                  </div>
                                  <div className="w-9/12 p-6 space-y-2">
                                    <div className="w-full flex">
                                      <p className="text-2xl">{data?.location?.name}</p>
                                    </div>
                                    <p className="">{data?.location?.address}</p>
                                    <div className="flex gap-x-3">
                                      <div>
                                        <p className="">Country</p>
                                        <p className="">Phone</p>
                                        <p className="">Email</p>
                                      </div>
                                      <div>
                                        <p className="">:</p>
                                        <p className="">:</p>
                                        <p className="">:</p>
                                      </div>
                                      <div>
                                        <p className="">{data?.location?.country?.name}</p>
                                        <p className=""></p>
                                        <p className=""></p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      </div>
                    ) : (
                      <div>
                        <GoogleMap
                          // onLoad={handleOnLoad}
                          onClick={() => setActiveMarker(null)}
                          zoom={2.2}
                          mapContainerStyle={{ height: '42vh' }}
                          className="border-2 "
                          style
                          center={{ lat: parseFloat(dataLocations[0]?.location?.latitude), lng: parseFloat(dataLocations[0]?.location?.longitude) }}
                          options={{
                            scrollwheel: false,
                            // styles: exampleMapStyles,
                          }}
                        >
                          {dataLocations?.map((data) => (
                            <Marker
                              key={data.id}
                              position={{ lat: parseFloat(data?.location?.latitude), lng: parseFloat(data?.location?.longitude) }}
                              onClick={() => [handleActiveMarker(data.id)]}
                              icon={{
                                url: <Place />,
                              }}
                            >
                              {activeMarker === data.id ? (
                                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                  <div className="w-[270px] h-full pb-2 pr-2 space-y-2 relative">
                                    <div className="w-full flex">
                                      <p className="text-2xl">{data?.location?.name}</p>
                                      {/* text-blue-400  */}
                                    </div>
                                    <p className="">{data?.location?.address}</p>
                                    <div className="flex gap-x-3">
                                      <div>
                                        <p className="">Country</p>
                                        <p className="">Phone</p>
                                        <p className="">Email</p>
                                      </div>
                                      <div>
                                        <p className="">:</p>
                                        <p className="">:</p>
                                        <p className="">:</p>
                                      </div>
                                      <div>
                                        <p className="">{data?.location?.country?.name}</p>
                                        <p className="">{data?.location?.contact?.phone}</p>
                                        <p className="">{data?.location?.contact?.email}</p>
                                      </div>
                                    </div>
                                  </div>
                                </InfoWindow>
                              ) : null}
                            </Marker>
                          ))}
                        </GoogleMap>
                      </div>
                    )
                  ) : (
                    ''
                  )}
                </div>
              );
            })
          ) : (
            <CircularProgress />
          )}
        </div>
        <Pagination count={Math.ceil(count / 4)} size="large" page={page} variant="outlined" shape="rounded" onChange={handleChange} />
      </div>
      <ModalWrapper componentName="editBusinessUnit" header="Edit Business Unit" maxWidth="sm">
        <BusinessUnitForm onFormSubmit={updateUnit} defaultValues={UnitDetail?.data} key={UnitDetail?.data?.id} locations={locations} />
      </ModalWrapper>
      <ModalWrapper componentName="addBusinessUnit" header="Create Business Unit" maxWidth="sm">
        <BusinessUnitForm onFormSubmit={addUnit} locations={locations} />
      </ModalWrapper>
    </div>
  );
}
