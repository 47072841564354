// /* eslint-disable */

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validationLocationLang = yup
  .object()
  .shape({
    name: yup.string().required('Name is required'),
    location_languages: yup.array().of(yup.object().shape({ name: yup.string().required('Name is required'), address: yup.string().required('Address is required') })),
  })
  .required();

const schemaLocationHasLang = yupResolver(validationLocationLang);

export default schemaLocationHasLang;
