import React, { Suspense, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, ButtonBase, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Hidden, InputBase, InputLabel } from '@mui/material';
import { Add, Delete, Edit, Search } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { BsFileExcel } from 'react-icons/bs';
import { utils, writeFileXLSX } from 'xlsx';
import ExcelJS from 'exceljs';

import MuiSelectSingleItem from '../../../components/Input/MuiSelectSingleItem';
import crmApi from '../../../api/CRM/CRMCustomers';
import CustomSelectRedesign from '../redesigning/CustomSelectRedesign';
import { format } from 'date-fns';
import store from '@/store';

function ConfirmDialog(props) {
  const { title, children, open, setOpen, onConfirm } = props;
  return (
    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="confirm-dialog">
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setOpen(false)} color="inherit">
          No
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function setExpiryItem(n,v,e){
  sessionStorage.setItem(n, JSON.stringify({expiryDate:Date.now()+parseInt(e), value:v}));
}

function getExpiryItem(n){
  var k=JSON.parse(sessionStorage.getItem(n));
  if(!k)return {msg:"Not set",expiryDate:undefined,val:undefined};
  if(Date.now()>k.expiryDate){/* sessionStorage.removeItem(n); You can remove it if you want */return {msg:"Expired",expiryDate:k.expiryDate,val:null};}
  return {msg:"Success",expiryDate:k.expiryDate,val:k.value};
}

const { deleteCRMProject, getCRMProfileAttribute, getCRMProjects, getCRMProjectAttribute, getCRMProjectProfiles } = crmApi();

export default function Table({ role, setIsCreate }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const activeClient = useSelector((state) => state.client.activeClient);
  const activeDetailClient = useSelector((state) => state.client.activeDetailClient);
  const crmProjectFilter = useSelector((state) => state.crmProjectFilter);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(null);
  const [data, setData] = useState(null);
  const [optionsFilter, setOptionsFilter] = useState({ profile__client: activeClient, ...crmProjectFilter.filterOffTable });
  const [profileAttributes, setProfileAttributes] = useState(null);

  const [filters, setFilters] = useState(crmProjectFilter.filterModels);
  const [sortModel, setSortModel] = useState(crmProjectFilter.sortModel);
  const [hiddenColumns, setHiddenColumns] = useState(crmProjectFilter.visibilityColumns);
  const [tableState, setTableState] = useState({ sorting: {}, filter: {} });

  // console.log({tableState});
  // console.log({hiddenColumns});
  // console.log({sortModel});
  // console.log({hiddenColumns});

  const { isSuperUser, userGroup } = role;

  const { control, getValues, setValue } = useForm();

  const renderAction = (dt) => {
    return (
      <Box className="w-full flex space-x-2 justify-center">
        <ButtonBase className="bz-btn-tb" onClick={() => {
          setExpiryItem('dt-crmp', { page: page, size: rowsPerPage }, 3 * 60000 )
          navigate(`/crm/projects/detail/${dt.id}`)
        }}>
          <Edit fontSize="24" />
        </ButtonBase>
        <ButtonBase className="bz-btn-tb" onClick={() => setConfirmOpen(dt)}>
          <Delete fontSize="24" />
        </ButtonBase>
      </Box>
    );
  };

  const {
    data: dataProject,
    refetch: refetchProject,
    isFetching: isFetchingProject,
    isFetched,
  } = useQuery({
    queryKey: ['crm-projects-list', activeClient],
    enabled: !!activeClient,
    queryFn: () => getCRMProjects(optionsFilter),
    onSuccess: () => {},
    onError: (error) => {
      console.log(error);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    staleTime: 0,
  });

  const {
    data: dataAttribute,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['crm-project-attributes', activeClient, dataProject],
    queryFn: () => getCRMProjectAttribute({ client: activeClient, max_size: true, on_project_list: true }),
    enabled: !!dataProject && !!isFetched,
    onSuccess(res) {
      if (res) {
        let newRows = [...dataProject];
        newRows = newRows.map((r) => {
          const columns = {};
          const options = {};
          if (r?.attributes && Object.keys(r.attributes).length > 0) {
            Object.keys(r?.attributes).forEach((k) => {
              const isDisplayAttribute = res.find((a) => a.key_name === k);
              if (isDisplayAttribute) {
                const { value_type: valType } = isDisplayAttribute;
                if (valType === 'Option') {
                  if (r.attributes[k]) {
                    const optValue = isDisplayAttribute.options.find((o) => String(o.name).toLowerCase() === String(r.attributes[k]).toLowerCase());
                    options[k] = optValue;
                  }
                  columns.options = options;
                }
                columns[k] = r.attributes[k];
              }
            });
          }
          return { ...r, ...columns };
        });
        setData(newRows);
      } else {
        setData(dataProject);
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    staleTime: 0,
  });

  async function getProfileAttribute(profileId) {
    return new Promise((resolve) => {
      getCRMProfileAttribute({ client: activeClient, profile: profileId, max_size: true, ordering: 'index' })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => console.error(err));
    });
  }

  async function processArray(array) {
    const profileAttributes = await Promise.all(
      array.map(async (item) => {
        const pA = await getProfileAttribute(item);
        return { id: item, attributes: pA };
      })
    );
    setProfileAttributes(profileAttributes);
  }

  useEffect(() => {
    setOptionsFilter({ profile__client: activeClient, ...crmProjectFilter.filterOffTable })
  }, [crmProjectFilter.filterOffTable])

  const handleSortModelChange = (newSortModel) => {
    console.log("newSortModel ", newSortModel);
    setSortModel(newSortModel);
    store.dispatch({ type: 'crmProjectFilter/setSortModels', payload: newSortModel });
  };

  const handleFilterModelChange = (newFilterModel) => {
    setFilters(newFilterModel);
    store.dispatch({ type: 'crmProjectFilter/setFilterModels', payload: newFilterModel });
  };

  const handleHiddenColumnsChange = (newVisibilityModel) => {
    if(dataAttribute !== undefined) {
      // console.log({newVisibilityModel});
      setHiddenColumns(newVisibilityModel);
    }
  };

  useEffect(() => {
    if(dataAttribute) {
      store.dispatch({ type: 'crmProjectFilter/setColumnVisible', payload: hiddenColumns });
    }
  }, [hiddenColumns, dataAttribute])

  const getTableStateChange = (newState) => {
    const { sorting, filter } = newState;
    if (
      JSON.stringify(sorting) !== JSON.stringify(tableState.sorting) ||
      JSON.stringify(filter) !== JSON.stringify(tableState.filter)
    ) {
      setTableState({ sorting, filter });
    }
  };

  const { data: projectProfile } = useQuery({
    queryKey: ['crm-project-profiles', activeClient],
    queryFn: () => getCRMProjectProfiles({ client: activeClient, max_size: true }),
    onSuccess(res) {
      if (res) {
        const mapProfileId = res.map((p) => p.id);
        processArray(mapProfileId);
      }
    },
    refetchOnWindowFocus: false,
  });

  const column = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <p title={params.row.name} className="line-clamp-2 whitespace-break-spaces py-2">
          {params.row.name || ''}
        </p>
      ),
      hide: hiddenColumns?.name || false,
      hideable: true,
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 196,
      renderCell: (params) => (
        <span title={params.row.location?.name} className="truncate">
          {params.row.location?.name || ''}
        </span>
      ),
      hide: hiddenColumns?.location || false,
      hideable: true,
    },
  ];

  if (dataAttribute && data) {
    dataAttribute.forEach((d) => {
      const objColumn = {
        field: d.key_name,
        headerName: d.name,
        sortable: true,
        headerAlign: 'center',
        flex: 1,
        hide: hiddenColumns[d?.key_name] || false,
        hideable: true,
      };
      if (d.value_type === 'Option') {
        const renderCell = (params) => {
          const options = params.row.options;
          if (options && d.key_name in options) {
            return (
              <Chip
                title={options ? options[d.key_name]?.name : ''}
                label={options ? options[d.key_name]?.name : ''}
                style={{ background: options[d.key_name]?.configuration?.color || '#fff', color: options[d.key_name]?.configuration?.color ? '#fff' : '#000' }}
              />
            );
          }
          return null;
        };
        objColumn.width = 196;
        objColumn.flex = 0;
        objColumn.renderCell = renderCell;
      }
      column.push(objColumn);
    });
  }

  if (isSuperUser || [8, 10].some((g) => userGroup.includes(g))) {
    column.push({
      field: 'id',
      headerName: 'Action',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => renderAction(params.row),
    });
  }

  const onDeleteClick = (param) => {
    deleteCRMProject(param.id)
      .then(() => refetchProject())
      .catch((err) => console.log(err));
  };

  const applyFilter = () => {
    const search = getValues('search') || '';
    const ordering = getValues('ordering') || '';
    const profile = getValues('profile');
    setOptionsFilter((state) => {
      return { ...state, search, ordering, profile: profile || '' };
    });

    store.dispatch({ type: 'crmProjectFilter/setFilterOnTable', payload: { on_project_list: true, max_size: true, ordering: ordering, search: search, profile: profile || '' } });
  };

  const generateReport = () => {};

  const exportExcel = async () => {
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('report', {
      headerFooter: { firstHeader: 'Report', firstFooter: 'Hello World' },
      pageSetup: { verticalCentered: true },
    });

    const allData = [...data];
    let exportedData;

    // FilterData
    const filteringData = tableState?.filter?.filteredRowsLookup
    const hasFalseValue = Object.values(filteringData).some(value => value === false);
    if (hasFalseValue) {
        exportedData = allData.filter(item => filteringData[item.id]);
    } else {
        exportedData = [...allData];
    }

    // SortingData
    const sortedIds = tableState?.sorting?.sortedRows
    const idOrderMap = new Map();
    sortedIds.forEach((id, index) => {
        idOrderMap.set(id, index);
    });

    // Sort firstData based on the order defined in secondData
    exportedData.sort((a, b) => {
        return idOrderMap.get(a.id) - idOrderMap.get(b.id);
    });

    const columns = [
      { header: 'Profile', key: 'profile' },
      { header: 'Name', key: 'name' },
      { header: 'Location', key: 'location' },
    ];

    exportedData.forEach((project) => {
      if (project.attributes) {
        Object.keys(project.attributes).forEach((attributeKey) => {
          const header = {
            header: attributeKey,
            key: attributeKey,
          };
          // Check if the header already exists in the columns array
          if (!columns.some((col) => col.key === attributeKey) && dataAttribute.some((obj) => obj?.key_name === attributeKey)) {
            columns.push(header);
          }
        });
      }
    });

    // Populate all Columns on each profile
    const cols = [];
    exportedData.forEach((d) => {
      const attributesMap = profileAttributes.find((p) => +p.id === +d.profile.id);
      if (attributesMap) {
        const attrs = attributesMap.attributes;
        attrs.forEach((a) => {
          const isExist = cols.find((at) => at.key_name === a.attribute.key_name);
          if (!isExist && dataAttribute.some((obj) => obj?.key_name === a.attribute.key_name)) {
            cols.push({ name: a.attribute.name, key_name: a.attribute.key_name });
          }
        });
      }
    });

    if (cols.length) {
      const columns = [{ header: 'Profile', key: 'profile' }, { header: 'Name', key: 'name' }, { header: 'Location', key: 'location' }];
      columns.push(
        ...cols.map((k) => {
          return { header: k.name, key: k.key_name };
        })
      );
      const filteredColumns = columns.filter(col => {
        return hiddenColumns[col.key] !== false;
      });

      if (filteredColumns) {
        ws.columns = filteredColumns
      }

      // Add sorted data rows to the worksheet
      exportedData.forEach((d) => {
        const row = [d.profile?.name || '', d.name || '', d.location?.name || ''];
        const rows = row.concat(
          cols
          .filter((k) => filteredColumns.some(col => col.key === k.key_name))
          .map((k) => {
            return d.attributes && d.attributes[k.key_name] !== undefined ? d.attributes[k.key_name] : '';
          })
        );
        ws.addRow(rows, { alignment: { horizontal: 'center', vertical: 'middle' } }).commit();
      });
    }

    // Write the workbook to a buffer and create a download link
    wb.xlsx.writeBuffer().then((d) => {
      const blob = new Blob([d], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `${activeDetailClient.name}_CRM-Projects_${format(new Date(), 'yyyyMMddhhmm')}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    let initialize = false;
    if (!initialize) {
      initialize = true;
      refetchProject();
    }
  }, [optionsFilter]);



  useEffect(() => {
    const sessionValue = getExpiryItem('dt-crmp');
    const { val, msg } = sessionValue
    if (data && msg === 'Success' && !!val) {
      setRowsPerPage(val.size)
      setPage(val.page)
      sessionStorage.removeItem('dt-crmp')
    }
    if (msg === 'Expired') {
      sessionStorage.removeItem('dt-crmp')
    }
  }, [data])

  const wProfile = getValues('profile');
  return (
    <Suspense fallback={<CircularProgress />}>
      {data && (
        <>
          <div className="w-full 5xl:flex 5xl:flex-wrap relative gap-4 mb-4 mt-1">
            <div className="grid grid-cols-3 5xl:flex gap-4">
              <div className="w-full 5xl:w-[10rem]">
                <div>
                  <InputLabel>Search</InputLabel>
                </div>
                <Controller
                  control={control}
                  name="search"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputBase
                      size="small"
                      placeholder="search"
                      endAdornment={<Search color="disabled" />}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      className="w-full h-[2.5rem] border border-[#BCB9B9] rounded px-4 bg-white hover:border-[#adacac]"
                    />
                  )}
                />
              </div>
              <div className="w-full 5xl:w-[10rem]">
                <div>
                  <InputLabel>Sorting</InputLabel>
                </div>
                <MuiSelectSingleItem
                  sx={{ height: '40px', py: 0 }}
                  FormControlClasses="h-[2.5rem]"
                  name="ordering"
                  label="sorting"
                  OptionLabel="sorting"
                  control={control}
                  options={[
                    { id: 'id', name: 'ID ascending' },
                    { id: '-id', name: 'ID descending' },
                    { id: 'name', name: 'Name ascending' },
                    { id: '-name', name: 'Name descending' },
                  ]}
                  isInputLabel
                />
              </div>
              {
                optionsFilter?.allData && (
                  <ProfileFilter control={control} setValue={setValue} projectProfile={projectProfile} wProfile={wProfile} />
                )
              }
            </div>
            <div className="flex gap-x-4 mt-4 5xl:mt-0">
              <div className="w-fit flex items-end 5xl:items-end">
                <button type="button" className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 bg-[#2C6D47]" onClick={applyFilter}>
                  <Add className="text-white" />
                  <p className="text-white">Apply</p>
                </button>
              </div>
              <div className="w-fit flex items-end 5xl:items-end">
                <button type="button" className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 bg-[#2C6D47]" onClick={exportExcel}>
                  <BsFileExcel className="text-white" />
                  <p className="text-white">Excel export</p>
                </button>
              </div>
              <div className="w-fit flex items-end 5xl:items-end ml-auto 5xl:hidden">
                <button type="button" className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 bg-[#2C6D47]" onClick={() => setIsCreate(true)}>
                  <Add className="text-white" />
                  <p className="text-white">Add Project</p>
                </button>
              </div>
            </div>
          </div>
          {
            crmProjectFilter && data && (
              <DataGrid
                loading={isFetchingProject || isFetching}
                autoHeight
                rows={data}
                columns={column}
                // columns={column.map(col => ({ ...col, disableColumnMenu: true }))}
                pageSize={rowsPerPage}
                page={page}
                filterModel={filters}
                sortModel={sortModel}
                columnVisibilityModel={hiddenColumns}
                onFilterModelChange={handleFilterModelChange}
                onSortModelChange={handleSortModelChange}
                onColumnVisibilityModelChange={(e) => {
                  handleHiddenColumnsChange(e)
                }}
                onStateChange={(evevev) => getTableStateChange(evevev)}
                onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
                rowsPerPageOptions={[10, 20, 50]}
                onPageChange={(e) => {
                  setPage(e)
                }}
              />
            )
          }
        </>
      )}
      <ConfirmDialog title="Delete Project" open={!!confirmOpen} setOpen={setConfirmOpen} onConfirm={() => onDeleteClick(confirmOpen)}>
        Are you sure you want to delete this project?
      </ConfirmDialog>
    </Suspense>
  );
}

function ProfileFilter({ control, projectProfile, wProfile, setValue }) {
  const [profile, setProfile] = useState(wProfile);
  return (
    <div className="w-full 5xl:w-[15rem]">
      <div>
        <InputLabel>Profile</InputLabel>
      </div>
      <Controller
        name="profile"
        control={control}
        render={({ field: { onChange, value } }) => (
          <CustomSelectRedesign
            options={projectProfile?.map((item) => ({ label: item.name, value: item.id }))}
            placeholder="Profile"
            // isMulti
            defaultValue={profile}
            onChange={(v) => {
              if (Array.isArray(v)) {
                setValue('profile', v.map((a) => a.value) || []);
              } else {
                setValue('profile', v ? v.value : ""); // Set value to "" if v is falsy
              }
              setProfile(v);
            }}
            className="w-full"
          />
        )}
      />
    </div>
  );
}
