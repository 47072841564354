// /* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { DevTool } from '@hookform/devtools';

import InputContainer from '../../Input/InputContainer';
import { handleModal } from '../../../store/slices/modalSlice';
import MuiCusotmTabs from '../../ui/Tab/MuiCustomTabs';
import MuiCustomTabPanel from '../../ui/Tab/MuiCustomTabPanel';
import getDefaultValues from '../../../helper/getDefaultValues';
import useDelete from '@/hooks/useDelete';

export default function TextForm({ defaultValues, supportData, onFormSubmit }) {
  const clientLanguage = useSelector((state) => state.content.activeSiteDetail.site_languages);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      name: defaultValues?.name,
      text_languages: getDefaultValues(clientLanguage, defaultValues?.text_languages),
      widget: defaultValues?.widget,
    },
  });

  const dispatch = useDispatch();

  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const devLang = [];

  defaultValues?.text_languages?.map((l) => {
    devLang.push(l);
    return devLang;
  });

  const onSubmit = (data) => {
    onFormSubmit({
      name: data.name,
      text_languages: data.text_languages,
      widget: defaultValues?.widget || supportData?.id,
    });
  };

  const handleCancel = () => {
    dispatch(handleModal({ id: '' }));
  };

  useEffect(() => {
    clientLanguage?.forEach((lang, index) => setValue(`text_languages[${index}][language]`, lang.language.id));
  }, [clientLanguage, setValue]);

  const destroyTextItems = useDelete({
    confText: `modals.text_delete.confirmation_text`,
    confTitle: `modals.text_delete.title`,
    success: `Text deleted`,
    validate: ['widget'],
  });

  const onDestroyTextEl = (id) => {
    destroyTextItems(`text_elements/${id}`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full">
        <InputContainer name="name" control={control} label="Name" />
        <MuiCusotmTabs
          optionsTab={clientLanguage.map((lang) => {
            return {
              name: lang?.language?.name,
              id: lang?.language?.id,
            };
          })}
          value={valueTab}
          onChange={handleChangeTab}
        />

        {clientLanguage?.map((lang, index) => (
          <MuiCustomTabPanel key={lang?.id} value={valueTab} index={index}>
            <div className="hidden">{defaultValues && <InputContainer name="name" control={control} hidden className="hidden" />}</div>
            <InputContainer name={`text_languages[${index}][content]`} control={control} label="Content" />
          </MuiCustomTabPanel>
        ))}
        <div className="ml-auto flex gap-x-3 mt-7">
          {defaultValues && (
            <Button color="error" onClick={() => onDestroyTextEl(defaultValues.id)} type="button" className="" variant="contained">
              Delete
            </Button>
          )}{' '}
          {/* <Button onClick={handleCancel} className="ml-auto flex" type="button" variant="outlined">
            Cancel
          </Button> */}
          <Button className="flex" type="submit" variant="outlined">
            {`${defaultValues ? 'UPDATE Text' : ' Add Text'}`}
          </Button>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </form>
  );
}
