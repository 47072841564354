import { AccountTree } from '@mui/icons-material';

import InventoryIcon from '@mui/icons-material/Inventory';
import Products from '@/pages/ProductManagement/ProductList';
import ProductDetail from '@/pages/ProductManagement/ProductDetails';
// import NewProductDetail from '@/pages/ProductManagement/NewProductDetails';
import ProductDetailNew from '@/pages/ProductManagement/ProductDetailNew';
import ProductManagement from '@/pages/ProductManagement/ProductManagement';
import ProductCollections from '@/pages/ProductManagement/ProductCollections';
import CollectionDetail from '@/pages/ProductManagement/CollectionDetail';
import ProductCategories from '@/pages/ProductManagement/ProductCategories';
import CategoryDetail from '@/pages/ProductManagement/CategoryDetail';
import MarketManagerList from '@/pages/ProductManagement/MarketManagerList';

const ProductManagementRoute = [
  {
    name: 'Products',
    element: <Products />,
    icon: <InventoryIcon />,
    privilege: [99, 5, 7, 8, 9, 10, 17],
    subscriptions: [5],
    path: '/product-management/products',
    isSidebar: true,
  },
  {
    name: 'Add Product',
    element: <ProductDetail />,
    privilege: [99, 7, 8, 9, 10, 17],
    subscriptions: [5],
    path: '/product-management/products/add',
  },
  {
    name: 'Product Detail',
    // element: <ProductDetail />,
    element: <ProductDetailNew />,
    privilege: [99, 7, 8, 9, 10, 17],
    subscriptions: [5],
    path: '/product-management/products/detail/:productId',
  },
  {
    name: 'Collections',
    element: <ProductCollections />,
    icon: <InventoryIcon />,
    privilege: [99, 5, 7, 8, 9, 10, 17],
    subscriptions: [5],
    path: '/product-management/collections',
    isSidebar: true,
  },
  {
    name: 'Collection Detail',
    element: <CollectionDetail />,
    icon: <InventoryIcon />,
    privilege: [99, 5, 7, 8, 9, 10, 17],
    subscriptions: [5],
    path: '/product-management/collections/detail/:collectionId',
    isSidebar: false,
  },
  {
    name: 'Add Collection',
    element: <CollectionDetail />,
    icon: <InventoryIcon />,
    privilege: [99, 5, 7, 8, 9, 10, 17],
    subscriptions: [5],
    path: '/product-management/collections/add',
    isSidebar: false,
  },
  {
    name: 'Categories',
    element: <ProductCategories />,
    icon: <InventoryIcon />,
    privilege: [99, 5, 7, 8, 9, 10, 17],
    subscriptions: [5],
    path: '/product-management/Categories',
    isSidebar: true,
  },
  {
    name: 'add Category',
    element: <CategoryDetail />,
    icon: <InventoryIcon />,
    privilege: [99, 5, 7, 8, 9, 10, 17],
    subscriptions: [5],
    path: '/product-management/Categories/add',
    isSidebar: false,
  },
  {
    name: 'Category Detail',
    element: <CategoryDetail />,
    icon: <InventoryIcon />,
    privilege: [99, 5, 7, 8, 9, 10, 17],
    subscriptions: [5],
    path: '/product-management/Categories/detail/:categoryId',
    isSidebar: false,
  },
  {
    name: 'Market Manager',
    element: <MarketManagerList />,
    icon: <InventoryIcon />,
    privilege: [99, 5, 7, 8, 9, 10, 17],
    subscriptions: [5],
    path: '/product-management/market-manager',
    isSidebar: true,
  },
];

export default ProductManagementRoute;