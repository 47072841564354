/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputBase, Box, Button, Tooltip, Pagination } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { visuallyHidden } from '@mui/utils';
import { Search, DeleteForever, GridView, ViewList } from '@mui/icons-material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import ModalWrapper from '../../components/ui/ModalWrapper';
import CollectionForm from '../../components/Form/Article/CollectionForm';
import { handleModal } from '../../store/slices/modalSlice';
import CustomSelect from '../../components/Input/CustomSelect';
import EditContact from '../../components/Form/CRM/EditContact';
import bgColor from '../../lib/CRMHistoriesStatusColor'
import { CircularProgress } from '@mui/material';
import TruncateMarkup from 'react-truncate-markup';
import dummyImage from '@/assets/simple.png'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const CompaniesheadCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'author',
    numeric: false,
    disablePadding: false,
    label: 'Phone',
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: 'PIC',
  },
  {
    id: 'collection',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
];

const PersonHeadCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'customer',
    numeric: false,
    disablePadding: false,
    label: 'Customer',
  },
  {
    id: 'position',
    numeric: false,
    disablePadding: false,
    label: 'Position',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'Phone',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'contact-title',
    numeric: false,
    disablePadding: false,
    label: 'Contact Title',
  },
];

const ActivitiesheadCells = [
  {
    id: 'timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'customer',
    numeric: false,
    disablePadding: false,
    label: 'Customer',
  },
  {
    id: 'action-type',
    numeric: false,
    disablePadding: false,
    label: 'Action Type',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'update-by-user',
    numeric: false,
    disablePadding: true,
    label: 'Updated by User',
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, tableId, isOnlyUser } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* {
          isOnlyUser=== false && (
            <TableCell padding="checkbox" sx={{ paddingLeft: '10px' }}>
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
              />
            </TableCell>
          )
        } */}
          {tableId !== 2 && (
            <TableCell padding="checkbox" sx={{ paddingLeft: '10px' }}>
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
              />
            </TableCell>
            )
          }
        {
          tableId === 0 && CompaniesheadCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              // sortDirection={orderBy === headCell.id ? order : false}
              style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
            >
              {/* <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}> */}
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null} */}
              {/* </TableSortLabel> */}
            </TableCell>
          ))
        }
        {
          tableId === 1 && PersonHeadCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              // sortDirection={orderBy === headCell.id ? order : false}
              style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
            >
              {/* <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}> */}
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> */}
            </TableCell>
          ))
        }
        {
          tableId === 2 && ActivitiesheadCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              // sortDirection={orderBy === headCell.id ? order : false}
              style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
              >
                {/* <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}> */}
                {headCell.label}
                {/* {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel> */}
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  );
}


function ActionCell(props) {
  const { onClick } = props;
  return (
    <div className="">
      <DeleteForever onClick={onClick} className="text-red-600" />
    </div>
  );
}

function CategoryCell(props) {
  const { data } = props;
  return (
    <ul className="">
      {data?.map((cat) => (
        <li key={cat.id} className="text-xs list-disc">
          {cat?.name}
        </li>
      ))}
    </ul>
  );
}

function CollectionCell(props) {
  const { data } = props;
  return (
    <ul className="">
      {data?.map((col) => (
        <li key={col.id} className="text-xs list-disc">
          {col?.name}
        </li>
      ))}
    </ul>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export function NameCell(props) {
  const { name, id } = props;

  return (
    <div className="">
      <Link to={`/customers/detail/${id}`} className="text-[#2C6D47] font-semibold">
        {name}
      </Link>
    </div>
  );
}

export default function EnhancedTable({
  dataApi,
  isDataLoad,
  setIsDataLoad,
  setIsAllSelected,
  filterComponent,
  dataCompanies,
  dataPerson,
  dataActivities,
  dataLength,
  onChangePage,
  page,
  rowsPerPage,
  handleChangeRowsPerPage,
  tabValue,
  setSelected,
  selected,
  reload,
  activeClient,
  isOnlyUser,
  isGrid,
}) {
  const qClient = useQueryClient();
  // const [tabValue, setTabValue] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [contactId, setContactId] = useState(0);
  const [dense, setDense] = useState(false);
  const [articlesData, setArticlesData] = useState([]);
  const [companiesData, setCompaniesData] = useState([]);
  const [personContactData, setPersonContactData] = useState([]);
  const [ActivitiesData, setActivitiesData] = useState([]);

  const [table, setTable] = useState();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
      setArticlesData(dataApi);
      setCompaniesData(dataCompanies);
      setPersonContactData(dataPerson);
      setActivitiesData(dataActivities);
  }, [dataCompanies, dataPerson, dataActivities]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      if (tabValue === 0) {
        const newSelecteds = companiesData?.map((n) => n.id);
        // console.log(newSelecteds.length)
        setIsAllSelected(true);
        setSelected(newSelecteds);
      } else {
        const newSelecteds = personContactData?.map((n) => n.id);
        setIsAllSelected(true);
        setSelected(newSelecteds);
      }
      return;
    }
    setIsAllSelected(false);
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty articlesData.
  const CompaniesEmptyRows = companiesData.length ? 0 : Math.max(0, rowsPerPage.companies);
  const PersonEmptyRows = personContactData.length ? 0 : Math.max(0, rowsPerPage.person);

  return (
    <div className="w-full">
      <div className="relative">
        <TabPanel value={tabValue} index={0}>
          <div className="px-[22px] py-[30px]">{filterComponent[0]}</div>
          { companiesData.length > 0 ? !isGrid ? (
            <div className='ml-5'>
              <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 2xl:grid-cols-6 gap-6 mt-4'>                
                {
                  companiesData?.slice(0, rowsPerPage.companies).map((row, index) => {
                    return (
                      <div key={row.id} className="w-full relative">
                        <Link to={`/customers/detail/${row.id}`} className="text-black">
                          <div className="h-full w-full gap-x-4 items-center hover:bg-[#F9F8F8] border border-transparent rounded-md pb-[16px] space-y-2 group">
                            <div
                              className={`relative w-full h-[15vh] flex items-center justify-center border-black border-[1.5px] border-dashed p-3 rounded-md `}
                            >
                              <img
                                src={row?.logo || dummyImage}
                                alt=""
                                className={`scale-50 object-cover ${!row?.logo && "grayscale"}`}
                              />
                            </div>
                            <TruncateMarkup className="space-y-1" lines={2}>
                              <div className="capitalize text-center font-medium group-hover:text-[#2C6D47] group-hover:font-semibold">{row?.name}</div>
                            </TruncateMarkup>
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div className="w-full py-4 mx-auto">
                <div className="my-5 flex items-center justify-end gap-x-5">
                <TablePagination
                  rowsPerPageOptions={[15, 20, 25, 50, 100]}
                  component="div"
                  count={dataLength.companies}
                  rowsPerPage={rowsPerPage.companies}
                  page={page.companies}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </div>
              </div>
            </div>
          ):(
            <>
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
                  <EnhancedTableHead
                    numSelected={selected.length}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={companiesData.length}
                    tableId={0}
                    isOnlyUser={isOnlyUser}
                  />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                          articlesData.slice().sort(getComparator(order, orderBy)) */}
                    {companiesData.slice(0, rowsPerPage.companies).map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.id} selected={isItemSelected}>
                          {isOnlyUser === false && (
                            <TableCell padding="checkbox" sx={{ paddingLeft: '10px' }}>
                              <Checkbox onClick={(event) => handleClick(event, row.id)} color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                            </TableCell>
                          )}
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.member_id}
                          </TableCell>
                          <TableCell align="left">
                            <NameCell name={row.name} id={row.id} />
                          </TableCell>
                          <TableCell align="left">{row.phone === 'null' ? '' : row.phone}</TableCell>
                          <TableCell align="left">{row.member_attributes?.PIC}</TableCell>
                          <TableCell align="left">{row?.email}</TableCell>
                        </TableRow>
                      );
                    })}
                    {CompaniesEmptyRows > 0 && (
                      <TableRow style={{ height: (dense ? 33 : 53) * CompaniesEmptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[15, 20, 25, 50, 100]}
                component="div"
                count={dataLength.companies}
                rowsPerPage={rowsPerPage.companies}
                page={page.companies}
                onPageChange={onChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : 
            (
              <CircularProgress />
            )
          }
          {/* </Paper> */}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {/* <Paper className='w-full h-full relative rounded-lg bg-white' elevation={2}> */}
          <div className="px-[22px] py-[30px]">{filterComponent[1]}</div>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={personContactData.length}
                tableId={1}
              />
              <TableBody>
                {stableSort(personContactData, getComparator(order, orderBy))
                  // .slice(page.person * rowsPerPage.person, page.person * rowsPerPage.person + rowsPerPage.person)
                  .slice(0, rowsPerPage.person)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.id} selected={isItemSelected}>
                        {isOnlyUser === false && (
                          <TableCell padding="checkbox" sx={{ paddingLeft: '10px' }}>
                            <Checkbox onClick={(event) => handleClick(event, row.id)} color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                          </TableCell>
                        )}

                        <TableCell align="left">
                          <div
                            className="cursor-pointer text-[#2C6D47] font-semibold"
                            onClick={() => {
                              setContactId(row.id);
                              dispatch(handleModal({ modalId: '', componentName: 'EditContact' }));
                            }}
                          >
                            {row.name}
                          </div>
                        </TableCell>
                        <TableCell align="left">{row.member?.name}</TableCell>
                        <TableCell align="left">{row.position}</TableCell>
                        <TableCell align="left">{row.phone}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">{row.contact_title?.name}</TableCell>
                      </TableRow>
                    );
                  })}
                {PersonEmptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * PersonEmptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[15, 20, 25]}
            component="div"
            count={dataLength.person}
            rowsPerPage={rowsPerPage.person}
            page={page.person}
            onPageChange={onChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {/* </Paper> */}
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          {/* <Paper className='w-full h-full relative rounded-lg bg-white' elevation={2}> */}
          <div className="px-[22px] py-[30px]">{filterComponent[2]}</div>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={ActivitiesData.length}
                tableId={2}
              />
              <TableBody>
                {stableSort(ActivitiesData, getComparator(order, orderBy))
                  // .slice(page.person * rowsPerPage.person, page.person * rowsPerPage.person + rowsPerPage.person)
                  .slice(0, rowsPerPage.activities)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const date = new Date(row.timestamp).toLocaleString('en-US', { dateStyle: "long", timeStyle: "short", hour12: false});
                    const dateFormat = moment(row.timestamp).format("YYYY-MM-DD hh:MM")

                    // const bgColor = {
                    //   Create: '#D4FFD4',
                    //   Update: '#FFFBE1',
                    //   Delete: '#FFE1E1',
                    //   Note: "red"
                    // };

                    return (
                      <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.id} selected={isItemSelected}>
                        <TableCell align="left">{row.timestamp === 'null' ? '' : dateFormat}</TableCell>
                        <TableCell align="left">
                          <NameCell name={row.member?.name} id={row.member?.id} />
                        </TableCell>
                        <TableCell align="left">
                          <span className="p-2 shadow-sm rounded-md" style={{ backgroundColor: bgColor[row.action] || "transparent" }}>
                            {row.action}
                          </span>
                        </TableCell>
                        <TableCell align="left">{row.description === 'null' ? '' : row.description}</TableCell>
                        <TableCell align="left">{row.user}</TableCell>
                      </TableRow>
                    );
                  })}
                {PersonEmptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * PersonEmptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[15, 20, 25, 50, 100]}
            component="div"
            count={dataLength.activities}
            rowsPerPage={rowsPerPage.activities}
            page={page.activities}
            onPageChange={onChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {/* </Paper> */}
        </TabPanel>
      </div>
      <ModalWrapper componentName="EditContact" header={isOnlyUser === true ? "Detail Contact" : "Edit Contact"} maxWidth="sm">
        <EditContact contactId={contactId} reload={reload} activeClient={activeClient} authorize={isOnlyUser} />
      </ModalWrapper>
    </div>
  );
}
