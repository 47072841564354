import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Fade, IconButton, Popover, Slide, Tabs, Tab, ButtonBase } from '@mui/material';
import { ArrowDropDown, Menu, PhotoCamera, Star, StarBorder } from '@mui/icons-material';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';

import { handleSideBar, setActiveTabs } from '../../../store/slices/sidebarTaskSlice';
import DetailContext from '../../../pages/Task/Projects/DetailContext';

import projectApi from '../../../api/task/project';
import teamApi from '../../../api/task/team';

import styles from './HeaderTask.module.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Input = styled('input')({ display: 'none' });

const teamHeaderTaskTheme = createTheme({
  components: {
    MuiTabs: { styleOverrides: { root: ({ theme }) => theme.unstable_sx({ minHeight: 28 }) } },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            textTransform: 'capitalize',
            minHeight: 32,
            height: 32,
            minWidth: 'auto',
            marginRight: '1rem',
          }),
      },
    },
  },
});

export default function BaseHeaderTask({ tab }) {
  const myContext = useContext(DetailContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const isStatusHidden = useSelector((state) => state.task.isStatusHidden);
  const [value, setValue] = useState(useSelector((state) => state.sidebar.activeTabs) || 0);
  const [projects, setProject] = useState({});
  const [teamMembers, setTeamMembers] = useState(false);
  const [teamId, setTeamId] = useState(false);
  const [projectFavorite, setProjectFavorite] = useState(false);
  const [anchorProject, setAnchorProject] = useState(null);

  const updateProject = useCallback(
    (projectId, data) => {
      const { updateTaskProject } = projectApi();
      updateTaskProject(projectId, data)
        .then((res) => {
          if (res.status === 200 || res.data) {
            myContext.dispatchProject('GET');
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    [myContext]
  );

  const handleActiveTabs = (event, newValue) => {
    const tabText = event.target.innerText.toLowerCase().replace(/\s+/g, '-');
    const currLoc = location.pathname;
    const activeLoc = myContext.urlModified(currLoc);

    navigate(`${activeLoc}/${tabText}`, { replace: false });
    setValue(newValue);
    dispatch(setActiveTabs(newValue));
  };

  const handleChangeFavorite = (favorite) => {
    updateProject(projects.id, { is_favorite: favorite });
  };
  const handleChangeProjectIcon = (icon) => {
    if (icon) {
      updateProject(projects.id, { icon });
    }
  };

  const getTeamUsers = useCallback((team) => {
    const { getTeamDetail } = teamApi();
    getTeamDetail(team)
      .then((res) => {
        if (res.status === 200 || res.data) {
          setTeamMembers(res.data);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  useEffect(() => {
    const teams = projects.team;
    if (teams && teamId !== teams.id) {
      setTeamId(teams.id);
      getTeamUsers(teams.id);
    }
  }, [teamId, projects.team, getTeamUsers]);

  useEffect(() => {
    const teams = projects.team;
    if (!teams) {
      setTeamId(false);
      setTeamMembers(false);
    }
  }, [teamMembers, projects.team, getTeamUsers]);

  useEffect(() => {
    if (myContext) {
      setProject(myContext.projects);
      setProjectFavorite(myContext.projects?.is_favorite);
    }
  }, [myContext]);

  const handleClickProject = (event) => {
    setAnchorProject(event.currentTarget);
  };

  const openProject = Boolean(anchorProject);
  const idProject = openProject ? 'simple-popover' : undefined;

  return (
    <ThemeProvider theme={teamHeaderTaskTheme}>
      <div style={{ flex: '1 1 auto' }} className="w-full flex flex-1 justify-between border-r border-gray-300 pr-4">
        <Slide direction="right" in={isSidebarOpen} mountOnEnter unmountOnExit>
          <Menu className="my-auto mr-2" onClick={() => dispatch(handleSideBar())} />
        </Slide>
        <Slide direction="right" in={!isSidebarOpen} mountOnEnter unmountOnExit>
          <Menu className="my-auto mr-2" onClick={() => dispatch(handleSideBar())} />
        </Slide>
        <div className="flex items-center mr-4">
          <Box className="border rounded-lg border-gray-300">
            {projects?.icon ? (
              <label htmlFor="icon-button-file">
                <Input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  onChange={(e) => {
                    handleChangeProjectIcon(e.target.files[0]);
                  }}
                />
                <img className={styles.projectIcon} src={projects.icon} alt="project icon" loading="lazy" />
              </label>
            ) : (
              <label htmlFor="icon-button-file">
                <Input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  onChange={(e) => {
                    handleChangeProjectIcon(e.target.files[0]);
                  }}
                />
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <PhotoCamera className='text-bz-green' />
                </IconButton>
              </label>
            )}
          </Box>
        </div>
        <div className="flex flex-1 flex-col items-start">
          <div className="flex items-center space-x-2 mt-2">
            <span>{projects?.name}</span>
            <ArrowDropDown onClick={handleClickProject} className={anchorProject ? 'border border-gray-300 shadow rounded-md' : ''} />
            {projectFavorite ? (
              <Star
                color="primary"
                onClick={() => {
                  handleChangeFavorite(!projectFavorite);
                }}
              />
            ) : (
              <StarBorder
                onClick={() => {
                  handleChangeFavorite(!projectFavorite);
                }}
              />
            )}
          </div>
          <div style={{ flex: '1 0 auto' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleActiveTabs} aria-label="project tabs" className="bz-tabs">
                {tab && tab.length > 0 && tab.map((tb) => <Tab key={tb.name} className="p-0" label={tb.name} {...a11yProps(tb.name)} />)}
              </Tabs>
            </Box>
          </div>
        </div>
        <Popover
          id={idProject}
          open={openProject}
          anchorEl={anchorProject}
          onClose={() => setAnchorProject(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Fade in={openProject}>
            <Box className="flex relative flex-col py-4 space-y-2 rounded-lg bg-[#ffffff]">
              <ButtonBase
                className="pl-4 pr-4"
                onClick={() => {
                  dispatch({ type: 'task/setIsStatusHide', payload: !isStatusHidden });
                }}
              >
                {isStatusHidden ? 'Show' : 'Hide'} All Status
              </ButtonBase>
              {/* <div style={{ borderBottom: 'var(--border)', borderWidth: 1 }} /> */}
              {/* <div className="pl-4 pr-4 text-base">Another action</div> */}
            </Box>
          </Fade>
        </Popover>
      </div>
    </ThemeProvider>
  );
}
