import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { Avatar, InputAdornment, InputBase, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, CircularProgress, Select, OutlinedInput, MenuItem, Checkbox } from '@mui/material';
import { GridView, Search, ViewList } from '@mui/icons-material';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { toastSuccess } from '@/constants/ToastConfig';
import { handleModal } from '@/store/slices/modalSlice';
import BzThumbnail from '@/assets/simple.png'
import POSApi from '@/api/pos/posApi';
import * as XLSX from 'xlsx';

function PairCustomerLogin({formatNominal, formatContact, setIsFetching, getAllCustomers}) {
  const fetchData = async (apiFunction, params) => {
    try {
      const res = params ? await apiFunction(params) : await apiFunction();
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const activeClient = useSelector((state) => state.modal.modalId);
  const outletCustomerData = useSelector((state) => state.modal.modalData);
  const dispatch = useDispatch();
  const { outletId } = useParams();

  const [isSucceedPair, setIsSucceedPair] = useState(false);
  
  const [succeedResponseData, setSucceedResponseData] = useState([]);
  const [failedResponseData, setFailedResponseData] = useState([]);
  
  const [selected, setSelected] = useState([]);  
  const [_, forceUpdate] = useState(0);

  const [isFetchingCustomers, setIsFetchingCustomers] = useState(true);
  const [nonUserCustomers, setNonUserCustomers] = useState([]);
  const customers = useCallback((params) => fetchData(POSApi().getAllOUtletCustomers, params), [outletId]);
  const getAllNonUserCustomers = useCallback(
    async (paramCustomers) => {
      const [customerList] = await Promise.all([customers(paramCustomers)]);
      if (customerList) {
        setNonUserCustomers(customerList.results || []);
      }
      setIsFetchingCustomers(false);
    },
    [customers, outletId]
  );

  useEffect(() => {
      setIsFetchingCustomers(true);
      const newParams = {
        outlet: outletId,
        max_size: true,
        user__isnull: true,
      }
      getAllNonUserCustomers(newParams);
  }, [getAllNonUserCustomers]);

  const handleSelectAllClick = (event) => {
    if (selected.length === nonUserCustomers.length) {
      setSelected([]);
      return;
    } else {
      const newSelecteds = nonUserCustomers?.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const submitPairCustomer = () => {
    const { pairCustomerUser } = POSApi();

    const newData = {
      customer_ids: selected
    }

    try {
      pairCustomerUser(outletId, newData)
      .then((res) => {
        const {data} = res;
        const newlyCreatedData = data.filter(item => item.NewlyCreated === true);
        const failedData = data.filter(item => item.NewlyCreated === false);

        console.log({newlyCreatedData});
        console.log({failedData});
        
        // Update state
        setSucceedResponseData(newlyCreatedData);
        setFailedResponseData(failedData);
        setIsSucceedPair(true);
        setIsFetching(true);
        const newParams = {
          outlet: outletId,
          max_size: true,
          ordering: 'member__name',
        };
        getAllCustomers(newParams);
        forceUpdate((prev) => prev + 1);
        toast('Success', {
          ...toastSuccess,
        });
      })
      .catch((err) => {
        console.error(err);
      });
    } catch (error) {
      console.error(error)
    }
  }

  const DownloadNewUser = () => {
    if (succeedResponseData.length === 0) {
      Swal.fire({
        title: 'No Data!',
        text: 'No data available to download.',
        icon: 'info',
      });
      return;
    }

    // Menyiapkan data untuk di-export ke Excel
    const worksheetData = succeedResponseData.map((item) => ({
      'Student Id': item.StudentId,
      'Password': item.Password,
      'Name': item.Name,
      'Class': item.Class,
    }));

    // Membuat worksheet dan workbook
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'New User Logins');

    // Mengonversi workbook ke format Excel dan mengunduhnya
    XLSX.writeFile(workbook, 'New_User_Logins.xlsx');
  };
  
  return (
    <div className="w-full space-x-5 relative" style={{ overflowY: "hidden" }}>
      {
        !isFetchingCustomers ? 
          isSucceedPair !== true ? (
            <div className="space-y-6">
              <div className="w-full border">
                <div className="max-h-[600px] overflow-y-auto">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            indeterminate={selected.length > 0 && selected.length < nonUserCustomers.length}
                            checked={nonUserCustomers.length > 0 && selected.length === nonUserCustomers.length}
                            onClick={handleSelectAllClick}
                            inputProps={{ 'aria-label': 'select all desserts' }}
                          />
                        </TableCell>
                        <TableCell align="center" className="!font-bold">
                          Student ID
                        </TableCell>
                        <TableCell align="center" className="!font-bold">
                          Name
                        </TableCell>
                        <TableCell align="center" className="!font-bold" width={200}>
                          Class
                        </TableCell>
                        <TableCell align="center" className="!font-bold">
                          Balance
                        </TableCell>
                        <TableCell align="center" className="!font-bold">
                          Contact
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {nonUserCustomers.length > 0 &&
                        nonUserCustomers.map((coll, idx) => {
                          const student_id = coll?.member?.on_list_attributes?.student_id || '-';
                          const name = coll?.member?.name || '-';
                          const className = coll?.member?.on_list_attributes?.class || '-';
                          const balance = coll?.balance || '-';
                          const contact = formatContact(coll?.member?.on_list_attributes?.contact);
                          const isItemSelected = isSelected(coll.id);
                          const labelId = `enhanced-table-checkbox-${idx}`;

                          // row should have red background if balance < 0
                          const rowClass = parseFloat(balance) < 0 ? 'bg-red-200' : '';

                          return (
                            <TableRow role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={coll.id} selected={isItemSelected}>
                              <TableCell padding="checkbox">
                                <Checkbox onClick={(event) => handleClick(event, coll.id)} color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                              </TableCell>
                              <TableCell align="center">{student_id}</TableCell>
                              <TableCell align="left">{name}</TableCell>
                              <TableCell align="left">{className}</TableCell>
                              <TableCell align="right">{formatNominal(balance)}</TableCell>
                              <TableCell align="right">{contact}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </div>
              </div>
              <div className="w-full flex justify-end">
                <button
                  className={`
                    flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 text-white
                    ${selected.length ? 'bg-[#2c6d47] cursor-pointer ' : 'bg-[#2c6d4723] cursor-default'}
                  `}
                  variant="contained" 
                  onClick={submitPairCustomer}
                  disabled={selected.length === 0}
                >
                  Submit
                </button>
              </div>
            </div>
          ):(
            <div className="space-y-6 ">
              <div className='space-y-6'>
                <div className='w-full flex justify-center'>
                  <p className='font-semibold text-lg'>New Users Created</p>
                </div>
                <div className="w-full border">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" className="!font-bold">
                          Student ID
                        </TableCell>
                        <TableCell align="center" className="!font-bold">
                          Password
                        </TableCell>
                        <TableCell align="center" className="!font-bold" width={450}>
                          Name
                        </TableCell>
                        <TableCell align="center" className="!font-bold">
                          Class
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {succeedResponseData.length > 0 &&
                        succeedResponseData.map((coll, idx) => {

                          return (
                            <TableRow key={idx}>
                              <TableCell align="center">{coll?.StudentId || "-"}</TableCell>
                              <TableCell align="left">{coll?.Password || "-"}</TableCell>
                              <TableCell align="left">{coll?.Name || "-"}</TableCell>
                              <TableCell align="left">{coll?.Class || "-"}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </div>
                <div className="w-full flex justify-end">
                  <button
                    className={`
                      flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 text-white
                      ${succeedResponseData.length > 0 ? 'bg-[#2c6d47] cursor-pointer ' : 'bg-[#2c6d4723] cursor-default'}
                    `}
                    variant="contained" 
                    onClick={DownloadNewUser}
                    disabled={succeedResponseData.length === 0}
                  >
                    Download New User Login
                </button>
                </div>
              </div>
              <div className='space-y-6'>
              <div className='w-full flex justify-center'>
                  <p className='font-semibold text-lg'>Failed to Create Users</p>
                </div>
                <div className="w-full border">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" className="!font-bold">
                          Student ID
                        </TableCell>
                        <TableCell align="center" className="!font-bold">
                          Password
                        </TableCell>
                        <TableCell align="center" className="!font-bold" width={450}>
                          Name
                        </TableCell>
                        <TableCell align="center" className="!font-bold">
                          Class
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {failedResponseData.length > 0 &&
                        failedResponseData.map((coll, idx) => {

                          return (
                            <TableRow key={idx}>
                              <TableCell align="center">{coll?.StudentId || "-"}</TableCell>
                              <TableCell align="left">{coll?.Password || "-"}</TableCell>
                              <TableCell align="left">{coll?.Name || "-"}</TableCell>
                              <TableCell align="left">{coll?.Class || "-"}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          )
        :
        <CircularProgress />
          }
    </div>
  )
}

export default PairCustomerLogin