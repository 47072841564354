/* eslint-disable */

import React, { useCallback, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import SortableTree from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Button, Card, CardContent, FormControl, MenuItem, Select, Stack } from '@mui/material';
import { Add, ArrowForward, Delete, Edit, Factory } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { Content, ContentBody } from '../../../components/ui/Content/Content';
import { shortText } from '../../../lib/StringHelper';
import PageForm from '../../../components/Form/Page/PageForm';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import { handleModal } from '../../../store/slices/modalSlice';
import request from '../../../helper/axiosUtil';
import BaseMainTitle from '../../../components/BaseMainTitle';
import BZHelmet from '../../../utils/BZHelmet';
import useDelete from '../../../hooks/useDelete';
import usePost from '../../../hooks/usePost';
import usePatchOrPut from '../../../hooks/usePatchOrPut';
import useGet from '../../../hooks/useGet';
import HandleWatchingState from '../../../helper/HandleWatchingState';
import { toastLoading, toastSuccess, toastError } from '../../../constants/ToastConfig';
import usePostExtra from '../../../hooks/usePostExtra';
import renderError from '../../../utils/Error/renderError';
import { useSnackbar } from 'notistack';
import BZButton from '../../../components/Button/BZButton';
import store from '@/store';

const MySwal = withReactContent(Swal);

const transPageAdd = 'modals.page_add.';
const transPageDelete = 'modals.page_delete.';
const transAlert = 'alert.';

function checkArrayForId(array, id) {
  return array?.some((obj) => obj.id.toString() === id.toString());
}

function renderTemplateInfo(data) {
  return data?.map((d) => (
    <div className="mb-3" key={d?.name}>
      <span>
        <strong>{d?.name}</strong> :{' '}
      </span>
      <a href={d?.bucket_name} target="_blank" rel="noreferrer" className="break-words">
        {d.bucket_name.includes('https://') ? d.bucket_name : ''}
      </a>
    </div>
  ));
}
function filterArr(arr) {
  if (arr.length > 0) {
    return arr.reduce((a, b) => a.filter((c) => b.includes(c)));
  }
  return false;
}
const buildData = ['Preview', 'Publish'];

function SiteStructureNew() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [treeData, setTreeData] = useState([]);
  const [buildTypes, setBuildTypes] = useState('Preview');
  const dispatch = useDispatch();
  const TEMPLATE_ID = useSelector((state) => state.content.activeTemplate);

  const languageId = useSelector((state) => state.client.activeAdminLanguage.id);
  const siteId = useSelector((state) => state.content.activeSite);
  const modal = useSelector((state) => state.modal);
  const templateList = useSelector((state) => state.content.templateList);
  const isGroups = useSelector((state) => state.auth.isGroups);
  const clientSubs = useSelector((state) => state.client.clientSubscriptions);
  const isSuperUser = useSelector((state) => state.auth.isSuperUser);

  const statusTemplate = HandleWatchingState(TEMPLATE_ID);

  const [activeTemplate, setActiveTemplate] = useState(useSelector((state) => (state.content.activeTemplate && Number(state.content.activeTemplate)) || ''));

  const isTemplateShowing = () => {
    const subs = [[1, 2], clientSubs];
    const groups = [[1, 2, 3, 4, 6], isGroups];

    let show = false;
    if (!isSuperUser) {
      if (isGroups.includes(7)) {
        show = false;
      }
      if (isGroups && filterArr(groups).length > 0) {
        show = true;
      }
    } else if (isSuperUser) {
      if (clientSubs && filterArr(subs).length > 0) {
        show = true;
      } else {
        show = false;
      }
    }
    return show;
  };

  const destroyPage = useDelete({
    confText: t(`${transPageDelete}confirmation_text`),
    confTitle: t(`${transPageDelete}title`),
    success: t(`${transAlert}delete_page_in_template_success`),
    validate: ['structure'],
  });

  function generateNprops(node) {
    const html = (
      <div title={node.name} className="">
        <span className="float-left">{`${shortText(node.name, 15)} `}</span>
        <div className="flex  right-0 absolute">
          <Button
            title="Add child"
            className="min-w-0 mx-1 w-6 h-6 p-0"
            size="small"
            variant="contained"
            color="success"
            onClick={() => dispatch(handleModal({ modalId: node.id, componentName: 'addPage' }))}
          >
            <Add fontSize="small" />
          </Button>
          <Button
            title="Update this item"
            className="min-w-0 mx-1 w-6 h-6 p-0"
            size="small"
            variant="contained"
            onClick={() => dispatch(handleModal({ modalId: node.id, componentName: 'editPage' }))}
          >
            <Edit fontSize="small" />
          </Button>

          <Button
            // disabled={node.parent === undefined}
            title="Delete this item"
            className="min-w-0 mx-1 w-6 h-6 p-0"
            size="small"
            variant="contained"
            color="error"
            onClick={() => destroyPage(`pages/${node.id}`)}
          >
            <Delete fontSize="small" />
          </Button>
          <Button title={`To Page ${node.name}`} size="small" variant="contained" className="min-w-0 mx-1 w-6 h-6 p-0">
            <Link to={`/page/detail/${node.id}`}>
              <ArrowForward fontSize="small" />
            </Link>
          </Button>
        </div>
      </div>
    );
    return html;
  }

  const handleBuildTypeChange = (value) => {
    setBuildTypes(value);
  };

  useGet({
    url: `templates/${checkArrayForId(templateList, TEMPLATE_ID) ? TEMPLATE_ID : templateList[0]?.id}/get_template_structure/?language=${languageId}`,
    validate: 'structure',
    onSuccess: (e) => {
      const applyExpand = (arr) => {
        return arr.map((val) => {
          if (val.children) {
            return {
              ...val,
              expanded: true,
              children: applyExpand(val.children),
            };
          }
          return { ...val, expanded: true };
        });
      };

      const result = applyExpand(e?.data?.pages);

      setTreeData(result ?? []);
    },

    isEnabled: statusTemplate,
  });

  const { data: templateDetail } = useGet({
    url: `templates/${checkArrayForId(templateList, TEMPLATE_ID) ? TEMPLATE_ID : templateList[0]?.id}`,
    validate: 'template',
    isEnabled: statusTemplate,
  });

  const { mutate: createPage } = usePostExtra({
    url: 'pages',
    validate: ['structure'],
    onSuccess: (e) => {
      if (e && [200, 201].includes(e.request.status)) {
        enqueueSnackbar('success', { variant: 'success' });
        // navigate('../article', { replace: true });
      } else {
        if (!modal.modalId) {
          enqueueSnackbar('success', { variant: 'success' });
        } else {
          enqueueSnackbar('error', { variant: 'error' });
          renderError({ data: JSON.parse(e.request.response) });
        }
      }
    },
  });

  const { data: pageDetail } = useGet({ url: `pages/${modal.modalId}`, validate: 'pages', isEnabled: modal.componentName === 'editPage' });

  const { mutate: updatePage } = usePatchOrPut({ url: `pages/${modal.modalId}`, validate: ['pages', 'structure'], method: 'patch' });

  const { mutate: patchStructure } = usePatchOrPut({
    url: `templates/${checkArrayForId(templateList, TEMPLATE_ID) ? TEMPLATE_ID : templateList[0]?.id}/update_template_structure`,
    validate: ['structure'],
    method: 'patch',
  });

  const buildPages = (data) => request({ url: `/templates/${checkArrayForId(templateList, TEMPLATE_ID) ? TEMPLATE_ID : templateList[0]?.id}/run_build/`, method: 'post', data });

  let onToast;
  const { mutateAsync: buildPage } = useMutation(buildPages, {
    onMutate: () => {
      MySwal.fire({
        title: `Build  ${templateDetail?.data?.name}`,
        html: `<div>Please Wait</div>`,
        buttons: false,
        didOpen: () => {
          Swal.showLoading();
        },
        allowOutsideClick: false,
      });
    },
    onSuccess: (e) => {
      console.log('🚀 ~ file: SiteStructureNew.jsx:274 ~ SiteStructureNew ~ e:', e);
      onToast = new Promise((resolve, reject) => {
        if (e && [200, 201].includes(e.request.status)) {
          setTimeout(() => {
            resolve('Success');
          }, 500);
        } else {
          const error = new Error('Request failed');
          error.data = JSON.parse(e.request.response);
          setTimeout(() => {
            reject(error);
          }, 500);
        }
      });

      toast.promise(onToast, {
        pending: {
          render() {
            return 'Loading';
          },
          ...toastLoading,
        },
        success: {
          render({ data }) {
            return ` ${data}`;
          },
          ...toastSuccess,
        },
        error: {
          render() {
            return 'Error';
          },
          ...toastError,
        },
      });
      if (e.request.status === 200) {
        MySwal.close();

        MySwal.fire({
          title: `Build  ${templateDetail?.data?.name} Success`,
          html: `<div style='color:blue'>${e.data.message}</div>`,
        });
      } else {
        MySwal.close();

        MySwal.fire({
          title: `Build  ${templateDetail?.data?.name} Error`,
          html: `<div style='color:red'>${e.message}</div>`,
        });
      }
    },
  });

  let act = '';
  if (templateList && templateList.length) {
    if (!act) {
      act = templateList[0].id;
    }
    if (templateList.some((item) => item.id === Number(activeTemplate))) {
      act = activeTemplate;
    }
  }

  const handleActiveTemplate = useCallback(
    (templateId) => {
      setActiveTemplate(templateId);
      store.dispatch({
        type: 'content/setActiveTemplate',
        payload: templateId,
      });
      dispatch(getActiveTemplateStructure(templateId));
    },
    [dispatch]
  );

  return (
    <>
      <BZHelmet title={t('pages.site_structure.title')} description="" content="" />
      <div>
        <BaseMainTitle title={t('pages.site_structure.title')} />
        <ContentBody>
          <Card>
            {/* {overlay} */}
            {/* <CardHeader title="SiteStructure" /> */}
            <CardContent>
              <div className="flex justify-between">
                <div className="">
                  <div className="flex items-center">
                    <div className=""> Template Name:</div>
                    {isTemplateShowing() ? (
                      <>
                        <div className="w-100 flex px-4 items-center justify-center space-x-2" style={{ color: '#c2c7d0' }}>
                          <FormControl sx={{ width: '100%' }}>
                            <Select sx={{ width: '100%', px: 0.2, py: 0.2 }} value={act} onChange={(e) => handleActiveTemplate(e.target.value)} displayEmpty className={''}>
                              <MenuItem disabled value="">
                                <em>Template</em>
                              </MenuItem>
                              {templateList &&
                                templateList.length > 0 &&
                                templateList?.map((cl) => (
                                  <MenuItem key={cl.name} value={cl.id}>
                                    {cl.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </div>
                      </>
                    ) : (
                      templateDetail?.data?.name
                    )}
                  </div>
                  <div className="">
                    Template ID:
                    {templateDetail?.data?.id}
                  </div>
                </div>

                <Stack direction="row" spacing={2}>
                  <FormControl sx={{ minWidth: 128 }}>
                    <Select sx={{ width: '100%', px: 0.2, py: 0.2 }} onChange={(e) => handleBuildTypeChange(e.target.value)} value={buildTypes} displayEmpty autoWidth>
                      <MenuItem disabled value="">
                        <em>Build type</em>
                      </MenuItem>
                      {buildData?.map((bt) => (
                        <MenuItem key={bt} value={bt}>
                          {bt}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <Button
                    variant="contained"
                    startIcon={<Factory />}
                    className="w-full whitespace-nowrap"
                    style={{ height: 'fit-content' }}
                    onClick={() => buildPage({ site_id: siteId, state: buildTypes.toLowerCase() })}
                    disabled={!buildTypes}
                  >
                    {t('build')}
                  </Button> */}
                  <BZButton variant={!buildTypes ? 'disabled' : 'primary'} onClick={() => buildPage({ site_id: siteId, state: buildTypes.toLowerCase() })}>
                    {t('build')}
                  </BZButton>

                  {/* <Button
                    variant="contained"
                    startIcon={<Add />}
                    className="w-full whitespace-nowrap"
                    style={{ height: 'fit-content' }}
                    onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addPage' }))}
                    disabled={treeData?.length > 0}
                  >
                    {t('pages.site_structure.add_page')}
                  </Button> */}

                  <BZButton variant={treeData?.length > 0 ? 'disabled' : 'secondary'} onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addPage' }))}>
                    {t('pages.site_structure.add_page')}
                  </BZButton>
                </Stack>
              </div>
            </CardContent>
            <CardContent className="relative">
              {treeData && (
                <div
                  style={{
                    height: '100vh',
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <SortableTree
                    treeData={treeData?.map((val) => ({ ...val, expanded: true }))}
                    onChange={(newTreeData) => setTreeData(newTreeData)}
                    onMoveNode={(newData) => patchStructure({ page_update: newData.treeData })}
                    slideRegionSize={0}
                    generateNodeProps={({ node }) => ({ title: generateNprops(node) })}
                  />
                </div>
              )}
              <div
                style={{
                  width: '35%',
                  position: 'absolute',
                  top: '0',
                  right: '2rem',
                  zIndex: '2',
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '1.25rem',
                    border: '1px solid black',
                  }}
                >
                  <span>
                    <strong>Template Information</strong>
                  </span>
                  {renderTemplateInfo(templateDetail?.data?.template_information)}
                </div>
              </div>
            </CardContent>
            <Button />
          </Card>
        </ContentBody>

        <ModalWrapper componentName="addPage" header={t(`${transPageAdd}title_add`)} maxWidth="sm">
          <PageForm onFormSubmit={createPage} />
        </ModalWrapper>
        <ModalWrapper componentName="editPage" header={t(`${transPageAdd}title_update`)} maxWidth="sm">
          <PageForm defaultValues={pageDetail?.data} key={pageDetail?.data?.id} onFormSubmit={updatePage} />
        </ModalWrapper>
      </div>
    </>
  );
}

export default SiteStructureNew;
