/* eslint-disable */
import qs from 'qs';
import { useQuery } from '@tanstack/react-query';
import request from '../helper/axiosUtil';
import requestHR from '../helper/axiosUtilHR';
import store from '../store';

export const CustomFetch = async ({ url, validate, isEnabled = true, onSuccess, queryString, module = 'bzpublish' }) => {
  const response = await request({ url: `/${url}${qs.stringify(queryString, { addQueryPrefix: true, arrayFormat: 'repeat' })}` });
  if (response?.response?.status === 401 || response?.response?.status === 0) {
    store.dispatch({ type: 'auth/setLogout' });
    store.dispatch({ type: 'client/setActiveClient', payload: false });
    store.dispatch({ type: 'lang/setLanguageList', payload: false });
  }
  return response;
};


export default function useGet({ url, validate, isEnabled = true, onSuccess, queryString, module = "bzpublish"}) {
  // const fetchDetail = () => {
  //   return request({ url: `/${url}${qs.stringify(queryString, { addQueryPrefix: true, arrayFormat: 'repeat' })}` });
  // };


  const fetchDetail = async () => {
    const response = await request({ url: `/${url}${qs.stringify(queryString, { addQueryPrefix: true, arrayFormat: 'repeat' })}` });
    if (response?.response?.status === 401 || response?.response?.status === 0) {
      store.dispatch({ type: 'auth/setLogout' });
      store.dispatch({ type: 'client/setActiveClient', payload: false });
      store.dispatch({ type: 'lang/setLanguageList', payload: false });
    }
    return response;
  };



  const fetchDetailHR = () => {
    return requestHR({ url: `/${url}${qs.stringify(queryString, { addQueryPrefix: true, arrayFormat: 'repeat' })}` });
  };

  const requestSwitcher = module === 'bzpublish' ? fetchDetail : fetchDetailHR

  return useQuery([validate], requestSwitcher, {
    onSuccess,
    enabled: isEnabled,
    refetchOnWindowFocus: false,
  });
};
