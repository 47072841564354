/* eslint-disable */
import React, { useState, useMemo, useEffect } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// import toTitle from 'to-title';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, ArcElement, Legend } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, ArcElement, BarElement, Tooltip, Legend, ChartDataLabels);

function colorGenerator(length) {
  const colors = [];
  const max = 255;

  for (let i = 0; i < length; i++) {
    colors.push([`rgba(${Math.floor(Math.random() * max)}, ${Math.floor(Math.random() * max)}, ${Math.floor(Math.random() * max)}, `]);
  }

  return function (opacity) {
    return colors.map((color) => `${color + opacity})`);
  };
}

function CardChart(props) {
  const [typeChart, setTypeChart] = useState('');
  const [isShowingValue, setIsShowingValue] = useState(false);
  const charts = (props.typeChart && props.typeChart.length) || ['pie', 'bar'];
  const colors = useMemo(() => colorGenerator(props.data?.length), [props.data?.length]);

  useEffect(() => {
    if (charts) {
      setTypeChart(charts[0]);
    }
  }, []);

  useEffect(() => {
    if (props.typeChart && props.typeChart.length) {
      setTypeChart(props.typeChart[0]);
    }
  }, [props.typeChart]);

  console.log('props.data ', props.data)

  return (
    <div className={['text-center', props.className].join(' ')}>
      <div className="my-2 pb-4 border-b-2 font-semibold text-base">{props.title}</div>
      <div className="h-full p-4">
        {charts?.length > 1 && (
          <ToggleButtonGroup color="primary" value={typeChart} className="mb-3 border-b-2" exclusive onChange={(e) => setTypeChart(e.currentTarget.value)}> 
            {charts.map((chart, idx) => (
              <ToggleButton
                key={idx}
                id={`chart-${idx}`}
                value={chart}
              >
                {chart}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )}

        <div className="flex items-center">
          <FormControlLabel control={<Switch checked={isShowingValue} onChange={() => { setIsShowingValue(!isShowingValue) }} />} label="Show Value" />
        </div>

        {typeChart === 'pie' && (
          <Pie
            style={props.chartStyle?.pie || { maxHeight: '25vw', paddingBottom: '20px' }}
            options={{
              plugins: {
                datalabels: {
                  display: isShowingValue,
                  color: 'black',
                  formatter(value) {
                    const sum = (props.data).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                    return value !== 0 ? `${value} (${((value / sum) * 100).toFixed(2)}%)` : ' ';
                  },
                  font: { size: '16px' },
                  anchor: 'end',
                  align: 'start',
                  offset: 10,
                  
                },
                legend: {
                  position: 'left',
                  labels: { boxWidth: 20 },
                  fullSize: true,
                },
                tooltip: {
                  callbacks: {
                    label: (context) => {
                      const value = context.raw;
                      const sum = props.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                      const percentage = ((value / sum) * 100).toFixed(2) + '%';
                      return `${value} (${percentage})`;
                    },
                  }
                }
              },
            }}
            data={{
              labels: props.labels,
              datasets: [
                {
                  data: props.data,
                  backgroundColor: colors(0.2),
                  borderColor: colors(1),
                  borderWidth: 1,
                },
              ],
            }}
          />
        )}

        {typeChart === 'bar' && (
          <Bar
            style={props.chartStyle?.bar}
            options={{
              responsive: true,
              plugins: {
                legend: { display: false },
                datalabels: {
                  formatter(value) {
                    const sum = (props.data).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                    return value !== 0 ? `${value} (${((value / sum) * 100).toFixed(2)}%)` : ' ';
                  },
                  display: isShowingValue,
                  color: 'black',
                  font: { size: '16px' },
                },
                tooltip: {
                  callbacks: {
                    label: (context) => {
                      const value = context.raw;
                      const sum = props.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                      const percentage = ((value / sum) * 100).toFixed(2) + '%';
                      return `${value} (${percentage})`;
                    },
                  }
                }
              },
              scale: { ticks: { precision: 0 } },
            }}
            data={{
              labels: props.labels,
              datasets: [
                {
                  data: props.data,
                  backgroundColor: props.data.length > 4 ? ['rgba(255, 99, 132, 0.2)'] : colors(0.2),
                  borderColor: props.data.length > 4 ? ['rgba(255, 99, 132, 1)'] : colors(1),
                  borderWidth: 1,
                },
              ],
            }}
          />
        )}
      </div>
    </div>
  );
}

export default CardChart;
