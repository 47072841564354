import React from 'react';
import { AccountTree, Menu, Newspaper, WebAsset, LocationOn } from '@mui/icons-material';

// import SiteStructure from '../pages/Content/SiteStructure/SiteStructure';
import PageDetail from '../pages/Content/Page/PageDetail';

import MenuList from '../pages/Content/Menu/Menu';
import MenuDetail from '../pages/Content/Menu/MenuDetail';

import Article from '../pages/Content/Article/Article';
import ArticleDetail from '../pages/Content/Article/ArticleDetail';
import ArticleAdd from '../pages/Content/Article/ArticleAdd';

import Asset from '../pages/Content/Asset/Asset';
import AssetAdd from '../pages/Content/Asset/AssetAdd';
import AssetDetail from '../pages/Content/Asset/AssetDetail';

import LocationList from '../pages/Content/Location/Location';
import LocationDetail from '../pages/Content/Location/LocationDetail';
import LocationAdd from '../pages/Content/Location/LocationAdd';
import SiteStructureNew from '../pages/Content/SiteStructure/SiteStructureNew';

import WidgetDetail from '../pages/SettingsBZ/WidgetDetail';
import ArticleWidgetAdd from '../pages/Content/Article/ArticleWidgetAdd';
import ArticleWidgetDetail from '../pages/Content/Article/ArticleWidgetDetail';

const contentRoute = [
  {
    name: 'Structure',
    element: <SiteStructureNew />,
    icon: <AccountTree />,
    privilege: [1, 4, 6],
    subscriptions: [1],
    path: '/site-structure',
    isSidebar: true,
  },
  {
    name: 'Page Detail',
    element: <PageDetail />,
    privilege: [1, 4, 6],
    subscriptions: [1],
    path: '/page/detail/:pageId',
  },
  // MENU
  {
    name: 'Menu',
    element: <MenuList />,
    icon: <Menu />,
    privilege: [1, 4, 6],
    subscriptions: [1],
    path: '/menu-list',
    isSidebar: true,
  },
  {
    name: 'Menu Detail',
    element: <MenuDetail />,
    privilege: [1, 4, 6],
    subscriptions: [1],
    path: '/menu-list/:menuId',
  },
  // ARTICLE
  {
    name: 'Article',
    element: <Article />,
    icon: <Newspaper />,
    privilege: [1, 2, 4, 6],
    subscriptions: [1],
    path: '/article',
    isSidebar: true,
  },
  {
    name: 'Article Add',
    element: <ArticleAdd />,
    privilege: [1, 2, 4, 6],
    subscriptions: [1],
    path: '/article/add',
  },
  {
    name: 'Article Detail',
    element: <ArticleDetail />,
    privilege: [1, 2, 4, 6],
    subscriptions: [1],
    path: '/article/detail/:articleId',
  },
  {
    name: 'Article Detail Widget',
    element: <ArticleWidgetDetail />,
    privilege: [1, 2, 4, 6],
    subscriptions: [1],
    path: '/widgets/detail/:widgetId/article/:articleId',
  },
  {
    name: 'Article Widget Detail',
    element: <ArticleWidgetAdd />,
    privilege: [1, 2, 4, 6],
    subscriptions: [1],
    path: '/widgets/detail/:widgetId/article/add',
  },
  // ASSET
  {
    name: 'Asset',
    element: <Asset />,
    icon: <WebAsset />,
    privilege: [1, 2, 3, 4, 5, 6],
    subscriptions: [1, 2],
    path: '/asset',
    isSidebar: true,
  },
  {
    name: 'Asset Add',
    element: <AssetAdd />,
    privilege: [1, 2, 3, 4, 5, 6],
    subscriptions: [1, 2],
    path: '/asset/add',
  },
  {
    name: 'Asset Detail',
    element: <AssetDetail />,
    privilege: [1, 2, 3, 4, 5, 6],
    subscriptions: [1, 2],
    path: '/asset/detail/:assetId',
  },
  // LOCATION
  {
    name: 'Location',
    element: <LocationList />,
    icon: <LocationOn />,
    privilege: [1, 2, 3, 4, 5, 6, 7, 8, 10, 17, 15, 19],
    subscriptions: [1, 2, 5, 10],
    path: '/location',
    isSidebar: true,
  },
  {
    name: 'Location Add',
    element: <LocationAdd />,
    privilege: [1, 2, 3, 4, 5, 6, 7, 8, 10, 17, 15, 19],
    subscriptions: [1, 2, 5, 10],
    path: '/location/add',
  },
  {
    name: 'Location Detail',
    element: <LocationDetail />,
    privilege: [1, 2, 3, 4, 5, 6, 7, 8, 10, 17, 15, 19],
    subscriptions: [1, 2, 5, 10],
    path: '/location/detail/:locationId',
  },
  {
    name: 'Widget Detail',
    element: <WidgetDetail />,
    privilege: [99, 6],
    subscriptions: [1],
    path: '/widget/detail/:widgetId',
  },
];

export default contentRoute;
