import { useQueries, useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, ButtonBase, CircularProgress, FormControl, InputLabel, MenuItem, Select, Skeleton, Tab, Tabs } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { Close, Delete, Edit } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { method, uniqueId } from 'lodash';

import AccountPeriods from '@/components/Modal/Accounting/AccountPeriods';
import BZHelmet from '@/utils/BZHelmet';
import accountingApi from '@/api/accounting/accounting';
import reportApi from '@/api/accounting/report';
import styles from './Settings.module.css';
import FinancialYears from '@/components/Modal/Accounting/FinancialYears';
import BasePromptDialog from '@/components/ui/Dialog/BasePromptDialog';
import usePatch from '@/hooks/request/usePatch';

const { getAccountingPeriods, getBusinessUnits, getFinancialYears, actionFinancialYears, deleteFinancialYears, getAccountingPeriodsLStatus, setAccountingPeriodsOpenClose } =
  accountingApi();
const { getLedgers } = reportApi();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
TabPanel.defaultProps = { children: <div /> };

function a11yProps(index) {
  return {
    id: `financial-year-tab-${index}`,
    'aria-controls': `financial-year-tabpanel-${index}`,
  };
}

const tabTitle = ['Open / Close Period', 'New Financial Year', 'Automation Settings'];

export default function AccountingPeriods() {
  const { enqueueSnackbar } = useSnackbar();

  const client = useSelector((state) => state.auth.client.id);
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeUser = useSelector((state) => state.auth.user);
  const usedClient = activeUser.is_superuser ? activeClient : client;
  const activeBusinessUnit = useSelector((state) => state.acc.activeBusinessUnit);

  const [fYear, setFYear] = useState(false);
  const [ledger, setLedger] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeMainTab, setActiveMainTab] = useState(0);
  const [tbData, setTbData] = useState([]);
  const [actionPeriod, setActionPeriod] = useState(false);
  const [financialYear, setFinancialYear] = useState(false);

  const [modalFYType, setModalFYType] = useState(false);
  const [isModalDelete, setModalDelete] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(12);

  const queryResult = useQueries({
    queries: [
      {
        queryKey: ['financialYears', usedClient],
        queryFn: () => getFinancialYears({ client: usedClient, max_size: true, ordering: '-year' }),
        onSuccess: (res) => {
          if (res.data.results) {
            setFYear(res.data.results[0].id);
          }
        },
        enabled: !!usedClient,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['ledgers', usedClient, activeBusinessUnit],
        queryFn: () => getLedgers({ max_size: true, business_unit: activeBusinessUnit }),
        onSuccess: (res) => {
          if (res.data.results) {
            setLedger(res.data.results[0].id);
          }
        },
        enabled: !!usedClient && !!activeBusinessUnit,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['businessUnits', usedClient],
        queryFn: () => getBusinessUnits({ client: usedClient, max_size: true }),
        enabled: !!usedClient,
        refetchOnWindowFocus: false,
      },
    ],
  });

  const [financialYears, l, businessUnits] = queryResult;
  const { data: fYearsData } = financialYears;
  const { data: ledgersData } = l;
  const { data: bUData } = businessUnits;

  const refetchFY = queryResult[0].refetch;

  const isLoaded = queryResult.every((query) => query.isSuccess);

  const fYears = fYearsData?.data?.results || [];
  const ledgers = ledgersData?.data?.results || [];

  const { isFetched } = useQuery({
    queryKey: ['accounting-periods', usedClient, fYear, ledger],
    enabled: !!usedClient && !!isLoaded && !!fYear && !!ledger,
    queryFn: () => getAccountingPeriods({ financial_year__client: usedClient, financial_year: fYear, accperstatus__ledger: ledger, max_size: true }),
    onError: (error) => {
      console.log(error);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 0,
  });

  const {
    isFetching: isAcpFetching,
    isFetched: isAcpFetched,
    refetch,
  } = useQuery({
    queryKey: ['accounting-periods-status', usedClient, fYear, ledger],
    enabled: !!usedClient && !!isLoaded && !!isFetched && !!fYear && !!ledger,
    queryFn: () => getAccountingPeriodsLStatus({ financial_year: fYear, accperstatus__ledger: ledger, max_size: true }),
    onSuccess: (res) => {
      const aps = res.data?.results;
      const bu = bUData.data?.results;
      let tbData = [];
      if (bu) {
        tbData = aps.map((y, index) => {
          const accper = `${String(y.year).substring(2)}-${String(y.month).length === 1 ? `0${y.month}` : y.month}`;
          let bUnitPeriods = { b: [] };
          bu.forEach((b) => {
            const bStatus = y.accper_statuses.find((a) => a.ledger.id === ledger && a.ledger.business_unit.id === b.id);
            if (bStatus) {
              bUnitPeriods.b.push({
                code: b.code,
                id: bStatus.id,
                status: bStatus.status,
              });
              bUnitPeriods[b.code] = bStatus.status.toUpperCase();
              bUnitPeriods.accPerId = bStatus.id;
            } else {
              const i = uniqueId(`${b.code}_`);
              bUnitPeriods.b.push({
                code: b.code,
                id: i,
                status: '',
              });
              bUnitPeriods[b.code] = '';
              bUnitPeriods.accPerId = i;
            }
          });

          return {
            id: y.id,
            index,
            period: `${accper}`,
            ...bUnitPeriods,
          };
        });
        setTbData(tbData);
      }
    },
    onError: (error) => {
      console.log(error);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 0,
  });

  const { mutate: updateAccountPeriodsOC, isLoading: isUpdateAccPer } = usePatch({
    url: `accounting/accounting_periods/${actionPeriod.id}/update_status_bulk`,
    validate: [['accounting-periods-status', usedClient, fYear, ledger]],
    method: 'put',
    callback: () => {
      setActionPeriod(false);
      enqueueSnackbar('Accounting period updated' || 'Success', { variant: 'success' });
    },
  });

  const handleActionSubmit = async (dataAct) => {
    const { type, units, note } = dataAct;

    if (!actionPeriod) return;
    await updateAccountPeriodsOC({
      accper_status_ids: units,
      status: type,
      note: note,
    });
  };

  const { mutate: updateFY, isLoading: isUpdateFY } = usePatch({
    url: `accounting/financial_years/${financialYear.id}/`,
    validate: [['accounting-periods-status', usedClient, fYear, ledger]],
    callback: () => {
      refetchFY();
      setActionPeriod(false);
      enqueueSnackbar('Financial year updated' || 'Success', { variant: 'success' });
    },
  });

  const handleActionSubmitFY = async (mode, data) => {
    if (mode && data) {
      if (mode === 'create') {
        actionFinancialYears(mode, data)
          .then(() => {
            refetchFY();
          })
          .catch(() => {});
      }
      if (mode === 'update' && financialYear) {
        await updateFY(data);
      }
    }
    setModalFYType(false);
  };

  const onConfirmDelete = () => {
    if (financialYear && financialYear.id) {
      deleteFinancialYears(financialYear.id)
        .then(() => {
          refetchFY();
          setFinancialYear(false);
        })
        .catch((err) => {
          if (err.response.data) {
            const d = err.response.data;
            Object.keys(d).forEach((k) => {
              enqueueSnackbar(d[k][0] || 'Error', { variant: 'Error' });
            });
          }
        });
    }
  };

  const onMainTabChange = (_, newValue) => {
    setActiveMainTab(newValue);
  };
  const onTabChange = (_, newValue) => {
    const currYear = fYears[newValue]?.id;
    setActiveTab(newValue);
    setFYear(currYear);
  };

  const onDeleteClick = (data) => {
    setFinancialYear(data);
    setModalDelete(true);
  };

  const bu = useMemo(() => bUData?.data?.results, [bUData]) || [];
  const buColumns = bu?.map((b) => {
    return {
      field: b.code,
      headerName: b.code,
      flex: 1,
      renderHeader: (params) => <strong>{params.field}</strong>,
    };
  });

  const renderAction = (dt, params) => {
    return (
      <Box className="w-full flex space-x-2 justify-center">
        <ButtonBase
          className="bz-btn-tb"
          onClick={() => {
            setActionPeriod(dt);
          }}
        >
          <Close fontSize="24" />
        </ButtonBase>
      </Box>
    );
  };

  const renderActionFY = (dt, params) => {
    return (
      <Box className="w-full flex space-x-2 justify-center">
        <ButtonBase
          className="bz-btn-tb"
          onClick={() => {
            setFinancialYear(dt);
            setModalFYType('update');
          }}
        >
          <Edit fontSize="24" />
        </ButtonBase>
        <ButtonBase className="bz-btn-tb" onClick={() => onDeleteClick(dt)}>
          <Delete fontSize="24" />
        </ButtonBase>
      </Box>
    );
  };

  const columns = [
    {
      field: 'period',
      headerName: '',
      maxWidth: 96,
      renderCell: (params) => <strong>{params.row.period}</strong>,
    },
    ...buColumns,
    {
      field: 'id',
      headerName: 'Action',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => renderAction(params.row, params),
    },
  ];

  const columnsFYear = [
    {
      field: '',
      headerName: 'No',
      filterable: false,
      maxWidth: 48,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: 'year',
      headerName: 'Year',
      flex: 1,
    },
    {
      field: 'id',
      headerName: 'Action',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => renderActionFY(params.row, params),
    },
  ];

  return (
    <>
      <BZHelmet title="Account Periods" description="bz publish account periods" />
      <Box className={styles.container}>
        <div className="w-full flex p-4 shadow-md">
          <h1 className="text-lg font-bold">Accounting Periods</h1>
        </div>
        {!isLoaded && activeBusinessUnit && <Skeleton className="w-full w h-12" />}
        {isLoaded && (
          <>
            <div className="flex flex-col mt-4">
              <div className="relative w-full h-full">
                <Tabs value={activeMainTab} onChange={onMainTabChange} aria-label="settings accounting periods tabs" className="bz-tabs">
                  {tabTitle.map((t, index) => (
                    <Tab key={t} className="p-4" label={t} {...a11yProps(index)} />
                  ))}
                </Tabs>
              </div>
              <div className="flex flex-col flex-1">
                <TabPanel value={activeMainTab} index={0} className="relative">
                  <div className="relative w-full h-full flex flex-1 gap-4">
                    <Tabs value={activeTab} onChange={onTabChange} aria-label="accounting periods tabs" className="bz-tabs" orientation="vertical">
                      {fYears?.map((tb, index) => (
                        <Tab key={tb.year} className="p-4" label={tb.year} {...a11yProps(index)} />
                      ))}
                    </Tabs>
                    <div className="flex flex-col flex-1">
                      {fYears?.map((tb, index) => {
                        return (
                          <TabPanel key={`panel${tb.year}`} value={activeTab} index={index} className="">
                            <div className="flex justify-end mb-4">
                              <FormControl size="small">
                                <InputLabel id="select-account">Ledger</InputLabel>
                                <Select
                                  sx={{ p: 0.5, border: 0 }}
                                  labelId="select-ledger"
                                  id="select-ledger"
                                  name="ledger"
                                  value={ledger}
                                  label="Ledger"
                                  placeholder="Ledger"
                                  onChange={(event) => {
                                    setLedger(event.target.value);
                                  }}
                                  className="h-12"
                                >
                                  {ledgers?.map((acc) => (
                                    <MenuItem key={acc.id} value={acc.id}>
                                      {acc.code} - {acc.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>

                            {isAcpFetching ||
                              (isUpdateAccPer && (
                                <div className="absolute z-10 left-1/2 top-36 flex justify-center">
                                  <CircularProgress size={48} />
                                </div>
                              ))}
                            {!isAcpFetching && isAcpFetched && tbData.length === 0 && <p className="text-center">{`No financial year data found on ${tb.year} period`}</p>}
                            {!isAcpFetching && isAcpFetched && tbData.length > 0 && (
                              <DataGrid
                                autoHeight
                                rows={tbData || []}
                                columns={columns}
                                pageSize={rowsPerPage}
                                onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
                                rowsPerPageOptions={[12]}
                                className={`m-auto max-w-5xl w-[100%]`}
                                sx={
                                  {
                                    // maxWidth: maxWGrid,
                                  }
                                }
                              />
                            )}
                          </TabPanel>
                        );
                      })}
                    </div>
                    {fYears.length === 0 && <p className="w-full text-center py-4">No data</p>}
                  </div>
                </TabPanel>
                <TabPanel value={activeMainTab} index={1} className="relative">
                  <div className="flex justify-end mb-4">
                    <button
                      className="bz-btn"
                      onClick={() => {
                        setModalFYType('create');
                      }}
                    >
                      <p>Add New Financial Year</p>
                    </button>
                  </div>
                  {isUpdateFY && (
                    <div className="absolute z-10 left-1/2 top-36 flex justify-center">
                      <CircularProgress size={48} />
                    </div>
                  )}
                  <DataGrid autoHeight rows={fYears || []} columns={columnsFYear} rowsPerPageOptions={[10, 20, 50]} className="m-auto max-w-[480px] w-[100%]" />
                </TabPanel>
                <TabPanel value={activeMainTab} index={2} className="">
                  Functionality in development
                </TabPanel>
              </div>
            </div>
          </>
        )}
      </Box>
      <AccountPeriods
        open={!!actionPeriod}
        close={() => {
          setActionPeriod(false);
        }}
        action={handleActionSubmit}
        data={actionPeriod}
        bsData={actionPeriod ? { b: actionPeriod.b?.filter((b) => !!b.status) || [], period: actionPeriod.period } : []}
      />
      <FinancialYears
        open={!!modalFYType}
        close={() => {
          setModalFYType(false);
          setFinancialYear(false);
        }}
        action={handleActionSubmitFY}
        type={modalFYType}
        data={financialYear}
      />
      <BasePromptDialog
        open={isModalDelete}
        title="Delete"
        content={financialYear?.year}
        action={onConfirmDelete}
        close={() => {
          setModalDelete(false);
          setTimeout(() => {
            setFinancialYear(false);
          }, 300);
        }}
      />
    </>
  );
}
