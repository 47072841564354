/* eslint-disable */

import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { DevTool } from '@hookform/devtools';
import { GoogleMap, useJsApiLoader, Marker, Autocomplete } from '@react-google-maps/api';
import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import { getSelectOptionWithKey } from '../../../lib/OptionsInputHelper';
import InputContainer from '../../Input/InputContainer';
import { getDefaultSelectObject, getOptionSelectWithKey } from '../../../lib/OptionsInputHelper';
import MuiCusotmTabs from '../../ui/Tab/MuiCustomTabs';
import MuiCustomTabPanel from '../../ui/Tab/MuiCustomTabPanel';
import getDefaultValues from '../../../helper/getDefaultValues';
import ReactSelectSingle from '../../Input/ReactSelectSingle';
import HandleWatchingState from '../../../helper/HandleWatchingState';
import MuiSelectSingleItem from '../../Input/MuiSelectSingleItem';
import useGet from '../../../hooks/useGet';
import { handleModal } from '../../../store/slices/modalSlice';
import { FaMapMarkerAlt } from 'react-icons/fa';
import _ from 'lodash';
import { removeEmptyProperties } from '@/helper/arrayHandler';
import schemaLocationHasLang from './validationLocationLang';
import schemaLocation from './validationLocation';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const lib = ['drawing', 'places'];

export default function LocationForm({ defaultValues, onFormSubmit, departmentId, department, b_unit }) {
  console.log({defaultValues});
  
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.client.activeClient);
  const clientLanguage = useSelector((state) => state.content.activeSiteDetail.site_languages);

  const [hasLang, setHasLang] = useState(defaultValues?.location_languages && defaultValues?.location_languages.length > 0);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      address: defaultValues?.address,
      name: defaultValues?.name,
      latitude: defaultValues?.latitude,
      longitude: defaultValues?.longitude,
      country: defaultValues?.country?.id,
      address_components: defaultValues?.address_components,
      location_languages: getDefaultValues(clientLanguage, defaultValues?.location_languages),
      business_unit: !b_unit ? defaultValues?.business_unit : b_unit[0]?.id,
      phone: defaultValues?.contact?.phone || "",
      email: defaultValues?.contact?.email || ""
    },
    resolver: hasLang ? schemaLocationHasLang : schemaLocation,
  });

  const [address, setAddress] = useState('');
  const [valueTab, setValueTab] = useState(0);
  const [addCompData, setAddCompData] = useState(null);
  const [userLat, setUserLat] = useState();
  const [userLong, setUserLong] = useState();

  const [utcOffset, setUtcOffset] = useState(defaultValues?.utc_offset || null);
  
  const [optionsBUnit, setOptionsBUnit] = useState([]);
  const [optionsDepartment, setOptionsDepartment] = useState([]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCKqMs4hmNgoQtmIUsd7diBKHj6UV610Jg',
    libraries: lib,
  });

  const watchingCountry = HandleWatchingState(watch('country') || isLoaded);

  const { data: CountriesData } = useGet({
    url: `countries`,
    queryString: {
      max_size: true,
      ordering: 'name',
    },
    validate: 'countries',
  });

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const optionsCountries = CountriesData?.data?.results?.map((item) => ({
    name: item?.name,
    id: item?.id,
  }));

  useEffect(() => {
    if (b_unit) {
      setOptionsBUnit(
        b_unit.map((item) => ({
          name: item?.name,
          id: item?.id,
        }))
      );
    }
  }, [b_unit]);

  useEffect(() => {
    if (department) {
      setOptionsDepartment(
        department.map((item) => ({
          name: item?.name,
          id: item?.id,
        }))
      );
    }
  }, [department]);

  useEffect(() => {
    if (defaultValues) {
      setUserLat(defaultValues?.latitude);
      setUserLong(defaultValues?.longitude);
    } else
      navigator.geolocation.getCurrentPosition((position) => {
        setUserLat(position.coords.latitude);
        setUserLong(position.coords.longitude);
      });
  }, [defaultValues]);

  const handleCancel = () => {
    // navigate(-1);
    dispatch(handleModal({ modalId: '', componentName: '' }));
  };

  const center = {
    lat: +userLat,
    lng: +userLong,
  };

  const markerDragEnd = (event) => {
    setUserLat(event.latLng.lat());
    setUserLong(event.latLng.lng());
    setValue('latitude', event.latLng.lat());
    setValue('longitude', event.latLng.lng());
  };

  const autocompleteRef = useRef(null);

  const onPlaceChanged = () => {
    const places = autocompleteRef.current.state.autocomplete.getPlace();
    
    console.log("🚀 ~ file: LocationForm.jsx:134 ~ onPlaceChanged ~ places:", places)
    const coordinate = places.geometry.location.toJSON();
    const { lat, lng } = coordinate;
    setUserLat(lat);
    setUserLong(lng);
    setValue('latitude', lat);
    setValue('longitude', lng);
    setUtcOffset(places?.utc_offset);

    const addressComponents = places?.address_components;
    const addressComp = addressComponents?.map((add) => {
      return {
        name: add.long_name,
        types: add.types,
      };
    });
    setAddCompData(addressComp);

    const countriesName = CountriesData?.data?.results?.map((country) => country.iso_code);
    const addressCountry = addressComponents.map((addr) => addr.short_name);
    const foundedCountry = _.intersection(countriesName, addressCountry);
    const countryCode = foundedCountry[0];
    const selectedCountryChange = CountriesData?.data?.results?.find(({ iso_code }) => iso_code === countryCode);
    setTimeout(() => {
      setValue('address', places.formatted_address);
      setValue('country', selectedCountryChange?.id);
      setValue('latitude', lat);
      setValue('longitude', lng);
      clientLanguage?.forEach((lang, index) => setValue(`location_languages[${index}][address]`, places.formatted_address));
      setAddress(places.formatted_address);
    }, 200);
  };

  const onSubmit = (data) => {
    console.log('🚀 ~ onSubmit ~ data:', data);

    onFormSubmit({
      location_languages: hasLang ? removeEmptyProperties(data.location_languages, ['name', 'address']) : [],
      country: data?.country,
      address: data.address,
      name: data.name,
      client: clientId,
      latitude: data?.latitude || userLat,
      longitude: data?.longitude || userLong,
      address_components: addCompData,
      contact: {
        email: data?.email,
        phone: data?.phone,
      },
      utc_offset: utcOffset
    });
    reset();
  };

  useEffect(() => {
    if (hasLang) {
      clientLanguage?.forEach((lang, index) => setValue(`location_languages[${index}][language]`, lang.language.id));
    } else {
      setValue(`location_languages`, []);
    }
  }, [clientLanguage, setValue, hasLang]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* {userLat} */}
      <div className="w-full flex gap-x-5">
        <div className="w-6/12 space-y-4">
          <div className="">
            <InputContainer name="name" control={control} label="Name" errors={errors.name} />
            <InputContainer
              name="address"
              control={control}
              label="Address"
              defaultValue={address}
              // errors={errors.name}
            />
          </div>

          <div className="full">
            {watchingCountry ? (
              <ReactSelectSingle
                name="country"
                control={control}
                options={getOptionSelectWithKey(optionsCountries)}
                defaultValue={getDefaultSelectObject(watch('country') || defaultValues?.country?.id, getOptionSelectWithKey(optionsCountries))}
                placeholder="Select country"
              />
            ) : (
              <CircularProgress />
            )}
          </div>
          <InputContainer name="email" control={control} label="Email" errors={errors.email} />
          <InputContainer name="phone" control={control} label="Phone" errors={errors.phone} />

          <FormControlLabel
            value="end"
            control={
              <Checkbox
                // defaultChecked={defaultValues?.has_languages}
                checked={hasLang}
                onChange={() => {
                  setHasLang(!hasLang);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Has Language"
            labelPlacement="end"
          />

          {hasLang && (
            <div className="">
              <MuiCusotmTabs
                optionsTab={clientLanguage?.map((lang, index) => {
                  return {
                    name: lang?.language?.name,
                    id: lang?.language?.id,
                    error: errors?.location_languages?.[index],
                  };
                })}
                value={valueTab}
                onChange={handleChangeTab}
              />
              {clientLanguage?.map((lang, index) => (
                <MuiCustomTabPanel key={lang.id} value={valueTab} index={index}>
                  <InputContainer name={`location_languages[${index}][name]`} control={control} label="Name" errors={errors?.location_languages?.[index]?.name} />
                  <InputContainer name={`location_languages[${index}][address]`} control={control} label="Address" errors={errors?.location_languages?.[index]?.address} />
                </MuiCustomTabPanel>
              ))}
            </div>
          )}

          {b_unit && department && (
            <div className="w-full grid grid-cols-2 gap-x-2">
              <ReactSelectSingle
                name="business_unit"
                control={control}
                options={getOptionSelectWithKey(optionsBUnit)}
                defaultValue={getDefaultSelectObject(b_unit[0]?.id, getOptionSelectWithKey(optionsBUnit))}
                placeholder="Select country"
                isDisabled={true}
              />
              <ReactSelectSingle
                name="department"
                control={control}
                options={getOptionSelectWithKey(optionsDepartment)}
                defaultValue={getDefaultSelectObject(department[0]?.id, getOptionSelectWithKey(optionsDepartment))}
                placeholder="Select country"
                isDisabled={true}
              />
            </div>
          )}
        </div>
        <div className="w-6/12">
          {isLoaded && center?.lat && center?.lng ? (
            <div className="w-full h-[400px] relative">
              <div className="absolute z-50 top-3 left-1/2 transform -translate-x-1/2">
                <Autocomplete onPlaceChanged={onPlaceChanged} ref={autocompleteRef}>
                  <div>
                    <input
                      className="p-2 "
                      type="text"
                      placeholder="Search Location"
                      onKeyPress={(e) => {
                        e.key === 'Enter' && e.preventDefault();
                      }}
                    />
                  </div>
                </Autocomplete>
              </div>
              <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
                {/* Child components, such as markers, info windows, etc. */}
                <Marker position={center} draggable onDragEnd={(event) => markerDragEnd(event)} />
              </GoogleMap>
            </div>
          ) : (
            <CircularProgress />
          )}
          <div className="flex space-x-2 items-center justify-center">
            <div className="grid grid-cols-2 gap-2.5 w-full">
              <InputContainer name="latitude" control={control} label="Latitude" defaultValue={center?.lat} />
              <InputContainer name="longitude" control={control} label="Longitude" defaultValue={center?.lng} />
            </div>
            <div
              onClick={() => {
                setUserLat(watch('latitude'));
                setUserLong(watch('longitude'));
              }}
              className="flex items-center group relative"
            >
              <button type="button" className="flex items-center space-x-2 w-[140px] justify-center p-1.5 rounded border-2 border-bz-greenLight mt-1.5 hover:bg-bz-greenLight">
                <FaMapMarkerAlt className="text-red-600 text-[1.5rem]" />
                <div className="group-hover:text-white">SYNC MAP</div>
              </button>
              <div className="absolute top-12 w-[400px] text-xs -left-8 hidden group-hover:block">Sync map for manual input only</div>
            </div>
          </div>
        </div>
      </div>
      <div className="ml-auto flex gap-x-3 mt-[32px]">
        <Button onClick={handleCancel} className="bz-btn secondary ml-auto" type="button" variant="outlined">
          Cancel
        </Button>
        <Button className="bz-btn" type="submit" variant="outlined">
          {defaultValues ? 'Update Location' : 'Create Location'}
        </Button>
      </div>
      {/* <DevTool control={control} /> */}
    </form>
  );
}
