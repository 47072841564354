import useTokenBearer from '@/hooks/useTokenBearer';
import axios from 'axios';
import Cookies from 'js-cookie';

const baseURL = import.meta.env.VITE_API_URL;
const token = Cookies.get('bzKey');

export default function axiosInstances() {
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      Authorization: `${useTokenBearer()} ${token}`,
      'Content-Type': 'application/json',
    },
  });
  const axiosInstanceF = axios.create({
    baseURL,
    headers: {
      Authorization: `${useTokenBearer()} ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });

  return { axiosInstance, axiosInstanceF };
}

export function axInstance(param) {
  const instance = axios.create({
    baseURL,
    headers: {
      Authorization: `${useTokenBearer()} ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (param) {
    if (param.url) {
      instance.defaults.url = param.url;
    }
    if (param.method) {
      instance.defaults.method = param.method;
    }
    if (param.data) {
      instance.defaults.data = param.data;
    }
    if (param.headers) {
      instance.defaults.headers = param.headers;
    }
  }
  return instance;
}
