/* eslint-disable */

import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import InputContainer from '../../Input/InputContainer';
import { handleModal } from '../../../store/slices/modalSlice';

function LayoutForm({ defaultValues, onFormSubmit }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    // resolver: schemaLayout
  });
  const template = useSelector((state) => state.content.activeTemplate);
  console.log('🚀 ~ file: LayoutForm.jsx:21 ~ LayoutForm ~ template:', template);
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    onFormSubmit({ name: data.name, template });
  };

  const handleCancel = () => {
    dispatch(handleModal({ id: '' }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full space-y-4">
        <InputContainer name="name" control={control} label="Name" errors={errors.name} />

        <div className="flex justify-end ml-auto gap-x-2">
          <Button onClick={handleCancel} className="flex" variant="outlined">
            Cancel
          </Button>
          <Button className=" flex" type="submit" variant="outlined">
            {defaultValues ? 'Update Layout' : 'Create Layout'}
          </Button>
        </div>
      </div>
    </form>
  );
}
export default LayoutForm;
