// /* eslint-disable */

import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { FormControlLabel, FormLabel, FormControl, Radio, RadioGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DevTool } from '@hookform/devtools';
import InputContainer from '../../Input/InputContainer';
import { handleModal } from '../../../store/slices/modalSlice';
import ReactSelectMultiple from '../../Input/ReactSelectMultiple';
import useGet from '../../../hooks/useGet';
import MuiCusotmTabs from '../../ui/Tab/MuiCustomTabs';
import MuiCustomTabPanel from '../../ui/Tab/MuiCustomTabPanel';
import getDefaultValues from '../../../helper/getDefaultValues';
import { generateSelectListDefault } from '../../../lib/OptionsInputHelper';
import schemaPage from './validationPage';
import CustomCheckbox from '../../Input/CustomCheckbox';

const transPage = 'modals.page_add.';

export default function PageForm({ defaultValues, onFormSubmit }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const clientLanguage = useSelector((state) => state.content.activeSiteDetail.site_languages);
  const templateId = useSelector((state) => state.content.activeTemplate);
  const clientId = useSelector((state) => state.client.activeClient);
  const modal = useSelector((state) => state.modal);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: defaultValues?.name,
      page_languages: getDefaultValues(clientLanguage, defaultValues?.page_languages),
      widgets: defaultValues?.widgets?.map((w) => w.id),
      use_global_sections: defaultValues?.use_global_sections,
    },
    resolver: schemaPage,
  });

  const [isDraft, setIsDraft] = React.useState(defaultValues?.is_draft ?? false);
  const [valueTab, setValueTab] = useState(0);

  const { data: widget } = useGet({
    url: `widgets/?client=${clientId}&max_size=true&category=1&internal=false`,
    validate: 'widgets',
  });

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleChangeDraft = (event) => {
    setIsDraft(event.target.value);
  };

  const optionsWidget = widget?.data?.results?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));

  const onSubmit = (data) => {
    let parent;
    if (modal.componentName === 'addPage' && !defaultValues) {
      parent = modal.modalId;
    } else {
      parent = defaultValues?.parent || null;
    }

    onFormSubmit({
      has_params: defaultValues?.has_params || false,
      name: data.name,
      is_draft: isDraft,
      page_languages: data.page_languages,
      parent,
      page_type: defaultValues?.page_type || 'General',
      widgets: data.widgets,
      template: templateId,
      use_global_sections: data.use_global_sections,
    });
    reset();
  };

  const handleCancel = () => {
    dispatch(handleModal({ id: '' }));
  };

  useEffect(() => {
    clientLanguage?.forEach((lang, index) => setValue(`page_languages[${index}][language]`, lang.language.id));
  }, [clientLanguage, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full">
        <InputContainer name="name" control={control} label="name" errors={errors.name} />
        <MuiCusotmTabs
          optionsTab={clientLanguage.map((lang) => {
            return {
              name: lang?.language?.name,
              id: lang?.language?.id,
            };
          })}
          className=""
          value={valueTab}
          onChange={handleChangeTab}
        />
        {clientLanguage?.map((lang, index) => (
          <MuiCustomTabPanel key={lang.id} value={valueTab} index={index}>
            <InputContainer name={`page_languages[${index}][name]`} control={control} label={t('Name')} errors={errors?.page_languages?.[index]?.name} />
            <InputContainer name={`page_languages[${index}][title]`} control={control} label={t('page_title')} />
            <InputContainer name={`page_languages[${index}][path]`} control={control} label={t('url_text')} placeholder="Auto Generate" disabled />
            <InputContainer name={`page_languages[${index}][description]`} control={control} label={t('description')} />
          </MuiCustomTabPanel>
        ))}

        <div className="">
          <Controller
            name="widgets"
            control={control}
            render={({ field: { onChange } }) => (
              <ReactSelectMultiple
                options={optionsWidget}
                defaultValue={generateSelectListDefault(defaultValues?.widgets)}
                placeholder={t('collections')}
                isMulti
                onChange={(v) => onChange(v?.map((val) => val.value))}
              />
            )}
          />
        </div>
        <div className="mt-5">
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">{t('publicity')}</FormLabel>
            <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={isDraft} onChange={handleChangeDraft}>
              <FormControlLabel value={false} control={<Radio />} label="Publish" />
              <FormControlLabel value control={<Radio />} label={t('draft')} />
            </RadioGroup>
          </FormControl>
        </div>
        <Controller
          name="use_global_sections"
          control={control}
          render={({ field: { onChange } }) => <CustomCheckbox label="Use Global Section" defaultChecked={defaultValues?.use_global_sections || true} onChange={onChange} />}
        />
        <div className="ml-auto flex gap-x-3 mt-7">
          <Button onClick={handleCancel} className="bz-btn secondary ml-auto flex" type="button" variant="outlined">
            Cancel
          </Button>
          <Button className="bz-btn flex" type="submit" variant="outlined">
            {`${defaultValues ? t(`${transPage}button_update`) : t(`${transPage}button_add`)}`}
          </Button>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </form>
  );
}
