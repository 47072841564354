/* eslint-disable */

import React, { useCallback, useEffect, useState } from 'react';
import { Button, ButtonBase, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { Close, ImageNotSupported } from '@mui/icons-material';
import { assetPlaceholder, renderAsset } from '../../../pages/Content/Asset/AssetCardSingle';
import MuiCusotmTabs from '../../ui/Tab/MuiCustomTabs';
import MuiCustomTabPanel from '../../ui/Tab/MuiCustomTabPanel';

export default function AssetDetailViewer({ defaultValues, checked, langThumbnail, onAddExistingImage, onAddNewImage, resetThumbnail }) {
  const clientLanguage = useSelector((state) => state.content.activeSiteDetail.site_languages);
  const isLanguage = checked.language;

  const [valueTab, setValueTab] = useState(0);
  const [selectedTabLanguage, setSelectedTabLanguage] = useState(1);

  useEffect(() => {
    setSelectedTabLanguage(clientLanguage[0].language.id);
  }, [clientLanguage]);

  const handleChangeTab = (event, newValue) => {
    const languageChanges = clientLanguage[newValue].language.id;
    setValueTab(newValue);
    setSelectedTabLanguage(languageChanges);
  };

  const usedDefaultAsset = useCallback(() => {
    const fileTab = defaultValues?.asset_languages?.filter((item) => item?.language === selectedTabLanguage);
    return isLanguage ? fileTab[0] : defaultValues;
  }, [defaultValues, isLanguage, selectedTabLanguage]);

  const renderAssetViewer = (thumb, isEdited) => {
    return (
      <>
        {/* <p className="text-lg font-semibold mb-2">Asset Thumbnail</p> */}
        <div className="relative h-[196px] flex items-center justify-center">
          {isEdited && (
            <ButtonBase className="absolute top-0 -right-4" onClick={() => resetThumbnail()}>
              <Close fontSize="12" />
            </ButtonBase>
          )}
          {thumb ? <img src={thumb} alt="" className="my-4 h-[inherit] object-contain" /> : <ImageNotSupported className="h-16 w-16" style={{ color: '#6d6e6f' }} />}
        </div>
        <div className="space-x-3 mt-4">
          <Button variant="outlined" onClick={() => onAddExistingImage(0)} size="small">
            Add
          </Button>

          <Button variant="outlined" component="label" size="small">
            Add New
            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              onChange={(e) => {
                const fl = e.target.files;
                onAddNewImage(fl);
              }}
            />
          </Button>
        </div>
      </>
    );
  };

  let htmlViewerNoLang;
  if (!isLanguage) {
    let thumb = defaultValues.thumbnail?.asset_file;
    let isEdited;
    if (langThumbnail && langThumbnail.asset_file) {
      thumb = langThumbnail.asset_file;
      isEdited = true;
    }
    htmlViewerNoLang = renderAssetViewer(thumb, isEdited);
  }

  return (
    <div>
      <div>
        {!isLanguage && (
          <div className="border border-slate-300 rounded-sm px-4 py-2">
            {/* {renderAsset(String(defaultValues.asset_type?.id), defaultValues.asset_file)} */}
            {htmlViewerNoLang && (
              <>
                {/* <Divider className="my-4" /> */}
                {htmlViewerNoLang}
              </>
            )}
          </div>
        )}
        {isLanguage && (
          <div className="border border-slate-300 rounded-sm px-4 py-2">
            <MuiCusotmTabs
              optionsTab={clientLanguage.map((lang) => {
                return {
                  name: lang?.language?.name,
                  id: lang?.language?.id,
                };
              })}
              value={valueTab}
              onChange={handleChangeTab}
            />
            {clientLanguage.map((lang, index) => {
              const typeId = defaultValues.asset_type?.id;
              const assetFl = usedDefaultAsset()?.asset_file;
              let thumb = usedDefaultAsset()?.thumbnail_file;
              let isEdited;
              if (Array.isArray(langThumbnail) && langThumbnail[0]?.thumbnail) {
                thumb = langThumbnail[0]?.thumbnail?.asset_file;
                isEdited = true;
              }
              return (
                <MuiCustomTabPanel key={lang?.language?.id} value={valueTab} index={index}>
                  {renderAssetViewer(thumb, isEdited)}
                </MuiCustomTabPanel>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
