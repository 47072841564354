/* eslint-disable */

import usePatchOrPut from '../../../hooks/usePatchOrPut';
import usePostExtra from '../../../hooks/usePostExtra';
import { handleSelect } from '../../../store/slices/assetCrudSlice';
import { handleModal } from '../../../store/slices/modalSlice';
import renderError from '../../../utils/Error/renderError';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import AssetForm from '../../../components/Form/Asset/AssetForm';

export default function UploadNewAsset({ defaultValues }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const collectAssetId = defaultValues?.article_assets.map((asset) => asset.id);

  const { mutate: pairingAssets } = usePatchOrPut({ url: `articles/${defaultValues?.id}/pair_assets`, validate: ['article'] });

  const onPairAssets = async (data) => {
    await pairingAssets(data);
    setTimeout(() => {
      dispatch(handleModal({ modalId: '' }));
    }, 500);
  };

  const { mutate: postAsset } = usePostExtra({
    url: 'assets',
    validate: ['assets'],
    disableReserAsset: true,
    onSuccess: (res) => {
      if (res.request.status === 201) {
        const newArrayAsset = [res.data.id];
        console.log('🚀 ~ file: UploadNewAsset.jsx:35 ~ UploadNewAsset ~ newArrayAsset:', newArrayAsset);
        defaultValues ? onPairAssets({ assets: newArrayAsset }) : dispatch(handleSelect(JSON.stringify(res.data)));
      } else {
        enqueueSnackbar('error', { variant: 'error' });
        renderError({ data: JSON.parse(e.request.response) });
      }
    },
  });

  const onPostAsset = async (data) => {
    await postAsset(data);
  };
  return <AssetForm onFormSubmit={onPostAsset} />;
}
