import { createSlice, current } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    isSuperUser: false,
    isGroups: [],
    client: Cookies.get('bzClient') ? JSON.parse(Cookies.get('bzClient')) : false,
    key: Cookies.get('bzKey'),
    user: Cookies.get('bzUser') ? JSON.parse(Cookies.get('bzUser')) : false,
    lang: 'en',
  },
  reducers: {
    setLoggin(state, action) {
      const currState = state;

      const data = action.payload;
      const { expiry, token, user } = data;
      const lang = localStorage.getItem('bzlang');
      const langId = localStorage.getItem('bzlangId');
      const expiryTime = new Date(expiry);
      if ((!lang || !langId) && user.client.languages.length > 0) {
        localStorage.setItem('bzLang', user.client.languages[0].abbreviation);
        localStorage.setItem('bzLangId', user.client.languages[0].id);
      } else {
        localStorage.setItem('bzLang', 'en');
        localStorage.setItem('bzLangId', 1);
      }
      localStorage.setItem('bzKey', token);
      localStorage.setItem('bzUser', JSON.stringify(user));

      if (!user?.is_superuser) {
        Cookies.set('bzaClient', user.client.id, { expires: expiryTime });
        if (user.sites && user.sites.length > 0) {
          Cookies.set('bzaSite', user.sites[0].id, { expires: expiryTime });
          localStorage.setItem('bzSiteId', user.sites[0].id);
        }
      }

      Cookies.set('bzKey', token, { expires: expiryTime });
      Cookies.set('bzUser', JSON.stringify(user), { expires: expiryTime });
      Cookies.set('bzClient', JSON.stringify(user.client), { expires: expiryTime });

      if (user.client && token && user) {
        currState.client = user.client;
        currState.key = token;
        currState.user = user;
        currState.isAuthenticated = true;
        currState.isSuperUser = user.is_superuser;
        if (!user.is_superuser && user.groups.length) {
          currState.isGroups = user.groups?.map((gr) => gr.id);
        }
      }
    },

    setLogginSSO(state, action) {
      const currState = state;
      currState.isSSO = true;

      const data = action.payload;
      const { token, user } = data;
      const userWithoutSidebar = { ...user, sidebar_items: null };
      const lang = localStorage.getItem('bzlang');
      const langId = localStorage.getItem('bzlangId');
      const expiryTime = token.expires_in / 86400;
      console.log('🚀 ~ file: authSlice.js:24 ~ setLoggin ~ expiryTime:', expiryTime);
      if ((!lang || !langId) && user.client.languages.length > 0) {
        localStorage.setItem('bzLang', user.client.languages[0].abbreviation);
        localStorage.setItem('bzLangId', user.client.languages[0].id);
      } else {
        localStorage.setItem('bzLang', 'en');
        localStorage.setItem('bzLangId', 1);
      }
      localStorage.setItem('bzKey', token.access_token);
      localStorage.setItem('bzUser', JSON.stringify(userWithoutSidebar));

      if (!user?.is_superuser) {
        Cookies.set('bzaClient', user.client.id, { expires: expiryTime });
        if (user.sites && user.sites.length > 0) {
          Cookies.set('bzaSite', user.sites[0].id, { expires: expiryTime });
          localStorage.setItem('bzSiteId', user.sites[0].id);
        }
      }

      Cookies.set('bzKey', token.access_token, { expires: expiryTime });
      Cookies.set('bzUser', JSON.stringify(userWithoutSidebar), { expires: expiryTime });
      Cookies.set('bzClient', JSON.stringify(user.client), { expires: expiryTime });

      if (user.client && token.access_token && user) {
        currState.client = user.client;
        currState.key = token.access_token;
        currState.user = user;
        currState.isAuthenticated = true;
        currState.isSuperUser = user.is_superuser;
        if (!user.is_superuser && user.groups.length) {
          currState.isGroups = user.groups?.map((gr) => gr.id);
        }
      }
    },
    setAuthenticated(state) {
      const currState = state;
      const objState = current(currState);
      if (objState.client && objState.key && objState.user) {
        currState.isAuthenticated = true;
        currState.isSuperUser = objState.user.is_superuser;

        if (!objState.user.is_superuser && objState.user.groups.length) {
          currState.isGroups = objState.user.groups?.map((gr) => gr.id);
        }
      }
    },
    setIsGroup(state, action) {
      const { isGroups, newUser } = action.payload;
      const currState = state;
      currState.isGroups = isGroups;
      currState.user = newUser;
      if (newUser) {
        localStorage.setItem('bzUser', JSON.stringify(newUser));
        Cookies.set('bzUser', JSON.stringify(newUser));
      }
    },
    setLogout(state) {
      const currState = state;
      currState.client = false;
      currState.key = false;
      currState.user = false;
      currState.isAuthenticated = false;
      Cookies.remove('bzClient');
      Cookies.remove('bzKey');
      Cookies.remove('bzUser');
      Cookies.remove('bzaSite');
      Cookies.remove('bzaTemplate');
      // Cookies.remove('bztAct');
      localStorage.removeItem('bzClient');
      localStorage.removeItem('bzKey');
      localStorage.removeItem('bzUser');
      localStorage.removeItem('isSSO');
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice;
