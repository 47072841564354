import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, ButtonBase, Divider, FormControl, Popover, Stack, TextField } from '@mui/material';
import { Edit, PersonAddAlt, Person as PersonIcon } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import DetailContext from '../../Projects/DetailContext';
import formatterInitialName from '../../../../helper/formatterInitialName';
import teamApi from '../../../../api/task/team';
import projectApi from '../../../../api/task/project';
import InputContainer from '@/components/Input/InputContainer';
import ReactSelectMultiple from '@/components/Input/ReactSelectMultiple';
import { fetchTeams} from '@/hooks/useTeamUsersData';
import TeamForm from '@/components/Form/Task/TeamForm';
import request from '@/helper/axiosUtil';
import InviteUser from '@/components/Modal/Task/ModalTeam/InviteUser';
import { projectUpdate } from '@/store/slices/taskCrudSlice';

function ProjectTeam() {
  const dispatch = useDispatch();
  const { formatter } = formatterInitialName();
  const { enqueueSnackbar } = useSnackbar();
  const myContextDetail = useContext(DetailContext);
  const { projects } = myContextDetail;
  const clientId = useSelector((state) => state.client.activeClient);
  const acvUser = useSelector((state) => state.auth?.user);
  const { data: TeamsData } = useQuery(['teams', { clientId }], fetchTeams);
  const qClient = useQueryClient();

  const [team, setTeam] = useState(false);
  const [activeUser, setActiveUser] = useState(false);
  const [anchorUser, setAnchorUser] = useState(false);
  const [isOwner, setIsOwner] = useState();
  const [isInvite, setIsInvite] = useState(false);


  const openUser = Boolean(anchorUser);
  const idUser = openUser ? 'simple-popover' : undefined;

  const getProjectTeamDetail = useCallback(() => {
    const { getTeamDetail } = teamApi();
    getTeamDetail(projects.team?.id)
      .then((res) => {
        if (res.status === 200 || res.data) {
          setTeam(res.data);
          // setActiveUserRole(res.data?.team_users?.find((data) => data.user.id === acvUser.id))
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [projects.team?.id]);

  useEffect(() => {
    if (projects.team) {
      getProjectTeamDetail();
    }
  }, [projects.team, getProjectTeamDetail]);

  useEffect(() => {
    if (projects && projects?.owner) {
      setIsOwner(projects?.owner?.id === acvUser.id)
    }
  }, [projects]);


  const handleUnAssignTeamUser = () => {
    const { deleteTeamUsers } = teamApi();

    if (activeUser) {
      const teamUser = [];
      teamUser.push(activeUser);
      deleteTeamUsers({ team_user_ids: teamUser })
        .then(() => {
          getProjectTeamDetail();
          setActiveUser(false);
          setAnchorUser(false);
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  // UPDATE TEAM
  const updateTeams = async (data) =>
    request({
      url: `/project-management/teams/${team?.id}/`,
      method: 'patch',
      data: {
        client: clientId,
        name: data.name,
        team_users: data.team_users,
      },
      headers: { 'Content-Type': 'application/json' },
    });

  const { mutateAsync: updateTeam } = useMutation(updateTeams, {
    onSuccess: (e) => {
      qClient.invalidateQueries('teams');
      if (e.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
        dispatch(projectUpdate());
      } else {
        enqueueSnackbar('success', { variant: 'success' });
      }
    },
  });

  const onUpdateTeam = async (data) => {
    await updateTeam(data);
    qClient.invalidateQueries('teams');
  };

  const handleInviteUser = () => {
    setIsInvite(!isInvite);
  };

  const onInviteUser = (data) => {
    const { team, email } = data;
    const { inviteTeams } = teamApi();
    inviteTeams(team, { email, language: 1 })
      .then((res) => {
        if (res.status === 200 || res.data) {
          enqueueSnackbar('Invite sent' || 'Success', { variant: 'success' });
          handleInviteUser();
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.response?.data?.non_field_errors[0], { variant: 'error' });
      });
  };

  return (
    <div className="w-full h-full px-6 py-6 space-y-3">
      {/* <div className="flex flex-wrap">
        <div className="flex space-x-2 mr-4 mt-2 items-center px-3 py-2 border border-gray-300 rounded-xl">
          <div className="w-10 h-10 border border-dashed rounded-full text-xs flex justify-center items-center mr-1">
            <PersonIcon color="disabled" />
          </div>
          <div className="flex flex-col">
            <span className="text-gray-500">Add member</span>
          </div>
        </div>
        {team &&
          team.team_users &&
          team.team_users.length > 0 &&
          team.team_users.map((tm) => (
            <ButtonBase
              key={tm.user.name}
              className="flex mr-4 mt-2 items-center px-3 py-2 border-gray-300 rounded-xl cursor-pointer"
              onClick={(event) => {
                setActiveUser(tm.id);
                setAnchorUser(event.currentTarget);
              }}
              sx={{ border: 1 }}
            >
              <div className="w-10 h-10 border rounded-full text-xs flex justify-center items-center mr-1">
                <span>{formatter(tm.user.name)}</span>
              </div>
              <div className="flex flex-col">
                <span>{tm.user.name}</span>
                <span className="text-xs text-gray-300">{tm.role.name}</span>
              </div>
            </ButtonBase>
          ))}
        <Popover
          id={idUser}
          open={openUser}
          anchorEl={anchorUser}
          onClose={() => {
            setActiveUser(false);
            setAnchorUser(false);
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{ sx: { boxShadow: 1 } }}
        >
          <Box className="px-4 py-2 flex flex-col space-y-2 border border-gray-300 rounded-md shadow-lg">
            <ButtonBase
              onClick={() => {
                handleUnAssignTeamUser(activeUser);
              }}
            >
              Delete user
            </ButtonBase>
          </Box>
        </Popover>
      </div> */}

      <div className="w-[70%]">
        <div className="min-h-[50px] flex justify-end">
          <button className="ml-auto min-w-[140px] flex items-center justify-center text-white py-[8px] bg-[#2C6D47] rounded-xl" onClick={handleInviteUser} startIcon={<PersonAddAlt />}>
            Invite User
          </button>
        </div>
        {
          team && (
            <TeamForm
              defaultValues={team}
              onFormSubmit={onUpdateTeam}
              hidePart={true}
            />
          )
        }
      </div>
      <InviteUser open={isInvite} data={TeamsData?.data?.results} onClose={handleInviteUser} action={onInviteUser} />
    </div>
  )
}

export default ProjectTeam
