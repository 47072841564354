// /* eslint-disable */

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { DevTool } from '@hookform/devtools';

import InputContainer from '../../Input/InputContainer';
import { handleModal } from '../../../store/slices/modalSlice';
import getDefaultValues from '../../../helper/getDefaultValues';

function ArticleKeywordForm({ defaultValues, onFormSubmit }) {
  const clientLanguage = useSelector((state) => state.content.activeSiteDetail.site_languages);
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.client.activeClient);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      name: defaultValues?.name,
      keyword_languages: getDefaultValues(clientLanguage, defaultValues?.keyword_languages),
    },
  });

  const onSubmit = (data) => {
    onFormSubmit({ name: data.name, client: clientId, keyword_languages: data?.keyword_languages });
  };

  const handleCancel = () => {
    dispatch(handleModal({ id: '' }));
  };

  useEffect(() => {
    clientLanguage?.forEach((lang, index) => setValue(`keyword_languages[${index}][language]`, lang.language.id));
  }, [clientLanguage, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full space-y-4">
        <InputContainer name="name" control={control} label="Name" value="" />

        <div className="flex justify-end ml-auto gap-x-2">
          <Button onClick={handleCancel} className="flex" variant="outlined">
            Cancel
          </Button>
          <Button className=" flex" type="submit" variant="outlined">
            {defaultValues ? 'Update Keyword' : 'Create Keyword'}
          </Button>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </form>
  );
}

export default ArticleKeywordForm;
