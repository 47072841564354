import { Box, Button, Tab, Tabs } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import WidgetTable from './WidgetTable';
import widgetApi from '../../api/widget';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
TabPanel.defaultProps = { children: <div /> };

function a11yProps(index) {
  return {
    id: `accounting-tab-${index}`,
    'aria-controls': `accounting-tabpanel-${index}`,
  };
}

const getInitialState = (languages) => {
  return languages.map((lang) => {
    return {
      name: '',
      description: '',
      language: lang.id,
      headers: [''],
      content: [['']],
      setups: '',
    };
  });
};

export default function WidgetTableContent({ data, setSectionDetail }) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const languages = useSelector((state) => state.content.activeSiteDetail.site_languages);
  const [tableContent, setTableContent] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeTable, setActiveTable] = useState(false);

  const prevTabRef = useRef();
  useEffect(() => {
    prevTabRef.current = activeTab;
  }, [activeTab]);

  useEffect(() => {
    if (!tableContent) {
      setTableContent(getInitialState(languages));
    }
  }, [tableContent, languages]);

  const onActiveItem = (item) => {
    if (item) {
      const tbContent = item.table_languages?.map((itm) => {
        return {
          name: itm.name,
          description: itm.description,
          language: itm.language,
          headers: itm.headers,
          content: itm.content,
          setups: item.setups,
        };
      });
      setTableContent(tbContent);
      setActiveTable(item);
    } else {
      setTableContent(getInitialState(languages));
      setActiveTable(null);
    }
  };

  const onTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const onValueChange = (param, value, index, idxTab) => {
    if (param === 'header') {
      const currState = [...tableContent];
      const currData = currState[idxTab];
      currData.header[index] = value;
      setTableContent(currState);
    }
  };

  const onSubmitTable = () => {
    const { postWidgetTable, updateWidgetTable } = widgetApi();
    if (activeTable) {
      updateWidgetTable(activeTable.id, { table_languages: tableContent })
        .then((res) => {
          if (res.status === 201 || res.data) {
            enqueueSnackbar('Table updated' || 'Success', { variant: 'success' });
            setSectionDetail(null);
            queryClient.invalidateQueries('section');
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      postWidgetTable({
        widget: data.id,
        setups: [
          {
            type: 'Table',
            styling: {},
          },
        ],
        table_languages: tableContent,
      })
        .then((res) => {
          if (res.status === 201 || res.data) {
            enqueueSnackbar('Table created' || 'Success', { variant: 'success' });
            setSectionDetail(null);
            queryClient.invalidateQueries('section');
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  const onDeleteTable = () => {
    const { deleteWidgetTable } = widgetApi();
    if (activeTable) {
      deleteWidgetTable(activeTable.id)
        .then(() => {
          enqueueSnackbar('Table deleted' || 'Success', { variant: 'success' });
          setSectionDetail(null);
          queryClient.invalidateQueries('section');
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  return (
    <div className="w-full h-full">
      <div className="flex gap-x-3 mb-4">
        <button onClick={() => onActiveItem(null)} type="button" className={`${!activeTable ? 'text-orange-500' : 'text-black'}`}>
          Add New
        </button>
        {data?.table_contents.length > 0 &&
          data?.table_contents.map((tb, i) => (
            <button key={`table-${tb.id}`} onClick={() => onActiveItem(tb)} type="button" className={`${activeTable?.id === tb.id ? 'text-orange-500' : 'text-black'}`}>
              {`${i + 1} . ${tb.table_languages.length ? tb.table_languages[0].name : 'Table'}`}{' '}
            </button>
          ))}
      </div>

      <div className="border-b">
        <Tabs className="bz-tabs" value={activeTab} onChange={onTabChange} aria-label="widget table tabs">
          {languages && languages.length > 0 && languages.map((tb, index) => <Tab key={tb.id} className="p-4" label={tb.language.name} {...a11yProps(index)} />)}
        </Tabs>
      </div>
      {tableContent &&
        languages &&
        languages.length > 0 &&
        languages.map((tb, index) => (
          <TabPanel key={`tab-${tb.name}`} value={activeTab} index={index}>
            <WidgetTable
              data={tableContent[index]}
              idxTab={index}
              onValueChange={onValueChange}
              activeTab={prevTabRef}
              setTableContent={setTableContent}
              tableContent={tableContent}
            />
          </TabPanel>
        ))}
      <div className="flex space-x-4 justify-end">
        <Button variant="contained" size="small" onClick={onSubmitTable} type="button">
          {activeTable ? 'Update Table' : 'Add Table'}
        </Button>
        {activeTable && (
          <Button variant="outlined" size="small" onClick={onDeleteTable} type="button">
            Delete Table
          </Button>
        )}
      </div>
    </div>
  );
}
