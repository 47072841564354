import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSearchParams, Link, useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Switch, IconButton, Collapse, TextareaAutosize, Button, CircularProgress } from '@mui/material'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { TextField, InputAdornment } from '@mui/material';
import { handleModal } from '@/store/slices/modalSlice';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import _ from 'lodash';

import CancelIcon from '@mui/icons-material/Cancel';
import SelectAutocomplete from '@/components/Input/ProductManagement/SelectAutoComplete';
import CustomSelectRedesign from '../../CRM/redesigning/CustomSelectRedesign';
import {DatePickerFieldWithoutController} from '@/components/Input/DatePickerField';
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';
import Swal from 'sweetalert2';
import { useProductContext } from '../ProductContext';
import ModalWrapper from '@/components/ui/ModalWrapper';
import Select from '@/components/Input/MuiSelectSingleItem';
import Input from '../../CRM/redesigning/InputContainerRedesign';
import ComponentPairOnProductDetail from '@/components/Form/ProductManagement/ComponentPairOnProductDetail';
import AddOnsPair from '@/components/Form/ProductManagement/AddOnsPair';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ProductDetailTab() {
  const { register, handleSubmit, control, errors, setValue } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const navigate = useNavigate();
  const { productId, vendorId } = useParams();
  const isFromProcurement = vendorId !== undefined;
  const params = useParams();
  const dispatch = useDispatch();

  const { 
    addOns,
    basicPrice,
    categoriesOptions,
    collectionsOptions,
    currencyOptions,
    departmentOptions,
    dimensions,
    editableCost,
    isInternal,
    isLoadingOptions,
    mode,
    productComponents,
    productData,
    productFiles,
    productFilesOnDelete,
    productGrandTotal,
    productMediaShow,
    productSellingPrice,
    productUnitOptions,
    productVendors,
    sellableProduct,
    sizeUnit,
    typesOptions,
    vendorOptions,

    // Set Func
    setAddOns,
    setBasicPrice,
    setDimensions,
    setEditableCost,
    setGrandTotal,
    setIsInternal,
    setProductMediaShow,
    setProductFiles,
    setproductFilesOnDelete,
    setProductComponents,
    setSellableProduct,
    setSellingPrice,
    setIsFetching
  } = useProductContext();

  const [openChildComponents, setOpenChildComponents] = useState(false);
  const [visibleComponents, setVisibleComponents] = useState({});

  const statusOptions = [
    { label: "draft", value: "draft" },
    { label: "active", value: "active" },
    { label: "archive", value: "archive" },
  ];

  const formatNominal = (value) => {
    if (value === null || value === undefined){
      return ""
    }

    const parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };

  const onChangeEditableCost = (value, fieldName) => {
    const inputNominal = value
    .replace(/[^\d.,]/g, '') 
    .replace(/,/g, '');

    setEditableCost(prev => ({
      ...prev,
      [fieldName]: inputNominal
    }));
  };

  const toggleChildComponentsVisibility = (compId) => {
    setVisibleComponents(prevState => ({
        ...prevState,
        [compId]: !prevState[compId]
    }));
    setOpenChildComponents(!openChildComponents)
  };

  useEffect(() => {
    const profit_value = +editableCost.profit_value || 0;
    const overhead_cost = +editableCost.overhead_cost || 0;
    const contingency_cost = +editableCost.contingency_cost || 0;
    const grandTotal = +productGrandTotal || 0;
    const basic_price = +basicPrice || 0
    
    const newSellingPrice = profit_value + overhead_cost + contingency_cost + grandTotal + basic_price;
    setSellingPrice(newSellingPrice.toFixed(2));
  }, [productData, editableCost, productComponents, basicPrice, productGrandTotal]);

  const InputForm = ({defaultValue, label, name, type = "text", disabled, onChangeValue, setState, control, className}) => (
    <div className={`w-full h-fit ${className}`}>
      <p className={`font-semibold `}>{label}</p>
      <Input
        control={control}
        defaultValue={defaultValue}
        label={label}
        name={name}
        disabled={disabled}
        type={type}
        onChangeValue={onChangeValue}
      />
    </div>
  )

  const SelectForm = ({defaultValue, label, name, multiple = false, control, options, disabled = false}) => {
    return (
    <div className="w-full h-fit">
      <p className={`font-semibold mb-[4px]`}>{label}</p>
      <Select
        name={name}
        control={control}
        label={label}
        defaultValue={defaultValue}
        options={options || []}
        multiple={multiple}
        OptionLabel={label}
        menuProps={{
          style: {
            maxHeight: 400,
          },
        }}
        isInputLabel
        disabled={disabled}
      />
    </div>
  )}

  const handleSwitchClickInternal = () => {
    setIsInternal((prev) => !prev);
  };
  const handleSwitchClickSellable = () => {
    setSellableProduct((prev) => !prev);
  };

  const handleChangeBasicPrice = (event, id) => {
    const value = event.target.value.replace(/,/g, '');
    setBasicPrice(value);
    // setGrandTotal(value)
  };

  const handleDimensionChange = useCallback((index, field, event) => {
  const newDimensions = [...dimensions];
  newDimensions[index][field] = event.target.value;
  setDimensions(newDimensions);
  }, [dimensions, setDimensions]);

  const addDimension = () => {
    setDimensions([...dimensions, { id: dimensions.length + 1, type: '', value: '' }]);
  };

  const removeLastDimension = () => {
    if (dimensions.length > 1) {
      setDimensions(dimensions.slice(0, -1));
    }
  };

  const handleMediaUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageSrc = URL.createObjectURL(file);

      setProductMediaShow([{ id: 0, file: file, logoPreview: imageSrc }]);
    }
  };

  const handleRemoveImage = () => {
    setProductMediaShow([]);
  };

  const handleFileUpload = (event) => {
    let newAttributeValues = _.cloneDeep(productFiles);

    for (let i = 0; i < event.target.files.length; i++) {
      newAttributeValues.push({ id: newAttributeValues.length, file: event.target.files[i], newFile: true });
    }
    setProductFiles(newAttributeValues);
  };

  const handleCheckedFiles = (event, id) => {
    const isChecked = event.target.checked;

    let newCheckedFiles = _.cloneDeep(productFilesOnDelete);

    if (isChecked) {
      newCheckedFiles.push(id);
    } else {
      let index = newCheckedFiles.indexOf(id);
      if (index !== -1) {
        newCheckedFiles.splice(index, 1);
      }
    }

    setproductFilesOnDelete(newCheckedFiles);
  };

  const deleteFilesFromPage = () => {
    let productFilesList = _.cloneDeep(productFiles);
    const checkedFile = _.cloneDeep(productFilesOnDelete);

    const remainFiles = productFilesList.filter((item) => !checkedFile.includes(item.id));
    
    setProductFiles(remainFiles);
  };

  const onComponentsChange = (data) => {
    let grandTotal = 0;

    data.forEach((dt) => {
      if (dt.product && dt.product.selling_price && dt.quantity) {
        grandTotal += parseFloat(dt.product.selling_price) * dt.quantity;
      }
    });

    const formattedGrandTotal = grandTotal.toLocaleString('en-US', { 
      minimumFractionDigits: 2, 
      maximumFractionDigits: 2 
    });
    
    const grandTotalNumber = formattedGrandTotal.replace(/,/g, '');
    
    // setProductData((dt) => ({ ...dt, grand_total: grandTotalNumber }));
    setGrandTotal(grandTotalNumber)
    setProductComponents(data);
  }

  const onAddOnsChange = (data) => {
    const dataProductIds = data.map(dt => dt.product.id);
    
    setAddOns((currentAddOns) => {
      const addOnsMap = currentAddOns.reduce((map, item) => {
        map[item.add_on.id] = item;
        return map;
      }, {});

      const newAddOns = [];

      data.forEach(dt => {
        const productId = dt.product.id;
        if (addOnsMap[productId]) {
          newAddOns.push(addOnsMap[productId]);
          delete addOnsMap[productId];
        } else {
          newAddOns.push({
            add_on: dt.product,
            quantity: 1,
            price: "",
            name: dt.product.name,
          });
        }
      });

      return newAddOns;
    });
  };

  const showErrorAlert = (error) => {
    let errorMessages = "An unexpected error occurred:<br>";

    if (error.response && error.response.data) {
      const errorData = error.response.data;
      for (const [field, messages] of Object.entries(errorData)) {
        const formattedMessages = messages.join(', ');
        errorMessages += `<strong>${field}:</strong> ${formattedMessages}<br>`;
      }
    } else {
      errorMessages += `<b>${error.message}</b>`;
    }

    Swal.fire({
      title: "Error",
      html: errorMessages,
      icon: "error"
    });
  };

  const validationSubmit = async (data) => {
    const { updateProductDetail, deleteProductFiles, addNewProduct } = ProductManagementApi();
    const { collections, vendor, ...dataWithoutCollections } = data;

    const newData = {
      ...dataWithoutCollections,
      selling_price: productSellingPrice,
      grand_total: productGrandTotal || "",
      price: basicPrice || "",
      currency: data.currency !== null ? data.currency : "",
      ...editableCost,
      client: clientId,
    };

    // Internal Product Condition
    if (newData?.internal_product === true || newData.vendor === null) {
      newData["vendor"] = "";
    }
    
    // Size Unit
    if (sizeUnit !== "" && dimensions.some(dt => dt.type !== "")) {
      newData["item_size"] = JSON.stringify({
        unit: sizeUnit,
        dimensions: dimensions
      });
    }

    const newFilesArray = [];
    productFiles.forEach((file) => {
      if (file?.newFile === true) {
        newFilesArray.push(file.file);
      }
    });
    if (newFilesArray.length > 0) {
      newData["files"] = newFilesArray;
    }

    const formData = new FormData();
    for (const key in newData) {
      if (key !== "files") {
        formData.append(key, newData[key]);
      }
    }

    newFilesArray.forEach(file => {
      formData.append('files', file);
    });

    if (productMediaShow.length > 0) {
      formData.append('media', productMediaShow[0]?.file);
    }

    const newComponentsArray = [];
    productComponents.forEach((dt) => {
      newComponentsArray.push({ 
        component: dt.product.id, 
        quantity: dt.quantity, 
        is_modifier: dt.is_modifier || false, 
        name: dt.name || ""
      });
    });

    if (newComponentsArray.length > 0) {
      newData["components"] = newComponentsArray;
      newComponentsArray.forEach((compt, index) => {
        formData.append(`components[${index}]`, JSON.stringify(compt));
      });
    }

    // Add vendors
    if (vendor !== undefined) {
      vendor.forEach((vend, index) => {
        formData.append(`vendors`, vend.value);
      });
    }

    if (vendor === undefined && isFromProcurement === true) {
      formData.append(`vendors`, vendorId);
    }

    collections.forEach((compt, index) => {
      formData.append(`collections`, compt);
    });

    const newAddOnsArray = [];
    if (addOns.length > 0) {
      let hasEmptyQuantity = false;
      addOns.forEach((dt) => {
        if (dt.quantity === "") {
          hasEmptyQuantity = true;
        }
        newAddOnsArray.push({
          product: dt.add_on.id,
          name: dt.name,
          price: +dt.price,
          quantity: +dt.quantity || 1,
        });
      });

      if (hasEmptyQuantity) {
        Swal.fire({
          title: "Warning!",
          text: "All Quantities in Add-Ons Cannot Be Empty.",
          icon: "warning"
        });
        return;
      }

      newAddOnsArray.forEach((compt, index) => {
        formData.append(`add_ons[${index}]`, JSON.stringify(compt));
      });
    }
    
    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    Swal.fire({
      title: 'Processing...',
      icon: 'info',
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      if (mode === "edit") {
        if (productFilesOnDelete.length > 0) {
          try {
            await deleteProductFiles(productId, productFilesOnDelete);
          } catch (error) {
            showErrorAlert(error);
            return;
          }
        }

        try {
          await updateProductDetail(productId, formData);
          Swal.fire({
            title: "Success!",
            text: "Successfully updated product detail!",
            icon: "success"
          }).then(() => {
            isFromProcurement ? navigate(`/vendor/detail/${vendorId}`) : navigate('/product-management/products')
          });
        } catch (error) {
          showErrorAlert(error);
        }
      } else {
        await addNewProduct(formData).then((result) => {
          const { id } = result?.data;
          Swal.fire({
            title: "Success!",
            text: "Successfully added new product!",
            icon: "success"
          }).then(() => {
            isFromProcurement ? navigate(`/vendor/${vendorId}/product/detail/${id}`) : navigate(`/product-management/products/detail/${id}`);
          });
        }).catch((error) => {
          showErrorAlert(error);
        });
      }
    } catch (error) {
      showErrorAlert(error);
    }
  };

  const [tabValue, setTabValue] = useState(0);
  const [mainTabValue, setMainTabValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeMainTabs = (_, newValue) => {
    setMainTabValue(newValue);
  };

  useEffect(() => {
    if (sellableProduct === false) {
      setTabValue(0);
    }
  }, [sellableProduct])

  const handleChangeIsModifier = (value, id) => {
    setProductComponents((dt) => {
      return dt.map((item) => {
        if (item.component === id) {
          return { ...item, is_modifier: value };
        }
        return item;
      });
    });
  };

  const handleChangeModifierName = (value, id) => {
    setProductComponents((dt) => {
      return dt.map((item) => {
        if (item.component === id) {
          return { ...item, name: value };
        }
        return item;
      });
    });
  };


  const handleChangeAddOnQty = (value, id) => {
    // Menghapus karakter yang tidak valid (non-numeric dan non-pemisah)
    const cleanedValue = value.replace(/[^0-9.,]/g, '');
    
    // Mengganti titik dengan koma jika diperlukan
    const formattedValue = cleanedValue.replace(',', '.');
    
    // Mengupdate state
    setAddOns((dt) => {
      return dt.map((item, idx) => {
        if (idx === id) {
          return { ...item, quantity: formattedValue };
        }
        return item;
      });
    });
  };

  const handleChangeAddOnPrice = (value, id) => {
    setAddOns((dt) => {
      return dt.map((item, idx) => {
        if (idx === id) {
          return { ...item, price: value };
        }
        return item;
      });
    });
  };

  const handlChangeAddOnName = (value, id) => {
    setAddOns((dt) => {
      return dt.map((item, idx) => {
        if (idx === id) {
          return { ...item, name: value };
        }
        return item;
      });
    });
  };

  const SizeUnitInput = ({ sizeUnit, setSizeUnit }) => (
    <TextField
      type="text"
      value={sizeUnit}
      size="small"
      className="w-full"
      sx={{ textTransform: 'capitalize', marginTop: '1px', background: 'white' }}
      onChange={(event) => setSizeUnit(event.target.value)}
      variant="outlined"
      InputProps={{
        inputProps: {
          min: 0,
          max: 4,
        },
      }}
    />
  );

  return (
    <>
      <form>
        <div className='flex gap-4 w-full mb-4'>
          <div className="w-[20%] space-y-2">
            <div className='w-full h-[250px] relative border border-black'>
              {
                productMediaShow.length > 0 && (
                  <CancelIcon className="absolute right-[2px] top-[2px] cursor-pointer" sx={{ color: 'red', height: '20px', width: '20px' }} onClick={handleRemoveImage} />
              )}
              <label className="bg-white w-full h-full flex items-center justify-center cursor-pointer">
                {
                  productMediaShow.length > 0 ? (
                    <img
                      src={productMediaShow[0]?.logoPreview ? productMediaShow[0]?.logoPreview : productMediaShow[0]?.file}
                      alt=""
                      style={{ width: '100%', height: '250px', objectFit: 'contain', border: '0.5px solid black' }}
                      className="bg-white"
                    />
                  ) : (
                    <span className="text-gray-500">Click to upload image</span>
                  )
                }
                <input type="file" style={{ display: 'none' }} name="uploaded_logo" accept="image/*" onChange={(e) => handleMediaUpload(e)} />
              </label>
            </div>
          </div>
          <div className="w-[80%] h-fit grid grid-cols-4 gap-y-2 gap-x-4">
            {
              productId !== undefined && (
                <>
                  <InputForm 
                    defaultValue={productData?.item_number && productData?.item_number} 
                    label="Item Number" 
                    name="item_number" 
                    control={control} 
                  />
                </>
              )
            }
            <InputForm className="col-span-2" defaultValue={productData?.name && productData?.name} label="Product Name" name="name" control={control} />
            <SelectForm
              defaultValue={productData?.status || ""} 
              options={statusOptions} 
              label="Status"
              name="status" 
              control={control} 
            />
            <div className="w-full h-fit">
              <p className="font-semibold mb-[5px]">Item Unit</p>
              <Controller
                name="unit"
                control={control}
                defaultValue={productData?.unit || ''}
                render={({ field }) => {
                  return (
                    <SelectAutocomplete
                      variant="outlined"
                      options={productUnitOptions?.map((dt) => ({ name: dt.name, value: dt.name }))}
                      defaultValue={field.value || null}
                      onChange={(e, val) => field.onChange(val)}
                      isLoading={isLoadingOptions}
                    />
                  );
                }}
              />
            </div>
            <div className="w-full h-fit">
              <p className={`font-semibold "mb-[4px]"`}>Price</p>
              <TextField
                name="selling_price"
                size="small"
                className='w-full'
                sx={{ textTransform: 'capitalize', marginTop: '4px', background: 'white' }}
                margin="normal"
                value={formatNominal(basicPrice)}
                onChange={handleChangeBasicPrice}
                control={control}
                // disabled={productComponents.length > 0}
              />
            </div>
            <div className="w-full h-fit">
              <p className="font-semibold mb-[5px]">Currency</p>
              <Controller
                name="currency"
                control={control}
                defaultValue={productData?.currency || ''}
                render={({ field }) => {
                  return (
                    <SelectAutocomplete
                      variant="outlined"
                      options={currencyOptions?.map((dt) => ({ name: dt.code, value: dt.code }))}
                      defaultValue={field.value || ""}
                      onChange={(e, val) => field.onChange(val)}
                      isLoading={isLoadingOptions}
                    />
                  );
                }}
              />
            </div>
            {
              isInternal !== true && !isFromProcurement && (
                <div className="w-full h-fit">
                  <p className="font-semibold mb-[5px]">Vendor</p>
                    <Controller
                      name="vendor"
                      control={control}
                      defaultValue={productVendors.map((item) => ({ label: item.name, value: item.id })) || []}
                      render={({ field: { onChange, value } }) => (
                        <CustomSelectRedesign
                          className="mt-[4px] bg-white"
                          options={vendorOptions.map((dt) => ({ label: `${dt.name} ${dt.vendor_attributes?.vendor_id ? " - " + dt.vendor_attributes?.vendor_id : ""}`, value: dt.id }))}
                          defaultValue={value}
                          isMulti
                          onChange={(v) => {
                            onChange(v);
                          }}
                        />
                      )}
                    />
                </div>
              )
            }
            <SelectForm
              defaultValue={productData?.category?.id && productData?.category?.id || ""} 
              options={categoriesOptions.map((dt) => ({ label: dt.name, value: dt.id }))} 
              label="Category"
              name="category" 
              control={control} 
            />
            <SelectForm
              defaultValue={productData?.collections?.length ? productData?.collections.map((dt) => dt.id) : []} 
              options={collectionsOptions.map((dt) => ({ label: dt.name, value: dt.id }))} 
              label="Collections"
              multiple={true}
              name="collections" 
              control={control} 
              // disabled={true}
            />
            <SelectForm
              defaultValue={productData?.type?.id && productData?.type?.id || ""} 
              options={typesOptions.map((dt) => ({ label: dt.name, value: dt.id }))} 
              label="Product Type"
              name="type" 
              control={control} 
            />
            {
              !isFromProcurement && ( 
                <>
                  <SelectForm
                    defaultValue={productData?.department?.id && productData?.department?.id || ""} 
                    options={departmentOptions.map((dt) => ({ label: dt.name, value: dt.id }))} 
                    label="Department"
                    name="department" 
                    control={control} 
                  />
                  <div className='flex gap-x-4'>
                    <div className='flex flex-col items-center gap-y-1'>
                      <p className={`font-semibold "mb-[4px]"`}>Sellable Product</p>
                      <Controller
                        name="sellable_product"
                        control={control}
                        defaultValue={sellableProduct || false}
                        render={({ field }) => (
                          <Switch
                            {...field}
                            inputProps={{ 'aria-label': 'Switch Sellable' }}
                            checked={field.value}
                            onClick={handleSwitchClickSellable}
                          />
                        )}
                      />
                    </div>
                    <div className='flex flex-col items-center gap-y-1'>
                      <p className={`font-semibold "mb-[4px]"`}>Internal Product</p>
                      <Controller
                        name="internal_product"
                        control={control}
                        defaultValue={isInternal || false}
                        render={({ field }) => (
                          <Switch
                            {...field}
                            inputProps={{ 'aria-label': 'Switch Internal Product' }}
                            checked={field.value}
                            onClick={handleSwitchClickInternal}
                          />
                        )}
                      />
                    </div>
                  </div>
                </>                    
              )
            }
          </div>
        </div>
        <div className='flex gap-4 w-full mb-4'>
          <div className="w-[20%]">
            <div className='w-full'>
              <p className={`font-semibold`}>Size Unit</p>
              <TextField
                type="text"
                value={sizeUnit}
                size="small"
                className='w-full'
                sx={{ textTransform: 'capitalize', marginTop: '1px', background: 'white' }}
                onChange={(event) => setSizeUnit(event.target.value)}
                variant="outlined"
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: 4,
                  },
                }}
              />
            </div>
            <div className="w-full max-h-[225px] overflow-y-auto overflow-x-hidden">
              {dimensions.map((dimension, index) => (
                <div key={dimension.id} className="grid grid-cols-2 gap-y-2 gap-x-4 mt-2">
                  <div name="Dimension Type">
                    <p className="font-semibold ">Dimension</p>
                    <TextField
                      type="text"
                      value={dimension.type}
                      size="small"
                      className="w-full"
                      sx={{ marginTop: '4px', background: 'white' }}
                      onChange={(event) => handleDimensionChange(index, 'type', event)}
                      variant="outlined"
                    />
                  </div>
                  <div name="Dimension Value">
                    <p className="font-semibold">Value</p>
                    <div className="flex">
                      <TextField
                        type="number"
                        value={dimension.value}
                        size="small"
                        className="w-full"
                        sx={{  marginTop: '4px', background: 'white' }}
                        onChange={(event) => handleDimensionChange(index, 'value', event)}
                        variant="outlined"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{sizeUnit}</InputAdornment>,
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='w-full flex justify-end gap-x-1 min-h-[25px] mt-2'>
              <div className='border rounded-lg bg-[#ff1414] cursor-pointer'>
                <RemoveIcon className='text-white' onClick={removeLastDimension} disabled={dimensions.length <= 1} />
              </div>
              <div className='border rounded-lg bg-[#2b6aad] cursor-pointer'>
                <AddIcon className='text-white' onClick={addDimension} />
              </div>
            </div>
          </div>
          <div className="w-[80%] h-fit">
            <p className={`font-semibold "mb-[4px]"`}>Description</p>
            <Controller
              name="item_description"
              control={control}
              defaultValue={productData?.item_description || ""}
              render={({ field }) => (
                <textarea 
                  {...field} 
                  className='w-full border rounded-md p-2 border-[#C4C4C4] resize-y max-h-[150px] !focus-visible:border-[#3f51b5]' 
                  id="" 
                />
              )}
            />
          </div>
        </div>
        <div className='border-t mt-6 pt-2'>
          <Box sx={{ width: '100%', position: "relative" }}>
            <Box sx={{ marginBottom: 2 }}>
              <Tabs 
                value={tabValue} 
                onChange={handleChangeTab} 
                aria-label="basic tabs example"
                TabIndicatorProps={{
                  sx: {
                    backgroundColor: '#2C6D47',
                  },
                }}
                sx={{
                  '.MuiButtonBase-root': {
                    fontWeight: '600',
                  },
                  '.Mui-selected': {
                    color: '#2C6D47!important',
                  },
                }}
              >
                <Tab value={0} label="Components" {...a11yProps(0)} disabled={sellableProduct === false && productComponents.length === 0} />
                {
                  productComponents.length > 0 && (
                    <Tab value={1} label="Modifiers" {...a11yProps(1)} />
                  )
                }
                {
                  sellableProduct === true && (
                    <Tab value={2} label="Add-On" {...a11yProps(2)} />
                  )
                }
              </Tabs>
            </Box>
            {
              tabValue === 0 && (
                <>
                  <div className='w-fit flex justify-between mb-4 items-center absolute top-0 right-0'>
                    {/* <p className={`font-semibold mb-[8px]`}>Components : </p> */}
                    <button
                      className='btn bg-[#2C6D47] p-2 text-white rounded-lg border ml-auto cursor-pointer'
                      name="update document list"
                      type="button"
                      onClick={() => 
                        dispatch(handleModal({
                          modalId: clientId, 
                          componentName: 'componentPair', 
                          modalData: { 
                            productId, 
                            pairComponents: productComponents.map((dt) => ({product: dt.product, quantity: dt.quantity})),
                            pairComponentIds: productComponents.map((dt) => dt.product.id),
                            filterOptions: { categoriesOptions, typesOptions, collectionsOptions } || null
                          }
                        }))
                      }  
                    >
                      Edit Components
                    </button>
                  </div>
                  <div className='border'>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center' className='!font-bold'>Name</TableCell>
                          <TableCell align='center' className='!font-bold'>Category</TableCell>
                          <TableCell align='center' className='!font-bold'>Type</TableCell>
                          <TableCell align='center' className='!font-bold'>Size</TableCell>
                          <TableCell align='center' className='!font-bold' width={300}>Description</TableCell>
                          <TableCell align='center' className='!font-bold'>Qty</TableCell>
                          <TableCell align='center' className='!font-bold'>Unit</TableCell>
                          <TableCell align='center' className='!font-bold'>Currency</TableCell>
                          <TableCell align='center' className='!font-bold'>Unit Cost</TableCell>
                          <TableCell align='center' className='!font-bold'>Total Cost</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          productComponents.length > 0 &&
                            productComponents.map((comp) => {
                              const { id } = comp?.product
                              const costInNumeric = comp?.product?.selling_price;
                              const totalCost = +costInNumeric * comp?.quantity;
                              const components = comp?.product?.components ? comp?.product?.components : null
                              const isComponents = components ? components?.length > 0 : false
                              const itemSizeDimensions = comp?.product?.item_size || null;
                              
                              return (
                                <>
                                  <TableRow>
                                    <TableCell align='center'>
                                      <div className={`flex items-center h-[35px] ${isComponents && "cursor-pointer"}`} onClick={() => components?.length > 0 && toggleChildComponentsVisibility(id)}>
                                        {
                                          isComponents && (
                                            <IconButton
                                              aria-label="expand row"
                                              size="small"
                                            >
                                              {!visibleComponents[id] ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                          )
                                        }
                                        <Link to={`/product-management/products/detail/${comp?.product.id}`} className="text-bz-green font-semibold" onClick={() => setIsFetching(true)}>
                                          <p>{comp?.product?.name}</p>
                                        </Link>
                                      </div>
                                    </TableCell>
                                    <TableCell align='center'>{comp?.product?.category?.name}</TableCell>
                                    <TableCell align='center'>{comp?.product?.type?.name}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }} align="left">
                                      {itemSizeDimensions !== null ? (
                                        <ul className="">
                                          {itemSizeDimensions?.dimensions?.map((cat) => (
                                            <li key={cat.id} className="text-xs list-disc">
                                              {cat.type + ": " + cat.value + " " + itemSizeDimensions.unit}
                                            </li>
                                          ))}
                                        </ul>
                                      ) : ""}
                                    </TableCell>
                                    <TableCell align='center' width={500}>{comp?.product?.item_description}</TableCell>
                                    <TableCell align='center'>{comp?.quantity}</TableCell>
                                    <TableCell align='center'>{comp?.product?.unit}</TableCell>
                                    <TableCell align='center'>{comp?.product?.currency}</TableCell>
                                    <TableCell align='right'>{formatNominal(costInNumeric)}</TableCell>
                                    <TableCell align='right'>{formatNominal(totalCost.toFixed(2))}</TableCell>
                                  </TableRow>
                                  {
                                    visibleComponents[id] &&
                                      components?.map((dt) => {
                                        const costInNumeric = dt?.product?.selling_price;
                                        const totalCost = +costInNumeric * dt?.quantity
                                        const itemSizeDimensionsComp = dt?.product?.item_size || null
        
                                        return (
                                          <TableRow>
                                            <TableCell align='center' className='bg-[#EBEDF0]'>
                                            <Link to={`/product-management/products/detail/${dt?.product.id}`} className="text-bz-green font-semibold" onClick={() => setIsFetching(true)}>
                                              <p>{dt?.product?.name}</p>
                                            </Link>
                                            </TableCell>
                                            <TableCell align='center' className='bg-[#EBEDF0]'>{dt?.product?.category?.name}</TableCell>
                                            <TableCell align='center' className='bg-[#EBEDF0]'>{dt?.product?.type?.name}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }} align="left" className='bg-[#EBEDF0]'>
                                              {itemSizeDimensionsComp !== null ? (
                                                <ul className="">
                                                  {itemSizeDimensionsComp?.dimensions?.map((cat) => (
                                                    <li key={cat.id} className="text-xs list-disc">
                                                      {cat.type}
                                                    </li>
                                                  ))}
                                                </ul>
                                              ) : ""}
                                            </TableCell>
                                            <TableCell align='center' className='bg-[#EBEDF0]' width={500}>{dt?.product?.item_description}</TableCell>
                                            <TableCell align='center' className='bg-[#EBEDF0]'>{dt?.quantity}</TableCell>
                                            <TableCell align='center' className='bg-[#EBEDF0]'>{dt?.product?.unit}</TableCell>
                                            <TableCell align='center' className='bg-[#EBEDF0]'>{dt?.product?.currency}</TableCell>
                                            <TableCell align='right' className='bg-[#EBEDF0]'>{formatNominal(costInNumeric)}</TableCell>
                                            <TableCell align='right' className='bg-[#EBEDF0]'>{formatNominal(totalCost.toFixed(2))}</TableCell>
                                          </TableRow>
                                    )})
                                  }
                                </>
                              )
                            })
                        }
                      </TableBody>
                    </Table>
                  </div>
                  <div className='flex justify-end pr-2 pt-1 font-semibold'>
                    <div className='w-[400px] space-y-4'>
                      <div className='flex justify-between'>
                        <div className='w-[11.7rem] text-right'>
                          <p>Grand Total :</p>
                        </div>
                        {/* <p>{productComponents.length > 0 ? formatNominal(productData?.grand_total) : "0"}</p> */}
                        <p>{productGrandTotal && formatNominal(productGrandTotal) || "0.00"}</p>
                      </div>
                      <div className='flex justify-between'>
                        <div className='w-[11.7rem] text-right'>
                          <p>Contingency Cost :</p>
                        </div>
                        <TextField
                          size="small"
                          name='contingency cost'
                          value={formatNominal(editableCost.contingency_cost)}
                          sx={{
                            border: 0,
                            width: 190,
                            input: {
                              textAlign: "right",
                              padding: "0 2px",
                              fontWeight: 600,
                            },
                          }}
                          onChange={(e) => onChangeEditableCost(e.target.value, "contingency_cost")}
                          className="assumptions-textfield"
                        />
                      </div>
                      <div className='flex justify-between'>
                        <div className='w-[11.7rem] text-right'>
                          <p>Overhead Cost :</p>
                        </div>
                        <TextField
                          size="small"
                          value={formatNominal(editableCost.overhead_cost)}
                          name='overhead cost'
                          sx={{
                            border: 0,
                            width: 190,
                            input: {
                              textAlign: "right",
                              padding: "0 2px",
                              fontWeight: 600,
                            },
                          }}
                          onChange={(e) => onChangeEditableCost(e.target.value, "overhead_cost")}
                          className="assumptions-textfield"
                        />
                      </div>
                      <div className='flex justify-between'>
                        <div className='w-[11.7rem] text-right'>
                          <p>Profit Value :</p>
                        </div>
                        <TextField
                          size="small"
                          value={formatNominal(editableCost.profit_value)}
                          name='profit cost'
                          sx={{
                            border: 0,
                            width: 190,
                            input: {
                              textAlign: "right",
                              padding: "0 2px",
                              fontWeight: 600,
                            },
                          }}
                          onChange={(e) => onChangeEditableCost(e.target.value, "profit_value")}
                          className="assumptions-textfield"
                        />
                      </div>
                      <div className='flex justify-between'>
                        <div className='w-[11.7rem] text-right'>
                          <p>Selling Price :</p>
                        </div>
                        <p>{formatNominal(productSellingPrice)}</p>
                      </div>
                    </div>
                  </div>
                </>
              )
            }
            {
              tabValue === 1 && (
                  <div name="MODIFIERS TABLE" className='border'>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center' className='!font-bold'>Name</TableCell>
                          <TableCell align='center' className='!font-bold'>Qty</TableCell>
                          <TableCell align='center' className='!font-bold'>Unit Cost</TableCell>
                          <TableCell align='center' className='!font-bold'>Total Cost</TableCell>
                          <TableCell align='center' className='!font-bold' width={200}>Modifiable</TableCell>
                          <TableCell align='center' className='!font-bold' width={400}>Selling Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          productComponents.length > 0 &&
                            productComponents.map((comp) => {
                              const costInNumeric = comp?.product?.selling_price;
                              const totalCost = +costInNumeric * comp?.quantity;
                              const components = comp?.product?.components ? comp?.product?.components : null;
                              return (
                                <>
                                  <TableRow>
                                    <TableCell align='left'>{comp?.product?.name}</TableCell>
                                    <TableCell align='center'>{comp?.quantity}</TableCell>
                                    <TableCell align='right'>{formatNominal(costInNumeric)}</TableCell>
                                    <TableCell align='right'>{formatNominal(totalCost.toFixed(2))}</TableCell>
                                    <TableCell align='center'>
                                      <Checkbox 
                                        checked={comp?.is_modifier} 
                                        onChange={(e) => {
                                          handleChangeIsModifier(e.target.checked, comp?.component);
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell align='center' className=''>
                                      {
                                        comp.is_modifier === true ? 
                                        (
                                          <TextField 
                                            id="outlined-basic" 
                                            variant="outlined"
                                            sx={{
                                              width: 300,
                                              input: {
                                                textAlign: 'left',
                                                padding: '4px 8px',
                                                fontWeight: 500,
                                              },
                                            }}
                                            value={comp.name ?? comp.product?.name ?? ""}
                                            onChange={(e) => handleChangeModifierName(e.target.value, comp.component)}
                                          />
                                        ):(
                                          "-"
                                        )
                                      }
                                    </TableCell>
                                  </TableRow>
                                </>
                              )
                            })
                        }
                      </TableBody>
                    </Table>
                  </div>
              )
            }
            {
              tabValue === 2 && (
                <div name="ADD-ON TABLE" className='w-full space-y-2'>
                  <div className='border'>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center' className='!font-bold'>Name</TableCell>
                          <TableCell align='center' className='!font-bold' width={170}>Qty</TableCell>
                          <TableCell align='center' className='!font-bold'>Cost</TableCell>
                          <TableCell align='center' className='!font-bold' width={500}>Add-on Price</TableCell>
                          <TableCell align='center' className='!font-bold' width={500}>Selling Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          addOns.length > 0 &&
                            addOns.map((comp, idx) => {
                              const costInNumeric = comp?.add_on?.selling_price;
                              const totalCost = +costInNumeric * comp?.quantity;
                              const components = comp?.add_on?.components ? comp?.add_on?.components : null;
                              const isComponents = components ? components?.length > 0 : false;
        
                              return (
                                <>
                                  <TableRow>
                                    <TableCell align='center'>
                                      <div className={`flex items-center h-[35px] ${isComponents && "cursor-pointer"}`} onClick={() => components?.length > 0 && setOpenChildComponents(!openChildComponents)}>
                                        {
                                          isComponents && (
                                            <IconButton
                                              aria-label="expand row"
                                              size="small"
                                            >
                                              {!openChildComponents ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                          )
                                        }
                                        <Link to={`/product-management/products/detail/${comp?.add_on?.id}`} className="text-bz-green font-semibold" onClick={() => setIsFetching(true)}>
                                          <p>{comp?.add_on?.name}</p>
                                        </Link>
                                      </div>
                                    </TableCell>
                                    <TableCell align='center'>
                                      <TextField 
                                        id="outlined-basic" 
                                        variant="outlined" 
                                        type='text' 
                                        sx={{
                                          input: {
                                            textAlign: 'left',
                                            padding: '4px 8px',
                                            fontWeight: 600,
                                          },
                                        }}
                                        value={comp?.quantity}
                                        onChange={(e) => handleChangeAddOnQty(e.target.value, idx)}
                                      />
                                    </TableCell>
                                    <TableCell align='right'>{formatNominal(totalCost)}</TableCell>
                                    <TableCell align='center'>
                                      <TextField 
                                          id="outlined-basic" 
                                          variant="outlined" 
                                          type='number' 
                                          sx={{
                                            input: {
                                              textAlign: 'left',
                                              padding: '4px 8px',
                                              fontWeight: 600,
                                            },
                                          }}
                                          value={comp?.price}
                                          onChange={(e) => handleChangeAddOnPrice(e.target.value, idx)}
                                        />
                                    </TableCell>
                                    <TableCell align='center' className=''>
                                      <TextField 
                                        id="outlined-basic" 
                                        variant="outlined"
                                        sx={{
                                          width: 300,
                                          input: {
                                            textAlign: 'left',
                                            padding: '4px 8px',
                                            fontWeight: 500,
                                          },
                                        }}
                                        value={comp?.name}
                                        onChange={(e) => handlChangeAddOnName(e.target.value, idx)}
                                      />
                                  </TableCell>
                                  </TableRow>
                                </>
                              )
                            })
                        }
                      </TableBody>
                    </Table>
                  </div>
                  <div className='w-full flex justify-end mb-4 items-center'>
                    <button
                      className='btn bg-[#2C6D47] p-2 text-white rounded-lg border ml-auto cursor-pointer'
                      name="update document list"
                      type="button"
                      onClick={() => 
                        dispatch(handleModal({
                          modalId: clientId, 
                          componentName: 'addOnPair',
                          modalData: { 
                            productId,
                            pairComponents: addOns.map((dt) => ({product: dt.add_on})),
                            pairComponentIds: addOns.map((dt) => dt.add_on.id)
                          } 
                        }))}
                    >
                      Select New Add-on
                    </button>
                  </div>
                </div>
              )
            }
          </Box>
        </div>
        <div name="product files" className='border-t mt-6 pt-2'>
          <div className='w-full flex justify-between mb-4 items-center'>
            <p className={`font-semibold mb-[8px]`}>Files : </p>
            <button
              className={
                productFilesOnDelete.length
                  ? 'btn bg-red-600 p-2 text-white rounded-lg border ml-auto cursor-pointer'
                  : 'btn bg-red-200 p-2 text-white rounded-lg border ml-auto'
              }
              onClick={deleteFilesFromPage}
              disabled={!productFilesOnDelete.length}
              name="update document list"
              type="button"
            >
              Delete
            </button>
          </div>
          <div className='grid grid-cols-1 2xl:grid-cols-2 gap-2 w-full'>
          {
            productFiles.length > 0 && (
              productFiles.map((item, index) => {
                const fileUrl = typeof item.file === 'string' && item.file !== "" ? item.file : "New Files";
                const fileName = fileUrl.split('/').pop();

                return (
                  <div className="w-full px-2" key={index}>
                    <div className="w-full flex gap-y-1 gap-x-3 border-x border p-2 rounded-md shadow-sm">
                      <input
                        className="mr-3 transform scale-150"
                        type="checkbox"
                        value={item.id}
                        checked={productFilesOnDelete.includes(item.id)}
                        onChange={(e) => handleCheckedFiles(e, item.id)}
                      />
                      <div className="w-full font-bold flex items-center" style={{ color: '#707070' }}>
                        {fileName || "new files"}
                      </div>
                      <div className="w-fit flex items-center justify-between">
                        <a
                          href={fileUrl}
                          download
                          className="btn btn-link flex items-center justify-center"
                          style={{ backgroundColor: '#E2E2E2', height: '32px', width: '32px', borderRadius: '50%' }}
                        >
                          <DownloadIcon color="primary" />
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })
            )
          }
          </div>
          <div className="mt-4 w-fit">
            <label className="w-full bg-[#2C6D47] p-2 text-white rounded-lg cursor-pointer flex justify-center">
              <input type="file" style={{ display: 'none' }} name="uploaded_files" multiple onChange={(e) => handleFileUpload(e)}  />
              Add new files
            </label>
          </div>
        </div>
        <div className="flex justify-end border-t mt-6 pt-2">
          <button
            className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
            type="submit"
            name="submit data company"
            onClick={handleSubmit(validationSubmit)}
          >
            {mode === "edit" ? "Update Product Data" : "Add Product"}
          </button>
        </div>
      </form>
      <ModalWrapper 
        componentName="componentPair" 
        header="Component List" 
        maxWidth="xl" 
        PaperProps={{
          style: {
            margin: '0',
            position: 'absolute',
            top: '10%',
          }
        }}
      >
        <ComponentPairOnProductDetail onProductChange={onComponentsChange} />
      </ModalWrapper>
      <ModalWrapper  componentName="addOnPair" header="Add-On Pair" maxWidth="xl">
        <AddOnsPair onProductChange={onAddOnsChange} />
      </ModalWrapper>
    </>
  )
}

export default ProductDetailTab