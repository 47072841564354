import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';

export default function DatePickerField({ control, name, defaultValue, className, inputFormat, mask, errors, ...rest }) {
  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || null}
        render={({ field: { onChange, value } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={className}
              inputFormat={inputFormat || 'dd/MM/yyyy'}
              mask={mask || '__-__-____'}
              onChange={onChange}
              value={value}
              renderInput={(params) => <TextField onChange={onChange} {...params} {...rest} />}
            />
          </LocalizationProvider>
        )}
      />
      {errors && <div className="text-[#d32f2f] text-[0.75rem] mt-[4px] border-[#d32f2f]">{errors?.message}</div>}
    </>
  );
}

export function DatePickerFieldWithoutController({ control, label, value, className, inputFormat, componentsProps, mask, errors, onChange, size, renderInput, sx, disabled, minDate, maxDate, ...rest }) {
  return (
    <>    
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          clearable
          label={label}
          inputFormat='dd/MM/yyyy'
          mask="__/__/____"
          disableMaskedInput={false}
          value={value}
          onChange={onChange}
          renderInput={renderInput}
          componentsProps={componentsProps}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
        />
      </LocalizationProvider>
    </>
  );
}

export function DatePickerFieldRegister({ name, label, register = {}, errors, watch, onPatchProjectAttribute = () => {}, ...props }) {
  const [date, setDate] = useState(null);
  const isError = errors && name in errors;
  const { clearErrors, setError, setValue } = props.form;

  const defaultValue = watch(name);
  useEffect(() => {
    if (!date && defaultValue) {
      setDate(defaultValue);
    }
  }, [date, defaultValue]);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        inputFormat='dd/MM/yyyy'
        name={name}
        label={label}
        value={date}
        onChange={(newValue) => {
          setValue(name, newValue);
          setDate(newValue);
          if (props.isEdit) {
            if (props.isRequired && !newValue) {
              setError(name, { type: 'custom', message: `${name} is a required field` });
              return;
            } else {
              clearErrors(name);
            }
            onPatchProjectAttribute({ [name]: newValue })
          }
        }}
        className="w-full"
        closeOnSelect
        renderInput={(params) => <TextField size="small" error={!isError} {...params} register={register} />}
      />
      {props?.showErrorMessage && isError && <div className="text-[#d32f2f] text-[0.75rem] mt-[4px] border-[#d32f2f]">{errors?.message}</div>}
    </LocalizationProvider>
  );
}
