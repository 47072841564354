import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSearchParams, Link, useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Switch, IconButton, Collapse, TextareaAutosize, Button, CircularProgress } from '@mui/material';
import { TextField, InputAdornment } from '@mui/material';
import { handleModal } from '@/store/slices/modalSlice';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import _ from 'lodash';

import CancelIcon from '@mui/icons-material/Cancel';
import SelectAutocomplete from '@/components/Input/ProductManagement/SelectAutoComplete';
import CustomSelectRedesign from '../../CRM/redesigning/CustomSelectRedesign';
import {DatePickerFieldWithoutController} from '@/components/Input/DatePickerField';
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';
import Swal from 'sweetalert2';
import { useProductContext } from '../ProductContext';
import ModalWrapper from '@/components/ui/ModalWrapper';
import Select from '@/components/Input/MuiSelectSingleItem';
import Input from '../../CRM/redesigning/InputContainerRedesign';
import ComponentPairOnProductDetail from '@/components/Form/ProductManagement/ComponentPairOnProductDetail';
import AddOnsPair from '@/components/Form/ProductManagement/AddOnsPair';
import moment from 'moment';
import { toast } from 'react-toastify';
import { toastSuccess } from '@/constants/ToastConfig';

function formatNominal(number, fixedDecimal = true) {
  if (isNaN(number)) {
      return "-";
  }

  const formattedNumber = fixedDecimal ? Number(number).toFixed(2) : number.toString();
  return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function TabProjection() {
  const { register, handleSubmit, control, errors, setValue } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const navigate = useNavigate();
  const { productId, vendorId } = useParams();
  const isFromProcurement = vendorId !== undefined;
  const params = useParams();
  const dispatch = useDispatch();

  const {
    currencyOptions,
    productData,
    pricingProjection,
    isFetchingProjection,
    getAllPricingManagerData,
  } = useProductContext();

  const costsType = [
    {name: "Total Item Cost", value: "item_cost"},
    {name: "Contingency", value: "contingency_cost"},
    {name: "Overhead", value: "overhead_cost"},
    {name: "Profit", value: "profit_value"},
    {name: "Total", value: "system_total"},
    {name: "Selling Price", value: "selling_price"},
  ]
  
  return !isFetchingProjection ? (
    <div className='border'>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='center' className='!font-bold'>Costs</TableCell>
            {
              pricingProjection?.product_price.map((dt, idx) => {
                return (
                  <TableCell key={idx} align='center' className='!font-bold'>{dt.date}</TableCell>
                )
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            costsType.map((dt, idx) => {
              
              return (
              <TableRow className={`${dt.value === "selling_price" ? "font-bold" : ""} ${dt.value === "system_total" && "italic"}`} key={idx}>
                <TableCell align='center' className={`${dt.value === "selling_price" ? "font-bold" : ""} ${dt.value === "system_total" && "italic"}`}>{dt.name}</TableCell>
                {
                  pricingProjection?.product_price.map((ppro, idx) => (
                    <TableCell key={idx} className={`${dt.value === "selling_price" ? "font-bold" : ""} ${dt.value === "system_total" && "italic"}`} align='right'>{formatNominal(ppro[dt.value])}</TableCell>
                  ))
                }
              </TableRow>
            )})
          }
          <TableRow>
            <TableCell align='center' colSpan={pricingProjection?.product_price.length + 1} />
          </TableRow>
          {/* <TableRow>
            <TableCell align='center'>Add-Ons</TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    </div>
  ):(
    <CircularProgress />
  )
  }

export default TabProjection