import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Search } from '@mui/icons-material'
import { Checkbox, InputBase, TableCell, TableRow } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Swal from 'sweetalert2';

import BaseMainTitle from '@/components/BaseMainTitle'
import BZHelmet from '@/utils/BZHelmet'
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';
import CustomTable from '@/components/Table/CustomTable'
import { useForm } from 'react-hook-form';
import { useDebounce } from 'use-debounce';

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
];

function NameCell(props) {
  const { name, id } = props;
  const TEMPLATE_ID = useSelector((state) => state.content.activeTemplate);
  return (
    <div className="">
      <Link to={`/product-management/categories/detail/${id}`} className="text-bz-green font-semibold">
        {name}
      </Link>
    </div>
  );
}

function TableCells(props) {
  const { row, labelId, ariaChecked, selected, handleClick } = props;
  console.log({ariaChecked});

  return (
    <TableRow hover role="checkbox" key={row.id}>
      <TableCell padding="checkbox">
        <Checkbox onClick={(event) => handleClick(event, row.id)} color="primary" checked={ariaChecked} inputProps={{ 'aria-labelledby': labelId }} />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="2">
        {row?.id}
      </TableCell>
      <TableCell align="left">
        <NameCell name={row?.name || "-"} id={row?.id} />
      </TableCell>
    </TableRow>
  );
}

function ProductCategories() {
  const { control, reset, resetField } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const dispatch = useDispatch();

  const [counts, setCounts] = useState(0);
  const [isFetching, setIstFetching] = useState(true);
  const [orderQuery, setOrderQuery] = useState('');
  const [ordeIsAsc, setOrdeIsAsc] = useState('');
  const [checkboxSelected, setCheckboxSelected] = useState([]);

  // FILTER OPTIONS
  const [categories, setCategories] = useState([]);
  const [searchCategories, setSearchCategories] = useState("");

  // TABLE PAGINATION
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [searchValue] = useDebounce(searchCategories, 300);
  const [paramCategories, setParamCategories] = useState(new URLSearchParams(`client=${clientId}&page=${page}&page_size=${rowsPerPage}`));

  useEffect(() => {
    const params = new URLSearchParams(`client=${clientId}&page=${page}&page_size=${rowsPerPage}`);

    if (searchValue) {
      params.set("search", searchValue.split(" ").join("+"));
    }
  
    setParamCategories(params);
  }, [searchValue, page, rowsPerPage]);

  const fetchData = async (apiFunction, params) => {
    try {
      const res = await apiFunction(params);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getCategoryList = useCallback((params = { client: clientId }) => fetchData(ProductManagementApi().getCategories, params), [clientId, paramCategories]);

  const getCategories = useCallback(
    async (paramCategories) => {
      const [categories] = await Promise.all([getCategoryList(paramCategories)]);

      if (categories) {
        setCategories(categories?.results);
        setCounts(categories.count)
      }

      setIstFetching(false);
    },
    [getCategoryList]
  );

  useEffect(() => {
    getCategories(paramCategories)
  }, [clientId, paramCategories])

  const DeleteDatabyId = () => {
    const { deleteOnecategory, bulkDeleteCollections } = ProductManagementApi();

    try {
      if (checkboxSelected.length > 1) {
        Swal.fire({
          title: "Coming Soon...",
          text: "Delete Multiple Categories is currently unavailable",
          icon: "info"
        })
      } else {
        deleteOnecategory(checkboxSelected[0])
          .then((res) => {
            if (res.status === 204) {
              // getCollections(paramCategories);
              setCheckboxSelected([]);
              Swal.fire({
                title: "Success !",
                text: "Successfully Deleted Categories !",
                icon: "success"
              })
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const InputLabel = ({ children }) => <p className="font-bold">{children}</p>;

  return (
    <div className="w-full h-full bg-white">
      <BZHelmet title="Product Categories" description="" content="" />
      <BaseMainTitle title="Product Collections" />
      <div className="w-full p-5 Categories">
        <div className='flex justify-between gap-4 w-full'>
          <div className="">
            <div>
              <InputLabel>Search Category</InputLabel>
            </div>
            <InputBase
              size="small"
              placeholder="search"
              endAdornment={<Search color="disabled" />}
              onChange={(v) => setSearchCategories(v.target.value)}
              value={searchCategories}
              className="w-[15rem] h-[2.5rem] border border-[#BCB9B9] rounded px-4 bg-white hover:border-[#adacac]"
            />
          </div>
          <div className="flex items-end pb-2 ">
            <button
              type="button"
              className={`
                flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5
                ${checkboxSelected.length ? "bg-[#FC581D] cursor-pointer " : "bg-[#FC581D26] cursor-default"}
              `}
              onClick={DeleteDatabyId}
              disabled={checkboxSelected.length ? false : true}
            >
              <p className="text-white">Delete Selected</p>
            </button>
            <Link
              to="/product-management/categories/add"
              className="flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 cursor-pointer"
              style={{ backgroundColor: '#2C6D47' }}
            >
              <AddIcon className="text-white" />
              <p className="text-white">Add New Category</p>
            </Link>
          </div>
        </div>
        <div className="mt-4">
          <CustomTable
            dataApi={categories}
            isFetching={!isFetching}
            headCells={headCells}
            TableCells={TableCells}
            count={counts}
            page={page - 1}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            checkboxSelected={checkboxSelected}
            setCheckboxSelected={setCheckboxSelected}
            ordeIsAsc={ordeIsAsc}
            orderQuery={orderQuery}
            name=""
            useCheckbox
          />
        </div>
      </div>
    </div>
  )
}

export default ProductCategories