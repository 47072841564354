// /* eslint-disable */

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validationLocation = yup
  .object()
  .shape({
    name: yup.string().required('Name is required'),
  })
  .required();

const schemaLocation = yupResolver(validationLocation);

export default schemaLocation;
