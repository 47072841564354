import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, InputAdornment, InputBase, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import { GridView, Search, ViewList } from '@mui/icons-material';
import _ from 'lodash';
import { handleModal } from '@/store/slices/modalSlice';
import HrApi from '@/api/HR/api';
import Swal from 'sweetalert2';
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';
import { Controller, useForm } from 'react-hook-form';
import Input from '@/pages/CRM/redesigning/InputContainerRedesign';
import CustomSelectRedesign from '@/pages/CRM/redesigning/CustomSelectRedesign';
import { fetchData } from '@/utils/fetchDataFunc';

function ManageMarket({
  mode = "add",
  getMarketManagersList
}) {
  const { register, handleSubmit, control, errors, setValue } = useForm();
  const activeClient = useSelector((state) => state.modal.modalId);
  const marketData = useSelector((state) => state.modal.modalData) || null;
  const dispatch = useDispatch();

  const [selectedValues, setSelectedValues] = useState(marketData?.data?.departments || []);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);

  const departments = useCallback((params = { max_size: true, client: activeClient, ordering: "name" }) => fetchData(HrApi().getDepartment, params ), [activeClient]);

  const getAllOptions = useCallback(async () => {
    try {
      const [departmentList] = await Promise.all([departments()]);
      if (departmentList) {
        const {results} = departmentList;

        setDepartmentOptions(results.map((dt) => ({ label: dt.name, value: dt.id })));
        setIsLoadingOptions(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoadingOptions(false);
    }
  }, [departments]);

  useEffect(() => {
    if (marketData) {
      const { data } = marketData;
      setValue("market_name", data?.market_name || "");
    }
  }, [marketData, setValue, departmentOptions]);

  useEffect(() => {
    getAllOptions();
  }, [getAllOptions]);

  const submitData = async (data) => {
    const { addNewMarketManager, updateMarketManager } = ProductManagementApi();

    try {
      const newData = {
        ...data,
        departments: selectedValues
      };
      
      if (mode === "edit") {
        newData["id"] = marketData?.marketId;
        await updateMarketManager(newData.id, newData);
        getMarketManagersList();
        dispatch(handleModal({ modalId: '', componentName: '' }));
        swal(
          "Success !",
          "Successfully Updated Market !",
          "success"
        );
      } else {
        await addNewMarketManager(newData);
        getMarketManagersList();
        dispatch(handleModal({ modalId: '', componentName: '' }));
        swal(
          "Success !",
          "Successfully Added Market !",
          "success"
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submitData)}>
        <div className={`w-full h-fit col-span-2`}>
          <p className={`font-semibold `}>Name</p>
          <Input
            control={control}
            defaultValue={marketData?.data?.market_name && marketData?.data?.market_name}
            label="name"
            name="market_name"
            disabled={false}
            type="text"
          />
        </div>
        <div className="w-full h-fit">
          <p className="font-semibold mb-[5px]">Departments</p>
            <CustomSelectRedesign
              className="mt-[4px] bg-white"
              options={departmentOptions}
              defaultValue={selectedValues.map((val) => departmentOptions.find((option) => option.value === val))} 
              isMulti
              onChange={(selectedOptions) => {
                const values = selectedOptions ? selectedOptions.map((option) => option.value) : [];
                setSelectedValues(values);
                onChange(values);
              }}
              isLoading={isLoadingOptions}
            />
        </div>
        <button
          type="submit"
          name="submit contact"
          className="flex h-[40px] mt-7 ml-auto gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4"
          style={{ backgroundColor: '#2C6D47' }}
        >
          <p className="text-white">{mode === "add" ? "Add" : "Update"}</p>
        </button>
      </form>
    </div>
  );
}

export default ManageMarket;