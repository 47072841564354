/* eslint-disable */

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validationCraete = yup
  .object()
  .shape({
    name: yup.string().required('Name is required'),
    email: yup.string().required('Email is required'),
    password: yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
    confirm_password: yup
      .string()
      .required('Confirm Password is required')
      .oneOf([yup.ref('password')], 'Passwords must match'),
  })
  .required();

const validationUpdate = yup
  .object()
  .shape({
    name: yup.string().required('Name is required'),
    email: yup.string().required('Email is required'),
    password: yup.string(),
    confirm_password: yup
      .string().oneOf([yup.ref('password')], 'Passwords must match'),
  })
  .required();

const schemaUserCreate = yupResolver(validationCraete);
const schemaUserUpdate = yupResolver(validationUpdate);

export { schemaUserCreate, schemaUserUpdate };
