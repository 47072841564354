import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSearchParams, Link, useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Switch, IconButton, Collapse, TextareaAutosize, Button, CircularProgress } from '@mui/material';
import { TextField, InputAdornment } from '@mui/material';
import { handleModal } from '@/store/slices/modalSlice';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import _ from 'lodash';

import CancelIcon from '@mui/icons-material/Cancel';
import SelectAutocomplete from '@/components/Input/ProductManagement/SelectAutoComplete';
import CustomSelectRedesign from '../../CRM/redesigning/CustomSelectRedesign';
import {DatePickerFieldWithoutController} from '@/components/Input/DatePickerField';
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';
import Swal from 'sweetalert2';
import { useProductContext } from '../ProductContext';
import ModalWrapper from '@/components/ui/ModalWrapper';
import Select from '@/components/Input/MuiSelectSingleItem';
import Input from '../../CRM/redesigning/InputContainerRedesign';
import ComponentPairOnProductDetail from '@/components/Form/ProductManagement/ComponentPairOnProductDetail';
import AddOnsPair from '@/components/Form/ProductManagement/AddOnsPair';
import moment from 'moment';
import { toast } from 'react-toastify';
import { toastSuccess } from '@/constants/ToastConfig';

function formatNominal(number, fixedDecimal = true) {
  if (isNaN(number)) {
      return "-";
  }

  const formattedNumber = fixedDecimal ? Number(number).toFixed(2) : number.toString();
  return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function TabCostBreakdown() {
  const { register, handleSubmit, control, errors, setValue } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const navigate = useNavigate();
  const { productId, vendorId } = useParams();
  const isFromProcurement = vendorId !== undefined;
  const params = useParams();
  const dispatch = useDispatch();

  const {
    isFetchingCostBreakdown,
    costBreakdown,
    setIsFetching,
    pricingProjection,
    isFetchingProjection
  } = useProductContext();
  
  const [openChildComponents, setOpenChildComponents] = useState(false);
  const [visibleComponents, setVisibleComponents] = useState({});

  const toggleChildComponentsVisibility = (compId) => {    
    setVisibleComponents(prevState => ({
        ...prevState,
        [compId]: !prevState[compId]
    }));
    setOpenChildComponents(!openChildComponents)
  };

  const PriceCell = ({ price,hasChild }) => {    
    return (
      <TableCell key={price.date} align='right'>
        <div className={`w-full ${hasChild && "font-semibold"}`}>
          <p>{formatNominal(price?.system_total)}</p>
          {price.original_prices.length > 0 && (
            <div className="w-full flex justify-end gap-x-1	!text-[8px]">
              <p className="h-fit">{formatNominal(price?.original_prices[0]?.original_price)}</p>
              <p className="h-fit">{price?.original_prices[0]?.original_currency}</p>
              <Tooltip title="Original Price">
                <InfoOutlinedIcon style={{ fontSize: '9px', cursor: "pointer" }} />
              </Tooltip>
            </div>
          )}
        </div>
      </TableCell>
    );
  };

  const renderComponent = (component, level = 1) => {    
    return (
      <>
        <TableRow key={component.component_id}>
          <TableCell align='center' className="font-bold" style={{ paddingLeft: `${level * 40}px` }}> {/* Indentasi */}
            <div className={`flex items-center h-[35px]`} onClick={() => component?.components.length > 0 && toggleChildComponentsVisibility(component.component_id)}>
              {component?.components.length > 0 && (
                <IconButton
                  aria-label="expand row"
                  size="small"
                >
                  {!visibleComponents[component.component_id] ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              )}
              <Link to={`/product-management/products/detail/${component.component_id}`} className="text-bz-green font-semibold" onClick={() => setIsFetching(true)}>
                <p className="font-semibold">{component?.component_name}</p>
              </Link>
            </div>
          </TableCell>
          {component?.prices.map((price) => (
            <PriceCell price={price} hasChild={component?.components.length > 0} />
          ))}
        </TableRow>
        {visibleComponents[component.component_id] && component?.components.length > 0 && component?.components.map((childComponent) => renderComponent(childComponent, level + 1))}
      </>
    );
  };

  return !isFetchingProjection ? (
    <div className='border'>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='center' className='!font-bold' width={450}>Name</TableCell>
            {
              pricingProjection?.product_price.map((dt, idx) => {
                return (
                  <TableCell key={idx} align='center' className='!font-bold'>{dt.date}</TableCell>
                )
              })
            }
          </TableRow>
        </TableHead>
          {
            pricingProjection && (
              <TableBody>
                <TableRow>
                  <TableCell align='center' className="font-bold">
                    <div className={`flex items-center h-[35px] `} onClick={() => pricingProjection?.components.length > 0 && toggleChildComponentsVisibility(pricingProjection.product_id)}>
                      {
                        pricingProjection?.components.length > 0 && (
                          <IconButton
                            aria-label="expand row"
                            size="small"
                          >
                            {!visibleComponents[pricingProjection.product_id] ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        )
                      }
                      <Link to={`/product-management/products/detail/${pricingProjection.component_id}`} className="text-bz-green font-semibold" onClick={() => setIsFetching(true)}>
                        <p className="font-semibold">{pricingProjection?.product_name}</p>
                      </Link>
                    </div>
                  </TableCell>
                  {
                    pricingProjection?.product_price.map((price, idx) => (
                      <TableCell key={idx} className="font-bold" align='right'>{formatNominal(price?.item_cost)}</TableCell>
                    ))
                  }
                </TableRow>
                {visibleComponents[pricingProjection.product_id] && pricingProjection?.components.map((childComponent) => renderComponent(childComponent))}
              </TableBody>
            )
          }
      </Table>
    </div>
  ):(
    <CircularProgress />
  )
}

export default TabCostBreakdown

// {
//   visibleComponents[pricingProjection.product_id] && pricingProjection?.components.map((comp, idx) => (
//     <>
//     <TableRow key={idx}>
//       <TableCell align='center' className="font-bold">
//         <div className={`flex items-center h-[35px] `} onClick={() => comp?.components.length > 0 && toggleChildComponentsVisibility(comp.component_id)}>
//         {
//           comp?.components.length > 0 && (
//             <IconButton
//               aria-label="expand row"
//               size="small"
//             >
//               {!visibleComponents[comp.component_id] ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
//             </IconButton>
//           )
//         }
//         <Link to={`/product-management/products/detail/${comp.component_id}`} className="text-bz-green font-semibold" onClick={() => setIsFetching(true)}>
//           <p className="font-semibold">{comp?.component_name}</p>
//         </Link>
//       </div>
//       </TableCell>
//       {
//         comp?.prices.map((price, idx) => {
//           return (
//           <TableCell key={idx} align='right'>
//             <div className="w-full">
//               <p>{formatNominal(price?.system_total)}</p>
//               {price.original_prices.length > 0 && (
//                 <div className="w-full flex justify-end gap-x-1	!text-[8px]">
//                   <p className="h-fit">{formatNominal(price?.original_prices[0]?.original_price)}</p>
//                   <p className="h-fit">{price?.original_prices[0]?.original_currency}</p>
//                   <Tooltip title="Original Price">
//                     <InfoOutlinedIcon style={{ fontSize: '9px', cursor: "pointer" }} />
//                   </Tooltip>
//                 </div>
//               )}
//             </div>
//           </TableCell>
//         )})
//       }
//     </TableRow>
//     </>
//   ))
// }