import React from 'react';
import { styled, Tabs, Tab, Box } from '@mui/material';
import PropTypes from 'prop-types';
import clsxm from '@/utils/clsxm';

const StyledTabs = styled((props) => <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />)({
  minHeight: 30,
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: 'var(--bzGreen)',
  },
  '& .MuiTabs-indicatorSpan :hover': { backgroundColor: '#28292B' },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
  '& .MuiTabs-indicatorSpan :hover': { backgroundColor: '#28292B' },
  marginRight: 20,
  padding: 0,
  minWidth: 0,
  minHeight: 0,
  color: '#858586',
  '&.Mui-selected': { color: 'var(--bzGreen)' },
  '&.Mui-focusVisible': { backgroundColor: 'rgba(100, 95, 228, 0.32)' },
  '&:hover': {
    color: '#28292B',
    opacity: 1,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div className=" py-4 " role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 0 }} className="">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function MuiCusotmTabs({ optionsTab, value, onChange }) {
  // const optionsTab = useSelector((state) => state.client.clientSiteLanguages);

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="mt-5">
        <StyledTabs className="bz-tabs" value={value} onChange={onChange} aria-label="basic tabs example">
          {optionsTab?.map((opt, index) => (
            <StyledTab key={opt?.id} className={clsxm('capitalize text-lg', opt?.error ? '!text-[#d32f2f]' : '')} label={opt?.name} {...a11yProps(index)} />
          ))}
        </StyledTabs>
      </Box>
    </div>
  );
}
