import useTokenBearer from '@/hooks/useTokenBearer';
import axios from 'axios';
import Cookies from 'js-cookie';

const client = axios.create({ baseURL: `${import.meta.env.VITE_API_URL}` });
const token = Cookies.get('bzKey');

export default function requestHR(options) {
  client.defaults.headers.common.Authorization = `${useTokenBearer()} ${token}`;

  const onSuccess = (response) => response;
  const onError = (error) => error;
  return client(options).then(onSuccess).catch(onError);
}
