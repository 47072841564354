import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSearchParams, Link, useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Switch, IconButton, Collapse, TextareaAutosize, Button, CircularProgress } from '@mui/material'
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi'
import BZHelmet from '@/utils/BZHelmet';
import BaseMainTitle from '@/components/BaseMainTitle';
import Input from '../CRM/redesigning/InputContainerRedesign';
import Select from '../../components/Input/MuiSelectSingleItem';
import { TextField, InputAdornment } from '@mui/material';
import ModalWrapper from '@/components/ui/ModalWrapper';
import { handleModal } from '@/store/slices/modalSlice';
import ComponentPairOnProductDetail from '@/components/Form/ProductManagement/ComponentPairOnProductDetail';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { ArrowBack } from '@mui/icons-material';
import SelectAutocomplete from '@/components/Input/ProductManagement/SelectAutoComplete';
import AddOnsPair from '@/components/Form/ProductManagement/AddOnsPair';
import CustomSelectRedesign from '../CRM/redesigning/CustomSelectRedesign';
import CustomTable from '@/components/Table/CustomTable';
import ManageMarket from '@/components/Form/ProductManagement/ManageMarket';
import { fetchData } from '@/utils/fetchDataFunc';

function MarketManagerList() {
  const { control, reset, resetField } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const dispatch = useDispatch()

  const headCells = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: 'Item Number',
    },
    {
      id: 'market_name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    // {
    //   id: 'departments',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Departments',
    // },
  ];
  
  function DepartmentsCell(props) {
    const { data } = props;
    
    return data.length > 0 && (
      <ul className="">
        {data?.map((col) => (
          <li key={col} className="text-xs list-disc">
            {col || ""}
          </li>
        ))}
      </ul>
    );
  }
  
  function NameCell(props) {
    const { name, id, row } = props;
    const TEMPLATE_ID = useSelector((state) => state.content.activeTemplate);
    return (
      <div className="">
        <p 
          className="text-bz-green font-semibold cursor-pointer"
          onClick={() => dispatch(handleModal({ 
            modalId: '', 
            componentName: 'editMarket' ,
            modalData: { 
              data: row,
              marketId: row.id
            }
          }))}
        >
          {name}
        </p>
      </div>
    );
  }
  
  function TableCells(props) {
    const { row, labelId, ariaChecked, selected, handleClick } = props;
  
    return (
      <TableRow hover role="checkbox" key={row?.id}>
        {/* <TableCell padding="checkbox">
          <Checkbox color="primary" checked={ariaChecked} inputProps={{ 'aria-labelledby': labelId }} />
        </TableCell> */}
        <TableCell component="th" id={labelId} scope="row" padding="2">
          {row?.id}
        </TableCell>
        <TableCell align="left">
          <NameCell name={row?.market_name || "-"} id={row?.id}  row={row}/>
        </TableCell>
        {/* <TableCell align="left">{row?.status || "-"}</TableCell> */}
      </TableRow>
    );
  }

  const [marketManagers, setMarketManagers] = useState([]);
  const [count, setcount] = useState(0);
  const [isFetching, setIsFetching] = useState(true);
  const [orderQuery, setOrderQuery] = useState('');
  const [ordeIsAsc, setOrdeIsAsc] = useState('');
  const [checkboxSelected, setCheckboxSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const marketManagersList = useCallback((params) => fetchData(ProductManagementApi().getMarketManagers, params), [clientId]);

  const getMarketManagersList = useCallback(
    async (param) => {
      const [market] = await Promise.all([marketManagersList(param)]);

      if (market) {
        setMarketManagers(market?.results);
        setcount(market?.count);
      } 
      
      setIsFetching(false) 
    },
    [marketManagersList]
  );

  useEffect(() => {
    getMarketManagersList({ departments__client: clientId })
  }, [clientId]);
  
  return (
    <div className="w-full h-full bg-white">
      <BZHelmet title="Market Manager List" description="" content="" />
      <BaseMainTitle title="Market Manager List" />
      <div className="w-full p-5">
        <div className="w-full flex justify-end">
          <button
            type="button"
            className="flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 px-5 cursor-pointer whitespace-nowrap"
            style={{ backgroundColor: '#2C6D47' }}
            onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addMarket', }))}
          >
            <AddIcon className="text-white" />
            <p className="text-white">Add Market</p>
          </button>
        </div>
        <div className="w-full relative">
          <div className="mt-4">
            <CustomTable
              dataApi={marketManagers}
              isFetching={!isFetching}
              headCells={headCells}
              TableCells={TableCells}
              count={count}
              page={page - 1}
              rowsPerPage={rowsPerPage}
              setPage={v => setPage(v)}
              setRowsPerPage={v => setRowsPerPage(v,)}
              checkboxSelected={checkboxSelected}
              setCheckboxSelected={setCheckboxSelected}
              ordeIsAsc={ordeIsAsc}
              orderQuery={orderQuery}
              name="Market Manager List"
              // useCheckbox
            />
          </div>
        </div>
      </div>
      <ModalWrapper componentName="addMarket" header="Add New Market" maxWidth="sm">
        <ManageMarket getMarketManagersList={getMarketManagersList} />
      </ModalWrapper>
      <ModalWrapper componentName="editMarket" header="Edit Market" maxWidth="sm">
        <ManageMarket mode="edit" getMarketManagersList={getMarketManagersList} />
      </ModalWrapper>
    </div>
  )
}

export default MarketManagerList