/* eslint-disable */

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Box } from '@mui/material';
import { DevTool } from '@hookform/devtools';
import PropTypes from 'prop-types';

import InputContainer from '../../Input/InputContainer';
import { handleModal } from '../../../store/slices/modalSlice';
import MuiCusotmTabs from '../../ui/Tab/MuiCustomTabs';

function JobPositionForm({ defaultValues, onFormSubmit }) {
  const { control, handleSubmit } = useForm({ defaultValues });
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.client.activeClient);
  const clientLanguage = useSelector((state) => state.content.activeSiteDetail.site_languages);

  const [valueTab, setValueTab] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const devLang = [];

  defaultValues?.position_languages?.map((l) => {
    devLang.push(l);
    return devLang;
  });

  const onSubmit = (data) => {
    let name;
    const position_languages = clientLanguage.reduce((acc, curr) => {
      console.log('acc', acc, 'curr', curr);
      const inputLang = devLang.find((dl) => dl.language === curr.id);
      const output = {};
      output.language = curr.language.id;
      output.name = data[`name-${curr.id}`] || inputLang?.name || null;
      name = output.name;
      return [...acc, output];
    }, []);
    console.log('name', name);
    onFormSubmit({ name, client: clientId, position_languages });
  };

  const handleCancel = () => {
    dispatch(handleModal({ id: '' }));
  };

  let langId;

  function getInputName(data) {
    clientLanguage?.map((lang, index) => {
      console.log('dat', data);
      return `name-${lang.id}`;
    });
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div className=" py-4 " role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && (
          <Box sx={{ p: 0 }} className="">
            {children}
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full space-y-4">
        <MuiCusotmTabs
          optionsTab={clientLanguage.map((lang) => {
            return {
              name: lang?.language?.name,
              id: lang?.language?.id,
            };
          })}
          value={valueTab}
          onChange={handleChangeTab}
        />
        {clientLanguage?.map((lang, index) => (
          <TabPanel key={lang.id} value={valueTab} index={index}>
            <InputContainer
              name={`name-${lang.id}`}
              control={control}
              label="Name"
              value=""
              defaultValue={devLang[index]?.name}
              // errors={errors.name}
            />
          </TabPanel>
        ))}
        <div className="flex justify-end ml-auto gap-x-2">
          <Button onClick={handleCancel} className="flex" variant="outlined">
            Cancel
          </Button>
          <Button className=" flex" type="submit" variant="outlined">
            {defaultValues ? 'Update Job Position' : 'Create Job Position'}
          </Button>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </form>
  );
}
export default JobPositionForm;
