// /* eslint-disable */

import React, { useState } from 'react';
import { useParams } from 'react-router';
import LocationForm from '../../../components/Form/Location/LocationForm';
import BaseBackButton from '../../../components/Button/BaseBackButton';
import BaseMainTitle from '../../../components/BaseMainTitle';
import BZHelmet from '../../../utils/BZHelmet';
import useGet from '../../../hooks/useGet';
import usePatchOrPut from '../../../hooks/usePatchOrPut';

export default function LocationDetail() {
  const [locationData, setLocationData] = useState([]);
  const { locationId } = useParams();
  console.log({locationData});
  

  useGet({
    url: `locations/${locationId}`,
    validate: 'location',
    onSuccess: (e) => {
      setLocationData(e.data);
    },
  });

  const { mutate: updateLocation } = usePatchOrPut({ url: `locations/${locationId}`, validate: ['location'] });

  const onUpdatelocation = async (data) => {
    await updateLocation(data);
  };
  return (
    <>
      <BZHelmet title="Location Detail" description="" content="" />
      <div>
        <BaseMainTitle title="Location Detail :" />
        <div className="p-5 ">
          <div className="space-y-5">
            <BaseBackButton />
            <div className="p-2 border-x border-t font-semibold">Location Detail</div>
          </div>
          <div className="bg-white border p-5 shadow rounded">
            <LocationForm defaultValues={locationData} key={locationData?.id} onFormSubmit={onUpdatelocation} />
          </div>
        </div>
      </div>
    </>
  );
}
