/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Button, Checkbox } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import Input from '@/pages/CRM/redesigning/InputContainerRedesign';
import POSApi from '@/api/pos/posApi';
import Swal from 'sweetalert2';
import { handleModal } from '@/store/slices/modalSlice';

const schema = yup.object().shape({
  name: yup.string().nullable(),
});

function OutletDiscountForm({ getAllPaymentTypes }) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const clientId = useSelector((state) => state.client.activeClient);
  const paymentData = useSelector((state) => state.modal.modalData);
  const dispatch = useDispatch();
  const mode = paymentData?.mode || "add";

  const [allowInTopUp, setAllowInTopUp] = useState(false);

  useEffect(() => {
    if (paymentData?.data) {
      setValue("name", paymentData?.data?.name || "");
      setAllowInTopUp(paymentData?.data?.allow_in_top_up || false);
    }
  }, [paymentData, setValue]);

  const submitPayment = async (data) => {
    const { updatePayment, addNewPayment } = POSApi();

    const newData = {
      ...data,
      client: clientId,
      allow_in_top_up: allowInTopUp,
    };
    

    try {
      if (mode === "add") {
        await addNewPayment(newData);
        getAllPaymentTypes(clientId);
        dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
        Swal.fire({
          title: 'Success!',
          text: 'Successfully added new Discount!',
          icon: 'success',
        });
      } else {
        await updatePayment(paymentData.data.id, newData);
        getAllPaymentTypes(clientId);
        dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
        Swal.fire({
          title: 'Success!',
          text: 'Successfully updated Discount Data!',
          icon: 'success',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'An error occurred while processing your request. Please try again later.',
        icon: 'error',
      });
      console.error('Error submitting discount:', error);
    }
  };

  return (
    <form className="space-y-4">
      <div className={`w-full h-fit col-span-2`}>
        <p className={`font-semibold`}>Payment Name</p>
        <Input control={control} label="Discount Name" name="name" type="text" />
      </div>
      <div className={`w-full h-fit col-span-2`}>
        <p className={`font-semibold`}>Allow in top up</p>
        <Checkbox
          onChange={(event) => setAllowInTopUp(event.target.checked)}
          color="primary"
          checked={allowInTopUp}
        />
      </div>
      <div className="w-full flex justify-end">
        <button
          className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
          type="button"
          onClick={handleSubmit(submitPayment)}
        >
          {mode === "add" ? "Add Payment" : "Edit Payment"}
        </button>
      </div>
    </form>
  );
}

export default OutletDiscountForm;
