/* eslint-disable */

import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import qs from 'qs';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import swal from 'sweetalert';
import request from '../helper/axiosUtil';
import { handleModal } from '../store/slices/modalSlice';
import { resetAsset } from '../store/slices/assetCrudSlice';

// New query
export const fetchEmployees = async ({ queryKey }) => {
  const transQuery = queryKey[1]?.queryKey;
  const url = `/employee-management/employees${qs.stringify(transQuery, { addQueryPrefix: true })}`;
  return request({ url });
};
// New query

export const fetchWhistleblowers = async ({ queryKey }) => {
  const [, { clientId }] = queryKey;
  return request({
    url: `/employee-management/whistleblower_reports/?client=${clientId}&max_size=true`
  });
};

export const useWhistleblowersData = (clientId) => {
  return useQuery(['Whistleblowers', { clientId }], fetchWhistleblowers);
};

export const fetchWhistleblowerDetail = ({ queryKey }) => {
  const [, { reportId }] = queryKey; // destructured reportId param passed as prop where queryKey[1] is the id
  return request({ url: `/employee-management/whistleblower_reports/${reportId}/` });
};

export const useWhistleblowerDetail = (dataId) => {
  const queryClient = useQueryClient();
  const dataModal = useSelector((state) => state.modal);

  return useQuery(['Whistleblowers', dataId], fetchWhistleblowerDetail, {
    initialData: () => {
      const collection = queryClient.getQueriesData('Whistleblowers')?.data?.find((collections) => collections.id === parseInt(dataId, 10));

      if (collection) {
        return { data: collection };
      }
      return undefined;
    },
  });
};

export const fetchEmployee = async ({ queryKey }) => {
  const [, { clientId }] = queryKey;
  return request({ url: `/employee-management/employees/?client=${clientId}&max_size=true` });
};


export const fetchEmployeeTeam = async ({ queryKey }) => {
  const [, { clientId, isTeam }] = queryKey;
  // const setIsTeam = isTeam ? `&is_whistleblower_committee=true : ''
  return request({ url: `/employee-management/employees/?client=${clientId}&max_size=true&is_whistleblower_committee=${isTeam}` });
};

export const fetchEmployeeDetail = ({ queryKey }) => {
  const [, { employeeId }] = queryKey; // destructured employeeId param passed as prop where queryKey[1] is the id
  return request({ url: `/employee-management/employees/${employeeId}/` });
};

const createEmployee = async (data) =>
  request({
    url: '/employee-management/employees/',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const useAddEmployee = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(createEmployee, {
    /** Optimistic Update Start */
    onSuccess: (e) => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries('Employee');
      if (e.request.status === 201) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
      queryClient.invalidateQueries('Employee'); // invalidating the unique key's cached data and refetches
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData('Employee', context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries('Employee');
    },
    // onSuccess
    /** Optimistic Update End */
  });
};

const updateEmployee = (id, data) => {
  return request({ url: `/employee-management/employees/${id}/`, method: 'patch', data });
};

export const useUpdateEmployee = (id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation((data) => updateEmployee(id, data), {
    onMutate: async (newData) => {
      // dispatch(isReactLoading());

      await queryClient.cancelQueries(['Employee', newData.id]);
      const previousData = queryClient.getQueriesData(['Employee', newData.id]);
      queryClient.setQueryData(['Employee', newData.id], newData);
      return { previousData, newData };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(['Employee', context.newData.id], context.previousData);
    },
    onSettled: (newData) => {
      queryClient.invalidateQueries(['Employee', newData.id]);
      queryClient.invalidateQueries(['Employees']);
      queryClient.invalidateQueries('EmployeesTeam');
      dispatch(handleModal({ modalId: '' }));
    },
  });
};




const deleteEmployee = async (dataId) =>
  request({
    url: `/employee-management/employees/${dataId}`,
    method: 'delete',
  });

const useDeleteEmployee = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(deleteEmployee, {
    onSuccess: (_data) => {
      if (_data.status === 204) {
        swal('Poof! Your imaginary file has been deleted!', { icon: 'success' });
      } else swal('Error');
      // console.log('data', _data);
      // dispatch(isDeleteOn({ statusCode: _data.status, statusMessage: _data.message }));
      queryClient.invalidateQueries('Employee');
    },
    onSettled: () => {
      queryClient.invalidateQueries('Employee');
    },
    onError: (err) => {
      console.log('err', err);
    },
  });
};

export const useDestroyEmployee = (id) => {
  const { mutate: deleteWid } = useDeleteEmployee(id);
  const dispatch = useDispatch();

  // console.log('red', deleteRes);

  return async (idData) => {
    await swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteWid(idData);
      } else {
        swal('Your imaginary file is safe!');
      }
    });
    // dispatch(isDeleteOn({ statusCode: '', statusMessage: '' }));
  };
};




const fetchBusinessUnit = async ({ queryKey }) => {
  const [, { clientId, search }] = queryKey;
  const setSaerch = search ? `&search=${search}` : ''
  return request({ url: `/accounting/business_units/?client=${clientId}&max_size=true${setSaerch}` });
};

export const useBusinessUnitData = (clientId, search) => {
  return useQuery(['BusinessUnits', { clientId, search }], fetchBusinessUnit);
};

export const fetchBusinessUnitDetail = ({ queryKey }) => {
  const dataId = queryKey[1];// destructured unitId param passed as prop where queryKey[1] is the id
  return request({ url: `/accounting/business_units/${dataId}/` });
};

export const useBusinessUnitDetail = (dataId, autoFetch) => {
  const queryClient = useQueryClient();
  const dataModal = useSelector((state) => state.modal);

  return useQuery(['BusinessUnit', dataId], fetchBusinessUnitDetail, {
    initialData: () => {
      const collection = queryClient.getQueriesData('BusinessUnit')?.data?.find((collections) => collections.id === parseInt(dataId, 10));

      if (collection) {
        return { data: collection };
      }
      return undefined;
    },
    enabled: autoFetch,
  });
};

const createBusinessUnit = async (data) =>
  request({
    url: '/accounting/business_units/',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const useAddBusinessUnit = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(createBusinessUnit, {
    /** Optimistic Update Start */
    onSuccess: (e) => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries('BusinessUnit');
      if (e.request.status === 201) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
      queryClient.invalidateQueries('BusinessUnit'); // invalidating the unique key's cached data and refetches
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData('BusinessUnit', context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries('BusinessUnit');
      dispatch(handleModal({ modalId: '' }));
    },
    // onSuccess
    /** Optimistic Update End */
  });
};

const updateBusinessUnit = (id, data) => {
  return request({ url: `/accounting/business_units/${id}/`, method: 'patch', data });
};

export const useUpdateBusinessUnit = (id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation((data) => updateBusinessUnit(id, data), {
    onMutate: async (newData) => {
      // dispatch(isReactLoading());

      await queryClient.cancelQueries(['BusinessUnit', newData.id]);
      const previousData = queryClient.getQueriesData(['BusinessUnit', newData.id]);
      queryClient.setQueryData(['BusinessUnit', newData.id], newData);
      return { previousData, newData };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(['BusinessUnit', context.newData.id], context.previousData);
    },
    onSettled: (newData) => {
      queryClient.invalidateQueries('BusinessUnit');
      dispatch(handleModal({ modalId: '' }));
    },
  });
};

const deleteBusinessUnit = async (dataId) =>
  request({
    url: `/accounting/business_units/${dataId}`,
    method: 'delete',
  });

const useDeleteBusinessUnit = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(deleteBusinessUnit, {
    onSuccess: (_data) => {
      if (_data.status === 204) {
        swal('Poof! Your imaginary file has been deleted!', { icon: 'success' });
      } else swal('Error');
      // console.log('data', _data);
      // dispatch(isDeleteOn({ statusCode: _data.status, statusMessage: _data.message }));
      queryClient.invalidateQueries('BusinessUnit');
    },
    onSettled: () => {
      queryClient.invalidateQueries('BusinessUnit');
    },
    onError: (err) => {
      console.log('err', err);
    },
  });
};

export const useDestroyBusinessUnit = (id) => {
  const { mutate: deleteWid } = useDeleteBusinessUnit(id);
  const dispatch = useDispatch();

  // console.log('red', deleteRes);

  return async (idData) => {
    await swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteWid(idData);
      } else {
        swal('Your imaginary file is safe!');
      }
    });
    // dispatch(isDeleteOn({ statusCode: '', statusMessage: '' }));
  };
};



const fetchDepartment = async ({ queryKey }) => {
  const [, { clientId, unitId }] = queryKey;
  return request({ url: `/employee-management/departments/?client=${clientId}&max_size=true&business_unit=${unitId || ''}` });
};

export const useDepartmentData = (clientId, unitId) => {
  return useQuery(['Department', { clientId, unitId }], fetchDepartment);
};

export const fetchDepartmentDetail = ({ queryKey }) => {
  const dataId = queryKey[1];// destructured unitId param passed as prop where queryKey[1] is the id
  return request({ url: `/employee-management/departments/${dataId}/` });
};

export const useDepartmentDetail = (dataId, autoFetch) => {
  const queryClient = useQueryClient();
  const dataModal = useSelector((state) => state.modal);

  return useQuery(['Departments', dataId], fetchDepartmentDetail, {
    initialData: () => {
      const collection = queryClient.getQueriesData('Departments')?.data?.find((collections) => collections.id === parseInt(dataId, 10));

      if (collection) {
        return { data: collection };
      }
      return undefined;
    },
    enabled: autoFetch,
  });
};

const updateDepartment = (id, data) => {
  return request({ url: `/employee-management/departments/${id}/`, method: 'patch', data });
};

export const useUpdateDepartment = (id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation((data) => updateDepartment(id, data), {
    onMutate: async (newData) => {
      // dispatch(isReactLoading());

      await queryClient.cancelQueries(['Department', newData.id]);
      const previousData = queryClient.getQueriesData(['Department', newData.id]);
      queryClient.setQueryData(['Department', newData.id], newData);
      return { previousData, newData };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(['Department', context.newData.id], context.previousData);
    },
    onSettled: (newData) => {
      queryClient.invalidateQueries(['Department', newData.id]);
      queryClient.invalidateQueries('BusinessUnits');
      dispatch(handleModal({ modalId: '' }));
      dispatch(resetAsset());
    },
  });
};




const createDepartment = async (data) =>
  request({
    url: '/employee-management/departments/',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const useAddDepartment = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(createDepartment, {
    /** Optimistic Update Start */
    onSuccess: (e) => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries('Department');
      if (e.request.status === 201) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
      queryClient.invalidateQueries('Department'); // invalidating the unique key's cached data and refetches
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData('Department', context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries('Department');
    },
    // onSuccess
    /** Optimistic Update End */
  });
};

const deleteDepartment = async (dataId) =>
  request({
    url: `/employee-management/departments/${dataId}`,
    method: 'delete',
  });

const useDeleteDepartment = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(deleteDepartment, {
    onSuccess: (_data) => {
      if (_data.status === 204) {
        swal('Poof! Your imaginary file has been deleted!', { icon: 'success' });
      } else swal('Error');
      // console.log('data', _data);
      // dispatch(isDeleteOn({ statusCode: _data.status, statusMessage: _data.message }));
      queryClient.invalidateQueries('Department');
    },
    onSettled: () => {
      queryClient.invalidateQueries('Department');
    },
    onError: (err) => {
      console.log('err', err);
    },
  });
};

export const useDestroyDepartment = (id) => {
  const { mutate: deleteWid } = useDeleteDepartment(id);
  const dispatch = useDispatch();

  // console.log('red', deleteRes);

  return async (idData) => {
    await swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteWid(idData);
      } else {
        swal('Your imaginary file is safe!');
      }
    });
    // dispatch(isDeleteOn({ statusCode: '', statusMessage: '' }));
  };
};
