import useTokenBearer from '@/hooks/useTokenBearer';
import axios from 'axios';
import Cookies from 'js-cookie';

const baseUrl = import.meta.env.VITE_API_URL;
const token = Cookies.get('bzKey');

const axiosInstance = axios.create({
  baseUrl,
  headers: {
    Authorization: `${useTokenBearer()} ${token}`,
    'Content-Type': 'application/json',
  },
});

const axiosInstanceForm = axios.create({
  baseUrl,
  headers: {
    Authorization: `${useTokenBearer()} ${token}`,
    'Content-Type': 'multipart/form-data',
  },
});

export default function api() {
  const getEmployee = async (unit, department, client) => {
    const method = 'get';
    const url = `${baseUrl}employee-management/employees/?client=${client}&max_size=true&current_business_unit=${unit || ''}&current_department=${department || ''}`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  };

  const getAllEmployeesWithDynamicQueries = async (params) => {
    const method = 'get';
    const url = `${baseUrl}employee-management/employees/`;
    const response = await axiosInstance({
      method,
      url,
      params
    });
    return response;
  };

  const getEmployeeById = async (id) => {
    const method = 'get';
    const url = `${baseUrl}employee-management/employees/${id}`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  };

  const createNewEmployee = async (data) => {
    const method = 'post';
    const url = `${baseUrl}employee-management/employees/`;

    const response = await axiosInstanceForm({
      method,
      url,
      data,
    });
    return response;
  };

  const updateEmployee = async (id, data) => {
    const method = 'put';
    const url = `${baseUrl}employee-management/employees/${id}/`;

    const response = await axiosInstanceForm({
      method,
      url,
      data,
    });
    return response;
  };

  const deleteEmployeeById = async (id) => {
    const method = 'delete';
    const url = `${baseUrl}employee-management/employees/${id}`;

    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  };

  const getAvailableManagersByDepartment = async (deparmentId) => {
    const method = 'get';
    const url = `${baseUrl}employee-management/employees/available_managers/?department_id=${deparmentId}`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  };
  
  const getDepartment = async (params) => {
    const method = 'get';
    const url = `${baseUrl}employee-management/departments/`;
    const response = await axiosInstance({
      method,
      url,
      params
    });
    return response;
  };

  const getDepartmentByUnit = async (id) => {
    const method = 'get';
    const url = `${baseUrl}employee-management/departments/?max_size=true&business_unit=${id}`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  };

  const getBusinessunit = async () => {
    const method = 'get';
    const url = `${baseUrl}accounting/business_units/?max_size=true`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  };

  const getBusinessunitByClient = async (params) => {
    const method = 'get';
    const url = `${baseUrl}accounting/business_units/`;
    const response = await axiosInstance({
      method,
      url,
      params
    });
    return response;
  };

  const getLanguange = async () => {
    const method = 'get';
    const url = `${baseUrl}languages/?max_size=true`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  };

  return { getEmployee, getAllEmployeesWithDynamicQueries, getEmployeeById, createNewEmployee, updateEmployee, deleteEmployeeById, getBusinessunit, getBusinessunitByClient, getAvailableManagersByDepartment, getDepartment, getDepartmentByUnit, getLanguange };
}
