/* eslint-disable */

import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonBase, CircularProgress, TableCell, TableRow } from '@mui/material';
import { Delete, DeleteForever, ModeEdit } from '@mui/icons-material';

import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import ModalWrapper from '../../components/ui/ModalWrapper';
import JobCollectionForm from '../../components/Form/Job/JobCollectionForm';
import { handleModal } from '../../store/slices/modalSlice';
import { fetchFilterLocation, useDestroyWidget, useJobTypesData, useKeywordsData } from '../../hooks/useJobPostersData';
import BaseMainTitle from '../../components/BaseMainTitle';
import BZHelmet from '../../utils/BZHelmet';
import useGet from '../../hooks/useGet';
import usePatchOrPut from '../../hooks/usePatchOrPut';
import usePost from '../../hooks/usePost';

import MuiSelectSingleItem from '../../components/Input/MuiSelectSingleItem';
import { getDefaultSelectObject, getOptionSelectWithKey, getSelectOptionWithKey } from '../../lib/OptionsInputHelper';
import BaseApplyButton from '../../components/Button/BaseApplyButton';
import MuiCusotmTabs from '../../components/ui/Tab/MuiCustomTabs';
import MuiCustomTabPanel from '../../components/ui/Tab/MuiCustomTabPanel';
import InputContainer from '../../components/Input/InputContainer';
import RenderResults from '../../components/RenderResults';
import CustomTable from '../../components/Table/CustomTable';
import useDelete from '../../hooks/useDelete';
import StagesNew from './StagesNew';
import HandleWatchingState from '../../helper/HandleWatchingState';
import { useQuery } from '@tanstack/react-query';
import ReactSelectMultiple from '../../components/Input/ReactSelectMultiple';
import { fetchFilterJob } from '../../hooks/useNewHrData';

const listStatus = [
  {
    id: 'Published',
    name: 'Published',
  },
  {
    id: 'Drafted',
    name: 'Drafted',
  },
  {
    id: 'Closed',
    name: 'Closed',
  },
];

const headCells = [
  {
    id: 'due_date',
    numeric: false,
    disablePadding: true,
    label: 'Due Date',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'unit',
    numeric: false,
    disablePadding: false,
    label: 'Business Unit',
  },
  {
    id: 'department',
    numeric: false,
    disablePadding: false,
    label: 'Department',
  },
  {
    id: 'location',
    numeric: false,
    disablePadding: false,
    label: 'Location',
  },
  {
    id: 'job_type',
    numeric: false,
    disablePadding: false,
    label: 'Job Type',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Job Status',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    headerAlign: 'center',
    label: 'Action',
  },
];

export function NameCell(props) {
  const { name, id } = props;
  return (
    <div className="">
      <Link to={`/hr/recruitment/job-poster/detail/${id}`} className="text-bz-green font-semibold">
        {name || 'Title'}
      </Link>
    </div>
  );
}

function renderStatus(status) {
  switch (status.toLowerCase()) {
    case 'published':
      return <div className="bg-bz-green text-white px-2 font-inter-bold rounded md:text-base text-sm w-fit">{status}</div>;
    case 'closed':
      return <div className="bg-gray-500 text-white px-2 font-inter-bold rounded md:text-base text-sm w-fit">{status}</div>;
    case 'drafted':
      return <div className="bg-gray-700 text-white px-2 font-inter-bold rounded md:text-base text-sm w-fit">{status}</div>;
    default:
      return <div>Invalid status</div>;
  }
}

function ActionCell(props) {
  const { onClick } = props;
  return (
    <div className="w-full flex space-x-2 justify-center">
      <ButtonBase className="bz-btn-tb" onClick={onClick}>
        <Delete fontSize="24" />
      </ButtonBase>
    </div>
  );
}

function TableCells(props) {
  const { row, labelId, ariaChecked, selected } = props;

  const destroyJob = useDelete({
    confText: `modals.job_delete.confirmation_text`,
    confTitle: `modals.job_delete.title`,
    success: 'Job deleted',
    validate: ['JobPosters'],
  });

  function DeptCell(props) {
    const { data } = props;
    return (
      <ul className="">
        {data?.map((col) => (
          <li key={col} className="text-xs list-disc">
            {col}
          </li>
        ))}
      </ul>
    );
  }

  return (
    <TableRow hover role="checkbox" aria-checked={ariaChecked} tabIndex={-1} key={row.id} selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox onClick={(event) => handleClick(event, row.id)} color="primary" checked={selected} inputProps={{ 'aria-labelledby': labelId }} />
      </TableCell> */}
      <TableCell component="th" id={labelId} scope="row">
        {row.due_date}
      </TableCell>
      <TableCell align="left">
        <NameCell name={row.name} id={row.id} />
      </TableCell>
      <TableCell align="left">{row.business_unit}</TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }} align="left">
        <DeptCell data={row?.departments} />
      </TableCell>
      <TableCell align="left">{row?.location?.name}</TableCell>
      <TableCell align="left">{row?.job_type}</TableCell>
      <TableCell align="left">{renderStatus(row?.status)}</TableCell>

      <TableCell>
        <ActionCell id={row.id} onClick={() => destroyJob(`job_posters/${row.id}`)} />
      </TableCell>
    </TableRow>
  );
}

export default function Job() {
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.client.activeClient);
  const site = useSelector((state) => state.content.activeSite);
  const modal = useSelector((state) => state.modal);

  const { control, watch, setValue } = useForm();

  const [dataJobs, setDataJobs] = useState([]);
  const [isDataLoad, setIsDataLoad] = useState(true);
  const [valueTab, setValueTab] = useState(0);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [isCancel, setIsCancel] = useState(false);
  const [isCancelDepartment, setIsCancelDepartment] = useState(false);

  const widget = watch('widget') || '';
  const company = watch('company') || '';
  const position = watch('position') || '';
  const currency = watch('currency') || '';
  const jobType = watch('jobType') || '';
  const search = watch('search') || '';

  const businessunitSelected = watch('unit');
  const departmentSelected = watch('department');

  useEffect(() => {
    if (!businessunitSelected) {
      setIsCancel(true);
    }
  }, [businessunitSelected]);

  useEffect(() => {
    if (!departmentSelected) {
      setIsCancelDepartment(true);
    }
  }, [departmentSelected]);

  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const timer = setTimeout(() => {
        setDebouncedValue(value);
        setIsDataLoad(false);
        setTimeout(() => {
          setIsDataLoad(true);
        }, 500);
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }, [value, delay]);

    return debouncedValue;
  };

  const debouncedSearchInput = useDebounce(search, 500);

  const { data: widgetList } = useGet({ url: `widgets/?client=${clientId}&max_size=true&category=5&internal=False`, validate: 'WidgetJobs' });

  const { data: keywords } = useKeywordsData(site);
  const { data: jobTypes } = useJobTypesData();

  const queryKey = {
    client: clientId,
    business_unit: watch('unit'),
    department: watch('department'),
    max_size: true,
    business_unit__isnull: false,
  };

  const { data: LocationList } = useQuery(['locationsJob', { queryKey }], fetchFilterLocation, {
    refetchOnWindowFocus: false,
  });

  // const { isFetching } = useGet({
  //   url: 'job_posters',
  //   queryString: {
  //     language: '1',
  //     article__client: clientId,
  //     business_unit: watch('unit'),
  //     department: watch('department'),
  //     page_size: 10,
  //     article__status: watch('status'),
  //     article__location: watch('location'),
  //     page,
  //     job_type: jobType,
  //     // status: 'Published',
  //     search: debouncedSearchInput,
  //     // widget: 'widgetValue',
  //     keywords: watch('keyword'),
  //     order: '-article__date',
  //   },
  //   validate: 'JobPosters',
  //   onSuccess: (e) => {
  //     setCount(e?.data?.count);
  //     setDataJobs(e?.data?.results);
  //   },
  //   isEnabled: isDataLoad,
  // });

  const queryKeyJob = {
    language: '1',
    article__client: clientId,
    business_unit: watch('unit'),
    department: watch('department'),
    page_size: 10,
    article__status: watch('status'),
    article__location: watch('location'),
    page,
    job_type: jobType,
    // status: 'Published',
    search: debouncedSearchInput,
    // widget: 'widgetValue',
    keywords: watch('keyword'),
    order: '-article__date',
  };

  const { isFetching } = useQuery(['JobPosters', { queryKeyJob }], fetchFilterJob, {
    onSuccess: (e) => {
      const listData = e?.data?.results || e?.data;
      setCount(e?.data?.count);
      setDataJobs(listData);
    },
    refetchOnWindowFocus: false,
  });

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const { mutate: onSubmitCollectionWidget } = usePost({ url: 'widgets', validate: ['WidgetJobs'] });

  const removeWidget = useDestroyWidget();

  const { data: collectionDetail } = useGet({ url: `widgets/${modal.modalId}`, validate: 'WidgetJob', isEnabled: Boolean(modal.modalId) });

  const { mutate: onUpdateCollectionWidget } = usePatchOrPut({ url: `widgets/${widget}`, validate: ['WidgetJobs'] });

  const data = useMemo(() => dataJobs, [dataJobs]);

  const { data: UnitList } = useGet({
    url: `accounting/business_units/?client=${clientId}&max_size=true`,
    validate: 'units',
  });

  const watchUnit = HandleWatchingState(watch('unit'));
  const { data: DepartementList } = useGet({
    url: `employee-management/departments/?client=${clientId}&max_size=true&business_unit=${watch('unit') || ''}`,
    validate: 'departments',
    isEnabled: watchUnit,
  });

  return (
    <>
      <BZHelmet title="Job Poster" description="" content="" />
      <div>
        <BaseMainTitle title="Job Poster" />
        <div className="p-5">
          <div className="bg-white border p-5 shadow rounded">
            <MuiCusotmTabs
              optionsTab={[
                {
                  name: 'Job Poster',
                  id: 'Job Poster',
                },
                {
                  name: 'Stages Setting',
                  id: 'Stages Setting',
                },
                // {
                //   name: 'Applicant Arcvhive',
                //   id: 'Applicant Arcvhive',
                // },
              ]}
              value={valueTab}
              onChange={handleChangeTab}
            />
            <MuiCustomTabPanel value={valueTab} index={0}>
              <div className="mt-5">
                <div className="ml-auto flex gap-x-3">
                  <Button className="ml-auto bz-btn" type="button" variant="filled">
                    <Link to="/hr/recruitment/job-posters/add">Add Job</Link>
                  </Button>
                </div>
                <div>
                  <div className="border-y py-4 my-4">
                    <div className="w-4/12">
                      <div className="flex items-center w-full justify-between">
                        <div>Job Collection</div>
                        <div>
                          {' '}
                          <Button
                            onClick={() => dispatch(handleModal({ modalId: widget, componentName: 'AddJobCollection' }))}
                            className="ml-auto bz-btn"
                            type="button"
                            variant="filled"
                          >
                            Add Job Collection
                          </Button>
                        </div>
                      </div>
                      <div className="w-full flex gap-2 mt-5">
                        <MuiSelectSingleItem name="widget" label="Select Collection" control={control} options={getSelectOptionWithKey(widgetList?.data?.results)} />

                        <div className="flex gap-2">
                          <Button
                            onClick={() => dispatch(handleModal({ modalId: widget, componentName: 'EditJobCollection' }))}
                            className="ml-auto flex bz-btn"
                            type="button"
                            variant="filled"
                          >
                            <ModeEdit className="w-5 h-5" />
                          </Button>
                          <Button onClick={() => removeWidget(widget)} className="ml-auto bz-btn" type="button" variant="filled">
                            <Delete className="w-5 h-5" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-y py-4 my-4">
                    <div className="space-y-2 ">
                      <div className="font-semibold">Search</div>
                      <InputContainer name="search" control={control} className="!my-0" />
                    </div>
                  </div>
                  <div className="font-semibold text-lg">Filter</div>

                  <div className="w-full grid grid-cols-3 gap-4">
                    <div className="space-y-2">
                      <div className="font-semibold">Business Unit</div>

                      <MuiSelectSingleItem name="unit" label="Select Business Unit" control={control} options={getSelectOptionWithKey(UnitList?.data?.results)} />
                    </div>
                    <div className="space-y-2">
                      <div className="font-semibold">Department</div>

                      <MuiSelectSingleItem
                        name="department"
                        label="Select Department"
                        control={control}
                        options={getSelectOptionWithKey(DepartementList?.data?.results)}
                        defaultValue={getDefaultSelectObject(watch(`department`), getOptionSelectWithKey(DepartementList?.data?.results))}
                        disabled={Boolean(!watch('unit'))}
                        isCancel={isCancel}
                        setIsCancel={setIsCancel}
                        resetFunction={() => setValue('department', '')}
                      />
                    </div>
                    <div className="space-y-2">
                      <div className="font-semibold">Location</div>
                      <MuiSelectSingleItem
                        name="location"
                        label="Select Location"
                        control={control}
                        options={getSelectOptionWithKey(LocationList?.data?.results)}
                        // disabled={Boolean(!watch('department'))}
                        isCancel={isCancelDepartment}
                        setIsCancel={setIsCancelDepartment}
                        resetFunction={() => setValue('location', '')}
                      />
                    </div>

                    <div className="space-y-2">
                      <div className="font-semibold">Job Type</div>

                      <MuiSelectSingleItem name="jobType" label="Select Job Type" control={control} options={getSelectOptionWithKey(jobTypes?.data?.results)} />
                    </div>

                    <div className="space-y-2">
                      <div className="font-semibold">Status</div>

                      <MuiSelectSingleItem name="status" label="Select Status" control={control} options={getSelectOptionWithKey(listStatus)} />
                    </div>
                    <div className="space-y-2">
                      <div className="font-semibold">Keyword</div>

                      {/* <MuiSelectSingleItem name="keyword" label="Select Keyword" control={control} options={getSelectOptionWithKey(keywords?.data?.results)} /> */}

                      <div className="w-full bg-white">
                        <Controller
                          name="keyword"
                          control={control}
                          render={({ field: { onChange } }) => (
                            <ReactSelectMultiple
                              options={keywords?.data?.results.map((item) => ({
                                label: item?.name,
                                value: item?.id,
                              }))}
                              placeholder="Select Keyword"
                              isMulti
                              floatLabel={false}
                              onChange={(v) => onChange(v?.map((val) => val.value))}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="mt-5">
                    <BaseApplyButton setPage={setPage} setIsDataLoad={setIsDataLoad} />
                  </div> */}
                  <div className="py-10">
                    <CustomTable
                      dataApi={data}
                      setIsDataLoad={setIsDataLoad}
                      headCells={headCells}
                      isFetching={!isFetching}
                      ActionCell={ActionCell}
                      TableCells={TableCells}
                      page={page - 1}
                      // useCheckbox
                      setPage={setPage}
                      rowsPerPage={rowsPerPage}
                      setRowsPerPage={setRowsPerPage}
                      count={count}
                    />
                  </div>
                </div>
              </div>
            </MuiCustomTabPanel>
            <MuiCustomTabPanel value={valueTab} index={1}>
              <div className="mt-5">
                <StagesNew url="recruitment_stages" validate="recruitmentStages" />
              </div>
            </MuiCustomTabPanel>
          </div>
          <ModalWrapper componentName="AddJobCollection" header="Add Job Collection" maxWidth="md">
            <JobCollectionForm onFormSubmit={onSubmitCollectionWidget} />
          </ModalWrapper>
          <ModalWrapper componentName="EditJobCollection" header="Edit Job Collection" maxWidth="md">
            <JobCollectionForm onFormSubmit={onUpdateCollectionWidget} defaultValues={collectionDetail?.data} key={collectionDetail?.data?.id} />
          </ModalWrapper>
        </div>
        {/* <DevTool control={control} /> */}
      </div>
    </>
  );
}
