/* eslint-disable */
import { AddOutlined, DeleteForever, Edit } from '@mui/icons-material';
import { Avatar, Button, TableCell, TableRow } from '@mui/material';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomTable from '../../../components/Table/CustomTable';
import { handleModal } from '../../../store/slices/modalSlice';
import moment from 'moment';
import { useSelector } from 'react-redux';

const headCellsDepartment = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'Owner',
    numeric: false,
    disablePadding: true,
    label: 'Owner',
  },

  {
    id: 'customer',
    numeric: false,
    disablePadding: false,
    label: 'Customer',
  },
  {
    id: 'active_topics',
    numeric: false,
    disablePadding: false,
    label: 'Active topics',
  },
  {
    id: 'active_topics',
    numeric: false,
    disablePadding: false,
    label: 'Last activity',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

function ActionCell(props) {
  const { id, onModalEdit, onClick } = props;
  const dispatch = useDispatch();

  return (
    <div className="">
      <Button onClick={() => dispatch(handleModal({ modalId: id, componentName: 'editProject' }))} className="w-fi p-0 min-w-0" type="button" variant="">
        <Edit className="text-blue-500" />
      </Button>
      <DeleteForever onClick={onClick} className="text-red-600 cursor-pointer" />
    </div>
  );
}

function convertToTimeAgo(dateString) {
  return (
    <div className="">
      <div className="">{moment(dateString).format('YYYY-MM-DD')}</div>
      <div className="text-xs">{moment(dateString).fromNow()}</div>
    </div>
  );
}

export default function ProjectList({ projects, onModalEdit, page, setPage, count, rowsPerPage, setRowsPerPage, onDestroyProject }) {
  const [isDataLoad, setIsDataLoad] = useState(true);
  const CLIENT_ID = useSelector((state) => state.client.activeClient);
  console.log('🚀 ~ ProjectList ~ CLIENT_ID:', CLIENT_ID);

  const data = useMemo(() => projects, [projects]);

  function TableCells(props) {
    const { row, labelId, ariaChecked, selected } = props;

    return (
      <TableRow className={row.is_archived ? 'bg-gray-100' : ''} hover tabIndex={-1} key={row.name + row.id}>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          <Link className="text-blue-400 flex " to={`/task/project/${row.id}/board`}>
            {row?.icon ? (
              <div className="flex items-center space-x-2">
                <Avatar src={row.icon} />
                {row?.team?.client?.id == CLIENT_ID ? <span>{row.name}</span> : <span>{`${row.name} (${row?.team?.client?.name})`}</span>}
              </div>
            ) : (
              <div className="">{row?.team?.client?.id == CLIENT_ID ? <span>{row.name}</span> : <span>{`${row.name} (${row?.team?.client?.name})`}</span>}</div>
            )}
            {row.is_archived ? <div className="ml-4 font-bold border-black border text-xs  rounded p-0.5 text-black">Archived</div> : ''}
          </Link>
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          {row?.owner ? row?.owner?.name : '-'}
        </TableCell>

        <TableCell component="th" id={labelId} scope="row" padding="normal" size="small" className="max-w-[200px]">
          {row?.member ? <div className="font-bold"> {row?.member.name}</div> : '-'}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="normal" size="small" className="max-w-[200px] text-rght">
          {row?.active_topic_count || 0}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="normal" size="small" className="max-w-[200px]">
          {row?.last_activity ? convertToTimeAgo(row.last_activity) : '-'}
        </TableCell>
        <TableCell align="left">
          <ActionCell onModalEdit={onModalEdit} id={row.id} onClick={() => onDestroyProject(row.id)} />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <div className="">
      {' '}
      <div className="w-full relative">
        <Link className="border-[1.5px] border-dashed border-slate-400 flex flex-col items-center justify-center rounded-md w-full h-[80px] mb-8" to="/task/project/new/blank">
          <div>
            <AddOutlined />
          </div>
          <div className="text-gray-500 text-center">Create Project</div>
        </Link>
      </div>
      <CustomTable
        dataApi={data}
        setIsDataLoad={setIsDataLoad}
        // isFetching={!isFetching}
        headCells={headCellsDepartment}
        ActionCell={ActionCell}
        TableCells={TableCells}
        page={page - 1}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
        disablePerPage={true}
      />
    </div>
  );
}
