import { Link } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';

// eslint-disable-next-line no-unused-vars
export default function SidebarProject({ dataProjects, onDestroyProject }) {
  const html =
    dataProjects.length > 0 &&
    dataProjects?.map((pr, i) => {
      
      return pr?.is_archived === false && (
      <div
        data-tooltip-id={'tooltip-project'}
        data-tooltip-content={pr?.member ? `${pr.member.name} (${pr.name})` : pr.name}
        data-tooltip-variant="light"
        key={`${pr.name + i}`}
        className="flex items-center justify-start py-2"
      >
        <Link to={`/task/project/${pr.id}/board`} className="flex space-y-3 items-center">
          <div className="ml-14 flex gap-3 items-center">
            <img src={pr.icon} alt="" className="w-6 h-6 object-cover rounded-md" />
            <span className="hover:text-orange-500">{pr.name}</span>
          </div>
        </Link>
        {/* <button type="button" onClick={() => onDestroyProject(pr.id)} className="ml-auto pr-2">
          x
        </button> */}
      </div>
    )}
    );
  return html;
}
