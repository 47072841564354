/* eslint-disable */

import { Add, Clear, Edit } from '@mui/icons-material';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import '@splidejs/splide/dist/css/splide.min.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode, Pagination } from 'swiper';

import { GoogleMap, useJsApiLoader, Marker, Autocomplete } from '@react-google-maps/api';
import { Button, CircularProgress } from '@mui/material';
import InputContainer from '../../Input/InputContainer';
import { getDefaultSelectObject, getOptionSelectWithKey } from '../../../lib/OptionsInputHelper';
import ReactSelectSingle from '../../Input/ReactSelectSingle';
import HandleWatchingState from '../../../helper/HandleWatchingState';
import useGet from '../../../hooks/useGet';
import { handleModal } from '../../../store/slices/modalSlice';
import ModalWrapper from '../../ui/ModalWrapper';
import LocationMap from './LocationMap';
import CustomCheckbox from '../../Input/CustomCheckbox';
import BaseLocationListViewer from '../../Viewer/BaseLocationListViewer';
import { resetLocation } from '../../../store/slices/locationCrudSlice';
import BaseLocationSingleViewer from '../../Viewer/BaseLocationSingleViewer';
import _ from 'lodash';

const MySwal = withReactContent(Swal);

const containerStyle = {
  width: '100%',
  height: '100%',
};

const lib = ['drawing', 'places'];

const options = {
  scrollwheel: false,
};

export default function LocationArrField({ name, defaultValues, control, register, setValue, unregister, watch, department, b_unit, isMainIdx, isLocationLoad }) {
  const dispatch = useDispatch();

  const modal = useSelector((state) => state.modal);

  const getExp = watch(name) && watch(name).length > 0 ? watch(name) : [];
  const forceWatchLoc = useWatch({ name, control });

  const findMainIndex = useCallback((arr) => {
    return arr && arr.length > 0 && arr?.findIndex((element) => element?.is_main);
  }, []);

  const handleWatchMain = HandleWatchingState(findMainIndex(forceWatchLoc) + 1);
  const handleWatchData = HandleWatchingState(findMainIndex(forceWatchLoc) + 1);
  const handleWatchModal = HandleWatchingState(modal?.modalId);

  const [address, setAddress] = useState('');
  const [addOther, setAddOther] = useState('');
  const [defLat, setDefLat] = useState('');
  const [defLong, setDefLong] = useState('');
  const [addCompData, setAddCompData] = useState(null);

  const userLat = watch(`${name}[${findMainIndex(getExp)}][location[latitude]]`);
  const userLong = watch(`${name}[${findMainIndex(getExp)}][location[longitude]]`);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCKqMs4hmNgoQtmIUsd7diBKHj6UV610Jg',
    libraries: lib,
  });

  const { data: CountriesData } = useGet({
    url: `countries`,
    queryString: { max_size: true, ordering: 'name' },
    validate: 'countries',
  });

  const optionsCountries = CountriesData?.data?.results?.map((item) => ({
    name: item?.name,
    id: item?.id,
  }));

  useEffect(() => {
    if (isLocationLoad && !defaultValues && !watch(`${name}[${findMainIndex(getExp)}][location[latitude]]`)) {
      navigator.geolocation.getCurrentPosition((position) => {
        setValue(`${name}[${findMainIndex(getExp)}][location[latitude]]`, position.coords.latitude);
        setValue(`${name}[${findMainIndex(getExp)}][location[longitude]]`, position.coords.longitude);
      });
    }
    navigator.geolocation.getCurrentPosition((position) => {
      setDefLat(position.coords.latitude);
      setDefLong(position.coords.longitude);
    });
  }, [defaultValues, findMainIndex, getExp, name, setValue, userLat, userLong, isLocationLoad]);

  const center = {
    lat: +userLat,
    lng: +userLong,
  };

  const markerDragEnd = (event) => {
    setValue(`${name}[${findMainIndex(getExp)}][location[latitude]]`, event.latLng.lat());
    setValue(`${name}[${findMainIndex(getExp)}][location[longitude]]`, event.latLng.lng());
  };

  const autocompleteRef = useRef(null);

  const onPlaceChanged = () => {
    const places = autocompleteRef.current.state.autocomplete.getPlace();
    const coordinate = places.geometry.location.toJSON();
    const { lat, lng } = coordinate;
    setValue(`${name}[${findMainIndex(getExp)}][location[latitude]]`, lat);
    setValue(`${name}[${findMainIndex(getExp)}][location[longitude]]`, lng);

    const addressComponents = places?.address_components;
    const addressComp = addressComponents?.map((add) => {
      return {
        name: add.long_name,
        types: add.types,
      };
    });
    setAddCompData(addressComp);

    const countriesName = CountriesData?.data?.results?.map((country) => country.iso_code);
    const addressCountry = addressComponents.map((addr) => addr.short_name);
    const foundedCountry = _.intersection(countriesName, addressCountry);
    const countryCode = foundedCountry[0];
    const selectedCountryChange = CountriesData?.data?.results?.find(({ iso_code }) => iso_code === countryCode);
    setTimeout(() => {
      setValue(`${name}[${findMainIndex(getExp)}][location[address]]`, places.formatted_address);
      setValue(`${name}[${findMainIndex(getExp)}][location[country]]`, selectedCountryChange?.id);

      setAddress(places.formatted_address);
    }, 200);
  };

  useEffect(() => {
    register(name);
    if (!defaultValues && getExp && getExp.length === 0) {
      const newArr = getExp;
      const newRecipients = [...newArr];
      setValue(`${name}`, newRecipients);
      setValue(`${name}[0][is_main]`, true);
    } else if (isLocationLoad && findMainIndex(forceWatchLoc) && findMainIndex(forceWatchLoc) >= 0) {
      // setValue(`${name}[${isMainIdx}][is_main]`, true);
      // setValue(`${name}[${isMainIdx}][is_main]`, false);
      // setTimeout(() => {}, 500);
    }
  }, [getExp, name, register, setValue, defaultValues, isMainIdx, isLocationLoad, findMainIndex, forceWatchLoc]);

  const onUnPairLocation = async (idxLoc) => {
    await MySwal.fire({
      title: 'Confirm Delete Location',
      html: 'Are you sure want to delete this location ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Delete`,
      denyButtonText: `Don't save`,
      confirmButtonColor: '#C62828',
      cancelButtonColor: '#8bc34a',
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        unregister(idxLoc);
        MySwal.fire({
          html: 'Location deleted',
          icon: 'success',
        });
      }
    });
  };

  const locationPair = useSelector((state) => state.locationCrud.selected);

  const handlePairLocationSingle = () => {
    setValue(`${name}[${findMainIndex(getExp)}][is_main]`, false);
    const assignLoc = { ...locationPair, country: locationPair?.country?.id };
    setValue(`${name}[${getExp.length + 1}][location]`, assignLoc);
    setValue(`${name}[${getExp.length - 1}][is_main]`, true);
    dispatch(handleModal({ modalId: '', componentName: '' }));
    dispatch(resetLocation());
  };

  const handlePairLocationList = () => {
    locationPair.forEach((loc, idx) => {
      setValue(`${name}[${getExp.length + idx}][location]`, { ...loc, country: loc?.country?.id });
    });
    dispatch(handleModal({ modalId: '', componentName: '' }));
    dispatch(resetLocation());
  };
  const handleCancelPairLocation = () => {
    dispatch(handleModal({ modalId: '', componentName: '' }));
    dispatch(resetLocation());
  };

  return (
    <div>
      {handleWatchMain ? (
        <div className="">
          {watch(`${name}[${findMainIndex(forceWatchLoc)}][is_main]`) && findMainIndex(forceWatchLoc) >= 0 ? (
            <div className=" relative w-full " key={`${name}-${0 + 1}`}>
              <div className="flex justify-between items-center my-3 ">
                <div className="font-bold">Main Location</div>
                <div className="">
                  {' '}
                  <button onClick={() => onUnPairLocation(`${name}[${findMainIndex(forceWatchLoc)}]`)} title="Delete location" className=" bg-white  rounded-full" type="button">
                    <Clear className="border-2 rounded-full text-red-400" />
                  </button>
                </div>
              </div>
              <div className="w-full">
                <div className="w-full flex flex-row-reverse gap-x-5">
                  <div className="w-6/12 space-y-4">
                    <div className="">
                      <InputContainer
                        name={`${name}[${findMainIndex(getExp)}][location[name]]`}
                        control={control}
                        label="Name"
                        // defaultValue={watch(`${name}[${findMainIndex(getExp)}][location[name]]`)}
                        // errors={errors.name}
                      />
                      <InputContainer name={`${name}[${findMainIndex(forceWatchLoc)}][location[address]]`} control={control} label="Address" />
                    </div>

                    <div className="full">
                      <ReactSelectSingle
                        name={`${name}[${findMainIndex(getExp)}][location[country]]`}
                        control={control}
                        options={getOptionSelectWithKey(optionsCountries)}
                        defaultValue={getDefaultSelectObject(watch(`${name}[${findMainIndex(getExp)}][location[country]]`), getOptionSelectWithKey(optionsCountries))}
                        placeholder="Select country"
                      />
                    </div>

                    <Controller
                      name={`${name}[${findMainIndex(getExp)}][is_main]`}
                      control={control}
                      render={({ field: { onChange } }) => (
                        <CustomCheckbox label="Main Location" defaultChecked={`${name}[${findMainIndex(getExp)}][is_main]`} onChange={onChange} disabled />
                      )}
                    />

                    <InputContainer name={`${name}[${findMainIndex(forceWatchLoc)}][location[contact[email]]]`} control={control} label="Email" />
                    <InputContainer name={`${name}[${findMainIndex(forceWatchLoc)}][location[contact[phone]]]`} control={control} label="Phone" />
                    <div className="">
                      {/* <div className="font-bold">Other Contacts</div> */}
                      <div className="my-5">
                        {/* <Button
                          className="flex items-center gap-x-3"
                          title="Add location"
                          type="button"
                          onClick={() => {
                            setAddOther(false);
                            setTimeout(() => {
                              setAddOther(
                                watch(`${name}[${findMainIndex(forceWatchLoc)}][location[contact[other]]]`) ? watch(`${name}[${findMainIndex(forceWatchLoc)}][location[contact[other]]]`).length + 1 : 1
                              );
                            }, 100);
                          }}
                        >
                          <Add className=" rounded border-2 text-bz-greenLight" />
                          <div className="">Add Other Contact</div>
                        </Button> */}
                        <div className="">
                          {watch(`${name}[${findMainIndex(forceWatchLoc)}][location[contact[other]]]`)?.map((ot, otIdx) => {
                            return (
                              otIdx + 1 !== addOther && (
                                <InputContainer name={`${name}[${findMainIndex(forceWatchLoc)}][location[contact[other[${otIdx}][label]]]]`} control={control} label="Phone" />
                              )
                            );
                          })}
                          {addOther && (
                            <div className="">
                              {' '}
                              <InputContainer name={`${name}[${findMainIndex(forceWatchLoc)}][location[contact[other[${addOther}][label]]]]`} control={control} label="Phone" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-6/12">
                    {isLoaded && center?.lat && center?.lng ? (
                      <div className="flex w-full h-[400px] relative">
                        <div className="absolute z-50 top-3 left-1/2 transform -translate-x-1/2">
                          <Autocomplete onPlaceChanged={onPlaceChanged} ref={autocompleteRef}>
                            <div>
                              <input
                                className="p-2 "
                                type="text"
                                placeholder="Search Location"
                                onKeyPress={(e) => {
                                  e.key === 'Enter' && e.preventDefault();
                                }}
                              />
                            </div>
                          </Autocomplete>
                        </div>
                        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10} options={options}>
                          <Marker position={center} draggable onDragEnd={(event) => markerDragEnd(event)} />
                        </GoogleMap>
                      </div>
                    ) : watch(`${name}[${findMainIndex(forceWatchLoc)}][is_main]`) ? (
                      <div>
                        {' '}
                        <InputContainer
                          name={`${name}[${findMainIndex(getExp)}][location[latitude]]`}
                          control={control}
                          label="Name"
                          hidden
                          defaultValue={+defLat}
                          setValue={() => setValue(`${name}[${findMainIndex(getExp)}][location[latitude]]`, +defLat)}
                          // errors={errors.name}
                        />
                        <InputContainer
                          name={`${name}[${findMainIndex(getExp)}][location[longitude]]`}
                          control={control}
                          label="Name"
                          hidden
                          defaultValue={+defLong}
                          setValue={() => setValue(`${name}[${findMainIndex(getExp)}][location[longitude]]`, +defLong)}
                          // errors={errors.name}
                        />
                      </div>
                    ) : (
                      <CircularProgress />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : isLocationLoad ? (
            <div className="flex items-center gap-x-3">
              <Button
                className="flex items-center gap-x-3 text-bz-greenLight"
                title="Add location"
                type="button"
                onClick={() => {
                  setValue(`${name}[${getExp.length + 1}][is_main]`, true);
                }}
              >
                <Add className=" rounded border-2 text-bz-greenLight" />
                <div className="">Add new Main Location</div>
              </Button>
              <Button
                className="flex items-center gap-x-3 text-bz-greenLight"
                title="Add location"
                type="button"
                onClick={() => {
                  dispatch(handleModal({ modalId: '', componentName: 'locationUnitMain' }));
                }}
              >
                <Add className=" rounded border-2 text-bz-greenLight" />
                <div className="">Add Existing Location main</div>
              </Button>
            </div>
          ) : (
            <CircularProgress />
          )}
        </div>
      ) : (
        <Button
          className="flex items-center gap-x-3 text-bz-greenLight"
          title="Add location"
          type="button"
          onClick={() => {
            setValue(`${name}[${getExp.length + 1}][is_main]`, true);
          }}
        >
          <Add className=" rounded border-2 text-bz-greenLight" />
          <div className="">Add Main Location</div>
        </Button>
      )}

      <div className="border-t-2 pt-4 mt-4">
        <div className="flex items-center gap-x-3">
          <Button
            className="flex items-center gap-x-3 text-bz-greenLight"
            title="Add location"
            type="button"
            onClick={() => {
              dispatch(handleModal({ modalId: getExp.length + 1, componentName: 'addLocation' }));
            }}
          >
            <Add className=" rounded border-2 text-bz-greenLight" />
            <div className="">Add New Location</div>
          </Button>
          <Button
            className="flex items-center gap-x-3 text-bz-greenLight"
            title="Add location"
            type="button"
            onClick={() => {
              dispatch(handleModal({ modalId: '', componentName: 'locationUnit' }));
            }}
          >
            <Add className=" rounded border-2 text-bz-greenLight" />
            <div className="">Add Existing Location</div>
          </Button>
        </div>

        <div className="bg-[#F1F5F9] my-5 border-2 ">
          <Swiper
            slidesPerView={2}
            spaceBetween={30}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination]}
            className="p-[35px]"
          >
            {getExp?.map((location, idx) => {
              const centerArr = {
                lat: +location?.location?.latitude,
                lng: +location?.location?.longitude,
              };

              const filterArr = modal.componentName === 'addLocation' ? idx + 1 !== getExp.length && !location?.is_main : idx === idx && !location?.is_main;

              return (
                filterArr && (
                  <SwiperSlide key={idx} className="group !w-8/1b2">
                    <div className=" relative w-full" key={`${name}-${idx + 1}`}>
                      <div className="w-full ">
                        <div className="bg-white flex w-fit justify-end items-center p-1 rounded-t-lg gap-x-1 ml-auto">
                          {' '}
                          {/* <button
                            onClick={() => dispatch(handleModal({ modalId: idx, componentName: 'editLocation', modalData: JSON.stringify(location) }))}
                            title="Edit location"
                            className=" bg-white  rounded-full"
                            type="button"
                          >
                            <Edit className="border-2 rounded-full text-bz-greenLight" />
                          </button> */}
                          <button onClick={() => onUnPairLocation(`${name}[${idx}]`)} title="Delete location" className=" bg-white  rounded-full" type="button">
                            <Clear className="border-2 rounded-full text-red-400" />
                          </button>
                        </div>
                        <div className="w-full flex flex-row-reverse bg-white shadow-xl">
                          <div className="w-8/12 p-4">
                            <div className="font-bold">{location?.location?.name}</div>
                            <div className="">{location?.location?.address}</div>
                          </div>

                          <div className="w-4/12 ">
                            {isLoaded && location?.location?.latitude && location?.location?.longitude ? (
                              <div className="flex w-full h-[200px] relative">
                                <GoogleMap mapContainerStyle={containerStyle} center={centerArr} zoom={10} options={options}>
                                  {/* Child components, such as markers, info windows, etc. */}
                                  <Marker position={centerArr} draggable={false} onDragEnd={(event) => markerDragEnd(event)} />
                                </GoogleMap>
                              </div>
                            ) : (
                              <CircularProgress />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              );
            })}
          </Swiper>
        </div>
      </div>

      <ModalWrapper
        componentName="addLocation"
        header="Add Location"
        maxWidth="lg"
        extraClose={() => {
          unregister(`${name}[${modal.modalId}]`);
        }}
      >
        {handleWatchModal ? (
          <>
            <div className="w-full">
              <div className="w-full flex flex-row-reverse gap-x-5">
                <div className="w-6/12 space-y-4">
                  <div className="">
                    <InputContainer
                      name={`${name}[${modal.modalId}][location[name]]`}
                      control={control}
                      label="Name"
                      defaultValue=""
                      // errors={errors.name}
                    />
                    <InputContainer
                      name={`${name}[${modal.modalId}][location[address]]`}
                      control={control}
                      label="Address"
                      // errors={errors.name}
                    />
                  </div>

                  <div className="full">
                    <ReactSelectSingle
                      name={`${name}[${modal.modalId}][location[country]]`}
                      control={control}
                      options={getOptionSelectWithKey(optionsCountries)}
                      defaultValue={getDefaultSelectObject(
                        watch(`${name}[${modal.modalId}][location[country]]`) || defaultValues?.country?.id,
                        getOptionSelectWithKey(optionsCountries)
                      )}
                      placeholder="Select country"
                    />
                  </div>

                  <Controller
                    name={`${name}[${modal.modalId}][new_is_main]`}
                    control={control}
                    render={({ field: { onChange } }) => <CustomCheckbox label="Main Location" onChange={onChange} />}
                  />

                  <InputContainer name={`${name}[${modal.modalId}][location[contact[email]]]`} control={control} label="Email" />
                  <InputContainer name={`${name}[${modal.modalId}][location[contact[phone]]]`} control={control} label="Phone" />
                </div>
                <div className="w-6/12">
                  <LocationMap idx={modal.modalId} setValue={setValue} CountriesData={CountriesData} name={name} watch={watch} />
                </div>
              </div>
            </div>
            <div className="flex justify-end ml-auto gap-x-2">
              <Button
                onClick={() => {
                  unregister(`${name}[${modal.modalId}]`);
                  // const newArr = getExp;
                  // setValue(`${name}`, newArr);
                  dispatch(handleModal({ modalId: '', componentName: '' }));
                }}
                className="bz-btn secondary flex"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                className="bz-btn"
                title="Add location"
                variant="outlined"
                type="button"
                onClick={() => {
                  if (watch(`${name}[${modal.modalId}][new_is_main]`)) {
                    setValue(`${name}[${findMainIndex(getExp)}][is_main]`, false);
                    setValue(`${name}[${modal.modalId}][is_main]`, true);
                    setValue(`${name}[${modal.modalId}][new_is_main]`, false);
                  } else setValue(`${name}[${modal.modalId}][is_main]`, false);

                  dispatch(handleModal({ modalId: '' }));
                }}
              >
                Save
              </Button>
            </div>
          </>
        ) : (
          <CircularProgress />
        )}
      </ModalWrapper>

      <ModalWrapper componentName="editLocation" header="Edit Location" maxWidth="lg">
        <div className="w-full">
          <div className="w-full flex flex-row-reverse gap-x-5">
            <div className="w-6/12 space-y-4">
              <div className="">
                <InputContainer
                  name={`${name}[${modal.modalId}][location[name]]`}
                  control={control}
                  label="Name"
                  // errors={errors.name}
                />
                <InputContainer
                  name={`${name}[${modal.modalId}][location[address]]`}
                  control={control}
                  label="Address"
                  defaultValue={address}
                  // errors={errors.name}
                />
              </div>

              <div className="full">
                <ReactSelectSingle
                  name={`${name}[${modal.modalId}][location[country]]`}
                  control={control}
                  options={getOptionSelectWithKey(optionsCountries)}
                  defaultValue={getDefaultSelectObject(
                    watch(`${name}[${modal.modalId}][location[country]]`) || defaultValues?.country?.id,
                    getOptionSelectWithKey(optionsCountries)
                  )}
                  placeholder="Select country"
                />
              </div>

              <Controller
                name={`${name}[${modal.modalId}][new_is_main]`}
                control={control}
                render={({ field: { onChange } }) => <CustomCheckbox label="Main Location" onChange={onChange} />}
              />
              <InputContainer name={`${name}[${modal.modalId}][location[contact[email]]]`} control={control} label="Email" />
              <InputContainer name={`${name}[${modal.modalId}][location[contact[phone]]]`} control={control} label="Phone" />
            </div>
            <div className="w-6/12">
              <LocationMap defaultValues idx={modal.modalId} setValue={setValue} CountriesData={CountriesData} name={name} watch={watch} />
            </div>
          </div>
        </div>
        <div className="flex justify-end ml-auto gap-x-2">
          <Button
            onClick={() => {
              const parseModalData = JSON.parse(modal.modalData);
              setValue(`${name}[${modal.modalId}]`, parseModalData);
              dispatch(handleModal({ modalId: '' }));
            }}
            className="bz-btn secondary flex"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            className="bz-btn w-fit"
            title="Add location"
            variant="outlined"
            type="button"
            onClick={() => {
              if (watch(`${name}[${modal.modalId}][new_is_main]`)) {
                setValue(`${name}[${findMainIndex(getExp)}][is_main]`, false);
                setValue(`${name}[${modal.modalId}][is_main]`, true);
              } else setValue(`${name}[${modal.modalId}][is_main]`, false);

              dispatch(handleModal({ modalId: '' }));
            }}
          >
            Save
          </Button>
        </div>
      </ModalWrapper>

      <ModalWrapper componentName="locationUnit" header="Pair Location" maxWidth="lg">
        <BaseLocationListViewer handleSubmit={handlePairLocationList} handleCancel={handleCancelPairLocation} />
      </ModalWrapper>
      <ModalWrapper componentName="locationUnitMain" header="Pair Main Location" maxWidth="lg">
        <BaseLocationSingleViewer handleSubmit={handlePairLocationSingle} handleCancel={handleCancelPairLocation} />
      </ModalWrapper>
    </div>
  );
}
