/* eslint-disable */
/* eslint-disable no-unused-vars */

import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import request from '../helper/axiosUtil';
import { resetAsset } from '../store/slices/assetCrudSlice';
import { handleCloseModal, handleModal } from '../store/slices/modalSlice';

export default function usePostExtra({ url, success, validate, disablePostData, onSuccess, disableReserAsset = false }) {
  const qClient = useQueryClient();
  const dispatch = useDispatch();

  const onCreate = (data) => {
    return request({
      url: `/${disablePostData ? data?.url : url}/`,
      method: 'post',
      data,
      headers: { 'Content-Type': 'application/json' },
    });
  };

  return useMutation((data) => onCreate(data), {
    /** Optimistic Update Start */
    onSuccess,
    onError: (_err, _newTodo, context) => {
      // qClient.setQueryData(validate, context.previousData);
    },
    onSettled: (e) => {
      validate?.forEach((val) => qClient.invalidateQueries(val));
      dispatch(handleCloseModal({ modalId: '' }));
      !disableReserAsset && dispatch(resetAsset());
    },
    // onSuccess
    /** Optimistic Update End */
  });
}
