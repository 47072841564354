import React, { Suspense, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Skeleton, Typography } from '@mui/material';
import { Add, ExpandMore } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import BaseMainTitle from '../../../components/BaseMainTitle';
import DndProfileAttribute from '../../../components/Form/CRM/DndProfileAttribute';
import DndProfileSideDrawer from '../../../components/Form/CRM/DndProfileSideDrawer';
import BZHelmet from '../../../utils/BZHelmet';

import crmApi from '../../../api/CRM/CRMCustomers';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import AttributeGroup from '../../../components/Modal/CRM/AttributeGroup';
import Attribute from '../../../components/Modal/CRM/Attribute';
import { handleModal } from '../../../store/slices/modalSlice';

import BaseInputValidation from '../../../components/Input/Base/BaseInputValidation';
import DroppableElement from '../../../components/ui/CRM/DroppableElement';
import DndProfileSidebar from '../../../components/Form/CRM/DndProfileSidebar';

const { actionCRMProjectDetailProfile, getCRMProfileGroups, getCRMProjectAttribute } = crmApi();

const mode = import.meta.env.VITE_MODE;
const crmDisableIndex = mode === 'development' ? [61] : [32];

const staticSchema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  // *
});

export default function DetailProfile() {
  const { profileId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    trigger,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(staticSchema),
    defaultValues: {},
  });

  const activeClient = useSelector((state) => state.client.activeClient);
  const activeSidePanel = useSelector((state) => state.crmProfile.activeSidePanel);
  const [groupSelected, setSelectedGroup] = useState(null);
  const [attributeSelected, setSelectedAttribute] = useState(null);
  const [gridLayout, setGridLayout] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [gridLayoutElements, setGridElements] = useState(null);
  const [structure, setStructure] = useState(null);
  const [dataAttribute, setDataAttribute] = useState(null);

  const { isFetching: isFetchingAttribute, refetch: refetchAttribute } = useQuery({
    queryKey: ['crm-project-attributes', activeClient],
    queryFn: () => getCRMProjectAttribute({ client: activeClient, max_size: true }),
    onSuccess(res) {
      setDataAttribute(res);
    },
  });

  const { data: profile } = useQuery({
    queryKey: ['crm-profile-detail', profileId],
    enabled: !!profileId && !!dataAttribute,
    queryFn: () => actionCRMProjectDetailProfile(profileId),
    onSuccess(res) {
      if (res.data) {
        setValue('name', res.data.name);
      }
      if (res.data.groups) {
        const groups = res.data.groups;
        const groupElement = [];
        const attributeElement = [];
        const objGroupElements = [];
        const newGridLayout = groups.map((gr, i) => {
          const groupAttributeElements = [];
          const { dimension: d, profile_attributes: attr } = gr;
          groupElement.push(+gr.id);
          let groupActiveLayout = [];
          if (attr?.length) {
            groupActiveLayout = attr.map((a, idx) => {
              const detailAttribute = dataAttribute.find((at) => +at.id === +a.attribute.id);
              if (detailAttribute) {
                groupAttributeElements.push({
                  identifier: a.id,
                  id: a.attribute.id,
                  client: activeClient,
                  name: a.attribute.name,
                  key_name: detailAttribute.key_name,
                  value_type: detailAttribute.value_type,
                  format: detailAttribute.format || null,
                  multiple_value: detailAttribute.multiple_value,
                  is_required: detailAttribute.is_required,
                  options: detailAttribute.options || [],
                  type: 'attribute',
                  configuration: a.configuration || null,
                  dimension: a.dimension,
                });
              }
              attributeElement.push(+a.attribute.id);
              const { dimension: dim } = a;
              return {
                w: dim[2] > 12 ? 12 : dim[2],
                h: dim[3],
                x: dim[0],
                y: dim[1],
                i: `${idx}|group|${i}|${gr.id}|${gr.name}|${a.attribute.id}|${a.attribute.name}`,
                moved: false,
                static: false,
                maxW: 12,
              };
            });
          }

          const objGroup = {
            w: d[2] > 12 ? 12 : d[2],
            h: d[3],
            x: d[0],
            y: d[1],
            i: `group|${i}|${gr.id}|${gr.name}`,
            maxW: 12,
            moved: false,
            static: false,
          };
          if (groupActiveLayout.length) {
            objGroup.activeLayout = groupActiveLayout;
          }
          objGroupElements.push({
            id: gr.id,
            profile: gr.profile,
            name: gr.name,
            index: gr.index,
            dimension: gr.dimension,
            is_displayed: gr.is_displayed,
            type: 'group',
            configuration: null,
            listElement: groupAttributeElements,
          });

          return objGroup;
        });
        setGridLayout(newGridLayout);
        setStructure({ group: groupElement, attribute: attributeElement });
        setGridElements(objGroupElements);
      }
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  const {
    data: dataGroup,
    isFetching: isFetchingGroup,
    refetch: refetchGroup,
  } = useQuery({
    queryKey: ['crm-project-profile-groups', profileId],
    enabled: !!profileId,
    queryFn: () => getCRMProfileGroups({ profile__client: activeClient, max_size: true, profile: profileId }),
    refetchOnWindowFocus: false,
  });

  // eslint-disable-next-line no-unused-vars
  const onCloseHandler = () => {
    navigate('/crm/projects');
  };

  const generateAttributeWithGroup = (elements, layout, index = 0, groupIndex = 0) => {
    return (
      elements.length > 0 &&
      elements.map((el, i) => {
        let profileAttributes = [];
        if (el.listElement && el.type === 'group') {
          profileAttributes = generateAttributeWithGroup(
            el.listElement,
            layout[i]?.activeLayout || [],
            i > 0 ? elements[i - 1].listElement?.length || el.listElement.length : i,
            i + 1
          );
        }
        let objElement = null;
        if (el.type === 'group') {
          objElement = {
            id: el.id,
            name: el.name,
            profile: profileId,
            index: i + 1,
            dimension: layout[i] ? [layout[i].x, layout[i].y, layout[i].w, layout[i].h] : el.dimension,
            profile_attributes: profileAttributes || [],
          };
        }
        if (el.type === 'attribute') {
          objElement = {
            id: el.identifier,
            attribute: el.id,
            profile: profileId,
            // index: i + index,
            dimension: layout[i] ? [layout[i].x, layout[i].y, layout[i].w, layout[i].h] : el.dimension,
            column_index: index + (layout[i] ? layout[i].y + layout[i].h : el.dimension[1] + el.dimension[3]),
            row_index: groupIndex,
          };
          if (!crmDisableIndex.includes(+activeClient)) {
            objElement.index = i + index;
          }
          if (el.configuration) {
            objElement.configuration = {};
            Object.keys(el.configuration).forEach((c) => {
              objElement.configuration[c] = el.configuration[c];
            });
          }
        }
        return objElement;
      })
    );
  };

  const onSave = async (data) => {
    const isFieldValid = await trigger();
    if (!isFieldValid) return;
  
    if (!data?.listElement) {
      enqueueSnackbar('No list element data available', { variant: 'Error' });
      return;
    }
  
    const newGroups = generateAttributeWithGroup(data.listElement, data.activeLayout).filter((g) => !!g);
    
    if (newGroups.length === 0) {
      enqueueSnackbar('No valid groups generated', { variant: 'Error' });
      return;
    }
  
    try {
      await actionCRMProjectDetailProfile(profileId, 'patch', { name: getValues('name'), groups: newGroups });
      enqueueSnackbar('Successfully update profile', { variant: 'Success' });
      navigate('/crm/projects/profile');
    } catch (err) {
      console.log(err);
      enqueueSnackbar(err.response?.data?.details ? err.response?.data?.details[0] : 'Something went wrong', { variant: 'Error' });
    }
  };

  const onChangeStructure = (type, action, id, groupAttributes) => {
    if (!type && !action && !id) return;
    const currStructure = structure ? { ...structure } : null;
    let currType = currStructure[type] ? [...currStructure[type]] : [];
    let currAttributes = currStructure.attribute ? [...currStructure.attribute] : [];
    if (action === 'add') {
      if (currStructure && currType && currType.length) {
        currType.push(+id);
      } else {
        currType = [+id];
      }
    } else if (action === 'remove') {
      currType = currType.filter((t) => +t !== +id);
      if (type === 'group' && groupAttributes) {
        currAttributes = currAttributes.filter((el) => !groupAttributes.includes(+el));
      }
    }
    if (action === 'remove' && type === 'group' && groupAttributes) {
      setStructure({ ...structure, [type]: currType, attribute: currAttributes });
    } else {
      setStructure({ ...structure, [type]: currType });
    }
  };

  const minH = `${(structure?.group.length || 1) * 196}px`;
  const dis = false;
  return (
    <Suspense fallback={<CircularProgress />}>
      <BZHelmet title={profile?.data?.name || 'Detail Profile'} description="" content="" />
      <BaseMainTitle title="Profile" />
      <section id="dnd-crm" className="w-full h-full p-5 relative">
        <div className="w-full h-full flex gap-4">
          <DndProfileSidebar
            form={{ control, errors }}
            group={{ isFetchingGroup, dataGroup, setSelectedGroup }}
            attribute={{ isFetchingAttribute, dataAttribute, setSelectedAttribute }}
            structure={structure}
          />
          {dis && (
            <div className="relative w-auto max-w-[396px] flex flex-col pb-4 gap-4">
              <BaseInputValidation control={control} name="name" label="Profile Name" errors={errors} required />

              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="group-content" id="group-header">
                  <Typography>Groups</Typography>
                </AccordionSummary>
                <AccordionDetails className="flex flex-col h-auto max-h-[380px] overflow-x-hidden overflow-y-auto">
                  {isFetchingGroup && <Skeleton />}
                  {!isFetchingGroup &&
                    dataGroup?.length > 0 &&
                    dataGroup.map((gr) => {
                      return (
                        <DroppableElement
                          key={`group-${gr.id}`}
                          structure={structure?.group}
                          data={gr}
                          label={gr.name}
                          type="group"
                          viewDetail={setSelectedGroup}
                          modalName="AttributeGroup"
                        />
                      );
                    })}
                  {!isFetchingGroup && !dataGroup?.length > 0 && <span className="m-auto">no group data</span>}
                  {!isFetchingGroup && (
                    <Button
                      variant="contained"
                      className="w-full h-8 mt-4 m-auto"
                      startIcon={<Add />}
                      onClick={() => {
                        dispatch(handleModal({ modalId: '', componentName: 'AttributeGroup' }));
                      }}
                    >
                      Group
                    </Button>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="attribute-content" id="attribute-header">
                  <Typography>Attribute</Typography>
                </AccordionSummary>
                <AccordionDetails className="flex flex-col h-auto max-h-[380px] overflow-x-hidden overflow-y-auto">
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isFetchingAttribute ? (
                    <Skeleton />
                  ) : dataAttribute?.length > 0 ? (
                    dataAttribute.map((attr) => {
                      return (
                        <DroppableElement
                          key={`attr-${attr.id}`}
                          structure={structure?.attribute}
                          data={attr}
                          label={attr.name}
                          type="attribute"
                          viewDetail={setSelectedAttribute}
                          modalName="Attribute"
                        />
                      );
                    })
                  ) : (
                    <span className="m-auto">no attribute data</span>
                  )}
                  {!isFetchingAttribute && (
                    <Button
                      variant="contained"
                      className="w-full h-8 mt-4 m-auto"
                      startIcon={<Add />}
                      onClick={() => {
                        dispatch(handleModal({ modalId: '', componentName: 'Attribute' }));
                      }}
                    >
                      Attribute
                    </Button>
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
          )}
          <div style={{ minHeight: minH }} className="w-full h-full pt-2 pb-4 pl-2 bg-[#eee]">
            {profileId && structure && gridLayout && gridLayoutElements && (
              <DndProfileAttribute isParent profileId={profileId} layout={gridLayout} elements={gridLayoutElements} onSave={onSave} changeStructure={onChangeStructure} />
            )}
            {!profileId && <DndProfileAttribute isParent onSave={onSave} />}
          </div>
        </div>
        <DndProfileSideDrawer open={!!activeSidePanel} />
        <ModalWrapper componentName="AttributeGroup" header={`${!groupSelected ? 'Add New' : 'Update'} Group`} maxWidth="sm">
          <AttributeGroup groupSelected={groupSelected} refetch={refetchGroup} />
        </ModalWrapper>
        <ModalWrapper componentName="Attribute" header={`${!attributeSelected ? 'Add New' : 'Update'} Attribute`} maxWidth="sm">
          <Attribute dataAttribute={dataAttribute} attributeSelected={attributeSelected} refetch={refetchAttribute} />
        </ModalWrapper>
      </section>
    </Suspense>
  );
}
