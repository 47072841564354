/* eslint-disable */

import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import InputContainer from '../../Input/InputContainer';
import CustomRadioGroup from '../../Input/CustomRadioGroup';
import CustomCheckbox from '../../Input/CustomCheckbox';

const optionParamQty = [
  {
    value: 0,
    label: 0,
  },
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
];

const optionPageType = [
  {
    value: 'General',
    label: 'General Page',
  },
  {
    value: 'Error',
    label: 'Error Page',
  },
  {
    value: 'Job_Poster',
    label: 'Job Poster',
  },
];

function PageSettingForm({ defaultValues, onFormSubmit }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: defaultValues?.name,
      use_page_description: defaultValues?.use_page_description,
      has_params: defaultValues?.has_params,
      is_draft: defaultValues?.is_draft,
      page_type: defaultValues?.page_type,
      params_qty: defaultValues?.params_qty,
    },
  });

  const onSubmit = (data) => {
    onFormSubmit({
      name: data.name,
      use_page_description: data.use_page_description,
      has_params: data.has_params,
      is_draft: data.is_draft,
      page_type: data.page_type,
      params_qty: data.params_qty,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full space-y-4">
        <div className="">
          {' '}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="text-white" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="rounded-t bg-bz-green text-white"
            >
              <Typography className="text-white">SEO</Typography>
            </AccordionSummary>
            <AccordionDetails className="space-y-4">
              <div>
                <div className="font-bold">SEO Keywords</div>
              </div>
              <div className="space-y-4">
                <div className="font-bold">Description : </div>
                <InputContainer name="name" control={control} label="Name" errors={errors.name} />

                <Controller
                  name="use_page_description"
                  control={control}
                  render={({ field: { onChange } }) => <CustomCheckbox label="Use Page Description" defaultChecked={defaultValues?.use_page_description} onChange={onChange} />}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="text-white" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="rounded-t bg-bz-green text-white mt-3"
            >
              <Typography className="text-white">Page Settings</Typography>
            </AccordionSummary>
            <AccordionDetails className="">
              <div className="flex flex-col space-y-4">
                <Controller
                  name="has_params"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <CustomCheckbox label="Has Parameters" options={optionParamQty} defaultChecked={defaultValues?.has_params} onChange={onChange} />
                  )}
                />

                <Controller
                  name="params_qty"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <CustomRadioGroup label="Parameters Quantity" options={optionParamQty} defaultValue={defaultValues?.params_qty} onChange={onChange} />
                  )}
                />

                <Controller
                  name="is_draft"
                  control={control}
                  render={({ field: { onChange } }) => <CustomCheckbox label="Is Draft" options={optionParamQty} defaultChecked={defaultValues?.has_params} onChange={onChange} />}
                />

                <Controller
                  name="page_type"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <CustomRadioGroup label=" Type of Page" options={optionPageType} defaultValue={defaultValues?.page_type} onChange={onChange} />
                  )}
                />

                <Button className=" w-fit p-2 rounded ml-auto" type="submit" variant="outlined">
                  Save Setting
                </Button>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        {/* <DevTool control={control} /> */}
      </div>
    </form>
  );
}
export default PageSettingForm;
