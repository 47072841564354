export function getStaticPeriod(index) {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const date = now.getDate().toString().padStart(2, '0');

  // last 6 months
  function lastNMonth(n) {
    return `${month - n > 0 ? year : year - 1}-${(month - n > 0 ? month - n : month + 12 - n).toString().padStart(2, '0')}-${date}`;
  }

  const strMonth = month.toString().padStart(2, '0');

  const period = {
    1: {
      // This year
      start_date: `${year}-01-01`,
      end_date: `${year}-${strMonth}-${date}`,
      isNow: true,
    },
    2: {
      // Last year
      start_date: `${year - 1}-${strMonth}-${date}`,
      end_date: `${year}-${strMonth}-${date}`,
    },
    3: {
      // Last 6 months
      start_date: lastNMonth(6),
      end_date: `${year}-${strMonth}-${date}`,
    },
    4: {
      // Last 3 months
      start_date: lastNMonth(3),
      end_date: `${year}-${strMonth}-${date}`,
    },
    5: {
      // Last month
      start_date: lastNMonth(1),
      end_date: `${year}-${strMonth}-${date}`,
    },
    6: {
      // Last 2 years
      start_date: `${year - 2}-${strMonth}-${date}`,
      end_date: `${year}-${strMonth}-${date}`,
    },
    7: {
      // All time
      start_date: null,
      end_date: null,
    },
    custom: {
      start_date: `${year}-${month}-${date}`,
      end_date: `${year}-${month}-${date}`,
    },
  };

  return period[index];
}

export function standardizeInputDate(date, timezoneOffset, isNow) {
  if (!date) return null;

  const absTimezoneOffset = Math.abs(timezoneOffset);
  const sign = timezoneOffset > 0 ? '-' : '+';
  const offset = `${
    sign +
    Math.floor(absTimezoneOffset / 60)
      .toString()
      .padStart(2, '0')
  }:${(Math.floor(absTimezoneOffset) % 60).toString().padStart(2, '0')}`;

  if (isNow) {
    return new Date().toISOString();
  }

  return `${date}T00:00:00${offset}`;
}
