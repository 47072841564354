import request from '../helper/axiosUtil';
import store from '../store';

export const fetchTeams = async ({ queryKey }) => {
  const [, { clientId }] = queryKey;
  const response = await request({ url: `/project-management/teams/?client=${clientId}&max_size=true` });
  if (response?.response?.status === 401 || response?.response?.status === 0) {
    store.dispatch({ type: 'auth/setLogout' });
    store.dispatch({ type: 'client/setActiveClient', payload: false });
    store.dispatch({ type: 'lang/setLanguageList', payload: false });
  }
  return response;
};

export const createTeams = (data) =>
  request({
    url: '/project-management/teams/',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const updateTeams = async (id, data) =>
  request({
    url: `/project-management/teams/${id}/`,
    method: 'patch',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const deleteTeams = async (id) =>
  request({
    url: `/project-management/teams/${id}/`,
    method: 'delete',
  });
