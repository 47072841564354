import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Skeleton, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';

import BaseInputValidation from '../../../components/Input/Base/BaseInputValidation';
import accountingApi from '../../../api/accounting/accounting';
import userApi from '../../../api/user';
import BaseAutocompleteValidation from '../../../components/Input/Base/BaseAutocompleteValidation';
import BaseLocation from '../../../components/Modal/Base/BaseLocation';
import BZHelmet from '../../../utils/BZHelmet';

const initialState = {
  baseCurrency: '',
  ledgerCurrency: [],
  code: '',
  name: '',
  registrasionNumber: '',
  taxNumber: '',
  email: '',
  phone: '',
  address: '',
  decimal_digits: 0,
  share: '',
  valuePerShare: '',
  location: '',
  businessShareholders: '',
  users: [],
};

const schema = yup.object().shape({
  name: yup.string().required('Name is a required field').nullable(),
  decimal_digits: yup.number().required().min(0).max(5),
});

export default function BusinessUnitDetail() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { businessUnitId } = useParams();
  const activeClient = useSelector((state) => state.client.activeClient);
  const client = useSelector((state) => state.auth.client.id);
  const isSuperUser = useSelector((state) => state.auth.isSuperUser);
  const activeUser = useSelector((state) => state.auth.user);
  const usedClient = activeUser.is_superuser ? activeClient : client;
  const isGroups = useSelector((state) => state.auth.isGroups);

  const { getBusinessUnit, addBusinessUnit, updateBusinessUnit, getCurrencies } = accountingApi();
  const { getUsers } = userApi();

  const [activeBusinessUnitId, setActiveBusinessUnitId] = useState(false);
  const [dataBusinessUnit, setDataBusinessUnit] = useState(false);
  const [dataCurrencies, setDataCurrencies] = useState(false);
  const [dataUsers, setDataUsers] = useState(false);
  const [isLocation, setIsLocation] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialState,
  });

  const pairLocation = (data) => {
    setValue('location', data.name || data.address, { shouldDirty: true });
    setSelectedLocation(data);
    setIsLocation(false);
  };

  const onSubmit = (data) => {
    const businessData = {
      client: usedClient,
      base_currency: data.baseCurrency?.id,
      ledger_currencies: data.ledgerCurrency.map((led) => led.id),
      code: data.code,
      name: data.name,
      registration_number: data.registrasionNumber,
      tax_payer_number: data.taxNumber,
      email: data.email,
      phone: data.phone,
      address: data.address,
      decimal_digits: data.decimal_digits,
      share: data.share,
      value_per_share: data.valuePerShare,
      locations: selectedLocation ? Array.of(selectedLocation?.id) : null,
      users: data.users.map((led) => led.id),
    };

    if (!businessUnitId) {
      addBusinessUnit(businessData)
        .then((res) => {
          if (res.status === 201 || res.data) {
            enqueueSnackbar('New business unit added' || 'Success', { variant: 'success' });
            navigate('/accounting/settings/business-units');
            reset();
          }
        })
        .catch((err) => {
          const er = err.response;
          if (er && Object.keys(er.data).length) {
            Object.keys(er.data).forEach((e) => {
              if (er.data[e]?.length) {
                enqueueSnackbar(`${e} - ${er.data[e]}` || `${er.status} - ${er.statusText}` || 'Error', { variant: 'error' });
              }
            });
          }
        });
    } else {
      updateBusinessUnit(businessUnitId, businessData)
        .then((res) => {
          if (res.status === 201 || res.data) {
            enqueueSnackbar('Business unit updated' || 'Success', { variant: 'success' });
            navigate('/accounting/settings/business-units');
            reset();
          }
        })
        .catch((err) => {
          const er = err.response;
          if (er && Object.keys(er.data).length) {
            Object.keys(er.data).forEach((e) => {
              if (er.data[e]?.length) {
                enqueueSnackbar(`${e} - ${er.data[e]}` || `${er.status} - ${er.statusText}` || 'Error', { variant: 'error' });
              }
            });
          }
        });
    }
  };

  const getBusinessUnitDetail = useCallback(
    (id) => {
      getBusinessUnit(id)
        .then((res) => {
          if (res.status === 200 && res.data) {
            const dt = res.data;
            setDataBusinessUnit(dt);
            if (dt.locations?.length > 0) {
              setValue('location', dt.locations[0].name || dt.locations[0].address);
              setSelectedLocation(dt.locations[0]);
            } else {
              setValue('location', '');
              setSelectedLocation(false);
            }
            setValue('baseCurrency', dt.base_currency);
            setValue('ledgerCurrency', dt.ledger_currencies);
            setValue('code', dt.code);
            setValue('name', dt.name);
            setValue('address', dt.address);
            setValue('decimal_digits', dt.decimal_digits || '0');
            setValue('email', dt.email);
            setValue('phone', dt.phone);
            setValue('registrasionNumber', dt.registration_number);
            setValue('taxNumber', dt.tax_payer_number);
            setValue('share', dt.share);
            setValue('valuePerShare', dt.value_per_share);
            setValue('users', dt.users);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    [getBusinessUnit, setValue]
  );

  const getCurrencyList = useCallback(
    (params) => {
      return new Promise((resolve, reject) => {
        getCurrencies(params)
          .then((res) => {
            if (res.status === 200 && res.data.results) {
              resolve(res.data.results);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [getCurrencies]
  );

  const getUserList = useCallback(
    (params) => {
      return new Promise((resolve, reject) => {
        getUsers(params)
          .then((res) => {
            if (res.status === 200 && res.data.results) {
              resolve(res.data.results);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [getUsers]
  );

  const getAllData = useCallback(async () => {
    const [a, b] = await Promise.all([getCurrencyList(), getUserList({ client: usedClient })]);
    if (a && b) {
      setDataCurrencies(a);
      setDataUsers(b);
      setIsLoaded(true);
    }
  }, [getCurrencyList, getUserList, usedClient]);

  useLayoutEffect(() => {
    if (!isLoaded) {
      getAllData();
    }
  }, [isLoaded, getAllData]);

  useLayoutEffect(() => {
    if (businessUnitId && !dataBusinessUnit) {
      setActiveBusinessUnitId(businessUnitId);
      getBusinessUnitDetail(businessUnitId);
    }
  }, [businessUnitId, dataBusinessUnit, getBusinessUnitDetail]);

  useEffect(() => {
    if (businessUnitId && businessUnitId !== activeBusinessUnitId) {
      setActiveBusinessUnitId(businessUnitId);
      getBusinessUnitDetail(businessUnitId);
    }
  }, [businessUnitId, activeBusinessUnitId, getBusinessUnitDetail]);

  return (
    <>
      <BZHelmet title={`${dataBusinessUnit ? dataBusinessUnit.code || dataBusinessUnit.name : 'Business Unit Detail'}`} description="bz publish business unit detail" />
      <Box>
        <div className="w-full flex p-4 shadow-md">
          <h1 className="text-lg font-bold">{businessUnitId ? 'Update' : 'Create'} Business Unit</h1>
        </div>
        {(!businessUnitId && isLoaded) || (businessUnitId && dataBusinessUnit && isLoaded) ? (
          <form onSubmit={handleSubmit(onSubmit)} className="p-4 mt-4">
            <div className="space-y-6 border p-4 border-gray-300 rounded-lg">
              <div className="flex gap-x-4">
                <BaseInputValidation control={control} name="name" label="Name" errors={errors} config={{ classes: '3xl:w-1/4' }} />
                <BaseInputValidation control={control} name="code" label="Code" errors={errors} config={{ classes: '3xl:w-1/4' }} />
              </div>
              <div className="flex gap-x-4">
                <BaseInputValidation control={control} name="email" label="Email" errors={errors} config={{ classes: '3xl:w-1/4' }} />
                <BaseInputValidation control={control} name="phone" label="Phone" errors={errors} config={{ classes: '3xl:w-1/4' }} />
                <BaseInputValidation control={control} name="address" label="Address" errors={errors} config={{ classes: '3xl:w-1/4' }} />
              </div>
              <div className="flex gap-x-4">
                <BaseInputValidation control={control} name="location" label="Location" errors={errors} config={{ disabled: true }} />
                <button
                  type="button"
                  className="flex min-w-[12rem] h-[40px] justify-center items-center text-center btn btn-primary rounded-xl border-0 px-4"
                  style={{ backgroundColor: '#2C6D47' }}
                  onClick={() => setIsLocation(true)}
                >
                  <p className="text-white">{selectedLocation ? 'Update Location' : 'Add Location'}</p>
                </button>
              </div>
              <div className="flex gap-x-4">
                <BaseAutocompleteValidation
                  control={control}
                  name="baseCurrency"
                  label="Base Currency"
                  errors={errors}
                  options={dataCurrencies}
                  optionLabel="code"
                  config={{ classes: '3xl:w-1/2' }}
                />
                <BaseInputValidation
                  control={control}
                  name="decimal_digits"
                  label="Decimal Digits"
                  type="number"
                  inputProps={{ inputMode: 'numeric', min: 0, max: 5 }}
                  errors={errors}
                  config={{ classes: '3xl:w-1/2' }}
                />
                <BaseAutocompleteValidation
                  control={control}
                  name="ledgerCurrency"
                  label="Ledger Currency"
                  errors={errors}
                  options={dataCurrencies}
                  optionLabel="code"
                  config={{ classes: '3xl:w-2/4', multiple: true }}
                />
              </div>
              <div className="flex gap-x-4">
                <BaseInputValidation control={control} name="registrasionNumber" label="Registration number" errors={errors} config={{ classes: 'w-1/4' }} />
                <BaseInputValidation control={control} name="taxNumber" label="Tax Payer Number" errors={errors} config={{ classes: 'w-1/4' }} />
                <BaseInputValidation control={control} name="share" label="Share" errors={errors} config={{ classes: 'w-1/4' }} />
                <BaseInputValidation control={control} name="valuePerShare" label="Value per Share" errors={errors} config={{ classes: 'w-1/4' }} />
              </div>
              {isSuperUser || (!isSuperUser && isGroups.includes(14)) ? (
                <div className="flex gap-x-4">
                  <BaseAutocompleteValidation
                    control={control}
                    name="users"
                    label="Users"
                    errors={errors}
                    options={dataUsers}
                    optionLabel="name"
                    config={{ classes: '3xl:w-2/4', multiple: true }}
                  />
                </div>
              ) : (
                <div />
              )}
            </div>
            <Stack direction="row" spacing={2} className="mt-5 float-right">
              <button
                type="button"
                className="flex h-[40px] gap-x-2 items-center text-center rounded-xl border px-4 border-[#2C6D47] hover:!bg-[#2c6d470a]"
                onClick={() => navigate('/accounting/settings/business-units')}
              >
                <p className="text-[#2C6D47]">Cancel</p>
              </button>
              <button type="submit" className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4" style={{ backgroundColor: '#2C6D47' }}>
                <p className="text-white">{businessUnitId ? 'Update' : 'Save'}</p>
              </button>
            </Stack>
          </form>
        ) : (
          <div className="w-full h-full flex flex-wrap p-4 mt-4 gap-x-4 gap-y-2">
            <Skeleton className="2xl:w-[45%]" />
            <Skeleton className="2xl:w-[45%]" />
            <Skeleton className="2xl:w-[45%]" />
            <Skeleton className="2xl:w-[45%]" />
            <Skeleton className="2xl:w-[45%]" />
            <Skeleton className="2xl:w-[45%]" />
          </div>
        )}
        {/* {!isLoaded ||
          (businessUnitId && !dataBusinessUnit && (
            <div className="w-full h-full flex flex-wrap p-4 mt-4 gap-x-4 gap-y-2">
            </div>
          ))} */}
      </Box>
      <BaseLocation open={isLocation} close={() => setIsLocation(false)} action={pairLocation} defaultLocation={selectedLocation} />
    </>
  );
}
