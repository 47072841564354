import useTokenBearer from '@/hooks/useTokenBearer';
import axios from 'axios';

const baseURL = import.meta.env.VITE_API_URL;

function auth() {
  async function signin(data) {
    const headers = {
      Authorization: `basic ${btoa(`${data.email}:${data.password}`)}`,
      'Content-Type': 'application/json',
    };
    const response = await axios.post(`${baseURL}admin-login/`, {}, { headers });
    return response;
  }
  async function signout(token) {
    const headers = {
      Authorization: `${useTokenBearer()}  ${token}`,
      'Content-Type': 'application/json',
    };
    const response = await axios.post(`${baseURL}admin-logout/`, { token }, { headers });
    return response;
  }

  return {
    signin,
    signout,
  };
}

export default auth;
