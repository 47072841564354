import React, { lazy, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import store from '../store/index';
import ProtectedRoute from './ProtectedRoute';
import superuserRoute from './SuperUserRoute';
import contentRoute from './ContentRoute';
import hrRoute from './HRRoute';
import jobRoute from './JobRoute';
import settingsRoute from './SettingsRoute';
import settingsBZRoute from './SettingsBZRoute';
import crmRoute from './CRMRoute';
import taskRoute from './TaskRoute';
import accountingRoute from './AccountingRoute';
import employeeRoute from './EmployeeRoute';
import procurementRoute from './ProcurementRoute';
import CrmRouteDynamic from './CrmRouteDynamic';
import ProductManagementRoute from './ProductManagementRoute';
import SalesRoute from './SalesRoute';

import Layout from '../components/Layout/Layout';
import usePageTracking from '../hooks/usePageTracking';
// import LayoutTask from '../components/Layout/LayoutTask/LayoutTask';

const AuthPage = lazy(() => import('../pages/Auth/Auth'));
const HomePage = lazy(() => import('../pages/Home'));
const SuperUserPage = lazy(() => import('../pages/SuperUser'));
const NotFound = lazy(() => import('../pages/NotFound'));

function filterArr(arr) {
  if (arr.length > 0) {
    return arr.reduce((a, b) => a.filter((c) => b.includes(c)));
  }
  return false;
}

// * IF WANT TO APPLY CUSTOM LAYOUT
function filterLayout(parent, child) {
  let layout;
  switch (parent) {
    case 'task':
      // layout = <LayoutTask>{child}</LayoutTask>;
      layout = child;
      break;
    default:
      layout = child;
      break;
  }
  return layout;
}

function Index() {
  usePageTracking();

  const {crmRoute, loading} = CrmRouteDynamic();

  const [allRoutes, setRoutes] = useState([]);
  const [isRoute, setIsRoute] = useState(false);
  const activeClient = useSelector((state) => state.client.activeDetailClient?.id);
  const activeSubs = useSelector((state) => state.client.clientSubscriptions || []);
  const clientSubs = useSelector((state) => state.client.clientSubscriptions);
  const languagesList = useSelector((state) => state.lang.languagesList);
  const isSuperUser = useSelector((state) => state.auth.isSuperUser);
  const isGroups = useSelector((state) => state.auth.isGroups);
  const client = useSelector((state) => state.client.activeClient);
  const aC = useRef(null);

  const isAuthenticated = useSelector((state) => {
    if (state.auth.isAuthenticated && state.auth.client && state.auth.key && state.auth.user) {
      return true;
    }
    if (state.auth.client && state.auth.key && state.auth.user) {
      store.dispatch({ type: 'auth/setAuthenticated' });
      return true;
    }
    return false;
  });

  const renderChildRoute = useCallback((rt) => {
    const subs = [rt.subscriptions, activeSubs];
    const groups = [rt.privilege, isGroups];
    if ((isSuperUser && filterArr(subs).length > 0) || (!isSuperUser && filterArr(groups).length > 0)) {
      return <Route key={rt.name} path={rt.path} element={filterLayout(rt.layout, rt.element)} />;
    }
    return null;
  }, [activeSubs, isGroups, isSuperUser]);

  const renderItemRoute = useCallback((rt, child) => {
    const subs = [rt.subscriptions, activeSubs];
    const groups = [rt.privilege, isGroups];
    if ((isSuperUser && filterArr(subs).length > 0) || (!isSuperUser && filterArr(groups).length > 0)) {
      return (
        <Route key={rt.name} element={<ProtectedRoute redirectPath="/login" isAllowed={isAuthenticated} />}>
          {!child ? renderChildRoute(rt) : child}
        </Route>
      );
    }
    return null;
  }, [activeSubs, isAuthenticated, isGroups, isSuperUser, renderChildRoute]);

  const renderNestedRoute = useCallback((rt) => {
    const rtChilds = rt.child.map((r) => renderChildRoute(r));
    const rtParent = (
      <Route path={rt.path}>
        <Route index element={rt.element} />
        {rtChilds}
      </Route>
    );
    return renderItemRoute(rt, rtParent);
  }, [renderChildRoute, renderItemRoute]);

  const renderRoute = useCallback((arr) => {
    const result = arr.flatMap(rt => rt.child?.length > 0 ? renderNestedRoute(rt) : renderItemRoute(rt))
    return result;
  }, [renderItemRoute, renderNestedRoute]);

  const routeAsyncRender = useCallback((route) => {
    return new Promise((resolve) => {
      const filteredRoute = route.filter(rt => !rt.client || rt.client.includes(+client));
      resolve(renderRoute(filteredRoute));
    });
  }, [renderRoute, client]);

  const joinRoute = useCallback(async () => {
    const routePromises = [
      superuserRoute, 
      contentRoute, 
      jobRoute, 
      settingsRoute, 
      settingsBZRoute,
      crmRoute,
      taskRoute, 
      accountingRoute, 
      hrRoute, 
      employeeRoute, 
      procurementRoute,
      ProductManagementRoute,
      SalesRoute
    ].map(routeAsyncRender);

    const routeResults = await Promise.all(routePromises);
    const newRoutes = routeResults.flat().filter(Boolean);
    if (newRoutes.length) {
      setIsRoute(true);
      setRoutes(newRoutes);
    }
  }, [routeAsyncRender, crmRoute]);

  useEffect(() => {
    if (clientSubs.length > 0 && activeSubs.length >= 0 && allRoutes.length === 0 && crmRoute.length > 0 && loading === false) {
      joinRoute();
    }
  }, [activeSubs, clientSubs, allRoutes, joinRoute, crmRoute]);

  useEffect(() => {
    if (clientSubs.length > 0 && activeClient && !aC.current) {
      aC.current = activeClient;
      setRoutes([]);
    }
  }, [activeClient, clientSubs]);

  useEffect(() => {
    if (activeClient && aC.current && activeClient !== aC.current) {
      aC.current = activeClient;
      setRoutes([]);
    }
  }, [activeClient, clientSubs]);

  const joinEmptySubRoute = useCallback(async () => {
    const superuserRoutes = await routeAsyncRender(superuserRoute);
    if (superuserRoutes) {
      setIsRoute(true);
      setRoutes(superuserRoutes);
    }
  }, [routeAsyncRender]);

  useEffect(() => {
    if (clientSubs.length === 0 && activeSubs.length === 0) {
      joinEmptySubRoute();
    }
  }, [activeSubs, clientSubs, joinEmptySubRoute]);

  const isAuth = isAuthenticated && languagesList;
  let html;

  if (isAuth && isRoute && allRoutes.length > 0) {
    html = (
      <Layout>
        <Routes>
          {allRoutes.filter(Boolean)}
          <Route element={<ProtectedRoute redirectPath="/" isAllowed={isSuperUser} />}>
            <Route path="/superuser" element={<SuperUserPage />} />
          </Route>
          <Route element={<ProtectedRoute redirectPath="/" isAllowed={!isAuth} />}>
            <Route path="/login" element={<AuthPage />} />
          </Route>
          <Route element={<ProtectedRoute redirectPath="/login" isAllowed={isAuth} />}>
            <Route path="/" element={<HomePage />} />
          </Route>
          <Route element={<ProtectedRoute redirectPath="/login" isAllowed={isAuth} />}>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Layout>
    );
  } else if (isAuthenticated && !languagesList) {
    html = null;
  } else if (!isAuthenticated) {
    html = (
      <Routes>
        <Route element={<ProtectedRoute redirectPath="/" isAllowed={!isAuth} />}>
          <Route path="/login" element={<AuthPage />} />
        </Route>
        <Route element={<ProtectedRoute redirectPath="/login" isAllowed={isAuth} />}>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    );
  }

  // console.log({allRoutes});

  return html || <div />;
}

export default Index;
