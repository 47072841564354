import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSearchParams, Link, useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Switch, IconButton, Collapse, TextareaAutosize, Button, CircularProgress } from '@mui/material';
import { TextField, InputAdornment } from '@mui/material';
import { handleModal } from '@/store/slices/modalSlice';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import _ from 'lodash';

import CancelIcon from '@mui/icons-material/Cancel';
import SelectAutocomplete from '@/components/Input/ProductManagement/SelectAutoComplete';
import CustomSelectRedesign from '../../CRM/redesigning/CustomSelectRedesign';
import {DatePickerFieldWithoutController} from '@/components/Input/DatePickerField';
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';
import Swal from 'sweetalert2';
import { useProductContext } from '../ProductContext';
import ModalWrapper from '@/components/ui/ModalWrapper';
import Select from '@/components/Input/MuiSelectSingleItem';
import Input from '../../CRM/redesigning/InputContainerRedesign';
import ComponentPairOnProductDetail from '@/components/Form/ProductManagement/ComponentPairOnProductDetail';
import AddOnsPair from '@/components/Form/ProductManagement/AddOnsPair';
import moment from 'moment';
import { toast } from 'react-toastify';
import { toastSuccess } from '@/constants/ToastConfig';
import TabInput from './TabInput';
import TabProjection from './TabProjection';
import TabCostBreakdown from './TabCostBreakdown';

function PricingManagerTab() {
  const { register, handleSubmit, control, errors, setValue } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const navigate = useNavigate();
  const { productId, vendorId } = useParams();
  const isFromProcurement = vendorId !== undefined;
  const params = useParams();
  const dispatch = useDispatch();

  const {
    currencyOptions,
    isLoadingOptions,
    productData,
    marketTabValue,
    setMarketTabValue,
    insideMarketTabValue,
    setInsideMarketTabValue,
    marketManagers,
    pricingProjection,
    costBreakdown,
    getAllPricingManagerData,
  } = useProductContext();

  const [pricingCurrency, setPricingCurrency] = useState(currencyOptions.find((dt) => dt.code === productData?.currency)?.id );
  
  const [startPeriod, setStartPeriod] = useState(null);

  useEffect(() => {
    getAllPricingManagerData({ currency: pricingCurrency })
  }, [pricingCurrency])

  useEffect(() => {
    const startPeriodFormatting = startPeriod !== null ? moment(startPeriod).format('YYYY-MM-DD') : null
    getAllPricingManagerData({ start_date: startPeriodFormatting, onlyPricingManagers: true })
  }, [startPeriod])

  const handleChangeMarketTab = (event, newValue) => {
    setMarketTabValue(newValue);
  };
  const handleChangeInsideMarketTab = (event, newValue) => {
    setInsideMarketTabValue(newValue);
  };
  
  return marketManagers.length > 0 ? (
    <div className='w-full space-y-6 border-t pt-4'>
      <Box sx={{ width: '100%', position: 'relative' }}>
        <Box sx={{ marginBottom: 2 }}>
          <Tabs
            value={marketTabValue}
            onChange={handleChangeMarketTab}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              sx: {
                backgroundColor: '#2C6D47',
              },
            }}
            sx={{
              '.MuiButtonBase-root': {
                fontWeight: '600',
              },
              '.Mui-selected': {
                color: '#2C6D47!important',
              },
            }}
          >
            {
              marketManagers.map((dt, idx) => (
                <Tab key={dt.id} value={dt.market_name} label={dt.market_name} />
              ))
            }
          </Tabs>
        </Box>
        <div className="space-y-4">
          <form action="" className="w-full">
            <div className='flex gap-4 w-full mb-4'>
              <div className="w-[10%] h-fit">
                <p className="font-semibold mb-[5px]">Currency</p>
                  <SelectAutocomplete
                    variant="outlined"
                    options={currencyOptions?.map((dt) => ({ name: dt.code, value: dt.id }))}
                    defaultValue={pricingCurrency}
                    onChange={(_, val) => setPricingCurrency(val)}
                    isLoading={isLoadingOptions}
                    disabled={insideMarketTabValue === 0}
                  />
              </div>
            </div>
          </form>
          <Box sx={{ width: '100%', position: 'relative' }}>
            <Box sx={{ marginBottom: 2 }}>
              <Tabs
                value={insideMarketTabValue}
                onChange={handleChangeInsideMarketTab}
                aria-label="basic tabs example"
                TabIndicatorProps={{
                  sx: {
                    backgroundColor: '#2C6D47',
                  },
                }}
                sx={{
                  '.MuiButtonBase-root': {
                    fontWeight: '600',
                  },
                  '.Mui-selected': {
                    color: '#2C6D47!important',
                  },
                }}
              >
                <Tab value={0} label="Input" />
                <Tab value={1} label="Projection" />
                <Tab value={2} label="Cost Breakdown" />
                <Tab value={3} label="Visualizations" />
              </Tabs>
            </Box>
              {insideMarketTabValue === 0 && <TabInput />}
              {insideMarketTabValue === 1 && pricingProjection !== null && <TabProjection />}
              {insideMarketTabValue === 2 && costBreakdown !== null && <TabCostBreakdown />}
          </Box>
        </div>
      </Box>
    </div>
  ) : (
    <></>
  )
}

export default PricingManagerTab