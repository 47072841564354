import qs from 'qs';
import axiosInstances from '../../helper/axiosInstances';

const baseURL = import.meta.env.VITE_API_URL;

export default function ProductManagementApi() {
  const { axiosInstance, axiosInstanceF } = axiosInstances();


  async function getOutletList(params) {
    const method = 'get';
    const url = `${baseURL}point-of-sale/outlets/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getOutletDetail(id) {
    const method = 'get';
    const url = `${baseURL}point-of-sale/outlets/${id}`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function addNewOutlet(data) {
    const method = 'post';
    const url = `${baseURL}point-of-sale/outlets/`;
    const response = await axiosInstance({
      method,
      url,
      data
    });
    return response;
  }

  async function updateOutletData(id, data) {
    const method = 'patch';
    const url = `${baseURL}point-of-sale/outlets/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data
    });
    return response;
  }

  async function bulkPairCollections(data) {
    const method = 'post';
    const url = `${baseURL}point-of-sale/outlet_collections/process_bulk/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function updateOutletCollectionScheduleById(id, data) {
    const method = 'patch';
    const url = `${baseURL}point-of-sale/outlet_collections/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function unpairCollectionById(id) {
    const method = 'delete';
    const url = `${baseURL}point-of-sale/outlet_collections/${id}`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function bulkUnpairCollections(data) {
    const method = 'delete';
    const url = `${baseURL}point-of-sale/outlet_collections/delete_bulk/`;
    const response = await axiosInstance({
      method,
      url,
      data
    });
    return response;
  }

  async function getAllOutletProducts(params) {
    const method = 'get';
    const url = `${baseURL}point-of-sale/outlet_products/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getAllProductsByCollections(params) {
    const method = 'get';
    const url = `${baseURL}point-of-sale/products/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getAllOutletCollections(params) {
    const method = 'get';
    const url = `${baseURL}point-of-sale/outlet_collections/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getAllHomeCollections(params) {
    const method = 'get';
    const url = `${baseURL}point-of-sale/home_collections/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function updateIndexingHomePos(id, data) {
    const method = 'put';
    const url = `${baseURL}point-of-sale/outlets/${id}/update_collection_and_product_is_initials/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function getAllOUtletCustomers(params) {
    const method = 'get';
    const url = `${baseURL}point-of-sale/customers/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getCustomerDetail(id) {
    const method = 'get';
    const url = `${baseURL}point-of-sale/customers/${id}`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function updateCustomerDetail(id, data) {
    const method = 'put';
    const url = `${baseURL}point-of-sale/customers/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function listOfClassForFilter(params) {
    const method = 'get';
    const url = `${baseURL}point-of-sale/customers/list_classes/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function pairCustomerUser(id, data) {
    const method = 'post';
    const url = `${baseURL}point-of-sale/outlets/${id}/insert_customer_users/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function unpairCustomerUser(id, data) {
    const method = 'put';
    const url = `${baseURL}point-of-sale/outlets/${id}/remove_customer_users/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function getAllDiscounts(params) {
    const method = 'get';
    const url = `${baseURL}point-of-sale/discounts/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addNewDiscount(data) {
    const method = 'post';
    const url = `${baseURL}point-of-sale/discounts/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function updateDiscount(id, data) {
    const method = 'put';
    const url = `${baseURL}point-of-sale/discounts/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteDiscountById(id) {
    const method = 'delete';
    const url = `${baseURL}point-of-sale/discounts/${id}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function getAllPaymentTypes(params) {
    const method = 'get';
    const url = `${baseURL}point-of-sale/payment_types/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addNewPayment(data) {
    const method = 'post';
    const url = `${baseURL}point-of-sale/payment_types/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function updatePayment(id, data) {
    const method = 'put';
    const url = `${baseURL}point-of-sale/payment_types/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  
  async function deletePaymentById(id) {
    const method = 'delete';
    const url = `${baseURL}point-of-sale/payment_types/${id}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function getAllServingTimes(params) {
    const method = 'get';
    const url = `${baseURL}point-of-sale/serving_times/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addNewServingTime(data) {
    const method = 'post';
    const url = `${baseURL}point-of-sale/serving_times/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function updateServingTime(id, data) {
    const method = 'put';
    const url = `${baseURL}point-of-sale/serving_times/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteServingTimeById(id) {
    const method = 'delete';
    const url = `${baseURL}point-of-sale/serving_times/${id}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  return {
    getOutletList,
    getOutletDetail,
    addNewOutlet,
    updateOutletData,
    unpairCollectionById,
    bulkUnpairCollections,
    updateOutletCollectionScheduleById,
    bulkPairCollections,
    getAllOutletProducts,
    getAllProductsByCollections,
    getAllOutletCollections,
    getAllHomeCollections,
    updateIndexingHomePos,
    getAllOUtletCustomers,
    getCustomerDetail,
    updateCustomerDetail,
    listOfClassForFilter,
    pairCustomerUser,
    unpairCustomerUser,
    getAllDiscounts,
    addNewDiscount,
    updateDiscount,
    deleteDiscountById,
    getAllPaymentTypes,
    addNewPayment,
    updatePayment,
    deletePaymentById,
    getAllServingTimes,
    addNewServingTime,
    updateServingTime,
    deleteServingTimeById
  };
}
