/* eslint-disable */
import { DeleteForever, Edit } from '@mui/icons-material';
import { Button, ButtonBase, TableCell, TableRow } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import BaseMainTitle from '../../../components/BaseMainTitle';
import BusinessUnitForm from '../../../components/Form/Settings/BusinessUnitForm';
import InputContainer from '../../../components/Input/InputContainer';
import CustomTable from '../../../components/Table/CustomTable';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import HandleWatchingState from '../../../helper/HandleWatchingState';
import useDebounce from '../../../hooks/useDebounce';
import useDelete from '../../../hooks/useDelete';
import useGet from '../../../hooks/useGet';
import { fetchFilterBunit } from '../../../hooks/useNewHrData';
import usePost from '../../../hooks/usePost';
import { useUpdateBusinessUnit } from '../../../hooks/useWhistleblowerData';
import { handleModal } from '../../../store/slices/modalSlice';
import BZHelmet from '../../../utils/BZHelmet';
import BZButton from '@/components/Button/BZButton';
import BaseDeleteButton from '@/components/Button/BaseDeleteButton';
import { Link } from 'react-router-dom';

const headCellsDepartment = [
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: 'Code',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: 'Email',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: true,
    label: 'Phone Number',
  },

  {
    id: 'location',
    numeric: false,
    disablePadding: false,
    label: 'Location',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

function ActionCell(props) {
  const { id } = props;
  const dispatch = useDispatch();

  const destroyDepartment = useDelete({
    confText: `Are you sure want to delete this Business Unit ?`,
    confTitle: `Confirm Delete Business Unit`,
    success: 'Business Unit deleted',
    validate: ['BusinessUnits'],
  });

  const removeDepartment = () => {
    destroyDepartment(`accounting/business_units/${id}`);
  };

  return (
    <div className="flex space-x-2 items-center">
      <Link to={`/settings/business-units/${id}`}>
        <ButtonBase
        // onClick={() => dispatch(handleModal({ modalId: id, componentName: 'editBusinessUnit' }))}
        className="bz-btn-tb" type="button">
          <Edit />
        </ButtonBase>
      </Link>
      <BaseDeleteButton className='!w-fit' onClick={() => removeDepartment()} variant="icon" />
    </div>
  );
}

export default function BusinessUnitList() {
  const { control, watch } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const modal = useSelector((state) => state.modal);

  const dispatch = useDispatch();

  const [dataUnits, setDataUnits] = useState([]);
  const [unitData, setunitData] = useState([]);
  const [count, setCount] = useState(0);
  const [isDataLoad, setIsDataLoad] = useState(true);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const searchInput = watch('search');

  const debouncedSearchInput = useDebounce(searchInput, 500);
  const unitId = modal.modalId;

  const watchingUnit = HandleWatchingState(unitId);

  useGet({
    url: `accounting/business_units`,
    queryString: {
      client: clientId,
      max_size: true,
      search: '',
    },
    validate: 'BusinessUnits',
    onSuccess: (e) => {
      const listData = e?.data?.results || e?.data;
      setunitData([{ name: '-- No Selected --', id: '' }, ...listData]);
    },
  });

  const { data: locations } = useGet({
    url: `locations`,
    queryString: {
      client: clientId,
      max_size: true,
    },
    validate: 'locations',
  });

  let isAutoFetchingDepartment;
  if (modal.componentName === 'editBusinessUnit') {
    isAutoFetchingDepartment = true;
  } else isAutoFetchingDepartment = false;

  const { data: UnitDetail } = useGet({
    url: `accounting/business_units/${unitId}/retrieve_with_locations/`,
    validate: 'BusinessUnit',
    isEnabled: watchingUnit,
  });

  const { mutate: updateUnit } = useUpdateBusinessUnit(unitId, UnitDetail?.data);

  const { mutate: postDepartment } = usePost({ url: 'accounting/business_units', validate: ['BusinessUnits'] });

  const onPostDepartment = async (postData) => {
    await postDepartment(postData);
  };

  const optionUnit = unitData?.map((item) => ({
    label: `${item?.name} ${item?.code ? `(${item.code})` : ''}`,
    value: item?.id,
  }));

  const sortAlphaUnit = optionUnit?.sort((a, b) => a.label.normalize().localeCompare(b.label.normalize()));

  const queryKey = {
    client: clientId,
    code: unitId,
    search: debouncedSearchInput || '',
    page,
    page_size: rowsPerPage,
  };

  const { isFetching } = useQuery(['BusinessUnitsNoLocationLis', { queryKey }], fetchFilterBunit, {
    onSuccess: (e) => {
      const listData = e?.data?.results || e?.data;
      setCount(e?.data?.count);
      setDataUnits(listData);
    },
    refetchOnWindowFocus: false,
  });

  const data = useMemo(() => dataUnits, [dataUnits]);

  const tabelAction = (
    <div className="">
      <div className="bg-gray-100 w-full py-5 px-3  rounded items-center">
        <div className="flex  justify-between">
          {' '}
          <div className="w-full max-w-[300px] ">
            <InputContainer name="search" control={control} label="Search" className="!my-0 bg-white" />
          </div>
          <div className="flex justify-end">
            <Link to="/settings/business-units/add">
              <BZButton variant="secondary">Add Business Unit</BZButton>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  function TableCells(props) {
    const { row, labelId, ariaChecked, selected } = props;

    return (
      <TableRow hover tabIndex={-1} key={row.name + row.id}>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          {row?.code || '-'}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          {row.name}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          {row.email}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          {row.phone}
        </TableCell>

        <TableCell component="th" id={labelId} scope="row" padding="normal" size="small" className="max-w-[200px]">
          {row?.locations && row?.locations.length > 0 ? (
            <div>
              <div className="font-bold"> {row?.locations[0]?.name || '-'}</div>
              {row?.locations[0]?.address || '-'}
            </div>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell align="left">
          <ActionCell
            id={row.id}
            // onClick={() => removeEmployee(row.id)}
          />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <div>
      <BZHelmet title="Business Units" description="" />
      <BaseMainTitle title="Business Units" />
      <div className="p-5">
        <div className="">
          <div className=" ">{tabelAction}</div>
        </div>

        <CustomTable
          dataApi={data}
          setIsDataLoad={setIsDataLoad}
          isFetching={!isFetching}
          headCells={headCellsDepartment}
          ActionCell={ActionCell}
          TableCells={TableCells}
          page={page - 1}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          count={count}
        />
      </div>

      <ModalWrapper componentName="editBusinessUnit" header="Edit Business Unit" maxWidth="xl">
        <BusinessUnitForm
          onFormSubmit={updateUnit}
          defaultValues={UnitDetail?.data}
          key={UnitDetail?.data?.id}
          locations={locations}
          close={() => dispatch(handleModal({ modalId: '', componentName: '' }))}
        />
      </ModalWrapper>
      <ModalWrapper componentName="addBusinessUnit" header="Create Business Unit" maxWidth="xl">
        <BusinessUnitForm onFormSubmit={onPostDepartment} locations={locations} close={() => dispatch(handleModal({ modalId: '', componentName: '' }))} />
      </ModalWrapper>
    </div>
  );
}
