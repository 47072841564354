// /* eslint-disable */

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BaseMainTitle from '../../components/BaseMainTitle';
import { getUsers } from '../../store/slices/usersTeamSlice';
import BZHelmet from '../../utils/BZHelmet';
import TableUser from './TableUser';
import useGet from '@/hooks/useGet';

export default function User() {
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.client.activeClient);
  // const user = useSelector((state) => state.users);
  const [dataUser, setDataUser] = useState([]);
  const [isUsersLoad, setIsUsersLoad] = useState(false);
  // useQuery(['users', { clientId }], fetchUsers, {
  //   onSuccess: (e) => {
  //     setDataUser(e?.data?.results);
  //     setIsUsersLoad(true);
  //   },
  // });

  useGet({
    url: `users/?client=${clientId}&max_size=true`,
    validate: 'users',
    onSuccess: (e) => {
      setDataUser(e?.data?.results);
      setIsUsersLoad(true);
    },
  });

  const data = useMemo(() => dataUser, [dataUser]);

  useEffect(() => {
    dispatch(getUsers(clientId));
  }, [dispatch, clientId]);
  return (
    isUsersLoad && (
      <div>
        <BZHelmet title="Users" description="bz publish user list" />

        <BaseMainTitle title="User List" />
        <div className="p-5">
          <TableUser usersDataApi={data || []} isUsersLoad={isUsersLoad} setIsUsersLoad={setIsUsersLoad} />
        </div>
      </div>
    )
  );
}
