import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Checkbox, Divider, Fade, FormControl, FormControlLabel, FormLabel, FormGroup, Modal, Select, Stack, TextField, Typography } from '@mui/material';

import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ModalStyle from '@/constants/ModalStyle';

const schema = yup.object().shape({
  costCenters: yup.array().required('Cost Center is a required field'),
  // costCenters: yup.array()
  //   .of(yup.number())
  //   .min(1, '')
  //   .matches(/\s[0-9]+\s[0-9]+\s[0-9]+/, '')
  //   .required('Cost Center is a required field'),
});

export default function ModalFilter(props) {
  const { open, close, action, data, costCenters } = props;
  const [isOpen, setOpen] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      costCenters: [],
    },
  });

  const handleSelect = (idChecked, values = []) => {
    const newNames = values?.includes(idChecked) ? values?.filter((id) => id !== idChecked) : [...values, idChecked];
    return newNames;
  };

  const onCloseHandler = () => {
    close();
    setOpen(false);
    reset({
      costCenters: [],
    });
  };

  const onSubmit = (dataFilter) => {
    const dataF = {
      costCenters: dataFilter.costCenters,
    };
    action(dataF);
    onCloseHandler();
  };

  const setDefaultState = useCallback(() => {
    if (data) {
      setValue(
        'costCenters',
        data?.map((d) => d)
      );
    }
  }, [data, setValue]);

  useEffect(() => {
    if (!isOpen && open && data) {
      setOpen(true);
      setDefaultState();
    }
  }, [open, setDefaultState, isOpen, data]);

  const costCenterVal = watch('costCenters');
  return (
    <Modal open={open} onClose={onCloseHandler} aria-labelledby="modal-filter" aria-describedby="modal-filter" className="w-full">
      <Fade in={open}>
        <Box sx={ModalStyle}>
          <Typography variant="h6" component="h2" className="capitalize">
            Filter
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            <div className="flex flex-col space-y-4">
              <Controller
                name="costCenters"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel id="costCenters" className="bz-label">
                      Cost Centers
                    </FormLabel>
                    <FormGroup className="grid grid-cols-2 gap-1">
                      {costCenters.map((item, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                className="bz-checkbox"
                                checked={field.value.includes(item.id)}
                                onChange={() => field.onChange(handleSelect(item.id, field.value))}
                                size="small"
                              />
                            }
                            label={`${item.code} - ${item.name}`}
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                )}
              />
            </div>
            <Divider className="mt-4" />
            <Stack direction="row" spacing={2} className="mt-5 float-right">
              <button type="button" className="bz-btn secondary" onClick={onCloseHandler}>
                <p className="">Cancel</p>
              </button>
              <button type="submit" className="bz-btn" disabled={!costCenterVal?.length}>
                <p>Save</p>
              </button>
            </Stack>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
}
