/* eslint-disable */

import useGet from '@/hooks/useGet';
import { CircularProgress } from '@mui/material';
import _ from 'lodash';
import { Chart } from 'react-google-charts';
import { useSelector } from 'react-redux';

function convertData(data, label) {
  // console.log("🚀 ~ convertData ~ data:", data)
  // return _.map(data, (item) => [`${item[label]} (${item.count})`, item.count]);
  return _.map(data, (item) => [item[label], item.count]);
}

const options = {
  title: 'Google chart',
  // legend: 'none',
  // pieSliceText: 'label',
  // sliceVisibilityThreshold: 0,

  // tooltip: { trigger: 'none' },
};

export default function HumanResources() {
  const clientId = useSelector((state) => state.client.activeClient);

  const { data: Graph, isFetching } = useGet({
    url: `accounting/business_units/graph_data`,
    queryString: {
      client: clientId,
    },
    validate: 'business_units_graph_data',
  });

  const graphBuDist = [[['unit', 'count']], convertData(Graph?.data?.business_unit_distribution, 'name')].flat();
  const graphGenderDist = [[['gender', 'count']], convertData(Graph?.data?.gender_distribution, 'gender')].flat();
  const graphDepartmentDist = [[['department', 'count']], convertData(Graph?.data?.department_distribution, 'department')].flat();
  const graphOfficeDist = [[['office', 'count']], convertData(Graph?.data?.office_distribution, 'office')].flat();

  return (
    <div className="relative w-sscreen h-screen">
      {isFetching ? (
        <div className="absolute left-0 top-0 w-[calc(100%-280px)] h-full translate-x-1/2 translate-y-1/2  ">
          <CircularProgress />
        </div>
      ) : (
        <div className="p-5">
          <div className="grid grid-cols-2 ">
            <div className="border p-[1rem]">
              <Chart chartType="PieChart" data={graphBuDist} options={{ ...options, title: 'Business Unit Distribution' }} width={'100%'} height={'400px'} />
            </div>
            <div className="border p-[1rem]">
              <Chart chartType="PieChart" data={graphGenderDist} options={{ ...options, title: 'Gender Distribution' }} width={'100%'} height={'400px'} />
            </div>
          </div>
          <div className="grid grid-cols-2 ">
            <div className="border p-[1rem]">
              <Chart chartType="PieChart" data={graphOfficeDist} options={{ ...options, title: 'Office Distribution' }} width={'100%'} height={'400px'} />
            </div>
            <div className="border p-[1rem]">
              <Chart chartType="PieChart" data={graphDepartmentDist} options={{ ...options, title: 'Department Distribution' }} width={'100%'} height={'400px'} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
