import useTokenBearer from '@/hooks/useTokenBearer';
import { axInstance } from '../helper/axiosInstances';

const baseURL = import.meta.env.VITE_API_URL;

function language() {
  async function getAllLanguage(param) {
    const method = 'get';
    const url = `${baseURL}languages/`;
    let headers;
    if (param?.key) {
      headers = {
        Authorization: `${useTokenBearer()} ${param.key}`,
        'Content-Type': 'application/json',
      };
    }
    const instance = await axInstance({
      method,
      url,
      headers,
    });
    const response = await instance.get(url);
    return response;
  }

  return { getAllLanguage };
}

export default language;
