/* eslint-disable */

import Pagination from '@mui/material/Pagination';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import HandleWatchingState from '../../helper/HandleWatchingState';
import { fetchFilterAssetsNew } from '../../hooks/useAssetsData';
import useGet from '../../hooks/useGet';
import { getDefaultSelectObject, getOptionSelectWithKey } from '../../lib/OptionsInputHelper';
import { handleFilter } from '../../store/slices/filterComponentSlice';
import BaseSearchButton from '../Button/BaseSearchButton';
import BaseSubmitButton from '../Button/BaseSubmitButton';
import ReactSelectSingle from '../Input/ReactSelectSingle';
import RenderResults from '../RenderResults';
import AssetCardNew from './AssetCardNew';

function containsNonNullValues(obj) {
  for (const key in obj) {
    const value = obj[key];
    if (value !== null && typeof value !== 'undefined' && !(typeof value === 'string' && value.trim() === '') && !(typeof value === 'object' && !containsNonNullValues(value))) {
      return true;
    }
  }
  return false;
}

function findObjectInArray(array, targetKey) {
  for (let i = 0; i < array.length; i++) {
    const obj = array[i];

    if (typeof obj === 'object' && obj !== null) {
      if (targetKey in obj) {
        return obj[targetKey];
      } else {
        const nestedObj = findObjectInArray(Object.values(obj), targetKey);

        if (nestedObj !== null) {
          return nestedObj;
        }
      }
    }
  }

  return null;
}

export default function BaseAssetViewer({ handleClick, fixBottom = true, assetComponent, assetQuality, setAssetQuality }) {
  const { control, watch, setValue } = useForm();
  const dispatch = useDispatch();
  const assetPair = useSelector((state) => state.assetCrud.selected);
  const clientId = useSelector((state) => state.client.activeClient);

  const allFilterData = useSelector((state) => state.filterComponent);
  const filterAsset = findObjectInArray(allFilterData.filterData, clientId)?.asset;

  const obj = findObjectInArray(allFilterData.filterData, clientId) || '';

  const [dataAssets, setDataAssets] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const [isDataLoad, setIsDataLoad] = useState(true);
  const [countAsset, setCountAsset] = useState(0);
  const pageSize = 15;
  const count = Math.ceil(countAsset / pageSize);

  const assetType = watch('asset_type') || '';
  const category = watch('category') || '';

  const handleChange = (e, p) => {
    setIsDataLoad(false);
    setTimeout(() => {
      setIsDataLoad(true);
    }, 500);
    setPage(p);
    const obj = findObjectInArray(allFilterData.filterData, clientId);
    dispatch(
      handleFilter({
        [clientId]: {
          ...obj,
          asset: {
            ...obj?.asset,
            page: p,
          },
        },
      })
    );
  };

  const queryKey = {
    client: clientId,
    language: 1,
    page_size: pageSize,
    page: page === 'page' ? 1 : page,
    asset_type: assetType,
    search: search,
    ...(category ? { categories: [category] } : {}),
  };

  useQuery(['assets', { queryKey }], fetchFilterAssetsNew, {
    onSuccess: (e) => {
      setDataAssets(e?.data?.results);
      setCountAsset(e.data.count);
    },
  });

  const { data: assetTypes } = useGet({
    url: `asset_types/?language=1`,
    validate: 'assetsType',
  });

  const { data: dataCategory } = useGet({
    url: `category_assets/?max_size=true&client=${clientId}`,
    validate: 'AssetCategories',
  });

  const onChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const watchingType = HandleWatchingState(watch('asset_type') || 'true');

  const watchingCategory = HandleWatchingState(watch('category') || 'true');

  useEffect(() => {
    if (containsNonNullValues(filterAsset)) {
      setValue('asset_type', filterAsset?.asset_type);
      setValue('category', filterAsset?.category);
      setValue('page', filterAsset?.page);
      setValue('search', filterAsset?.search || '');
      if (filterAsset?.page) {
        setPage(filterAsset?.page);
      }
      if (filterAsset?.search) {
        setSearch(filterAsset?.search);
      }
    }
  }, [setValue, filterAsset?.asset_type, filterAsset?.category, filterAsset?.page]);

  return (
    <div className={fixBottom ? 'mb-20' : ''}>
      <div className=" py-4 my-4 border-y-2 ">
        <div className="flex gap-x-3 items-center">
          <div className="min-w-[200px] w-2/12 bg-white  h-full">
            {/* <MuiSelectSingleItem name="asset_type" label="Select File Type " control={control} options={getSelectOptionWithKey(assetTypes?.data?.results) || []} /> */}
            <ReactSelectSingle
              name="asset_type"
              label="Type"
              floatLabel={false}
              control={control}
              // extraAction={() => setValue('stage', '')}
              options={getOptionSelectWithKey(
                assetTypes?.data?.results && assetTypes?.data?.results.length > 0 ? [{ name: '-- No Selected --', id: '' }, ...assetTypes?.data?.results] : []
              )}
              defaultValue={getDefaultSelectObject(
                watch('asset_type'),
                getOptionSelectWithKey(
                  assetTypes?.data?.results && assetTypes?.data?.results.length > 0 ? [{ name: '-- No Selected --', id: '' }, ...assetTypes?.data?.results] : []
                )
              )}
              placeholder="Select Type"
              // isCancel={isCancel}
              // setIsCancel={setIsCancel}
              // resetFunction={() => setValue('stage', '')}
              extraAction={() => {
                if (watchingType) {
                  dispatch(
                    handleFilter({
                      [clientId]: {
                        ...obj,
                        asset: {
                          ...obj?.asset,
                          asset_type: watch('asset_type'),
                          page: 1,
                        },
                      },
                    })
                  );
                }
              }}
              // dispatchCondition={watchingType}
            />
          </div>
          <div className="min-w-[200px] w-2/12 bg-white  h-full">
            {/* <MuiSelectSingleItem name="category" label="Select Category " control={control} options={getSelectOptionWithKey(dataCategory?.data?.results) || []} /> */}

            <ReactSelectSingle
              name="category"
              label="Category"
              floatLabel={false}
              control={control}
              options={getOptionSelectWithKey(
                dataCategory?.data?.results && dataCategory?.data?.results.length > 0 ? [{ name: '-- No Selected --', id: '' }, ...dataCategory?.data?.results] : []
              )}
              defaultValue={getDefaultSelectObject(
                watch('category'),
                getOptionSelectWithKey(
                  dataCategory?.data?.results && dataCategory?.data?.results.length > 0 ? [{ name: '-- No Selected --', id: '' }, ...dataCategory?.data?.results] : []
                )
              )}
              placeholder="Select Category"
              extraAction={() => {
                if (watchingCategory) {
                  dispatch(
                    handleFilter(
                      obj
                        ? {
                            [clientId]: {
                              ...obj,
                              asset: {
                                ...obj.asset,
                                category: watch('category'),
                                page: 1,
                              },
                            },
                          }
                        : {
                            [clientId]: {
                              asset: {
                                category: watch('category'),
                                page: 1,
                              },
                            },
                          }
                    )
                  );
                }
              }}
              // dispatchCondition={watchingCategory}
            />
          </div>
          <BaseSearchButton
            handlePersistSearch={(e) => {
              setIsDataLoad(false);
              setTimeout(() => {
                setIsDataLoad(true);
              }, 500);
              setPage(1);

              dispatch(
                handleFilter({
                  [clientId]: {
                    ...obj,
                    asset: {
                      ...obj?.asset,
                      search: e.target.value,
                      page: 1,
                    },
                  },
                })
              );
            }}
            label="search"
            onChange={onChangeSearch}
            setPage={setPage}
            defaultValue={filterAsset?.search}
            setIsDataLoad={setIsDataLoad}
          />
          {/* {containsNonNullValues(filterAsset) && (
            <BaseSubmitButton
              onClick={() => {
                setValue('asset_type', '');
                setValue('category', '');
                setValue('page', '');
                setPage('page', 1);
                setSearch('');
                const obj = findObjectInArray(allFilterData.filterData, clientId);
                dispatch(
                  handleFilter(
                    obj
                      ? {
                          [clientId]: {
                            ...obj,
                            asset: {
                              type: '',
                              category: '',
                              page: 1,
                            },
                          },
                        }
                      : {
                          [clientId]: {
                            asset: {
                              type: '',
                              category: '',
                              page: 1,
                            },
                          },
                        }
                  )
                );
              }}
              disabled={false}
              text="Reset Filter"
            />
          )} */}
        </div>
      </div>
      <div className="flex justify-between">
        <RenderResults
          condition={count > 0}
          component={
            <div className="pt-5">
              {' '}
              <AssetCardNew assetsData={dataAssets} assetComponent={assetComponent} setAssetQuality={setAssetQuality} assetQuality={assetQuality} />
              {/* <div className="my-5 flex items-center justify-between"> */}
              <div
                className={
                  fixBottom
                    ? 'flex left-0 h-20 px-5 items-center gap-x-3 mt-6 bottom-0 absolute bg-white w-full overflow-x-hidden border-t justify-between'
                    : 'my-5 flex items-center justify-between'
                }
              >
                <div className=" flex items-center gap-x-5">
                  <Pagination count={count} size="large" page={page} variant="outlined" shape="rounded" onChange={handleChange} />
                  <div className="">{`Total ${countAsset}`}</div>
                </div>
                <div className="">
                  {handleClick && (
                    // <button onClick={handleClick} type="button">
                    //   Submit
                    // </button>

                    <BaseSubmitButton text="pair assets" onClick={handleClick} disabled={Boolean(!assetPair.length > 0)} />
                  )}
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}
