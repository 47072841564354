import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Divider, Fade, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: '60vw',
  maxHeight: '90%',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  border: '1px solid transparent',
  boxShadow: 24,
  p: 4,
};

const schema = yup.object().shape({
  code: yup.string().required('Code is a required field'),
  name: yup.string().required('Name is a required field'),
  type: yup.string().required('Type is a required field'),
});

export default function AccountGroup(props) {
  const cl = useSelector((state) => state.auth.client.id);
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeUser = useSelector((state) => state.auth.user);
  const client = activeUser.is_superuser ? activeClient : cl;

  const { open, close, action, type, data } = props;
  const [isOpen, setOpen] = useState(false);
  const [mode, setMode] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      code: '',
      name: '',
      type: '',
    },
  });

  const onCloseHandler = () => {
    close();
    setOpen(false);
    reset({
      code: '',
      name: '',
      type: '',
    });
    setTimeout(() => {
      setMode(false);
    }, 300);
  };

  const onSubmit = (dataAcc) => {
    const dataAccGroup = {
      client,
      code: dataAcc.code,
      name: dataAcc.name,
      type: dataAcc.type,
    };
    action(mode, dataAccGroup);
    onCloseHandler();
  };

  useEffect(() => {
    if (!mode && type) {
      setMode(type);
    }
  }, [mode, type]);

  const setDefaultState = useCallback(() => {
    if (!mode && type) {
      setMode(type);
    }
    if (data) {
      setValue('code', data?.code);
      setValue('name', data?.name);
      setValue('type', data?.type);
    }
  }, [mode, type, data, setValue]);

  useEffect(() => {
    if (!isOpen && open) {
      setMode(false);
      setOpen(true);
      setDefaultState();
    }
  }, [open, setDefaultState, isOpen]);

  return (
    <Modal open={open} onClose={onCloseHandler} aria-labelledby="modal-account-group" aria-describedby="modal-account-group" className="w-full">
      <Fade in={open}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" className="capitalize">
            {mode && mode} Account Group
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            <div className="flex flex-col space-y-4">
              <FormControl>
                <Controller
                  control={control}
                  name="code"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      label="Code"
                      size="small"
                      error={!!errors.code?.message}
                      helperText={errors.code?.message}
                      placeholder=""
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <Controller
                  control={control}
                  name="name"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      label="Name"
                      size="small"
                      error={!!errors.name?.message}
                      helperText={errors.name?.message}
                      placeholder=""
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, value, ref } }) => (
                    <>
                      <InputLabel sx={{ top: '-5px', '&.Mui-focused': { top: 0 }, '&.MuiFormLabel-filled': { top: 0 } }} id="select-type">
                        Account
                      </InputLabel>
                      <Select
                        sx={{
                          pt: 0.5,
                          pb: 1,
                          border: 0,
                          '& label': { display: 'none' },
                        }}
                        labelId="select-type"
                        name="type"
                        inputRef={ref}
                        value={value}
                        label="Type"
                        onChange={onChange}
                        error={!!errors.type?.message}
                      >
                        <MenuItem value="">
                          <em>Type</em>
                        </MenuItem>
                        {['Balance', 'Result'].map((tp, i) => (
                          <MenuItem key={`${tp + i}`} value={tp}>
                            {tp}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                />
              </FormControl>
            </div>
            <Divider className="mt-4" />
            <Stack direction="row" spacing={2} className="mt-5 float-right">
              <button type="button" className="bz-btn secondary" onClick={onCloseHandler}>
                <p className="text-[#2C6D47]">Cancel</p>
              </button>
              <button type="submit" className="bz-btn">
                <p>
                  {mode && mode === 'create' && 'Add'}
                  {mode && mode === 'update' && 'Update'}
                </p>
              </button>
              {/* <Button variant="outlined" onClick={onCloseHandler}>
                Cancel
              </Button> */}
              {/* <Button type="submit" variant="contained">
                {mode && mode === 'create' && 'Add'}
                {mode && mode === 'update' && 'Update'}
              </Button> */}
            </Stack>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
}
