/* eslint-disable */

import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import usePostExtra from '../../../hooks/usePostExtra';
import renderError from '../../../utils/Error/renderError';
// import ArticleFormNew from '../../../components/Form/Article/ArticleFormNew';
import BZHelmet from '../../../utils/BZHelmet';
import BaseMainTitle from '../../../components/BaseMainTitle';
import BusinessUnitForm from '../../../components/Form/Whistleblower/BusinessUnitForm';

export default function UnitAdd() {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoaded] = useState(true);

  const { mutate: postArticle } = usePostExtra({
    url: 'accounting/business_units/create_with_locations',
    validate: ['BusinessUnit'],
    onSuccess: (e) => {
      if (e.request.status === 201) {
        enqueueSnackbar('success', { variant: 'success' });
        // navigate('../article', { replace: true });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
        renderError({ data: JSON.parse(e.request.response) });
      }
    },
  });

  const onPostArticle = async (data) => {
    await postArticle(data);
  };
  return (
    <>
      <BZHelmet title="Business Unit Add" description="" content="" />
      <div>
        <BaseMainTitle title="Add  Business Unit" />
        <div className="p-5 ">
          <div className="bg-white border p-5 shadow rounded">
            <BusinessUnitForm onFormSubmit={onPostArticle} isLoaded={isLoaded} />
          </div>
        </div>
      </div>
    </>
  );
}
