import React from 'react';

import clsxm from '@/utils/clsxm';

export default function BaseHelper({ value, mt = true, className }) {
  return (
    value && (
      <h3
        className={clsxm(
          'text-[0.75rem] leading-[1.33] text-[#666687]',
          mt ? 'mt-[4px]' : '',
          className
        )}
      >
        {value}
      </h3>
    )
  );
}
