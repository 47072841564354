/* eslint-disable no-unused-vars */
// /* eslint-disable */
/* eslint-disable import/no-unresolved */

import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Box, Fade, Select, Skeleton, MenuItem, Popover, Avatar, Button } from '@mui/material';
import { AccountCircle, ArrowDropDown, KeyboardArrowDown, Settings } from '@mui/icons-material';
import ReactCountryFlag from 'react-country-flag';

import { getActiveSiteDetail } from '../../store/slices/content/contentSlice';
import { getActiveBusinessUnitDetail } from '../../store/slices/accounting/accountingSlice';

import store from '../../store';
import authApi from '../../api/auth';
import authSSOApi from '../../api/authSSO';
import styles from './Header.module.css';
import logo from '../../assets/logo.png';
import TimerTask from '../ui/Task/TimerTask/TimerTask';
import { getActiveClientDetail } from '@/store/slices/clientSlice';
import clsxm from '@/utils/clsxm';

export default function Header(props) {
  const dispatch = useDispatch();
  const runningTask = useSelector((state) => state.task.runningTask);
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const { isLoaded } = props;
  const [activeClient, setActiveClient] = useState(useSelector((state) => state.client.activeClient) || '');
  const [activeSite, setActiveSite] = useState(useSelector((state) => state.content.activeSite) || '');
  const [aCS, setACS] = useState([]);
  const [activeBusinessUnit, setActiveBusinessUnit] = useState(useSelector((state) => state.acc.activeBusinessUnit) || '');
  const [aCB, setACB] = useState([]);
  const [anchorAvatar, setAnchorAvatar] = useState(null);
  // tempSolution
  const [isInitialize, setIsInitialize] = useState({
    site: false,
    business: false,
  });

  const user = useSelector((state) => state.auth.user);
  const clientSites = useSelector((state) => state.client.clientSites);
  const activeDetailClient = useSelector((state) => state.client.activeDetailClient);
  const clientList = useSelector((state) => state.client.clientList) || [];
  const clientSiteLanguages = useSelector((state) => state.client.clientSiteLanguages);
  const activeAdminLanguage = useSelector((state) => state.client.activeAdminLanguage);
  const clientBusinessUnit = useSelector((state) => state.client.clientBusinessUnit);
  const isSuperUser = useSelector((state) => state.auth.isSuperUser);
  const isGroups = useSelector((state) => state.auth.isGroups);
  const key = useSelector((state) => state.auth.key);
  const clientSubscriptions = useSelector((state) => state.client.clientSubscriptions || []);

  const isSSO = JSON.parse(localStorage.getItem('isSSO'));
  // const isSSO = true;

  const handleLogout = () => {
    const { signout } = authApi();
    const { signoutSSO } = authSSOApi();
    if (!isSSO) {
      signout(key)
        .then((res) => {
          store.dispatch({ type: 'auth/setLogout' });
          store.dispatch({ type: 'client/setActiveClient', payload: false });
          store.dispatch({ type: 'lang/setLanguageList', payload: false });
          store.dispatch({ type: 'acc/activeBusinessUnit', payload: false });
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      signoutSSO(key)
        .then((res) => {
          store.dispatch({ type: 'auth/setLogout' });
          store.dispatch({ type: 'client/setActiveClient', payload: false });
          store.dispatch({ type: 'lang/setLanguageList', payload: false });
          store.dispatch({ type: 'acc/activeBusinessUnit', payload: false });
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  const handleActiveSite = useCallback(
    (site) => {
      setActiveSite(site);
      store.dispatch({ type: 'content/setActiveSite', payload: site });
      dispatch(getActiveSiteDetail(site));
    },
    [dispatch]
  );

  const handleActiveClient = (id) => {
    // dispatch(resetFilter());
    // localStorage.removeItem('filterComponent');
    dispatch(getActiveClientDetail(id));
    store.dispatch({ type: 'client/setActiveClient', payload: id });
    store.dispatch({ type: 'crmFilter/resetState' });
    setActiveClient(id);
  };

  useEffect(() => {
    if (clientSites && clientSites.length > 0 && !isInitialize.site) {
      const currStat = isInitialize;
      currStat.site = true;
      setIsInitialize(currStat);
      const listCs = clientSites.map((cs) => cs.id);
      const acs = listCs.includes(Number(activeSite)) ? activeSite : '';
      if (!acs) {
        handleActiveSite(clientSites[0].id);
      }
      setACS(acs);
    }
  }, [clientSites, activeSite, handleActiveSite, isInitialize]);

  const handleActiveBusinessUnit = useCallback(
    (unit) => {
      setActiveBusinessUnit(unit);
      store.dispatch({ type: 'acc/setActiveBusinessUnit', payload: unit });
      dispatch(getActiveBusinessUnitDetail(unit));
    },
    [dispatch]
  );

  useEffect(() => {
    if (clientBusinessUnit && clientBusinessUnit.length > 0 && !isInitialize.business) {
      const currStat = isInitialize;
      currStat.business = true;
      setIsInitialize(currStat);
      const listCb = clientBusinessUnit.map((cb) => cb.id);
      const acb = listCb.includes(Number(activeBusinessUnit)) ? activeBusinessUnit : '';
      if (!acb) {
        handleActiveBusinessUnit(clientBusinessUnit[0].id);
      }
      setACB(acb);
    } else if (clientBusinessUnit && !clientBusinessUnit.length && !isInitialize.business) {
      store.dispatch({ type: 'acc/setActiveBusinessUnit', payload: false });
      store.dispatch({ type: 'acc/activeBusinessUnitDetail', payload: false });
    }
  }, [clientBusinessUnit, activeBusinessUnit, handleActiveBusinessUnit, isInitialize]);

  const handleClickAvatar = (event) => {
    setAnchorAvatar(event.currentTarget);
  };

  const openAvatar = Boolean(anchorAvatar);
  const idAvatar = openAvatar ? 'simple-popover' : undefined;
  const navItem = (
    <Popover
      id={idAvatar}
      open={openAvatar}
      anchorEl={anchorAvatar}
      onClose={() => setAnchorAvatar(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className="mt-2"
    >
      <Fade in={openAvatar}>
        <Box style={{ minWidth: '250px' }} className="flex relative flex-col py-4 space-y-2 rounded-lg bg-[#ffffff]">
          <div className="pl-6 pr-3">
            <Link to={`/user/detail/${user.id}`} onClick={() => setAnchorAvatar(null)}>
              <Button variant="text" className="flex flex-col justify-start items-start text-left capitalize text-base">
                <span>User Profile</span>
              </Button>
            </Link>
          </div>
          <div style={{ borderBottom: 'var(--border)', borderWidth: 1 }} />
          <div className="pl-8 pr-3 text-base cursor-pointer" onClick={handleLogout} aria-hidden="true">
            Logout
          </div>
        </Box>
      </Fade>
    </Popover>
  );

  let acs = '';
  if (clientSites && clientSites.length) {
    if (clientSites.some((item) => item.id === Number(aCS))) {
      acs = aCS;
    }
  }
  let acb = '';
  if (clientBusinessUnit && clientBusinessUnit.length) {
    if (clientBusinessUnit.some((item) => item.id === Number(aCB))) {
      acb = aCB;
    }
  }

  return (
    <div className={[styles.header, 'relative flex px-4 shadow border-b border-[#F4F1EC] bdg-[#F4F1EC]'].join(' ')}>
      <div className={clsxm('flex items-center w-[260px] space-x-2')}>
        {isSuperUser ? (
          <Link to="/superuser">
            {activeDetailClient?.logo ? <Avatar sx={{ width: 32, height: 32 }} alt="" src={activeDetailClient.logo} /> : <AccountCircle className="mr-2" />}
          </Link>
        ) : (
          <Avatar sx={{ width: 32, height: 32 }} alt="" src={activeDetailClient.logo} />
        )}
        {isSuperUser ? (
          <FormControl sx={{ width: '100%' }}>
            <Select sx={{ width: '100%', px: 0.2, py: 0.2 }} value={activeClient || ''} onChange={(e) => handleActiveClient(e.target.value)} displayEmpty className={styles.select}>
              <MenuItem disabled value="">
                <em>Client</em>
              </MenuItem>
              {clientList?.map((cl) => (
                <MenuItem key={cl.name} value={cl.id}>
                  {cl.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <div className="font-black text-xl">{activeDetailClient?.name}</div>
        )}
      </div>
      <div className="ml-auto flex space-x-3 items-center">
        {isLoaded ? (
          <Skeleton className="w-20 h-12" />
        ) : (
          <>
            <div className="flex flex-row space-x-4">
              {clientSubscriptions?.includes(1) && (
                <FormControl>
                  <Select value={activeSite} sx={{ minWidth: 192, width: '100%', px: 0.2, py: 0.2 }} onChange={(e) => handleActiveSite(e.target.value)} displayEmpty>
                    <MenuItem disabled value="">
                      <em>Site</em>
                    </MenuItem>
                    {clientSites &&
                      clientSites.length > 0 &&
                      clientSites?.map((cl) => (
                        <MenuItem key={cl.name} value={cl.id}>
                          {cl.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
              {/* {(isSuperUser || (!isSuperUser && isGroups?.includes(13))) && (
                <FormControl>
                  <Select sx={{ minWidth: 192, width: '100%', px: 0.2, py: 0.2 }} value={acb} onChange={(e) => handleActiveBusinessUnit(e.target.value)} displayEmpty>
                    <MenuItem disabled value="">
                      <em>Business unit</em>
                    </MenuItem>
                    {clientBusinessUnit &&
                      clientBusinessUnit.length > 0 &&
                      clientBusinessUnit?.map((cl) => (
                        <MenuItem key={cl.code} value={cl.id}>
                          {cl.code}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )} */}
            </div>
            <div className="flex space-x-2">
              {clientSiteLanguages.length > 0 &&
                clientSiteLanguages.map((lang) => {
                  let active;
                  if (lang.id === activeAdminLanguage.id) {
                    active = styles.activeLanguage;
                  }
                  return (
                    <button
                      key={lang.flag}
                      type="button"
                      className={[styles.lang, active].join(' ')}
                      onClick={() =>
                        store.dispatch({
                          type: 'client/setActiveAdminLanguage',
                          payload: lang,
                        })
                      }
                    >
                      <ReactCountryFlag
                        countryCode={lang.flag}
                        svg
                        style={{
                          width: '24px',
                          height: '100%',
                        }}
                      />
                    </button>
                  );
                })}
            </div>
            <div className="flex items-center space-x-[6px] p-[3px] rounded-[8px] bg-[#F4F1EC]">
              <div>
                {user?.avatar ? (
                  <div className="ml-1 relative  rounded-full  drop-shadow-2xl">
                    <Avatar src={user?.avatar} className="w-8 h-8" />
                  </div>
                ) : (
                  <div className="ml-1 relative px-1 py-1 rounded-full bg-white drop-shadow-2xl cursor-pointer">
                    <Avatar className="w-8 h-8 drop-shadow-2xl">{user?.name?.substring(0, 1)}</Avatar>
                    {/* <div className={styles.iconSettings} onClick={handleClickAvatar} aria-hidden="true">
                      <Settings />
                    </div>
                    {navItem} */}
                  </div>
                )}
              </div>
              <div className="flex ">
                {!runningTask && <span>{user?.name}</span>}
                {runningTask && (
                  <TimerTask type={2}>
                    <span>{user?.name}</span>
                  </TimerTask>
                )}
                <div className="" onClick={handleClickAvatar} aria-hidden="true">
                  <KeyboardArrowDown />
                </div>
                {navItem}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
