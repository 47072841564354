/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Checkbox, Divider, Fade, FormControl, FormControlLabel, Input, Modal, Stack, TextField, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { Search } from '@mui/icons-material';
import { InputBase } from '@mui/material';
import _ from 'lodash';
import { useDebounce } from 'use-debounce';
import CRMApi from '../../../api/CRM/CRMCustomers';
import { handleModal } from '../../../store/slices/modalSlice';
import ModalWrapper from '../../ui/ModalWrapper';
import AddNewLocation from './AddNewLocation';
import swal from 'sweetalert';
import store from '@/store';

const lib = ['drawing', 'places'];

function LocationPair({ mode, submit, reload, StateLocations, StateMainLocation, SetStateLocations, SetStateMainLocation }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCKqMs4hmNgoQtmIUsd7diBKHj6UV610Jg',
    libraries: lib,
  });

  const isFromCrmProject = mode && mode === 'crmProject';
  const activeClient = useSelector((state) => state.modal.modalId);
  const memberId = useSelector((state) => state.modal.modalData);
  const stateLocationPair = useSelector((state) => state.CrmFilter.locationPair);
  const dispatch = useDispatch();
  
  const [selectedMainLocation, SetSelectedMainLocation] = useState(stateLocationPair.mainLocation || null);
  const [selectedLocation, SetSelectedLocation] = useState(stateLocationPair.selectedLocation || []);
  const [searchLocation, SetSearchLocation] = useState("");
  const [locations, SetLocations] = useState([]);
  const [picklocations, SetPickLocations] = useState([]);
  const [searchValue] = useDebounce(searchLocation, 300);

  console.log({StateLocations});
  console.log({mode});
  
  const { getAllLocations, bulkUpdateLocation } = CRMApi();

  const getLocationsData = useCallback(async () => {
    try {
      const { data: { results: locationsData } } = await getAllLocations({ client: activeClient, max_size: true });
  
      const memberIdInt = parseInt(memberId);
      const isCrmProject = isFromCrmProject;

      const { selectedLocation, mainLocation } = stateLocationPair;
      
      const filteredLocations = locationsData
      .filter(location => location?.member === memberIdInt || selectedLocation.some(sel => sel === location.id));
      
      const selectedLocations = isCrmProject ? null : filteredLocations;
      const selectedLocationsId = isCrmProject ? null :
        filteredLocations
          .map((location) => {
            if (location.is_main) {
              SetSelectedMainLocation(mainLocation ? mainLocation : location.id);
            }
            return location.id;
          });
  
      const selectedLocationsForAddMode = locationsData
        .filter((data) => StateLocations.includes(data?.id) || selectedLocation.includes(data.id));
        
      if (!mode) {
        SetSelectedLocation(selectedLocationsId);
        SetPickLocations(selectedLocations);
      } else {
        SetPickLocations(selectedLocationsForAddMode);
        SetSelectedLocation([...StateLocations, ...selectedLocation]);
        SetSelectedMainLocation(mainLocation ? mainLocation : StateMainLocation);
      }
    } catch (error) {
      console.error(error);
    }
  }, [stateLocationPair, mode, isFromCrmProject]);

  // const getLocationsData = useCallback(async () => {
  //   try {
  //     const fetchData = await getAllLocations({ client: activeClient, max_size: true });
  //     const response = fetchData.data;
  //     const locationsData = response.results;
  //     const selectedLocationsId = !isFromCrmProject ? locationsData
  //       .filter((result) => !result.member || result.member === parseInt(memberId))
  //       .filter((location) => location.member === parseInt(memberId))
  //       .map((location) => {
  //         if (location.is_main) {
  //           SetSelectedMainLocation(location.id);
  //         }
  //         return location.id;
  //       }) : null        
        
  //       const selectedLocations = !isFromCrmProject ? locationsData
  //       .filter((result) => !result.member || result.member === parseInt(memberId))
  //       .filter((location) => location.member === parseInt(memberId))
  //       .map((location) => {
  //         return location;
  //       }) : null
        
  //       const selectedLocationsForAddMode = locationsData
  //       .filter((data) => StateLocations.includes(data?.id))

  //     if (!mode) {
  //       SetSelectedLocation(selectedLocationsId);
  //       SetPickLocations(selectedLocations);
  //     } else {
  //       SetPickLocations(selectedLocationsForAddMode)
  //       SetSelectedLocation(StateLocations);
  //       SetSelectedMainLocation(StateMainLocation);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, []);

  useEffect(() => {
    getLocationsData();
  }, []);

  const getAllLocation = useCallback(async () => {
    try {
      const fetchData = await getAllLocations({ client: activeClient, search: searchValue, max_size: true });
      const response = fetchData.data;
      const locationsData = response.results;

      SetLocations(locationsData);
    } catch (error) {
      console.error(error);
    }
  }, [searchValue]);

  useEffect(() => {
    getAllLocation()
  }, [searchValue]);

  const handleSelectedLocations = (value, locationId, locationData) => {
    let newSelectedLocations = new Set(selectedLocation);
    let pickLocationsClone = _.cloneDeep(picklocations)

    if (value === true) {
      if (!isFromCrmProject) {
        newSelectedLocations.add(locationId);
        pickLocationsClone.push(locationData);
      } else {
        newSelectedLocations = [locationId];
        pickLocationsClone = [locationData];
        SetSelectedMainLocation(locationId);
      }
    } else {
      newSelectedLocations.delete(locationId);
      pickLocationsClone = pickLocationsClone.filter((data) => data.id !== locationId);

      if (selectedMainLocation === locationId) {
        SetSelectedMainLocation(null)
      }
    }

    SetPickLocations([...pickLocationsClone]);
    SetSelectedLocation([...newSelectedLocations]);

    store.dispatch({
      type: 'crmFilter/setLocationPair',
      payload: {
        selectedLocation: [...newSelectedLocations],
        mainLocation: selectedMainLocation,
      },
    });
  };

  const handleSelectedMainLocation = (value, locationId) => {
    SetSelectedMainLocation(locationId);
    store.dispatch({ type: 'crmFilter/setLocationPair', payload: {selectedLocation:[...selectedLocation], mainLocation:  locationId} });
  };

  const submitData = () => {
    if (!mode) {
      const unselectedLocations = locations
        .filter((location) => location.member === parseInt(memberId) && !selectedLocation.includes(location.id))
        .map((location) => {
          return {
            member: null,
            id: location.id,
            is_main: false,
          };
        });

      const data = selectedLocation.map((selected) => {
        return {
          member: memberId,
          id: selected,
          is_main: selectedMainLocation === selected,
        };
      });

      if (selectedLocation.includes(selectedMainLocation)) {
        bulkUpdateLocation([...data, ...unselectedLocations])
          .then(() => {
            SetStateLocations(selectedLocation);
            SetStateMainLocation(selectedMainLocation);
            swal('Success !', 'You successfully set locations !', 'success');
            store.dispatch({ type: 'crmFilter/resetLocationPair' });
            dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
            reload(true);
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        swal('Error !', 'Please select one of the selected location as main location !', 'error');
      }
    } else {      
      if (selectedLocation.includes(selectedMainLocation)) {
        SetStateLocations(selectedLocation);
        SetStateMainLocation(selectedMainLocation);
        store.dispatch({ type: 'crmFilter/resetLocationPair' });
        dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
        reload(true);
        if (mode === 'crmProject') {
          const objLocation = locations.find((l) => l.id === selectedMainLocation)
          submit(objLocation)
        }
      } else {
        swal('Error !', 'Please select one of the selected location as main location !', 'error');
      }
    }
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const InputLabel = ({ children }) => <p className="font-bold">{children}</p>;

  return (
    isLoaded && (
      <div className="w-full flex space-x-5 relative">
        <div className="w-[70%]">
          <div className="grid grid-cols-2 gap-6 pb-2">
            {locations?.map((el) => {
              let locs = el.name;
              let { address } = el;

              const center = {
                lat: parseFloat(el?.latitude),
                lng: parseFloat(el?.longitude),
              };

              const langId = parseInt(localStorage.getItem('langId'), 10);
              el.location_languages.forEach((locationLanguage) => {
                if (locationLanguage.id === langId) {
                  locs = locationLanguage.name;
                  address = locationLanguage.address;
                }
              });

              return (
                <div key={el.id} className="w-full border rounded-md shadow-sm">
                  <div className="flex justify-between bg-[#2C6D47] p-2 rounded-t-md">
                    <div className="text-white">
                      <input
                        type="checkbox"
                        id={`location-pair-${el.id}`}
                        checked={selectedLocation.includes(el.id)}
                        onChange={(e) => {
                          handleSelectedLocations(e.target.checked, el.id, el);
                        }}
                      />
                      <label htmlFor={`location-pair-${el.id}`}>{el.name}</label>
                    </div>
                    {/* <div className="text-white">
                      <input
                        type="radio"
                        id={`location-pair-${el.id}`}
                        checked={selectedMainLocation === el.id}
                        onChange={(e) => {
                          handleSelectedMainLocation(e.target.checked, el.id);
                        }}
                      />
                      &nbsp;
                      <label htmlFor={`location-pair-${el.id}`}>Main location</label>
                    </div> */}
                  </div>
                  <div className="flex gap-x-2 p-2 min-h-[150px]">
                    <div className="w-[40%] max-h-[150px]">
                      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14}>
                        <Marker position={center} />
                      </GoogleMap>
                    </div>
                    <div className="w-[60%] space-y-2">
                      <p className="link font-bold uppercase">{locs}</p>
                      <p className="add">{address}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-[30%] space-y-5">
          <div className="w-full">
            <div>
              <InputLabel>Search Location</InputLabel>
            </div>
            <InputBase
              size="small"
              placeholder="search"
              endAdornment={<Search color="disabled" />}
              onChange={(v) => SetSearchLocation(v.target.value)}
              value={searchLocation}
              className="w-full h-[2.5rem] border border-[#BCB9B9] rounded px-4 bg-white hover:border-[#adacac]"
            />
          </div>
          <div className="w-full flex justify-end gap-x-3 pb-4 border-b">
            <button
              className="flex border-[#2C6D47] text-[#2C6D47] bg-white h-[40px] text-sm font-semibold gap-x-2 items-center text-center btn btn-primary rounded-xl border-[2px] px-4"
              type="submit"
              onClick={
                submit
                // dispatch(handleModal({ componentName: '' }));
                // dispatch(handleModal({ modalId: activeClient, componentName: 'addNewLocation', modalData: memberId }));
              }
            >
              Create New Location
            </button>
            <button className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white" variant="contained" onClick={submitData}>
              Submit
            </button>
          </div>
          <div className="space-y-3 px-2">
            {picklocations?.map((el) => {
              let locs = el.name;
              let { address } = el;

              const center = {
                lat: parseFloat(el?.latitude),
                lng: parseFloat(el?.longitude),
              };

              const langId = parseInt(localStorage.getItem('langId'), 10);
              el.location_languages.forEach((locationLanguage) => {
                if (locationLanguage.id === langId) {
                  locs = locationLanguage.name;
                  address = locationLanguage.address;
                }
              });

              return (
                <div key={el.id} className="w-full border rounded-md shadow-sm">
                  <div className="flex justify-between bg-[#2C6D47] p-2 rounded-t-md">
                    <div className="text-white">
                      <input
                        type="checkbox"
                        id={`location-pair-${el.id}`}
                        checked={selectedLocation.includes(el.id)}
                        onChange={(e) => {
                          handleSelectedLocations(e.target.checked, el.id);
                        }}
                      />
                      <label htmlFor={`location-pair-${el.id}`}>{el.name}</label>
                    </div>
                    <div className="text-white">
                      <input
                        type="radio"
                        id={`location-pair-${el.id}`}
                        checked={selectedMainLocation === el.id}
                        onChange={(e) => {
                          handleSelectedMainLocation(e.target.checked, el.id);
                        }}
                        disabled={!!isFromCrmProject}
                      />
                      <label htmlFor={`location-pair-${el.id}`}>Main location</label>
                    </div>
                  </div>
                  <div className="flex gap-x-2 p-2 min-h-[150px]">
                    <div className="w-[40%] max-h-[150px]">
                      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14}>
                        <Marker position={center} />
                      </GoogleMap>
                    </div>
                    <div className="w-[60%] space-y-2">
                      <p className="link font-bold uppercase">{locs}</p>
                      <p className="add">{address}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <ModalWrapper componentName="addNewLocation" header="New Location" maxWidth="sm" backToModal={true}>
          <AddNewLocation />
        </ModalWrapper> */}
      </div>
    )
  );
}

export default LocationPair;
