import axiosInstances from '../../helper/axiosInstances';

const baseURL = import.meta.env.VITE_API_URL;

export default function project() {
  const { axiosInstance, axiosInstanceF } = axiosInstances();

  async function getReportTask(data) {
    const method = 'post';
    const url = `${baseURL}project-management/assignee_logs/get_report/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }
  async function getProjectTasks(params) {
    const method = 'get';
    const url = `${baseURL}project-management/projects/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }
  async function deleteProjectTask(idProject) {
    const method = 'delete';
    const url = `${baseURL}project-management/projects/${idProject}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function getTaskProject(projectId, params) {
    const method = 'get';
    const url = `${baseURL}project-management/projects/${projectId}/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function updateTaskProject(projectId, dataProject) {
    const method = 'patch';
    const url = `${baseURL}project-management/projects/${projectId}/`;

    let data = dataProject;
    if (dataProject.icon) {
      data = new FormData();

      data.append('icon', dataProject.icon);
    }

    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function addProjectSection(data) {
    const method = 'post';
    const url = `${baseURL}project-management/sections/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteProjectSection(sectionId) {
    const method = 'delete';
    const url = `${baseURL}project-management/sections/${sectionId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }
  async function updateProjectSection(sectionId, data) {
    const method = 'patch';
    const url = `${baseURL}project-management/sections/${sectionId}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function addSectionTopic(data) {
    const method = 'post';
    const url = `${baseURL}project-management/topics/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function updateSectionTopic(idTopic, data) {
    const method = 'patch';
    const url = `${baseURL}project-management/topics/${idTopic}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteSectionTopic(idTopic) {
    const method = 'delete';
    const url = `${baseURL}project-management/topics/${idTopic}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function getTopicDetail(topicId) {
    const method = 'get';
    const url = `${baseURL}project-management/topics/${topicId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function getTopics(params) {
    const method = 'get';
    const url = `${baseURL}project-management/topics/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function reorderProjectSection(idProject, data) {
    const method = 'put';
    const url = `${baseURL}project-management/projects/${idProject}/update_section_indexes/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function reorderSectionTopic(idSection, data) {
    const method = 'put';
    const url = `${baseURL}project-management/sections/${idSection}/update_topic_indexes/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function getLabels(params) {
    const method = 'get';
    const url = `${baseURL}project-management/labels/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addLabel(data) {
    const method = 'post';
    const url = `${baseURL}project-management/labels/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function updateLabel(idLabel, data) {
    const method = 'patch';
    const url = `${baseURL}project-management/labels/${idLabel}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteLabel(idLabel) {
    const method = 'delete';
    const url = `${baseURL}project-management/labels/${idLabel}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function createComments(comment) {
    const data = new FormData();

    data.append('topic', comment.topic);
    data.append('creator', comment.creator);
    data.append('detail', comment.detail);

    if (comment.attachments && comment.attachments.length) {
      comment.attachments.forEach((file, index) => {
        data.append(`attachments[${index}]file_attached`, file);
      });
    }

    const method = 'post';
    const url = `${baseURL}project-management/comments/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteTopicComment(idComment) {
    const method = 'delete';
    const url = `${baseURL}project-management/comments/${idComment}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function updateTopicComment(idComment, data) {
    const method = 'patch';
    const url = `${baseURL}project-management/comments/${idComment}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteAttachment(idAttachment) {
    const method = 'delete';
    const url = `${baseURL}project-management/attachments/${idAttachment}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function getTopicTypes(params) {
    const method = 'get';
    const url = `${baseURL}project-management/topic_types/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }
  async function addTopicType(dt) {
    const data = new FormData();

    data.append('project', dt.project);
    data.append('name', dt.name);
    data.append('color', dt.color);
    if (dt.file) {
      data.append('icon', dt.file);
    }

    const method = 'post';
    const url = `${baseURL}project-management/topic_types/`;
    const response = await axiosInstanceF({
      method,
      url,
      data,
    });
    return response;
  }

  async function updateTopicType(idTopic, dt) {
    const data = new FormData();

    if (dt.project) {
      data.append('project', dt.project);
    }
    data.append('name', dt.name);
    data.append('color', dt.color);
    if (dt.file) {
      data.append('icon', dt.file);
    }

    const method = 'patch';
    const url = `${baseURL}project-management/topic_types/${idTopic}/`;
    const response = await axiosInstanceF({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteTopicType(idTopic) {
    const method = 'delete';
    const url = `${baseURL}project-management/topic_types/${idTopic}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function getTopicStatuses(params) {
    const method = 'get';
    const url = `${baseURL}project-management/topic_statuses/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }
  async function addTopicStatus(dt) {
    const data = new FormData();

    data.append('project', dt.project);
    data.append('name', dt.name);
    if (dt.file) {
      data.append('icon', dt.file);
    }

    const method = 'post';
    const url = `${baseURL}project-management/topic_statuses/`;
    const response = await axiosInstanceF({
      method,
      url,
      data,
    });
    return response;
  }

  async function updateTopicStatus(idTopic, dt) {
    const data = new FormData();

    if (dt.project) {
      data.append('project', dt.project);
    }
    data.append('name', dt.name);
    if (dt.file) {
      data.append('icon', dt.file);
    }

    const method = 'patch';
    const url = `${baseURL}project-management/topic_statuses/${idTopic}/`;
    const response = await axiosInstanceF({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteTopicStatus(idTopic) {
    const method = 'delete';
    const url = `${baseURL}project-management/topic_statuses/${idTopic}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function getTopicPriorities(params) {
    const method = 'get';
    const url = `${baseURL}project-management/topic_priorities/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }
  async function addTopicPriority(dt) {
    const data = new FormData();

    data.append('project', dt.project);
    data.append('name', dt.name);
    if (dt.file) {
      data.append('icon', dt.file);
    }

    const method = 'post';
    const url = `${baseURL}project-management/topic_priorities/`;
    const response = await axiosInstanceF({
      method,
      url,
      data,
    });
    return response;
  }

  async function updateTopicPriority(idTopic, dt) {
    const data = new FormData();

    if (dt.project) {
      data.append('project', dt.project);
    }
    data.append('name', dt.name);
    data.append('index', dt.index);
    if (dt.file) {
      data.append('icon', dt.file);
    }

    const method = 'patch';
    const url = `${baseURL}project-management/topic_priorities/${idTopic}/`;
    const response = await axiosInstanceF({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteTopicPriority(idTopic) {
    const method = 'delete';
    const url = `${baseURL}project-management/topic_priorities/${idTopic}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function duplicateTopic(idTopic, dt) {
    const data = new FormData();

    if (dt.comments) {
      data.append('include_comments', true);
    }

    const method = 'post';
    const url = `${baseURL}project-management/topics/${idTopic}/duplicate/`;
    const response = await axiosInstanceF({
      method,
      url,
      data,
    });
    return response;
  }

  async function getTopicSections(params) {
    const method = 'get';
    const url = `${baseURL}project-management/sections/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function logWorkHours(data, id, params, action = 'post') {
    const method = action;
    let url = `${baseURL}project-management/assignee_logs/`;
    if (action === 'patch') {
      url = `${baseURL}project-management/assignee_logs/${id}/`;
    }
    const response = await axiosInstance({
      method,
      url,
      data,
      params,
    });
    return response;
  }

  async function deleteLogTime(id) {
    const method = 'delete';
    let url = `${baseURL}project-management/assignee_logs/${id}`;

    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  return {
    getReportTask,
    getProjectTasks,
    deleteProjectTask,
    getTaskProject,
    updateTaskProject,
    addProjectSection,
    deleteProjectSection,
    updateProjectSection,
    addSectionTopic,
    updateSectionTopic,
    deleteSectionTopic,
    getTopicDetail,
    getTopics,
    reorderProjectSection,
    reorderSectionTopic,
    getLabels,
    addLabel,
    updateLabel,
    deleteLabel,
    createComments,
    deleteTopicComment,
    updateTopicComment,
    deleteAttachment,
    getTopicTypes,
    addTopicType,
    updateTopicType,
    deleteTopicType,
    getTopicStatuses,
    addTopicStatus,
    updateTopicStatus,
    deleteTopicStatus,
    getTopicPriorities,
    addTopicPriority,
    updateTopicPriority,
    deleteTopicPriority,
    duplicateTopic,
    getTopicSections,
    logWorkHours,
    deleteLogTime,
  };
}
