/* eslint-disable */

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

import { Tabs, Tab, Box, styled, Button, FormControlLabel, Checkbox } from '@mui/material';
import EmailEditor from 'react-email-editor';

import { Clear, Add } from '@mui/icons-material';

import { createEmailContent, fetchEmailContent, updateEmailContent, deleteEmailContent } from '../../hooks/useSettingsBzData';
import InputContainer from '../../components/Input/InputContainer';

import ModalWrapper from '../../components/ui/ModalWrapper';
import { handleModal } from '../../store/slices/modalSlice';

import DEFAULT_EMAIL_DESIGN from '../../utils/Settings/EmailDesign/DefaultDesignJson';
import BZHelmet from '../../utils/BZHelmet';

const StyledTabs = styled((props) => <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />)({});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(() => ({}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div className=" " role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 0 }} className="">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Email() {
  const [emailContentData, setEmailContentData] = useState([]);

  const [templateDesign, setTemplateDesign] = useState('');
  const [templateBody, setTemplateBody] = useState('');
  const [valueTab, setValueTab] = useState(0);
  const [valueTabAdd, setValueTabAdd] = useState(0);
  const { control, handleSubmit, setValue, register, watch, getValues, unregister } = useForm();

  const clientId = useSelector((state) => state.client.activeClient);
  const siteId = useSelector((state) => state.content.activeSite);
  const clientLanguages = useSelector((state) => state.content.activeSiteDetail.site_languages);

  const emailEditorRef = useRef(null);

  const qClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const {
    isFetching,
    isFetched,
    data: dataCont,
  } = useQuery(['emailContent', { clientId, siteId }], fetchEmailContent, {
    onSuccess: (e) => {
      setEmailContentData(e.data.results);
    },
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: patchEmailContent } = useMutation(updateEmailContent, {
    onSuccess: (e) => {
      qClient.invalidateQueries('emailContent');
      if (e.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
    },
  });

  const { mutateAsync: postMenu } = useMutation(createEmailContent, {
    onSuccess: (e) => {
      qClient.invalidateQueries('menus');
      dispatch(handleModal({ modalId: '' }));
      if (e.request.status === 201) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
    },
  });

  const { mutateAsync: deleteContext } = useMutation(deleteEmailContent, {
    onSuccess: (e) => {
      if (e.request.status === 204) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
    },
  });

  const onDesignLoad = useCallback(() => {
    const contentDesign = dataCont?.data?.results[valueTab]?.content_languages[valueTabAdd]?.content_design;
    const templateJson = contentDesign ? JSON.parse(contentDesign) : JSON.parse(DEFAULT_EMAIL_DESIGN);
    if (emailEditorRef.current?.editor && !isFetching && isFetched) {
      emailEditorRef.current.editor.loadDesign(templateJson);
    }
  }, [dataCont?.data?.results, valueTab, valueTabAdd, isFetching, isFetched]);

  const handleUpdateDesign = () => {
    emailEditorRef.current?.editor.exportHtml(({ design, html }) => {
      setTemplateDesign(JSON.stringify(design));
      setTemplateBody(html);
    });
  };

  const onLoad = () => {
    if (emailEditorRef.current?.editor) {
      emailEditorRef.current.editor.addEventListener('design:updated', handleUpdateDesign);

      onDesignLoad();

      emailContentData.forEach((emailContent) => {
        emailContent.content_languages.forEach((contentLanguage, idx) => {
          setValue(`subject-${emailContent.id}-${idx}`, contentLanguage.subject);
        });
      });
    } else {
      setTimeout(() => {
        onLoad();
      }, 1000);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };
  const handleChangeTabAdd = (event, newValue) => {
    setValueTabAdd(newValue);
  };

  const onPostContext = async (data) => {
    const contentLanguages = clientLanguages.map((clientLanguage) => ({
      body: '<html></html>',
      content_design: '',
      language: clientLanguage.id,
      subject: '-',
    }));
    await postMenu({ client: clientId, context: data.context, from_email: data.from_email, content_languages: contentLanguages, site: siteId });
  };

  const onPatchEmailContent = async (data) => {
    const { id } = emailContentData[valueTab];
    const recipients = data[`recipients-${id}`].filter((email) => email !== null);
    const fromEmail = data[`from_email-${id}`];
    const recipientParty = data[`recipient_party-${id}`];
    const contentLanguages = _.cloneDeep(emailContentData[valueTab]?.content_languages);
    contentLanguages.forEach((contentLanguage, idx) => {
      contentLanguages[idx].subject = data[`subject-${id}-${idx}`];
    });
    const hasDynamicRecipient = data[`has_dynamic_recipient-${id}`];

    const bodyPatch = {
      from_email: fromEmail,
      recipient_party: recipientParty,
      recipients,
      has_dynamic_recipient: hasDynamicRecipient,
      content_languages: contentLanguages,
    };
    await patchEmailContent({ id, ...bodyPatch });
  };

  const onDeleteEmailContent = async () => {
    const { id } = emailContentData[valueTab];
    await deleteContext(id);
  };

  const getHasDynamicRecipient = (id) => {
    return watch(`has_dynamic_recipient-${id}`);
  };

  const getRecipients = (emailId) => {
    return watch(`recipients-${emailId}`);
  };

  useEffect(() => {
    emailContentData.forEach((emailContent) => {
      setValue(`recipients-${emailContent.id}`, emailContent.recipients);
    });
  }, [emailContentData, setValue]);

  useEffect(() => {
    if (!isFetching) {
      onDesignLoad();
    }
  }, [onDesignLoad, isFetching]);

  useEffect(() => {
    if (templateDesign && !isFetching) {
      setEmailContentData((oldEmailContentData) => {
        const newEmailContentData = _.cloneDeep(oldEmailContentData);
        newEmailContentData[valueTab].content_languages[valueTabAdd].content_design = templateDesign;
        return newEmailContentData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateDesign, isFetching]);

  useEffect(() => {
    if (templateBody && !isFetching) {
      setEmailContentData((oldEmailContentData) => {
        const newEmailContentData = _.cloneDeep(oldEmailContentData);
        newEmailContentData[valueTab].content_languages[valueTabAdd].body = templateBody;
        return newEmailContentData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateBody, isFetching]);

  return (
    <>
      <BZHelmet title="Emails" description="bz publish email settings" />
      <div className="p-5 ">
        <div className="bg-white border p-5 shadow rounded">
          <form onSubmit={handleSubmit(onPatchEmailContent)}>
            <div className="flex items-center">
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="mt-5">
                <StyledTabs className="bz-tabs" value={valueTab} onChange={handleChangeTab} aria-label="basic tabs example">
                  {emailContentData?.map((email, index) => (
                    <StyledTab key={email.id} className="capitalize text-lg" label={email.context} {...a11yProps(index)} />
                  ))}
                </StyledTabs>
              </Box>
              {emailContentData.length > 0 ? (
                <button className="" title="Add context" type="button" onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addEmailContent' }))}>
                  <Add className="ml-5 rounded border-2 text-bz-greenLight" />
                </button>
              ) : (
                <button
                  className="flex gap-x-3 items-center"
                  title="Add context"
                  type="button"
                  onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addEmailContent' }))}
                >
                  <Add className="ml-5 rounded border-2 text-bz-greenLight" />
                  <div>Add Context</div>
                </button>
              )}
            </div>
            {emailContentData?.map((email, index) => (
              <TabPanel key={email.id} value={valueTab} index={index}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="mt-5">
                  <StyledTabs className="bz-tabs" value={valueTabAdd} onChange={handleChangeTabAdd} aria-label="basic tabs example">
                    {clientLanguages?.map((lang, idxClientLanguage) => (
                      <StyledTab key={lang.language.id} className="capitalize text-lg" label={lang.language.name} {...a11yProps(idxClientLanguage)} />
                    ))}
                  </StyledTabs>
                </Box>
                <div className=" ">
                  <InputContainer name={`from_email-${email.id}`} control={control} label="From" defaultValue={email.from_email} />
                  <InputContainer
                    name={`recipient_party-${email.id}`}
                    control={control}
                    label="Recipient Party"
                    defaultValue={email.recipient_party}
                    // errors={errors.summary}
                  />

                  <FormControlLabel
                    control={<Checkbox {...register(`has_dynamic_recipient-${email.id}`)} defaultChecked={email.has_dynamic_recipient} />}
                    label="Dynamic recipient"
                  />
                  {!getHasDynamicRecipient(email.id) && (
                    <div className="flex flex-col gap-2">
                      <div className="">Recipients</div>
                      <div className="flex">
                        <ul className="flex flex-wrap items-center gap-x-5">
                          {getRecipients(email.id)?.map((recipient, idx) => (
                            <li className="flex relative " key={`recipient-${email.id}-${idx + 1}`}>
                              <InputContainer name={`recipients-${email.id}[${idx}]`} control={control} label="Recipient" defaultValue={recipient} />

                              <button
                                onClick={() => {
                                  unregister(`recipients-${email.id}[${idx}`);
                                  const recipients = getValues(`recipients-${email.id}`) || [];
                                  setValue(`recipients-${email.id}`, recipients);
                                }}
                                title="Delete recipient"
                                className="absolute bg-white right-0 translate-x-3 translate-y-1"
                                type="button"
                              >
                                <Clear className="border-2 rounded-full text-red-400" />
                              </button>
                              {getRecipients(email.id)?.length - 1 === idx && (
                                <button
                                  className="absolute -right-12 top-6 w-fit"
                                  title="Add recipient"
                                  type="button"
                                  onClick={() => {
                                    const recipients = getValues(`recipients-${email.id}`) || [];
                                    if (!recipients.length) {
                                      register(`recipients-${email.id}`);
                                    }
                                    const newRecipients = [...recipients, ''];
                                    setValue(`recipients-${email.id}`, newRecipients);
                                  }}
                                >
                                  <Add className="ml-5 rounded border-2 text-bz-greenLight" />
                                </button>
                              )}
                            </li>
                          ))}
                        </ul>
                        {getRecipients(email.id)?.length === 0 && (
                          <button
                            className=""
                            title="Add recipient"
                            type="button"
                            onClick={() => {
                              const recipients = getValues(`recipients-${email.id}`) || [];
                              if (!recipients.length) {
                                register(`recipients-${email.id}`);
                              }
                              const newRecipients = [...recipients, ''];
                              setValue(`recipients-${email.id}`, newRecipients);
                            }}
                          >
                            <Add className="rounded border-2 text-bz-greenLight" />
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                  {email?.content_languages.map(
                    (contentLanguage, idx) =>
                      valueTabAdd === idx && <InputContainer name={`subject-${email.id}-${idx}`} control={control} label="Subject" defaultValue={contentLanguage.subject} />
                  )}
                </div>

                <div className="">
                  <EmailEditor ref={emailEditorRef} onLoad={onLoad} minHeight="75vh" />

                  <div className="flex justify-between mt-4">
                    <Button variant="contained" color="error" onClick={onDeleteEmailContent}>
                      Delete Context
                    </Button>
                    <Button type="submit" variant="contained">
                      Submit
                    </Button>
                  </div>
                </div>
              </TabPanel>
            ))}
          </form>
          {/* <DevTool control={control} /> */}
        </div>
        <ModalWrapper componentName="addEmailContent" header="Add Email Content" maxWidth="sm">
          <form onSubmit={handleSubmit(onPostContext)}>
            <InputContainer
              name="context"
              control={control}
              label="name"
              // errors={errors.name}
            />
            <InputContainer
              name="from_email"
              control={control}
              label="Email"
              // errors={errors.name}
            />
            <Button className="bg-blue-600 text-white w-fit h-fit ml-auto " type="submit" variant="filled">
              Submit
            </Button>
          </form>
        </ModalWrapper>
      </div>
    </>
  );
}
