/* eslint-disable */
import { DevTool } from '@hookform/devtools';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import InputBaseContainer from '@/components/Input/InputBaseContainer';
import ReactSelectSingleHR from '@/components/Input/ReactSelectSingleHR';
import { fetchFilterDepartment } from '@/hooks/useNewHrData';
import { getDefaultSelectObject, getOptionSelectWithKey } from '@/lib/OptionsInputHelper';
import { useQuery } from '@tanstack/react-query';
import useGet from '../../../hooks/useGet';
import { handleModal } from '../../../store/slices/modalSlice';

function DepartmentForm({ defaultValues, onFormSubmit, unitData, reload }) {
  console.log('defaultValues ', defaultValues);
  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: defaultValues?.name,
      business_unit: defaultValues?.business_unit?.id || '',
      manager: defaultValues?.manager?.id || '',
      parent: defaultValues?.parent?.id || null,
      team_leader: defaultValues?.team_leader?.id || '',
      locations: defaultValues?.locations[0]?.id || '',
      whistleblower_report_recipient: defaultValues?.whistleblower_report_recipient,
    },
  });
  const idBusinessUnit = watch('business_unit');
  const locationId = watch('locations');
  // console.log('idBusinessUnit ', idBusinessUnit)
  // console.log('locationId ', locationId)

  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.client.activeClient);

  const [isDisableLocations, setDisableLocations] = useState(true);
  const [optionsLocs, setOptionsLocs] = useState([]);
  console.log('🚀 ~ DepartmentForm ~ optionsLocs:', optionsLocs);
  const [allEmployees, setAllEmployees] = useState([]);
  console.log('🚀 ~ DepartmentForm ~ allEmployees:', allEmployees);

  const { data: locations, refetch: locationsRefetch } = useGet({
    url: `locations`,
    queryString: {
      max_size: true,
      business_unit: idBusinessUnit,
    },
    validate: 'locations',
    isEnabled: Boolean(idBusinessUnit),
  });

  // let optionsLocations

  useGet({
    url: `employee-management/employees/available_managers`,
    queryString: {
      department_id: defaultValues?.id,

      // max_size: true,
      // is_active: true,
    },
    isEnabled: Boolean(defaultValues?.id),
    validate: 'employees',
    onSuccess: (res) => {
      setAllEmployees(res.data);
    },
  });

  useEffect(() => {
    if (idBusinessUnit) {
      locationsRefetch();
    }
  }, [idBusinessUnit]);

  useEffect(() => {
    if (locations) {
      let optionsLocations = locations?.data?.results?.map((item) => ({
        name: item?.name,
        id: item?.id,
      }));

      setOptionsLocs(optionsLocations);
    }
  }, [locations]);

  const devLang = [];

  defaultValues?.category_languages?.map((l) => {
    devLang.push(l);
    return devLang;
  });

  const optionsUnit = unitData?.map((item) => ({
    name: item?.name,
    id: item?.id,
  }));

  const optionsEmployees = allEmployees?.map((item) => ({
    name: `${item?.first_name} ${item?.last_name}`,
    id: item?.id,
  }));

  const defUnit = {
    name: defaultValues?.business_unit?.name,
    value: defaultValues?.business_unit?.id,
  };
  const defMan = {
    name: defaultValues?.manager?.first_name || '-',
    value: defaultValues?.manager?.id || '',
  };
  const defLead = {
    name: defaultValues?.team_leader?.first_name,
    value: defaultValues?.team_leader?.id,
  };

  const defLocations = {
    name: locationId?.name,
    value: locationId?.id,
  };

  const onSubmit = (data) => {
    let dataLocations = [];

    if (JSON.stringify(defaultValues?.locations) === JSON.stringify(data?.locations)) {
      dataLocations.push(data?.locations?.map((v) => v.id));
    }

    let newData = {
      ...data,
      client: clientId,
      locations: data.locations ? [data.locations] : [],
    };

    onFormSubmit(newData);
    reload(true);
  };

  const handleCancel = () => {
    dispatch(handleModal({ id: '' }));
  };

  const [dataDepartment, setDataDepartment] = useState([]);

  // const sortAlphaUnit = optionUnit?.sort((a, b) => a.label.normalize().localeCompare(b.label.normalize()));
  const unitId = watch('business_unit');

  const queryKey = {
    client: clientId,
    business_unit: unitId,
    max_size: true,
  };

  useQuery(['Departments', { queryKey }], fetchFilterDepartment, {
    onSuccess: (e) => {
      const listData = e?.data?.results || e?.data;
      setDataDepartment(listData);
    },
    refetchOnWindowFocus: false,
  });

  const optionsDept = dataDepartment?.map((item) => ({
    name: item?.name,
    id: item?.id,
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full space-y-4">
        <InputBaseContainer name="name" control={control} label="Name" errors={errors.name} />
        <div className="mt-5 space-y-5 !w-full">
          <ReactSelectSingleHR
            name="business_unit"
            label="business unit"
            control={control}
            floatLabel={false}
            options={getOptionSelectWithKey(optionsUnit && optionsUnit.length > 0 ? [{ name: '-', id: '' }, ...optionsUnit] : [])}
            defaultValue={getDefaultSelectObject(
              watch('business_unit'),
              getOptionSelectWithKey(optionsUnit && optionsUnit.length > 0 ? [{ name: '-', id: '' }, ...optionsUnit] : [])
            )}
          />

          {locations && (
            <ReactSelectSingleHR
              name="locations"
              label="locations"
              control={control}
              floatLabel={false}
              options={getOptionSelectWithKey(optionsLocs && optionsLocs.length > 0 ? [{ name: '-', id: '' }, ...optionsLocs] : [])}
              defaultValue={getDefaultSelectObject(
                watch('locations'),
                getOptionSelectWithKey(optionsLocs && optionsLocs.length > 0 ? [{ name: '-', id: '' }, ...optionsLocs] : [])
              )}
            />
          )}
          {defaultValues?.id && (
            <ReactSelectSingleHR
              name="manager"
              label="manager"
              control={control}
              floatLabel={false}
              options={getOptionSelectWithKey(optionsEmployees && optionsEmployees.length > 0 ? [{ name: '-', id: '' }, ...optionsEmployees] : [])}
              defaultValue={getDefaultSelectObject(
                watch('manager'),
                getOptionSelectWithKey(optionsEmployees && optionsEmployees.length > 0 ? [{ name: '-', id: '' }, ...optionsEmployees] : [])
              )}
            />
          )}
          {defaultValues?.id && (
            <ReactSelectSingleHR
              name="team_leader"
              label="team leader"
              control={control}
              floatLabel={false}
              options={getOptionSelectWithKey(optionsEmployees && optionsEmployees.length > 0 ? [{ name: '-', id: '' }, ...optionsEmployees] : [])}
              defaultValue={getDefaultSelectObject(
                watch('team_leader'),
                getOptionSelectWithKey(optionsEmployees && optionsEmployees.length > 0 ? [{ name: '-', id: '' }, ...optionsEmployees] : [])
              )}
            />
          )}

          <ReactSelectSingleHR
            name="parent"
            label="parent"
            control={control}
            floatLabel={false}
            options={getOptionSelectWithKey(optionsDept && optionsDept.length > 0 ? [{ name: '-', id: '' }, ...optionsDept] : [])}
            defaultValue={getDefaultSelectObject(watch('parent'), getOptionSelectWithKey(optionsDept && optionsDept.length > 0 ? [{ name: '-', id: '' }, ...optionsDept] : []))}
          />
        </div>

        <div className="flex justify-end ml-auto gap-x-2">
          <Button onClick={handleCancel} className="flex" variant="outlined">
            Cancel
          </Button>
          <Button className=" flex" type="submit" variant="outlined">
            {defaultValues ? 'Update Department' : 'Create Department'}
          </Button>
        </div>
      </div>
      <DevTool control={control} />
    </form>
  );
}
export default DepartmentForm;
