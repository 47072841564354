import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
// import LayoutGlobalWarning from './LayoutGlobalWarning';

function Layout(props) {
  const [isLoaded, setIsLoaded] = useState(true);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(false);
    }, 300);
  }, []);

  const { children } = props;
  const html = (
    <StyledEngineProvider injectFirst>
      <Header isLoaded={isLoaded} />
      <div className="flex flex-1 overflow-x-hidden overflow-y-hidden">
        <Sidebar isLoaded={isLoaded} />
        <div className="relative flex flex-1 flex-col w-full h-auto max-h-[calc(100vh_-_56px)] overflow-y-auto bg-white">
          {isLoaded ? (
            <div className="flex items-center justify-center absolute  h-[100vh] w-full">
              <CircularProgress className="!text-[#2C6D47] " />
            </div>
          ) : (
            children
          )}
        </div>
      </div>
      {/* <LayoutGlobalWarning /> */}
    </StyledEngineProvider>
  );
  return <div>{isAuthenticated ? html : children}</div>;
}

export default withTranslation()(Layout);
