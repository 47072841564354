// /* eslint-disable */

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

yup.addMethod(yup.date, 'format', function (format) {
  return this.transform((value, originalValue) => {
    if (originalValue === '') return null;
    const parsedDate = moment(originalValue, format, true);
    return parsedDate.isValid() ? parsedDate.toDate() : new Error('Invalid date');
  });
});

const validationEmployee = yup
  .object()
  .shape({
    first_name: yup.string().required('First Name is required'),
    email: yup.string().required('Email is required'),
    employment_id: yup.string().required('Employment ID is required'),
    // gender: yup.string().required('Gender is required'),
    // marital_status: yup.string().required('Marital Status is required'),
    // hired_date: yup.date().required('Date is required').format('YYYY-MM-DD', { message: 'Please enter a valid date in the format yyyy-MM-dd' }),
    employment_histories: yup.array().of(
      yup.object().shape({
        business_unit: yup.string().required('Business Unit is required'),
        department: yup.string().required('Department Unit is required'),
      })
    ),
  })
  .required();

const schemaEmployee = yupResolver(validationEmployee);

export default schemaEmployee;
