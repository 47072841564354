/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material/';
import { Link } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import CRMApi from '../../../api/CRM/CRMCustomers';
import CardChart from '../../../components/Chart/CardChart';
import { exportToExcel } from '../../../helper/exportToExcel';
import BaseMainTitle from '../../../components/BaseMainTitle';
import BZHelmet from '../../../utils/BZHelmet';
import { getStaticPeriod, standardizeInputDate } from '../../../lib/StaticPeriod';

export default function Countries({ attributeReports }) {
  const now = new Date()
  const [reportData, setReportData] = useState();
  const clientId = useSelector((state) => state.client.activeClient);
  const [period, setPeriod] = useState({ start_date: `${now.getFullYear()}-01-01`, end_date: `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`, isNow: true })
  const [staticPeriod, setStaticPeriod] = useState("1")

  const getAttributeData = useCallback(
    (reportObject) => {
      const { getDynamicReport } = CRMApi();
      const timezoneOffset = new Date().getTimezoneOffset()
      const params = { client: clientId }
      let data = {
          start_date: standardizeInputDate(period.start_date, timezoneOffset),
          end_date: standardizeInputDate(period.end_date, timezoneOffset, period.isNow),
          report: reportObject.id
      }

      return new Promise((resolve, reject) => {
        getDynamicReport(params, data)
          .then((res) => {
            if (res.status === 200 && res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [clientId, period]
  );

  useEffect(() => {
    attributeReports.forEach((item) => {
      getAttributeData(item).then((res) => setReportData((data) => ({ ...data, [`report-${item.id}`]: res.results })));
    });
  }, [getAttributeData, attributeReports]);
  
  useEffect(() => {
    setPeriod(getStaticPeriod(staticPeriod))
  }, [staticPeriod])

  const handleDownloadTable = (data) => {
    let dataToExport = []
    let num = 1
    data.forEach(category => {
        category?.members?.forEach(member => {
            dataToExport.push({
                'No': num,
                'Company': member.member__name,
                'Description': category?.variable,
                'Timestamp': member.timestamp
            })
            num++
        })
    })

    exportToExcel(dataToExport, "Reports")
  }

  return reportData && (
    <>    
      <BZHelmet title="Countries" description="" content="" />  
      <BaseMainTitle title="Countries" />
      <div className="p-5">
        <div className="w-full px-3 mb-4">
          <div className='flex'>
            <div className='flex flex-col mr-4'>
                <label className='font-semibold'>Period</label>
                <select value={staticPeriod} className='h-[30px] border border-black rounded-sm' onChange={(e) => setStaticPeriod(e.target.value)}>
                    <option value="1">This year</option>
                    <option value="2">Past year</option>
                    <option value="3">Past 6 months</option>
                    <option value="4">Past 3 months</option>
                    <option value="5">Past month</option>
                    <option value="6">Past 2 years</option>
                    <option value="7">All time</option>
                    <option value="custom">Custom</option>
                </select>
            </div>

            <div className='flex flex-col mr-4'>
                <label className='font-semibold'>From</label>
                <input 
                  disabled={staticPeriod !== "custom"} 
                  type='date' value={period.start_date || ""} 
                  onChange={(e) => setPeriod({ ...period, start_date: e.target.value })} 
                  className={['h-[30px] px-1', staticPeriod === "custom" ? 'border border-black rounded-sm' : ''].join(' ')} 
                />
            </div>
            <div className='flex flex-col mr-4'>
                <label className='font-semibold'>To</label>
                <input 
                  disabled={staticPeriod !== "custom"} 
                  type='date' value={period.end_date || ""} 
                  onChange={(e) => setPeriod({ ...period, end_date: e.target.value })} 
                  className={['h-[30px] px-1', staticPeriod === "custom" ? 'border border-black rounded-sm' : ''].join(' ')} 
                />
            </div>
          </div>
        </div>
        <div>
          {
            attributeReports?.map((attributeReport) => {
              const reportId = attributeReport.id
              const attributeData = reportData["report-" + reportId]
              const labels = attributeData?.map(item => item.variable)
              const data = attributeData?.map(item => item.count)

              return (
                <div key={attributeReport.id} className='grid grid-cols-3 gap-4'>
                  <div>
                    <div className="p-4">
                      <button variant="outline-primary" className="border rounded-md p-1 border-blue-600" onClick={() => handleDownloadTable(attributeData)}>
                        <DownloadIcon className="text-blue-600" />
                      </button>
                    </div>
                    <TableContainer component={Paper} sx={{ height: "fit-content"}}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                          <TableCell className="font-semibold text-base">{attributeReport.title}</TableCell>
                          <TableCell className="font-semibold text-base" align="right">Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell className="p-0" colSpan={2}>
                              {
                                attributeData?.map((report, idx) => (
                                  <Accordion className='w-full'>
                                    <AccordionSummary
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography>{report.variable}</Typography>
                                      <Typography className='ml-auto'>{report.count}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <div className="h-full pb-4 ml-3 flex flex-col gap-y-2">
                                          {report.members.map(member => (<Link to={`/customers/detail/${member.member__id}`}><div key={member.member__id} className='hover:text-sky-600'>{member.member__name}</div></Link>))}
                                      </div>
                                    </AccordionDetails>
                                  </Accordion>
                                ))
                              }
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div className='col-span-2 border rounded-md shadow-lg'>
                    <CardChart
                      className=""
                      title={attributeReport.title}
                      labels={labels}
                      data={data}
                    />
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </>
  );
}
