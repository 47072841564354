import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { CircularProgress, InputAdornment, InputBase, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import _ from 'lodash';
import { handleModal } from '@/store/slices/modalSlice';
import BzThumbnail from '@/assets/simple.png'


function CollectionUpdateForm({
  searchLabel,
  listOfData = [],
  onSearch,
  search="",
  checked,
  listOfCollectionPaired = [],
  onSubmit,
  mode,
  modalMode,
  isFetching
}) {
  const dispatch = useDispatch();
  const [listComponents, setListComponents] = useState(listOfCollectionPaired);
  const [listPickComponents, setPickComponents] = useState(checked);

  const handleSelectedCollections = (value, collectionId, collectionData) => {
    let newSelectedCollections = _.cloneDeep(listComponents);
    let pickCollectionsClone = _.cloneDeep(listPickComponents);

    if (value === true) {
      newSelectedCollections.push(collectionData);
      pickCollectionsClone.push(collectionId);
    } else {
      newSelectedCollections = newSelectedCollections.filter((data) => data.id !== collectionId);
      pickCollectionsClone = pickCollectionsClone.filter((data) => data !== collectionId);
    }
      
    setListComponents([...newSelectedCollections]);
    setPickComponents([...pickCollectionsClone]);
  };

  const onSubmitModal = () => {
    onSubmit(listComponents, listPickComponents, modalMode);
    dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
  }

  const InputLabel = ({ children }) => <p className="font-bold">{children}</p>;

  return (
    <div className="w-full flex space-x-5 relative">
      <div className="w-[70%]">
        <div className="grid grid-cols-3 gap-6 pb-2">
          { !isFetching ?
            listOfData?.map((dt) => (
              <div key={dt.id} className="w-full border rounded-md shadow-sm">
                <div className="flex justify-between bg-[#2C6D47] p-4 rounded-t-md">
                  <div className="text-white flex items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4"
                      id={`location-pair-${dt?.id}`}
                      checked={listPickComponents?.includes(dt?.id)}
                      onChange={(e) => {
                        handleSelectedCollections(e.target.checked, dt.id, dt);
                      }}
                    />
                    <label className="text-lg ml-2" htmlFor={`location-pair-${dt.id}`}>
                      <p>{dt.name}</p>
                      {modalMode === "products" && <p>ID: {dt?.item_number}</p>}
                    </label>
                  </div>
                </div>
                <div className="flex gap-x-2 min-h-[150px] p-2 justify-center items-center">
                  <img className={`scale-90 max-h-[150px] origin-top-left ${!dt?.media_main && "grayscale"}`} src={dt?.media_main || BzThumbnail} />
                </div>
              </div>
            ))
          : 
            <CircularProgress />
        }
        </div>
      </div>
      <div className="w-[30%] space-y-5">
        <div className="w-full">
          <div>
            <InputLabel>{searchLabel || ""}</InputLabel>
          </div>
          <InputBase
            size="small"
            placeholder="search"
            endAdornment={<Search color="disabled" />}
            onChange={(v) => onSearch(v.target.value)}
            value={search}
            className="w-full h-[2.5rem] border border-[#BCB9B9] rounded px-4 bg-white hover:border-[#adacac]"
            />
        </div>
        <div className="w-full flex justify-end gap-x-3 pb-4 border-b">
          <button 
            className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white" 
            variant="contained" 
            onClick={onSubmitModal}
          >
            Submit
          </button>
        </div>
        <div className="space-y-3 px-2">
        {
          listComponents?.length > 0 && 
            listComponents.map((dt, idx) => (
              <div key={dt?.id} className="w-full border rounded-md shadow-sm">
                <div className="flex justify-between bg-[#2C6D47] p-4 rounded-md items-center text-white">
                  <div className=" flex items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4"
                      checked
                      onChange={(e) => {
                        handleSelectedCollections(e.target.checked, dt.id, dt);
                      }}
                      id={`location-pair-${dt?.id}`}
                    />
                    <label className="text-lg ml-2" htmlFor={`location-pair-${dt?.id}`}>{dt?.name}</label>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default CollectionUpdateForm