/* eslint-disable */

import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { Button, Checkbox } from '@mui/material';
import InputContainer from '../../Input/InputContainer';
import FileContatiner from '../../Input/FileContatiner';
import SelectAutocomplete from '../../Input/SelectAutocomplete';
import { fetchSectionCatalogueType } from '../../../hooks/useSettingsBzData';
import { getSelectOptionNoKey, getSelectOptionWithKey, generateSelectDefaultWithKey, getOnChangeSelect } from '../../../lib/OptionsInputHelper';

function SectionCatalogueForm({ defaultValues, onFormSubmit }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  const [metaLocked, setMetaLocked] = React.useState(false);

  const handleChange = (event) => {
    setMetaLocked(event.target.checked);
  };

  const dispatch = useDispatch();
  const clientList = useSelector((state) => state.client.clientList);
  const clientId = useSelector((state) => state.client.activeClient);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('id', defaultValues.id);
    formData.append('name', data.name ?? '');
    formData.append('client', data.client);
    formData.append('catalog_type', data.catalog_type.toLowerCase());
    formData.append('metadata_locked', metaLocked);
    if (data.zip_file) {
      formData.append('zip_file', data.zip_file[0]);
    }
    if (data.metadata_file) {
      formData.append('metadata_file', data.metadata_file[0]);
    }
    if (data.screenshot && data.screenshot[0] && data.screenshot[0] === 'h') {
      null;
    } else {
      if (data.screenshot) {
        formData.append('screenshot', data.screenshot[0]);
      }
    }
    onFormSubmit(formData);
  };

  const handleCancel = () => {
    dispatch(handleModal({ id: '' }));
  };

  const { data: SectionCatalogueType } = useQuery(['sectionCatalogueType'], fetchSectionCatalogueType);

  return (
    <div className="">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full space-y-4">
          <InputContainer name="name" control={control} label="Name" errors={errors.name} />
          <FileContatiner name="zip_file" label="Zip File" errors="" control={control} />
          <Controller
            name="client"
            control={control}
            render={({ field: { onChange } }) => (
              <SelectAutocomplete
                options={getSelectOptionWithKey(clientList)}
                defaultValue={generateSelectDefaultWithKey(defaultValues?.client, getSelectOptionWithKey(clientList))}
                placeHolder="Select Client"
                variant="outlined"
                onChange={(e, newValue) => onChange(getOnChangeSelect(newValue))}
              />
            )}
          />
          <Controller
            name="catalog_type"
            control={control}
            render={({ field: { onChange } }) => (
              <SelectAutocomplete
                options={getSelectOptionNoKey(SectionCatalogueType?.data)}
                defaultValue={generateSelectDefaultWithKey(defaultValues?.catalog_type.toLowerCase(), getSelectOptionNoKey(SectionCatalogueType?.data))}
                placeHolder="Catalog Type"
                variant="outlined"
                onChange={(e, newValue) => onChange(getOnChangeSelect(newValue))}
              />
            )}
          />
          <FileContatiner name="screenshot" label="Screenshot" errors="" control={control} />
          <FileContatiner name="metadata_file" label="Metadata" errors="" control={control} />
          <div className="flex items-center gap-x-3">
            <Checkbox checked={metaLocked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
            <div className="">Metadata Locked</div>
          </div>
          <div className="flex justify-end ml-auto gap-x-2">
            <Button onClick={handleCancel} className="flex" variant="outlined">
              Cancel
            </Button>
            <Button className=" flex" type="submit" variant="outlined">
              {defaultValues ? 'Update Section' : 'Create Section'}
            </Button>
          </div>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </div>
  );
}

export default SectionCatalogueForm;
